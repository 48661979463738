import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const ReceiptFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
      </style>
      <g
        id="svg_11"
        stroke="null">
        <path
          d="M82.72589600950113,1149.8673878093805 L20.71505688439241,1191.9712926441275 L20.71505688439241,33.04028225193849 C20.71505688439241,16.015982287329347 34.516039507788335,2.214999663933428 51.54033947239748,2.214999663933428 L848.4596738840186,2.214999663933428 C865.4838631653869,2.214999663933428 879.2849564720236,16.015982287329347 879.2849564720236,33.04028225193849 L879.2849564720236,1190.941938503846 L823.1652328433119,1150.1219592634284 L814.2286679738128,1143.6137846990669 L806.1189069137656,1151.1291767555317 L754.3789191270911,1199.0218150888527 L702.6389313404165,1151.1291767555317 L695.0294585291945,1144.078654310807 L686.2821620016181,1149.646021327599 L605.7612110861861,1200.903430183991 L541.3059325860106,1150.4097356897437 L533.0124373460852,1143.9126294494713 L524.7189421061598,1150.4097356897437 L461.4081283167922,1200.0068959327782 L403.3603026317679,1150.741785412415 L394.99818378249046,1143.6469896713338 L386.36489099303117,1150.4097356897437 L322.4143280713164,1200.5049705167853 L253.14654225722185,1150.1219592634284 L245.2360110307776,1144.3664307371223 L237.32547980433336,1150.1219592634284 L167.75774240742555,1200.7263369985662 L98.19011569375864,1150.1219592634284 L90.54622039461961,1144.5545922466363 L82.72589600950113,1149.8673878093805 z"
          fill={frameColor}
          strokeWidth="2.43"
          id="svg_2"
          stroke="#404040"></path>
        <rect
          x="95.81363582859865"
          y="76.31357860813966"
          width="708.3727416992188"
          height="708.3727416992188"
          fill="#FFFFFF"
          id="svg_10"
          stroke={frameBackgroundColor}
          strokeWidth="37"></rect>
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 0)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="998.39997240305"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
