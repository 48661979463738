import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";
import "../styles.scss";

export const cupFrame = (props: Omit<QRFRameProps, "frameType">): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />

      <path
        stroke="none"
        fillRule="evenodd"
        fill={frameColor}
        d="M 82 24.078125 C 82 21.722656 80.105469 19.808594 77.769531 19.808594 C 65.574219 19.808594 30.425781 19.808594 18.230469 19.808594 C 15.894531 19.808594 14 21.722656 14 24.078125 C 14 25.414062 14 26.886719 14 28.222656 C 14 30.582031 15.894531 32.492188 18.230469 32.492188 C 30.425781 32.492188 65.574219 32.492188 77.769531 32.492188 C 80.105469 32.492188 82 30.582031 82 28.222656 C 82 26.886719 82 25.414062 82 24.078125 Z M 82 24.078125 "
      />
      <path
        fill="none"
        strokeWidth="5.01602"
        strokeLinecap="round"
        stroke={frameColor}
        d="M 77.039062 32.492188 L 18.960938 32.492188 C 18.960938 32.492188 23.667969 84.089844 25.011719 98.808594 C 25.214844 101.007812 27.039062 102.6875 29.226562 102.6875 L 66.773438 102.6875 C 68.960938 102.6875 70.785156 101.007812 70.988281 98.808594 C 72.332031 84.089844 77.039062 32.492188 77.039062 32.492188 Z M 77.039062 32.492188 "
      />
      <path
        fill="none"
        strokeWidth="5.01602"
        strokeLinecap="round"
        stroke={frameColor}
        d="M 82 24.078125 C 82 21.722656 80.105469 19.808594 77.769531 19.808594 C 65.574219 19.808594 30.425781 19.808594 18.230469 19.808594 C 15.894531 19.808594 14 21.722656 14 24.078125 C 14 25.414062 14 26.886719 14 28.222656 C 14 30.582031 15.894531 32.492188 18.230469 32.492188 C 30.425781 32.492188 65.574219 32.492188 77.769531 32.492188 C 80.105469 32.492188 82 30.582031 82 28.222656 C 82 26.886719 82 25.414062 82 24.078125 Z M 82 24.078125 "
      />
      <path
        fill="none"
        strokeWidth="5.01602"
        strokeLinecap="round"
        stroke={frameColor}
        d="M 71.339844 19.808594 L 24.660156 19.808594 L 24.660156 15.582031 C 24.660156 13.222656 26.550781 11.3125 28.886719 11.3125 C 37.761719 11.3125 58.238281 11.3125 67.113281 11.3125 C 69.449219 11.3125 71.339844 13.222656 71.339844 15.582031 C 71.339844 17.699219 71.339844 19.808594 71.339844 19.808594 Z M 71.339844 19.808594 "
      />

      <path
        d="M20.5954 35.9505C20.4772 34.7033 21.4581 33.625 22.711 33.625H73.289C74.5419 33.625 75.5228 34.7033 75.4046 35.9505L69.3616 99.7005C69.2582 100.792 68.342 101.625 67.2461 101.625H28.7539C27.658 101.625 26.7418 100.792 26.6384 99.7005L20.5954 35.9505Z"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="48%"
        height="45px"
        style={{ borderRadius: "3px" }}
        x="25"
        y="34">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "3px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
