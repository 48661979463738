import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const brochureFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } =
    props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" stroke="#BFBFBF" />
      <path
        d="M30.3587 83.0838L14.1249 29.028C13.9006 28.2823 13.9807 27.4788 14.3473 26.7928C14.714 26.1067 15.3386 25.5958 16.0804 25.3711C24.7702 22.7446 51.2063 14.7526 59.8943 12.1248C60.6376 11.8998 61.4387 11.9816 62.1225 12.3495C62.8063 12.7174 63.3152 13.3426 63.5395 14.0883C64.1209 16.0251 64.7902 18.2519 64.7902 18.2519M81.6114 28.0289C81.6076 27.2509 81.2932 26.5063 80.742 25.9592C80.1892 25.4123 79.4431 25.1087 78.6679 25.114C69.5934 25.1738 41.9835 25.3546 32.9087 25.4128C32.1335 25.4182 31.3911 25.732 30.846 26.2866C30.3009 26.8412 29.998 27.5883 30.0037 28.3676C30.074 39.2467 30.3203 77.2062 30.3922 88.085C30.396 88.863 30.7104 89.6076 31.2616 90.1548C31.8144 90.7017 32.5605 91.0053 33.3357 90.9999C42.4105 90.9417 70.0204 90.7609 79.0949 90.7012C79.8701 90.6958 80.6125 90.3819 81.1576 89.8273C81.7029 89.2743 82.0056 88.5257 81.9999 87.7464C81.9296 76.8673 81.6833 38.9078 81.6114 28.0289Z"
        stroke={frameColor}
        stroke-width="3.57079"
        stroke-miterlimit="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.0138 28.4344C31.0062 27.3314 31.893 26.4305 32.996 26.4207L78.5575 26.0177C79.6636 26.0079 80.5676 26.8978 80.5751 28.0039L80.9862 87.9863C80.9938 89.0962 80.0962 90 78.9862 90H33.4248C32.3257 90 31.4325 89.113 31.4249 88.0138L31.0138 28.4344Z"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="50%"
        height="47px"
        style={{ borderRadius: "3px" }}
        x="32"
        y="34"
      >
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "3px",
          }}
        >
          {urlQr ? <img src={urlQr} width="100%" height="auto" /> : children}
        </div>
      </foreignObject>
      <foreignObject width="100%" height="18px" y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}
        >
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
