import React from "react";

import { QRFRameProps } from "../types";
import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const eyelashesFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M53.2934 28.459C53.8595 25.1521 57.4389 23.448 60.3769 22.8207C63.0136 22.2039 66.0195 21.9404 68.0891 20.2819C69.5133 19.1501 70.4777 17.5233 70.9407 15.6936C70.9622 15.5751 71.1519 15.5739 71.1716 15.6953C71.6306 17.7996 70.8931 20.0865 69.4636 21.7171C67.9818 23.3869 65.8747 24.2278 63.7711 24.6949C59.9895 25.5544 56.0786 25.351 53.5066 28.5488C53.4404 28.6454 53.2699 28.5775 53.2934 28.459Z"
        fill={frameColor}
      />
      <path
        d="M72.2611 12.7137C71.6768 13.0589 71.399 13.3391 71.0563 13.9274C70.7133 13.3391 70.4355 13.0589 69.8515 12.7137C70.4355 12.3685 70.7133 12.0886 71.0563 11.5C71.399 12.0886 71.6768 12.3685 72.2611 12.7137Z"
        fill={frameColor}
      />
      <path
        d="M75.7202 16.997C75.1362 17.3423 74.8581 17.6221 74.5154 18.2107C74.1727 17.6221 73.8947 17.3423 73.3106 16.997C73.8947 16.6516 74.1727 16.3717 74.5154 15.7831C74.8581 16.3717 75.1362 16.6516 75.7202 16.997Z"
        fill={frameColor}
      />
      <path
        d="M68.136 16.997C67.552 17.3423 67.2742 17.6221 66.9312 18.2107C66.5886 17.6221 66.3108 17.3423 65.7265 16.997C66.3108 16.6516 66.5886 16.3717 66.9312 15.7831C67.2742 16.3717 67.552 16.6516 68.136 16.997Z"
        fill={frameColor}
      />
      <path
        d="M77.8076 23.7116C76.761 25.0789 75.1609 25.9411 73.4721 26.1076C72.2291 26.236 70.155 25.9509 69.6079 24.6992C69.6351 24.6733 69.6638 24.6487 69.6905 24.6225C71.2168 25.4468 73.0176 25.5531 74.5519 24.9738C76.163 24.3595 77.3957 23.0188 77.8897 21.4707L77.6753 21.3845C76.9986 22.7795 75.6883 23.7875 74.287 24.0932C73.0286 24.3721 71.6885 24.0875 70.6874 23.4096C71.0538 22.8448 71.3348 22.225 71.5038 21.5572C71.5417 21.431 71.3528 21.354 71.2911 21.4662C66.8733 28.4037 58.3404 23.6583 53.1661 28.6529C53.0705 28.7427 53.1926 28.9018 53.3021 28.841C54.4216 28.2747 55.5839 27.9499 56.772 27.7575C57.0842 28.7351 57.6635 29.5626 58.3841 30.1309L58.5606 29.9808C58.155 29.2066 58.021 28.3562 58.1106 27.5916C58.1358 27.5893 58.1612 27.5875 58.1864 27.5853C58.8334 29.061 60.0844 30.1145 61.4449 30.5L61.54 30.2883C60.5246 29.6117 59.877 28.5455 59.683 27.4912C59.852 27.4837 60.0215 27.4762 60.1917 27.4689C61.0446 29.1007 62.6819 30.2506 64.4667 30.414L64.5134 30.1862C63.6653 29.8968 62.9395 29.3617 62.4058 28.7082C62.0794 28.3094 61.8255 27.8639 61.6548 27.4032C61.8502 27.3929 62.0454 27.3814 62.2406 27.3683C63.3545 29.459 65.7162 30.7238 67.9908 30.4128L67.9818 30.1804C66.9148 30.1062 65.9246 29.6589 65.1576 29.0074C64.5676 28.5083 64.1091 27.882 63.8323 27.2141C64.0309 27.1872 64.2286 27.1563 64.4258 27.1226C65.9241 29.6881 69.3506 30.6667 71.8098 29.0995L71.7095 28.89C70.4785 29.3265 69.1224 29.1965 68.0839 28.6295C67.2637 28.1855 66.6302 27.4648 66.2753 26.6723C66.4398 26.6185 66.6033 26.5591 66.7658 26.4955C67.5091 27.6232 68.7835 28.2632 70.0766 28.4216C71.4616 28.5807 72.7942 28.236 74.0759 27.8071L74.0215 27.5808C72.7755 27.7726 71.4519 27.8883 70.2493 27.532C69.3693 27.2747 68.4857 26.6668 68.0924 25.8476C68.1937 25.7875 68.292 25.7226 68.3909 25.6585C68.6509 26.0218 68.9861 26.3027 69.3236 26.5026C71.0665 27.4729 73.1961 27.3177 74.9524 26.5476C76.2082 25.9801 77.3104 25.0284 78 23.8403L77.8076 23.7116Z"
        fill={frameColor}
      />
      <path
        d="M42.7064 28.459C42.1405 25.1521 38.5611 23.448 35.6232 22.8207C32.9865 22.2039 29.9805 21.9404 27.9107 20.2819C26.4866 19.1501 25.5222 17.5233 25.0592 15.6936C25.0377 15.5751 24.8481 15.5739 24.8284 15.6953C24.3694 17.7996 25.107 20.0865 26.5362 21.7171C28.0182 23.3869 30.1254 24.2278 32.2288 24.6949C36.0104 25.5544 39.9211 25.351 42.4935 28.5488C42.5596 28.6454 42.7301 28.5775 42.7064 28.459Z"
        fill={frameColor}
      />
      <path
        d="M23.7389 12.7137C24.3231 13.0589 24.601 13.3391 24.9438 13.9274C25.2865 13.3391 25.5645 13.0589 26.1486 12.7137C25.5645 12.3685 25.2865 12.0886 24.9438 11.5C24.601 12.0886 24.3231 12.3685 23.7389 12.7137Z"
        fill={frameColor}
      />
      <path
        d="M20.2797 16.997C20.8639 17.3423 21.1418 17.6221 21.4846 18.2107C21.8274 17.6221 22.1052 17.3423 22.6893 16.997C22.1052 16.6516 21.8274 16.3717 21.4846 15.7831C21.1418 16.3717 20.8639 16.6516 20.2797 16.997Z"
        fill={frameColor}
      />
      <path
        d="M27.8639 16.997C28.448 17.3423 28.7259 17.6221 29.0686 18.2107C29.4114 17.6221 29.6893 17.3423 30.2735 16.997C29.6893 16.6516 29.4114 16.3717 29.0686 15.7831C28.7259 16.3717 28.448 16.6516 27.8639 16.997Z"
        fill={frameColor}
      />
      <path
        d="M18.1924 23.7116C19.2391 25.0789 20.8391 25.9411 22.5279 26.1076C23.7707 26.236 25.8451 25.9509 26.392 24.6992C26.3648 24.6733 26.3363 24.6487 26.3095 24.6225C24.7831 25.4468 22.9824 25.5531 21.448 24.9738C19.837 24.3595 18.6043 23.0188 18.1103 21.4707L18.3245 21.3845C19.0014 22.7795 20.3118 23.7875 21.7128 24.0932C22.9715 24.3721 24.3115 24.0875 25.3124 23.4096C24.9462 22.8448 24.6652 22.225 24.4963 21.5572C24.4582 21.431 24.6472 21.354 24.7089 21.4662C29.1265 28.4037 37.6595 23.6583 42.8336 28.6529C42.9295 28.7427 42.8074 28.9018 42.6976 28.841C41.5784 28.2747 40.4161 27.9499 39.228 27.7575C38.9158 28.7351 38.3365 29.5626 37.6158 30.1309L37.4394 29.9808C37.845 29.2066 37.979 28.3562 37.8894 27.5916C37.8642 27.5893 37.8386 27.5875 37.8134 27.5853C37.1667 29.061 35.9156 30.1145 34.5551 30.5L34.4599 30.2883C35.4753 29.6117 36.1231 28.5455 36.317 27.4912C36.1479 27.4837 35.9784 27.4762 35.8084 27.4689C34.9554 29.1007 33.3182 30.2506 31.5332 30.414L31.4865 30.1862C32.3347 29.8968 33.0606 29.3617 33.5941 28.7082C33.9207 28.3094 34.1744 27.8639 34.3451 27.4032C34.1498 27.3929 33.9545 27.3814 33.7592 27.3683C32.6455 29.459 30.2838 30.7238 28.0093 30.4128L28.0181 30.1804C29.0851 30.1062 30.0753 29.6589 30.8424 29.0074C31.4323 28.5083 31.8909 27.882 32.1677 27.2141C31.9691 27.1872 31.7714 27.1563 31.5741 27.1226C30.0758 29.6881 26.6494 30.6667 24.1902 29.0995L24.2905 28.89C25.5216 29.3265 26.8774 29.1965 27.9162 28.6295C28.7364 28.1855 29.3698 27.4648 29.7247 26.6723C29.5601 26.6185 29.3966 26.5591 29.2343 26.4955C28.4909 27.6232 27.2164 28.2632 25.9235 28.4216C24.5383 28.5807 23.2058 28.236 21.9242 27.8071L21.9785 27.5808C23.2245 27.7726 24.548 27.8883 25.7506 27.532C26.6308 27.2747 27.5142 26.6668 27.9076 25.8476C27.8063 25.7875 27.7079 25.7226 27.6091 25.6585C27.3492 26.0218 27.0139 26.3027 26.6762 26.5026C24.9335 27.4729 22.8039 27.3177 21.0476 26.5476C19.7917 25.9801 18.6896 25.0284 18 23.8403L18.1924 23.7116Z"
        fill={frameColor}
      />
      <rect
        x="17"
        y="39"
        width="60"
        height="61"
        rx="2"
        fill={frameBackgroundColor}
      />

      <foreignObject
        width="65%"
        height="65px"
        style={{ borderRadius: "3px" }}
        x="17"
        y="38">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "5px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
