import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const AsianFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 88 0.5 C 92.142188 0.5 95.5 3.857813 95.5 8 L 95.5 120 C 95.5 124.142188 92.142188 127.5 88 127.5 L 8 127.5 C 3.857813 127.5 0.5 124.142188 0.5 120 L 0.5 8 C 0.5 3.857813 3.857813 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 1012.5 812.5 C 1012.5 591.585938 827.816406 412.5 600 412.5 C 372.183594 412.5 187.5 591.585938 187.5 812.5 C 187.5 1033.414062 372.183594 1212.5 600 1212.5 C 827.816406 1212.5 1012.5 1033.414062 1012.5 812.5 Z M 1012.5 812.5 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 869.214844 275.242188 C 943.335938 274.59375 1017.449219 275.246094 1091.546875 277.199219 C 1104.488281 284.25 1109.6875 295.324219 1107.152344 310.421875 C 1109.6875 325.523438 1104.488281 336.597656 1091.546875 343.648438 C 1017.449219 345.605469 943.335938 346.253906 869.214844 345.605469 C 869.214844 322.148438 869.214844 298.699219 869.214844 275.242188 Z M 869.214844 275.242188 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 600.074219 279.152344 C 679.382812 279.152344 758.699219 279.152344 838.011719 279.152344 C 838.011719 300 838.011719 320.847656 838.011719 341.695312 C 590.804688 340.789062 343.765625 336.226562 96.894531 328.015625 C 92.5625 317.3125 91.914062 306.238281 94.945312 294.789062 C 101.707031 291.859375 108.859375 289.90625 116.398438 288.925781 C 278.285156 286.960938 439.511719 283.703125 600.074219 279.152344 Z M 600.074219 279.152344 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 869.214844 357.328125 C 942.039062 356.679688 1014.847656 357.332031 1087.648438 359.285156 C 1102.21875 366.320312 1108.71875 378.046875 1107.152344 394.464844 C 1108.71875 410.882812 1102.21875 422.609375 1087.648438 429.644531 C 1014.847656 431.597656 942.039062 432.25 869.214844 431.597656 C 869.214844 406.84375 869.214844 382.085938 869.214844 357.328125 Z M 869.214844 357.328125 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 600.074219 365.148438 C 679.382812 365.148438 758.699219 365.148438 838.011719 365.148438 C 838.011719 384.691406 838.011719 404.238281 838.011719 423.78125 C 590.804688 422.875 343.765625 418.316406 96.894531 410.101562 C 92.59375 400.714844 91.945312 390.945312 94.945312 380.785156 C 101.707031 377.855469 108.859375 375.902344 116.398438 374.921875 C 278.285156 372.957031 439.511719 369.699219 600.074219 365.148438 Z M 600.074219 365.148438 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 225.613281 427.691406 C 307.613281 427.609375 389.527344 430.21875 471.355469 435.507812 C 313.992188 504.078125 228.179688 624.601562 213.914062 797.078125 C 221.109375 933.378906 279.621094 1040.875 389.441406 1119.558594 C 562.324219 1218.015625 722.898438 1201.074219 871.164062 1068.742188 C 997.164062 927.535156 1016.664062 772.484375 929.671875 603.589844 C 881.8125 531.539062 818.753906 476.8125 740.496094 439.417969 C 754.796875 438.113281 769.097656 436.8125 783.402344 435.507812 C 852.335938 437.429688 921.25 440.039062 990.132812 443.328125 C 1124.820312 606.089844 1158.628906 789.15625 1091.546875 992.519531 C 1078.464844 1020.699219 1064.816406 1048.714844 1050.59375 1076.5625 C 1017.808594 1125.058594 979.449219 1168.707031 935.523438 1207.507812 C 781.550781 1323.789062 611.230469 1353.761719 424.546875 1297.414062 C 385.867188 1281.4375 348.164062 1263.199219 311.429688 1242.6875 C 289.589844 1222.601562 266.183594 1204.363281 241.21875 1187.964844 C 80.585938 1022.148438 36.378906 828.007812 108.597656 605.542969 C 121.679688 577.363281 135.332031 549.351562 149.554688 521.503906 C 165.570312 505.445312 178.574219 487.203125 188.558594 466.777344 C 200.390625 452.976562 212.742188 439.945312 225.613281 427.691406 Z M 225.613281 427.691406 "
        />

        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="600"
          y="1450.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
