import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const garlandFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <rect
        x="18"
        y="44.3699"
        width="60"
        height="60"
        rx="2"
        fill={frameBackgroundColor}
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.873 1.19341C75.873 1.42751 75.873 1.66161 75.873 1.8957C64.6499 7.22635 52.755 9.18271 40.1885 7.7648C33.0569 6.94473 26.3274 4.98835 20 1.8957C20 1.66161 20 1.42751 20 1.19341C20.2293 1.032 20.484 0.981836 20.7639 1.04292C36.0854 8.0099 51.8361 8.94624 68.0159 3.85207C70.3981 3.03272 72.7261 2.09634 75 1.04292C75.3272 0.953564 75.6181 1.00373 75.873 1.19341Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6369 3.8019C21.5642 3.66814 21.5642 3.66814 21.6369 3.8019Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.2361 3.70158C74.2954 3.7076 74.3317 3.74103 74.3453 3.8019C73.2252 6.23913 72.1157 8.68038 71.0169 11.1257C69.3254 12.6808 67.634 14.2359 65.9425 15.7909C65.8697 15.8578 65.797 15.8578 65.7242 15.7909C65.2149 14.6873 64.7057 13.5837 64.1965 12.4801C67.2157 9.70441 70.2348 6.92878 73.254 4.15304C73.5914 3.99795 73.9188 3.84746 74.2361 3.70158Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6369 3.8019C22.6166 3.89309 22.7257 4.19407 21.9643 4.70484C21.7895 4.42349 21.6804 4.12252 21.6369 3.8019Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.6012 4.60451C24.872 5.05466 26.1452 5.50613 27.4206 5.95892C26.2748 7.01235 25.129 8.06578 23.9831 9.11921C23.4977 7.9806 22.9703 6.86035 22.4008 5.75827C22.8232 5.38683 23.2233 5.00224 23.6012 4.60451Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.1984 5.3068C70.3652 5.30853 70.4198 5.37542 70.3621 5.50745C68.1448 7.46231 65.944 9.43543 63.7599 11.4267C63.467 11.0243 63.3034 10.5896 63.2689 10.1225C64.6693 8.83498 66.0698 7.54739 67.4703 6.2599C68.4202 6.00828 69.3297 5.69059 70.1984 5.3068Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4028 6.31006C28.9562 6.42945 29.5018 6.57994 30.0397 6.76153C28.3482 8.31659 26.6567 9.87165 24.9653 11.4267C24.7049 10.9442 24.5593 10.4426 24.5288 9.92182C25.8421 8.73114 27.1335 7.52722 28.4028 6.31006Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.0695 6.91202C65.1813 6.95185 65.1995 7.01867 65.124 7.11268C64.3586 7.83312 63.5765 8.53541 62.7778 9.21953C62.4944 8.70516 62.2761 8.17012 62.123 7.61431C63.1448 7.47877 64.1269 7.24471 65.0695 6.91202Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.0218 7.11268C31.9346 7.25845 32.8439 7.42569 33.75 7.61431C33.3348 8.77569 32.8437 9.91269 32.2768 11.0254C30.5656 12.6153 28.8378 14.1871 27.0933 15.7408C26.4934 14.6368 25.9478 13.4998 25.4564 12.3297C27.3138 10.5886 29.169 8.84963 31.0218 7.11268Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.6072 8.91855C43.7136 9.05851 45.8234 9.12543 47.9365 9.11921C50.0496 9.10877 52.1593 9.05861 54.2659 8.96872C56.7438 11.1949 57.253 13.7532 55.7937 16.6437C55.6845 16.7774 55.5754 16.7774 55.4663 16.6437C54.8311 16.0763 54.2127 15.4911 53.6111 14.888C53.477 14.8096 53.3315 14.7595 53.1746 14.7375C52.2719 15.4333 51.3989 16.1689 50.5556 16.9447C49.8448 16.2412 49.1174 15.5555 48.373 14.888C48.2389 14.8096 48.0934 14.7595 47.9365 14.7375C47.0338 15.4333 46.1608 16.1689 45.3175 16.9447C44.6067 16.2412 43.8793 15.5555 43.1349 14.888C43.0008 14.8096 42.8553 14.7595 42.6984 14.7375C41.8655 15.386 41.047 16.0548 40.2431 16.744C39.7625 16.1894 39.4715 15.5539 39.37 14.8378C38.9762 12.496 39.722 10.5229 41.6072 8.91855ZM51.8651 10.5238C52.1309 10.5054 52.3674 10.5723 52.5744 10.7244C52.8899 11.327 53.1627 11.9457 53.3929 12.5805C53.3394 12.7793 53.2303 12.9466 53.0655 13.0821C52.0834 13.149 51.1012 13.149 50.1191 13.0821C49.8146 12.8604 49.7237 12.5761 49.8462 12.2293C50.4961 11.6241 51.1691 11.0556 51.8651 10.5238ZM43.3532 10.6241C43.6609 10.5988 43.9519 10.649 44.2262 10.7746C44.8594 11.2562 45.4596 11.7745 46.0268 12.3297C46.1121 12.6063 46.0576 12.8571 45.8631 13.0821C44.9285 13.2433 43.9828 13.2767 43.0258 13.1824C42.461 12.9299 42.3336 12.5453 42.6439 12.0287C42.8501 11.5413 43.0866 11.0731 43.3532 10.6241Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.9127 13.5336C30.9933 13.5204 31.0661 13.5372 31.131 13.5837C30.4797 15.0116 29.8067 16.4329 29.1121 17.8476C28.7433 18.0907 28.3614 18.1075 27.9663 17.8978C27.7844 17.5299 27.6025 17.1621 27.4206 16.7942C28.6067 15.7205 29.7707 14.6337 30.9127 13.5336Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.5437 13.7342C69.6393 13.7211 69.7121 13.7545 69.7619 13.8345C69.1511 15.1347 68.5509 16.4389 67.9613 17.7473C67.6821 18.0331 67.3365 18.1168 66.9246 17.9981C66.5266 17.6517 66.3266 17.2337 66.3244 16.744C67.4196 15.754 68.4927 14.7507 69.5437 13.7342Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.5893 15.9414C43.4973 16.6757 44.4067 17.4114 45.3175 18.1486C46.2202 17.4528 47.0932 16.7171 47.9365 15.9414C48.7749 16.6956 49.6297 17.4313 50.501 18.1486C50.6174 18.0793 50.7448 18.0291 50.883 17.9981C51.6303 17.2943 52.3941 16.6088 53.1746 15.9414C53.8556 16.4501 54.4375 17.0354 54.9207 17.6971C54.0868 18.6836 52.9955 19.3191 51.6468 19.6033C49.3234 19.7647 46.9954 19.7983 44.6627 19.7036C43.1708 19.4278 41.9159 18.7756 40.8978 17.7473C40.825 17.6804 40.825 17.6135 40.8978 17.5466C41.4838 17.0247 42.0476 16.4897 42.5893 15.9414Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.873 20.5564C75.873 20.7905 75.873 21.0246 75.873 21.2587C67.1594 25.4644 57.8653 27.5712 47.9911 27.5793C38.1202 27.5302 28.7899 25.4569 20 21.359C20 21.0581 20 20.7571 20 20.4561C20.2808 20.3503 20.5718 20.3335 20.873 20.4059C36.4526 27.4428 52.4215 28.2789 68.7798 22.9141C70.8684 22.1882 72.9054 21.3521 74.8909 20.4059C75.2535 20.3124 75.5809 20.3626 75.873 20.5564Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.0536 23.9675C72.1991 23.9675 72.3446 23.9675 72.4901 23.9675C73.4096 27.466 74.3554 30.9608 75.3274 34.4516C75.2911 34.5018 75.2546 34.552 75.2183 34.6021C74.1915 34.2875 73.1729 33.953 72.1627 33.5989C72.2857 31.6369 71.3036 30.4162 69.2163 29.9369C67.6156 29.845 66.4698 30.4637 65.7788 31.793C65.3047 33.0259 65.5411 34.1295 66.4881 35.1038C65.8737 35.8025 65.219 36.4714 64.5238 37.1103C63.9498 35.4677 63.4769 33.7956 63.1052 32.094C65.5125 31.6158 66.5857 30.2446 66.3244 27.9806C66.1479 27.3212 65.8024 26.7527 65.2877 26.275C66.6274 25.8477 67.9732 25.4298 69.3254 25.0209C70.268 24.7162 71.1774 24.365 72.0536 23.9675Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2738 24.3688C25.2322 24.3805 26.087 25.2834 25.8383 27.0776C25.1832 28.4603 24.0737 28.9118 22.5099 28.432C22.3927 28.3579 22.3199 28.2576 22.2917 28.1311C22.6624 26.8848 22.9898 25.6307 23.2738 24.3688Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5476 25.0711C29.0546 25.9148 31.6009 26.6337 34.1865 27.2281C33.5928 29.2949 32.9744 31.3516 32.3314 33.3982C31.2998 33.1213 30.3176 33.2384 29.3849 33.7494C29.1109 33.9348 28.8381 34.1187 28.5665 34.3011C27.9538 33.7545 27.3536 33.1859 26.7659 32.5956C25.9757 32.8712 25.1755 33.1054 24.3651 33.2979C24.1621 31.7559 23.2527 30.7359 21.6369 30.2379C21.7396 29.9097 21.8669 29.592 22.0189 29.2848C24.0446 29.9591 25.5724 29.424 26.6022 27.6796C26.9593 26.8048 26.9412 25.9352 26.5476 25.0711Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M63.5417 26.6763C64.4235 26.8006 64.9873 27.2689 65.2332 28.0809C65.5544 29.9421 64.6996 30.9787 62.6687 31.191C62.3815 29.8507 62.0177 28.5297 61.5774 27.2281C62.2383 27.0431 62.893 26.8591 63.5417 26.6763Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.3889 28.2816C45.6267 28.6742 49.8646 28.6742 54.1022 28.2816C54.2659 28.2983 54.4296 28.315 54.5933 28.3317C56.29 29.9422 56.7811 31.8316 56.0665 34.0002C55.4727 35.3151 54.4725 36.2682 53.0655 36.8595C52.9381 36.9766 52.8109 37.0935 52.6836 37.2106C52.6472 38.3142 52.6108 39.4178 52.5744 40.5214C52.2024 41.47 51.4931 41.7876 50.4464 41.4745C50.2783 41.3161 50.1509 41.1321 50.0645 40.9227C50.0282 39.5515 49.9917 38.1805 49.9554 36.8093C49.6643 36.5417 49.3734 36.5417 49.0824 36.8093C49.046 38.1136 49.0096 39.4178 48.9732 40.722C48.803 41.4775 48.3119 41.7283 47.5 41.4745C47.3727 41.3574 47.2454 41.2404 47.1181 41.1233C47.0817 39.7857 47.0453 38.4479 47.0089 37.1103C46.835 36.5834 46.5076 36.483 46.0268 36.8093C45.9905 38.1805 45.954 39.5515 45.9177 40.9227C45.6156 41.4917 45.1245 41.6757 44.4445 41.4745C43.809 41.2583 43.4634 40.8402 43.4078 40.2204C43.3714 39.1837 43.335 38.147 43.2986 37.1103C40.2965 35.7706 39.1326 33.597 39.8066 30.5891C40.1156 29.6849 40.643 28.9157 41.3889 28.2816ZM44.3353 30.6894C45.5115 30.7168 46.2027 31.2686 46.4087 32.3448C46.0222 33.7096 45.1128 34.1444 43.6806 33.649C42.8715 33.0175 42.7078 32.265 43.1895 31.3917C43.5167 31.0668 43.8986 30.8328 44.3353 30.6894ZM50.9921 30.6894C52.0156 30.6787 52.6885 31.1134 53.0109 31.9936C52.9456 33.6547 52.0544 34.2065 50.3373 33.649C49.4915 32.9632 49.3642 32.1773 49.9554 31.2914C50.2656 31.0242 50.6111 30.8235 50.9921 30.6894Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.125 30.89C70.0238 30.6973 71.006 31.4665 71.0715 33.1976C70.3964 33.0576 69.7416 32.8569 69.1072 32.5956C68.548 33.1599 67.9659 33.7117 67.3611 34.251C67.2884 34.3179 67.2157 34.3179 67.1429 34.251C66.1911 32.8131 66.5185 31.6928 68.125 30.89Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4187 31.2914C22.7889 31.5985 23.4255 32.4011 23.3284 33.6992C22.4516 34.0293 21.5604 34.3302 20.6548 34.6021C20.7842 33.4886 21.0388 32.385 21.4187 31.2914Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8036 34.2008C31.2054 34.1843 31.6054 34.2009 32.004 34.251C32.1052 34.2899 32.178 34.3568 32.2222 34.4516C31.9722 35.3557 31.6812 36.2419 31.3492 37.1103C30.6811 36.4323 30.0264 35.7468 29.3849 35.0536C29.7517 34.6176 30.2246 34.3334 30.8036 34.2008Z"
        fill={frameColor}
      />
      <foreignObject
        width="52%"
        height="49px"
        style={{ borderRadius: "3px" }}
        x="23"
        y="50">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="105">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
