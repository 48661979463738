import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const ticketsFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />

      <rect
        x="10"
        y="10"
        width="60"
        height="60"
        rx="2"
        fill={frameBackgroundColor}
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M85 68.4062C85 68.75 85 69.0938 85 69.4375C83.5018 70.275 83.1867 71.4208 84.0547 72.875C84.3104 73.1603 84.6255 73.3322 85 73.3906C85 73.849 85 74.3073 85 74.7656C83.5018 75.6031 83.1867 76.7489 84.0547 78.2031C84.3104 78.4884 84.6255 78.6603 85 78.7188C85 79.2344 85 79.75 85 80.2656C84.6255 80.3241 84.3104 80.4959 84.0547 80.7812C83.1843 82.225 83.4994 83.3709 85 84.2188C85 84.5625 85 84.9062 85 85.25C83.5817 85.6082 82.7223 86.4963 82.4219 87.9141C71.8873 92.4282 61.3455 96.9256 50.7969 101.406C50.4253 100.892 49.967 100.462 49.4219 100.117C48.7287 100.057 48.0698 100.028 47.4453 100.031C47.3594 99.7734 47.2734 99.5156 47.1875 99.2578C48.3735 97.174 47.8006 95.8277 45.4688 95.2188C45.3061 94.9508 45.1915 94.6643 45.125 94.3594C46.2735 92.2754 45.7005 90.9291 43.4062 90.3203C43.2125 90.019 43.0407 89.7038 42.8906 89.375C43.8629 88.1342 43.8056 86.9596 42.7188 85.8516C42.1042 85.6438 41.5312 85.386 41 85.0781C41 84.849 41 84.6197 41 84.3906C41.4393 84.2114 41.8117 83.9248 42.1172 83.5312C42.3689 82.7831 42.4549 82.0382 42.375 81.2969C43.375 80.8827 44.349 80.4243 45.2969 79.9219C45.4115 79.5781 45.526 79.2344 45.6406 78.8906C46.9333 78.1953 47.3057 77.164 46.7578 75.7969C46.4336 75.3256 46.0039 75.0391 45.4688 74.9375C45.4142 74.4095 45.4715 73.8939 45.6406 73.3906C46.9333 72.6953 47.3057 71.664 46.7578 70.2969C46.4336 69.8256 46.0039 69.5391 45.4688 69.4375C45.4174 69.0759 45.4747 68.7321 45.6406 68.4062C46.9811 68.1544 47.7259 67.3523 47.875 66C59.5053 66 71.1354 66 82.7656 66C82.9091 67.3471 83.6539 68.1491 85 68.4062Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.0547 70.2109C60.4506 70.2109 69.8463 70.2109 79.2422 70.2109C79.2422 74.5651 79.2422 78.9193 79.2422 83.2734C69.8463 83.2734 60.4506 83.2734 51.0547 83.2734C51.0547 78.9193 51.0547 74.5651 51.0547 70.2109Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.0391 71.4141C78.0391 74.9662 78.0391 78.5182 78.0391 82.0703C69.4453 82.0703 60.8516 82.0703 52.2578 82.0703C52.2578 78.5182 52.2578 74.9662 52.2578 71.4141C60.8516 71.4141 69.4453 71.4141 78.0391 71.4141Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.3672 74.1641C62.1296 74.1142 62.8745 74.2002 63.6016 74.4219C63.8626 74.6541 64.0917 74.9119 64.2891 75.1953C64.06 75.3672 63.8307 75.5391 63.6016 75.7109C62.5676 74.7205 61.6797 74.835 60.9375 76.0547C60.6467 77.6899 61.3057 78.4061 62.9141 78.2031C63.2757 78.0672 63.5048 77.8094 63.6016 77.4297C63.8307 77.6016 64.06 77.7734 64.2891 77.9453C63.2853 79.2878 62.0822 79.4884 60.6797 78.5469C59.3934 76.841 59.6225 75.38 61.3672 74.1641Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.3203 74.3359C55.5807 74.3359 56.8412 74.3359 58.1016 74.3359C58.1016 74.6224 58.1016 74.9088 58.1016 75.1953C57.5859 75.1953 57.0703 75.1953 56.5547 75.1953C56.5547 76.513 56.5547 77.8307 56.5547 79.1484C56.2682 79.1484 55.9818 79.1484 55.6953 79.1484C55.6953 77.8307 55.6953 76.513 55.6953 75.1953C55.237 75.1953 54.7787 75.1953 54.3203 75.1953C54.3203 74.9088 54.3203 74.6224 54.3203 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.4453 74.3359C58.7318 74.3359 59.0182 74.3359 59.3047 74.3359C59.3047 75.9401 59.3047 77.5443 59.3047 79.1484C59.0182 79.1484 58.7318 79.1484 58.4453 79.1484C58.4453 77.5443 58.4453 75.9401 58.4453 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.6328 74.3359C64.9193 74.3359 65.2057 74.3359 65.4922 74.3359C65.4638 74.9687 65.4924 75.5989 65.5781 76.2266C66.0329 75.6284 66.5198 75.0555 67.0391 74.5078C67.44 74.2787 67.8412 74.2787 68.2422 74.5078C67.569 75.1515 66.9961 75.8676 66.5234 76.6562C67.0856 77.5058 67.7159 78.3078 68.4141 79.0625C68.0703 79.1771 67.7266 79.1771 67.3828 79.0625C66.7751 78.5123 66.2307 77.9107 65.75 77.2578C65.5068 77.8522 65.4209 78.4824 65.4922 79.1484C65.2057 79.1484 64.9193 79.1484 64.6328 79.1484C64.6328 77.5443 64.6328 75.9401 64.6328 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.8516 74.3359C71.8516 74.5651 71.8516 74.7943 71.8516 75.0234C71.0726 74.9687 70.3277 75.026 69.6172 75.1953C69.6172 75.5391 69.6172 75.8828 69.6172 76.2266C70.4193 76.2266 71.2213 76.2266 72.0234 76.2266C72.0234 76.513 72.0234 76.7995 72.0234 77.0859C71.2213 77.0859 70.4193 77.0859 69.6172 77.0859C69.6172 77.487 69.6172 77.888 69.6172 78.2891C70.3277 78.4583 71.0726 78.5156 71.8516 78.4609C71.8516 78.6901 71.8516 78.9193 71.8516 79.1484C70.8203 79.1484 69.7891 79.1484 68.7578 79.1484C68.7578 77.5443 68.7578 75.9401 68.7578 74.3359C69.7891 74.3359 70.8203 74.3359 71.8516 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.3672 74.3359C73.6275 74.3359 74.8881 74.3359 76.1484 74.3359C76.1484 74.6224 76.1484 74.9088 76.1484 75.1953C75.6328 75.1953 75.1172 75.1953 74.6016 75.1953C74.6016 76.513 74.6016 77.8307 74.6016 79.1484C74.315 79.1484 74.0287 79.1484 73.7422 79.1484C73.7422 77.8307 73.7422 76.513 73.7422 75.1953C73.2838 75.1953 72.8256 75.1953 72.3672 75.1953C72.3672 74.9088 72.3672 74.6224 72.3672 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.8516 74.3359C72.015 74.5798 72.0723 74.8662 72.0234 75.1953C71.2213 75.1953 70.4193 75.1953 69.6172 75.1953C70.3277 75.026 71.0726 74.9687 71.8516 75.0234C71.8516 74.7943 71.8516 74.5651 71.8516 74.3359Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.6172 78.2891C70.4193 78.2891 71.2213 78.2891 72.0234 78.2891C72.0723 78.6182 72.015 78.9045 71.8516 79.1484C71.8516 78.9193 71.8516 78.6901 71.8516 78.4609C71.0726 78.5156 70.3277 78.4583 69.6172 78.2891Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.0391 71.4141C78.2104 74.9937 78.2678 78.603 78.2109 82.2422C69.5307 82.2992 60.8797 82.242 52.2578 82.0703C60.8516 82.0703 69.4453 82.0703 78.0391 82.0703C78.0391 78.5182 78.0391 74.9662 78.0391 71.4141Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.0234 87.7422C60.14 87.5724 59.2232 87.5151 58.2734 87.5703C59.1751 87.373 60.2063 87.3443 61.3672 87.4844C61.2737 87.6076 61.159 87.6935 61.0234 87.7422Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.7578 87.5703C69.8154 87.3721 70.9038 87.3434 72.0234 87.4844C71.8469 87.5725 71.675 87.6585 71.5078 87.7422C70.6244 87.5724 69.7076 87.5151 68.7578 87.5703Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.7578 87.5703C69.7076 87.5151 70.6244 87.5724 71.5078 87.7422C65.0176 90.4427 58.5436 93.1927 52.0859 95.9922C51.8627 95.8908 51.7195 95.7189 51.6562 95.4766C50.5718 92.8527 49.4546 90.2461 48.3047 87.6562C48.7734 87.5157 49.2317 87.5442 49.6797 87.7422C50.6651 89.9551 51.5818 92.1895 52.4297 94.4453C57.8645 92.1556 63.3071 89.864 68.7578 87.5703Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.0234 87.7422C60.14 87.5724 59.2232 87.5151 58.2734 87.5703C59.1368 88.193 60.0534 88.2502 61.0234 87.7422Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.3828 87.5703C56.7266 87.5703 57.0703 87.5703 57.4141 87.5703C57.477 87.9837 57.5916 88.3847 57.7578 88.7734C57.6096 89.1631 57.3232 89.335 56.8984 89.2891C56.7266 88.7162 56.5547 88.1432 56.3828 87.5703Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.4609 87.5703C53.7991 87.5292 54.1141 87.5865 54.4062 87.7422C54.7213 88.4869 55.0365 89.2318 55.3516 89.9766C55.0992 90.2916 54.8127 90.3488 54.4922 90.1484C54.0986 89.3047 53.7549 88.4453 53.4609 87.5703Z"
        fill="white"
      />

      <foreignObject
        width="51%"
        height="49px"
        style={{ borderRadius: "3px" }}
        x="15"
        y="15">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
