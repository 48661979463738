import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const cartFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        opacity="0.974"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 34C11.8574 34 15.7148 34 19.5723 34C22.5988 49.451 25.6847 64.8904 28.8301 80.3183C44.4653 80.4985 60.1007 80.5241 75.7363 80.3955C77.9732 70.1306 80.2105 59.8634 82.4482 49.5938C83.9639 49.9641 85.4811 50.3243 87 50.6746C87 50.8804 87 51.0864 87 51.2922C84.4873 62.524 82.0701 73.7948 79.748 85.1045C75.736 85.0787 71.7243 85.1045 67.7129 85.1817C71.7658 86.5219 73.5402 89.3267 73.0361 93.5962C72.2622 96.4034 70.4878 98.2046 67.7129 99C66.6842 99 65.6556 99 64.6269 99C59.9271 97.2624 58.3069 93.9688 59.7666 89.1188C60.8033 87.0936 62.4234 85.7812 64.6269 85.1817C56.3978 85.0787 48.1686 85.0787 39.9395 85.1817C43.9924 86.5219 45.7668 89.3267 45.2627 93.5962C44.4887 96.4034 42.7143 98.2046 39.9395 99C38.9108 99 37.8822 99 36.8535 99C32.1536 97.2624 30.5335 93.9688 31.9932 89.1188C33.0299 87.0936 34.65 85.7812 36.8535 85.1817C32.8421 85.1045 28.8304 85.0787 24.8184 85.1045C21.7883 69.7129 18.728 54.3249 15.6377 38.9406C13.1287 38.6354 10.5828 38.5326 8 38.6318C8 37.0879 8 35.5439 8 34ZM37.625 89.8907C39.8086 89.6825 40.7602 90.6604 40.4795 92.8242C39.5745 94.329 38.3658 94.6121 36.8535 93.6734C35.8361 92.1316 36.0933 90.8708 37.625 89.8907ZM65.3984 89.8907C67.582 89.6825 68.5336 90.6604 68.2529 92.8242C67.348 94.329 66.1392 94.6121 64.6269 93.6734C63.6095 92.1316 63.8667 90.8708 65.3984 89.8907Z"
        fill={frameColor}
      />
      <rect
        x="27"
        y="17"
        width="50"
        height="50"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="45%"
        height="42px"
        style={{ borderRadius: "3px" }}
        x="30"
        y="21">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="99">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
