import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const WaiterFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 88 0.5 C 92.142083 0.5 95.5 3.857917 95.5 8 L 95.5 120 C 95.5 124.142083 92.142083 127.5 88 127.5 L 8 127.5 C 3.857917 127.5 0.5 124.142083 0.5 120 L 0.5 8 C 0.5 3.857917 3.857917 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(9.375,0,0,9.375,0,0)"
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 711.335938 712.5 C 712.769531 712.5 712.601562 713.46875 712.277344 713.738281 C 711.78125 714.164062 706.496094 717.875 706.496094 717.875 C 706.496094 717.933594 706.433594 717.984375 706.433594 718.042969 C 703.796875 722.9375 691.414062 723.417969 690.09375 723.417969 C 689.816406 726.539062 687.125 728.960938 683.820312 728.960938 L 225.515625 728.960938 C 222.265625 728.960938 219.570312 726.539062 219.242188 723.417969 C 217.921875 723.417969 205.535156 722.9375 202.945312 718.042969 C 202.894531 717.984375 202.894531 717.933594 202.894531 717.875 C 202.894531 717.875 197.609375 714.164062 197.117188 713.738281 C 196.785156 713.46875 196.566406 712.5 198.054688 712.5 Z M 711.335938 712.5 "
        />
        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 577.285156 861.0625 C 570.296875 846.917969 568.257812 836.316406 569.527344 819.378906 C 570.792969 802.378906 570.792969 784.574219 573.597656 769.570312 C 576.125 756.230469 580.035156 746.976562 585.429688 741.761719 C 588.128906 739.125 592.082031 738.480469 593.679688 735.039062 C 595.273438 731.648438 591.96875 728.582031 585.101562 727.9375 C 572.664062 726.859375 563.632812 731.058594 558.074219 740.578125 C 545.085938 762.902344 552.738281 803.941406 510.027344 786.621094 C 485.15625 777.371094 487.136719 766.394531 471.566406 748.808594 C 462.980469 739.074219 452.464844 729.390625 444.652344 725.734375 C 441.023438 724.007812 437.996094 723.578125 435.957031 725.085938 C 430.398438 729.28125 436.074219 735.414062 438.652344 739.285156 C 449.335938 755.101562 452.910156 758.382812 458.964844 769.785156 C 446.636719 759.941406 411.695312 728.097656 401.621094 725.355469 C 397.332031 724.171875 393.855469 724.171875 391.714844 725.839844 C 391.488281 726 391.332031 726.160156 391.105469 726.378906 C 391.050781 726.484375 390.941406 726.539062 390.886719 726.648438 C 389.292969 728.53125 388.855469 730.03125 389.292969 732.402344 L 388.902344 732.238281 C 384.722656 730.460938 380.210938 728.800781 375.757812 727.988281 C 370.96875 727.128906 367.273438 727.617188 365.40625 729.980469 C 360.289062 736.652344 368.929688 742.03125 373.21875 745.6875 C 405.527344 773.226562 434.085938 804.261719 463.96875 834.003906 L 464.1875 834.222656 L 464.242188 834.273438 L 464.296875 834.332031 L 464.46875 834.492188 L 464.523438 834.542969 C 478.9375 849.066406 494.511719 868.378906 506.945312 886.664062 C 508.820312 889.410156 510.640625 892.152344 512.34375 894.839844 C 516.527344 901.292969 515.640625 900.753906 521.703125 898.28125 C 547.292969 887.90625 563.742188 878.8125 572.664062 873.382812 C 581.957031 867.625 581.355469 869.296875 577.285156 861.0625 Z M 577.285156 861.0625 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 187.5 121.875 L 712.5 121.875 C 722.855469 121.875 731.25 130.269531 731.25 140.625 L 731.25 665.625 C 731.25 675.980469 722.855469 684.375 712.5 684.375 L 187.5 684.375 C 177.144531 684.375 168.75 675.980469 168.75 665.625 L 168.75 140.625 C 168.75 130.269531 177.144531 121.875 187.5 121.875 Z M 187.5 121.875 "
        />
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="1000.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
