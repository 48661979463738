import { EventTimeFormat, ThemeColor } from "@/types/qr";

export interface QREventAPIPayload {
  "qr-name": string;
  color: ThemeColor;
  "info-image": string;
  "info-title": string;
  "info-description": string;
  "info-action-title": string;
  "info-action-url": string;
  "time-format": EventTimeFormat;
  "time-all-day": boolean;
  "time-timezone": string;
  "time-start": string;
  "time-end": string;
  "time-action-title": string;
  "location-address": string;
  "location-numeration": string;
  "location-postal-code": string;
  "location-city": string;
  "location-state": string;
  "location-country": string;
  "organizer-name": string;
  "organizer-phone": string;
  "organizer-email": string;
  "organizer-website": string;
  "organizer-about": string;
  facilities?: FacilitiesObjectType;
}

export type FacilitiesObjectType = {
  [key in API_Facility]?: boolean;
};

export enum API_Facility {
  wifi = "wifi",
  accommodation = "accommodation",
  wheelchairAccess = "wheelchair_access",
  toilet = "toilet",
  babyStroller = "baby_stroller",
  petFriendly = "pet_friendly",
  parking = "parking",
  bus = "bus",
  taxi = "taxi",
  restRooms = "rest_rooms",
  cafe = "cafe",
  bar = "bar",
  restaurant = "restaurant",
}
