import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const BucketFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 88 0.5 C 92.142083 0.5 95.5 3.857917 95.5 8 L 95.5 120 C 95.5 124.142083 92.142083 127.5 88 127.5 L 8 127.5 C 3.857917 127.5 0.5 124.142083 0.5 120 L 0.5 8 C 0.5 3.857917 3.857917 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(9.375,0,0,9.375,0,0)"
        />
        <path
          fill="none"
          strokeWidth="3.42174"
          strokeLinecap="round"
          stroke={frameColor}
          strokeOpacity="1"
          d="M 12.65 31.432917 C 12.63625 31.21875 12.629583 31.001667 12.629583 30.7825 C 12.629583 25.749167 16.467083 21.66375 21.195833 21.66375 C 22.797083 21.66375 24.2975 22.1325 25.580833 22.950417 C 25.570417 22.765417 25.565417 22.579167 25.565417 22.3925 C 25.565417 17.359167 29.402917 13.27375 34.131667 13.27375 C 36.085417 13.27375 37.887083 13.971667 39.329583 15.145417 C 40.705 12.0675 43.654583 9.939167 47.0675 9.939167 C 50.479167 9.939167 53.42875 12.0675 54.805833 15.145417 C 56.248333 13.971667 58.049583 13.27375 60.00375 13.27375 C 64.730833 13.27375 68.57 17.359167 68.57 22.3925 C 68.57 22.579167 68.565 22.765417 68.552917 22.950417 C 69.8375 22.1325 71.33625 21.66375 72.939583 21.66375 C 77.666667 21.66375 81.505833 25.749167 81.505833 30.7825 C 81.505833 31.001667 81.49875 31.21875 81.48375 31.432917 M 37.105 18.412917 C 37.105 18.412917 38.9325 13.56625 44.0975 15.47375 M 58.429583 25.412083 C 58.429583 25.412083 64.467083 24.618333 65.500417 30.734583 M 87.5125 37.104167 C 87.812083 35.722083 87.467917 34.27625 86.58 33.174583 C 85.692083 32.0725 84.3525 31.432917 82.935833 31.432917 C 67.945417 31.432917 28.054583 31.432917 13.064167 31.432917 C 11.6475 31.432917 10.307917 32.0725 9.42 33.174583 C 8.531667 34.27625 8.187917 35.722083 8.4875 37.104167 C 11.043333 48.9075 16.894583 75.9375 19.312083 87.099583 C 19.938333 89.994167 22.499583 92.060833 25.4625 92.060833 C 35.975833 92.060833 60.024167 92.060833 70.5375 92.060833 C 73.500417 92.060833 76.061667 89.994167 76.687917 87.099583 C 79.105417 75.9375 84.956667 48.9075 87.5125 37.104167 Z M 87.5125 37.104167 "
          transform="matrix(9.375,0,0,9.375,0,0)"
        />  

        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameBackgroundColor}
          d="M 826.226562 349.890625 C 829.035156 336.929688 825.8125 323.378906 817.484375 313.046875 C 809.160156 302.71875 796.601562 296.71875 783.324219 296.71875 C 642.785156 296.71875 268.808594 296.71875 128.273438 296.71875 C 114.992188 296.71875 102.433594 302.71875 94.109375 313.046875 C 85.785156 323.378906 82.558594 336.929688 85.367188 349.890625 C 109.328125 460.546875 164.183594 713.953125 186.847656 818.59375 C 192.71875 845.734375 216.730469 865.109375 244.511719 865.109375 C 343.074219 865.109375 568.523438 865.109375 667.085938 865.109375 C 694.863281 865.109375 718.875 845.734375 724.746094 818.59375 C 747.410156 713.953125 802.265625 460.546875 826.226562 349.890625 Z M 826.226562 349.890625 "
        />
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="1000.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
