import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const GiftboxFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 7.999875 0.5 L 88.000125 0.5 C 92.142 0.5 95.500125 3.857839 95.500125 8 L 95.500125 120 C 95.500125 124.142161 92.142 127.5 88.000125 127.5 L 7.999875 127.5 C 3.858 127.5 0.499875 124.142161 0.499875 120 L 0.499875 8 C 0.499875 3.857839 3.858 0.5 7.999875 0.5 Z M 7.999875 0.5 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 807.085938 999.75 L 192.914062 999.75 C 178.417969 999.75 166.667969 984.308594 166.667969 965.257812 L 166.667969 416.5625 L 833.332031 416.5625 L 833.332031 965.257812 C 833.332031 984.308594 821.582031 999.75 807.085938 999.75 Z M 807.085938 999.75 "
        />

        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 48 38.636159 L 15.272625 38.636159 L 15.272625 93.034509 C 15.272625 94.923481 16.4265 96.454239 17.849625 96.454239 L 78.15075 96.454239 C 79.573875 96.454239 80.727375 94.923481 80.727375 93.034509 L 80.727375 38.636159 Z M 48 38.636159 L 82.37175 38.636534 C 83.271375 38.636534 84 37.961365 84 37.128282 L 84 29.235559 C 84 28.402851 83.271375 27.727307 82.37175 27.727307 L 13.628625 27.727307 C 12.729 27.727307 12 28.402851 12 29.235559 L 12 37.128282 C 12 37.961365 12.729 38.636534 13.628625 38.636534 Z M 48 38.636159 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill="white"
          d="M 443.183594 301.886719 C 443.183594 301.886719 413.871094 226.96875 287.808594 199.375 C 244.320312 189.851562 220.980469 227.546875 226.160156 254.179688 C 232.226562 285.378906 262.261719 301.144531 223.410156 370.632812 C 209.367188 395.75 216.425781 421.191406 233.207031 428.515625 C 284.488281 450.890625 391.664062 423.523438 442.972656 376.335938 Z M 443.183594 301.886719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="white"
          d="M 556.820312 301.878906 C 556.820312 301.878906 587.597656 226.964844 719.96875 199.375 C 765.625 189.855469 790.128906 227.539062 784.699219 254.179688 C 778.332031 285.375 746.792969 301.136719 787.585938 370.632812 C 802.328125 395.742188 794.921875 421.191406 777.300781 428.515625 C 723.457031 450.890625 610.914062 423.515625 557.039062 376.335938 Z M 556.820312 301.878906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill="white"
          d="M 534.425781 402.363281 L 465.578125 402.363281 C 453.207031 402.363281 443.183594 390.976562 443.183594 376.9375 L 443.183594 302.816406 C 443.183594 288.777344 453.207031 277.394531 465.578125 277.394531 L 534.425781 277.394531 C 546.800781 277.394531 556.820312 288.777344 556.820312 302.816406 L 556.820312 376.9375 C 556.820312 390.976562 546.800781 402.363281 534.425781 402.363281 Z M 534.425781 402.363281 "
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 42.545625 28.988372 C 42.545625 28.988372 39.731625 21.794449 27.629625 19.144786 C 23.45475 18.230308 21.214125 21.849962 21.711375 24.407352 C 22.29375 27.403226 25.177125 28.917104 21.447375 35.589647 C 20.09925 38.0015 20.776875 40.444486 22.387875 41.147787 C 27.310875 43.296324 37.59975 40.668417 42.525375 36.137284 Z M 42.545625 28.988372 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 42.545625 28.988372 C 42.545625 28.988372 39.731625 21.794449 27.629625 19.144786 C 23.45475 18.230308 21.214125 21.849962 21.711375 24.407352 C 22.29375 27.403226 25.177125 28.917104 21.447375 35.589647 C 20.09925 38.0015 20.776875 40.444486 22.387875 41.147787 C 27.310875 43.296324 37.59975 40.668417 42.525375 36.137284 Z M 42.545625 28.988372 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 53.45475 28.987622 C 53.45475 28.987622 56.409375 21.794074 69.117 19.144786 C 73.5 18.230683 75.852375 21.849212 75.331125 24.407352 C 74.719875 27.402851 71.692125 28.916354 75.60825 35.589647 C 77.0235 38.00075 76.3125 40.444486 74.620875 41.147787 C 69.451875 43.296324 58.64775 40.667667 53.47575 36.137284 Z M 53.45475 28.987622 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 53.45475 28.987622 C 53.45475 28.987622 56.409375 21.794074 69.117 19.144786 C 73.5 18.230683 75.852375 21.849212 75.331125 24.407352 C 74.719875 27.402851 71.692125 28.916354 75.60825 35.589647 C 77.0235 38.00075 76.3125 40.444486 74.620875 41.147787 C 69.451875 43.296324 58.64775 40.667667 53.47575 36.137284 Z M 53.45475 28.987622 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 51.304875 38.636534 L 44.6955 38.636534 C 43.507875 38.636534 42.545625 37.543136 42.545625 36.195049 L 42.545625 29.077644 C 42.545625 27.729557 43.507875 26.636534 44.6955 26.636534 L 51.304875 26.636534 C 52.492875 26.636534 53.45475 27.729557 53.45475 29.077644 L 53.45475 36.195049 C 53.45475 37.543136 52.492875 38.636534 51.304875 38.636534 Z M 51.304875 38.636534 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke={frameColor}
          fill="none"
          strokeWidth="3.24103"
          strokeLinecap="butt"
          strokeOpacity="1"
          d="M 51.304875 38.636534 L 44.6955 38.636534 C 43.507875 38.636534 42.545625 37.543136 42.545625 36.195049 L 42.545625 29.077644 C 42.545625 27.729557 43.507875 26.636534 44.6955 26.636534 L 51.304875 26.636534 C 52.492875 26.636534 53.45475 27.729557 53.45475 29.077644 L 53.45475 36.195049 C 53.45475 37.543136 52.492875 38.636534 51.304875 38.636534 Z M 51.304875 38.636534 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />

        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="500"
          y="1150.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
