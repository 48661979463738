import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const HandFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="none"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 7.999875 0.5 L 88.000125 0.5 C 92.142 0.5 95.500125 3.857839 95.500125 8 L 95.500125 120 C 95.500125 124.142161 92.142 127.5 88.000125 127.5 L 7.999875 127.5 C 3.858 127.5 0.499875 124.142161 0.499875 120 L 0.499875 8 C 0.499875 3.857839 3.858 0.5 7.999875 0.5 Z M 7.999875 0.5 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={"white"}
          d="M 83.332031 0 L 916.667969 0 C 962.691406 0 1000 37.300781 1000 83.3125 L 1000 1249.6875 C 1000 1295.699219 962.691406 1333 916.667969 1333 L 83.332031 1333 C 37.308594 1333 0 1295.699219 0 1249.6875 L 0 83.3125 C 0 37.300781 37.308594 0 83.332031 0 Z M 83.332031 0 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 924.972656 1028.820312 C 915.332031 1020.792969 890.792969 1020.640625 879.042969 1022.488281 C 886.824219 1021.265625 930.6875 998.445312 926.363281 986.3125 C 914.925781 954.277344 855.230469 985.367188 855.230469 985.367188 C 855.230469 985.367188 879.585938 966.652344 873.691406 957.53125 C 863.859375 942.351562 820.171875 958.125 786.160156 988.238281 C 785.476562 988.84375 784.804688 989.453125 784.132812 990.066406 C 796.027344 979.195312 766.5625 970.351562 735.09375 998.542969 C 725.664062 1006.980469 691.882812 1051.507812 627.785156 1064.859375 C 570.464844 1076.804688 520.652344 1061.296875 502.226562 1054.734375 C 544.867188 1046.65625 535.957031 1044.863281 580.378906 1041.46875 C 610.207031 1039.195312 676.816406 1037.917969 695.683594 1019.011719 C 718.898438 995.738281 666.5 990.417969 646.691406 988.4375 C 625.277344 986.304688 603.808594 982.789062 447.96875 952.730469 C 366.261719 936.976562 283.839844 966.183594 178.257812 1026.207031 C -5.261719 1130.53125 -374.039062 1442.671875 -395.832031 1464.945312 L -54.433594 1486.835938 C -51.566406 1482.359375 -48.792969 1478.058594 -46.113281 1473.984375 C 35.035156 1350.496094 126.601562 1210.011719 261.058594 1136.582031 C 309.261719 1110.253906 376.109375 1124.0625 452.773438 1150.992188 C 554.886719 1186.871094 572.582031 1184.496094 666.679688 1155.136719 C 732.007812 1134.746094 833.335938 1087.40625 897.453125 1063.378906 C 914.597656 1056.953125 945.472656 1045.90625 924.972656 1028.820312 Z M 924.972656 1028.820312 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={"#BFBFBF"}
          d="M 630.652344 1077.855469 C 628.851562 1077.855469 627.070312 1077.84375 625.292969 1077.8125 C 605.84375 1077.523438 528.792969 1068.941406 501.316406 1055.664062 C 500.519531 1055.289062 500.0625 1054.445312 500.15625 1053.570312 C 500.257812 1052.707031 500.882812 1051.988281 501.738281 1051.789062 C 540.464844 1042.636719 568.476562 1040.75 595.574219 1038.925781 C 621.847656 1037.160156 646.664062 1035.5 677.144531 1026.753906 C 695.007812 1021.632812 701.144531 1013.925781 700.964844 1008.878906 C 700.789062 1003.78125 694.417969 999.398438 683.941406 997.171875 C 672.769531 994.792969 650.128906 991.355469 623.925781 987.367188 C 576.816406 980.207031 512.300781 970.398438 471.941406 960.230469 C 470.835938 959.953125 470.167969 958.832031 470.4375 957.71875 C 470.726562 956.609375 471.847656 955.941406 472.945312 956.214844 C 513.117188 966.335938 577.527344 976.132812 624.542969 983.277344 C 650.824219 987.273438 673.511719 990.722656 684.792969 993.121094 C 697.441406 995.8125 704.851562 1001.503906 705.105469 1008.734375 C 705.410156 1017.472656 695.136719 1025.902344 678.292969 1030.734375 C 647.382812 1039.597656 622.355469 1041.28125 595.847656 1043.0625 C 570.394531 1044.769531 544.121094 1046.539062 508.894531 1054.382812 C 539.609375 1066.015625 607.363281 1073.410156 625.355469 1073.679688 C 661.066406 1074.230469 700.679688 1069.265625 746.410156 1058.539062 C 768.273438 1053.402344 790.988281 1045.78125 812.949219 1038.410156 C 833.539062 1031.503906 854.828125 1024.367188 875.945312 1019.039062 C 877.050781 1018.765625 878.175781 1019.433594 878.453125 1020.535156 C 878.730469 1021.648438 878.066406 1022.769531 876.949219 1023.054688 C 855.992188 1028.339844 834.773438 1035.453125 814.265625 1042.332031 C 792.210938 1049.726562 769.402344 1057.382812 747.359375 1062.558594 C 703.558594 1072.835938 665.316406 1077.855469 630.652344 1077.855469 Z M 630.652344 1077.855469 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={"#BFBFBF"}
          d="M 709.800781 1070.292969 C 708.800781 1070.292969 707.929688 1069.585938 707.757812 1068.566406 C 707.566406 1067.441406 708.328125 1066.378906 709.457031 1066.191406 C 742.691406 1060.640625 773.441406 1038.527344 805.996094 1015.113281 C 820.765625 1004.488281 836.039062 993.507812 851.855469 983.925781 C 852.835938 983.324219 854.105469 983.640625 854.703125 984.613281 C 855.292969 985.59375 854.976562 986.871094 854.007812 987.464844 C 838.324219 996.964844 823.113281 1007.898438 808.414062 1018.472656 C 775.460938 1042.175781 744.328125 1064.566406 710.140625 1070.273438 C 710.023438 1070.285156 709.90625 1070.292969 709.800781 1070.292969 Z M 709.800781 1070.292969 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={"#BFBFBF"}
          d="M 666.261719 1076.042969 C 665.1875 1076.042969 664.28125 1075.222656 664.203125 1074.128906 C 664.105469 1072.992188 664.96875 1072.003906 666.113281 1071.917969 C 694.953125 1069.753906 738.617188 1029.851562 773.699219 997.777344 C 777.035156 994.730469 780.324219 991.722656 783.542969 988.804688 C 784.386719 988.03125 785.699219 988.09375 786.464844 988.941406 C 787.238281 989.789062 787.171875 991.09375 786.328125 991.867188 C 783.109375 994.785156 779.828125 997.785156 776.492188 1000.835938 C 739.089844 1035.023438 696.6875 1073.785156 666.417969 1076.042969 C 666.363281 1076.042969 666.308594 1076.042969 666.261719 1076.042969 Z M 666.261719 1076.042969 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 208.332031 354.910156 L 791.667969 354.910156 C 803.171875 354.910156 812.5 364.234375 812.5 375.738281 L 812.5 958.925781 C 812.5 970.429688 803.171875 979.753906 791.667969 979.753906 L 208.332031 979.753906 C 196.828125 979.753906 187.5 970.429688 187.5 958.925781 L 187.5 375.738281 C 187.5 364.234375 196.828125 354.910156 208.332031 354.910156 Z M 208.332031 354.910156 "
        />

        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="500"
          y="250.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
