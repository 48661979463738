import React from "react";

import { QRFRameProps } from "../types";
import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const musicFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M20 35C20 33.8954 20.8954 33 22 33H75.5C76.6046 33 77.5 33.8954 77.5 35V100.704C77.5 101.726 76.73 102.583 75.7141 102.692L45.2065 105.978C45.0692 105.993 44.9307 105.993 44.7933 105.979L21.801 103.68C20.7786 103.578 20 102.718 20 101.69V35Z"
        fill={frameBackgroundColor}
      />
      <path
        d="M78.0516 101.618L78.0516 101.618C76.7483 103.546 74.9081 105.075 72.7371 105.994L72.737 105.994C71.321 106.593 69.7645 106.928 68.1412 106.941L67.8344 106.944V106.957L23.406 109.106C21.4449 109.201 19.5305 108.487 18.1071 107.132C16.6863 105.778 15.8819 103.9 15.8819 101.936V37.3791C15.8819 33.1561 19.3051 29.7327 23.5283 29.7327H72.4701C76.6933 29.7327 80.1176 33.1562 80.1179 37.3792V94.8589C80.1179 97.3547 79.3543 99.6918 78.0516 101.618ZM75.479 98.0046L75.4791 98.0044C75.8868 97.0382 76.1126 95.982 76.1139 94.8594V94.8591V37.7899C76.1139 35.5509 74.298 33.7366 72.0593 33.7366H23.938C21.7002 33.7366 19.8858 35.5511 19.8858 37.7899V101.006C19.8858 101.739 20.2544 102.424 20.8677 102.828C21.4813 103.232 22.2565 103.299 22.9303 103.009L22.9306 103.009L57.6927 88.0312L57.6934 88.0309C58.0299 87.8849 58.4191 87.9191 58.7268 88.1209C59.0345 88.3238 59.2191 88.6668 59.2191 89.0355V90.4148V90.415C59.2204 93.5565 59.8951 96.1272 60.9383 98.0889L60.9384 98.089C61.9837 100.053 63.3905 101.406 64.8991 102.173L64.8995 102.174C65.9086 102.685 66.9634 102.94 68.0313 102.941H68.0319C69.7129 102.94 71.2562 102.435 72.5476 101.562C73.8385 100.691 74.8671 99.4516 75.479 98.0046ZM55.486 95.6741V99.9759C55.0995 99.898 54.6796 99.8933 54.2537 99.975C52.8846 100.237 51.8075 101.35 52.0445 102.577L52.0445 102.577C52.282 103.803 53.696 104.438 55.0653 104.175L55.0653 104.175C56.1742 103.962 57.0737 103.204 57.2653 102.273H57.3093V101.964V92.9999V92.6491L56.9612 92.693L48.4346 93.767L48.1639 93.8011L48.1639 94.074L48.164 101.05C47.7774 100.972 47.3573 100.967 46.9313 101.049C45.5626 101.312 44.4851 102.424 44.7224 103.651L44.7224 103.651C44.9599 104.878 46.3738 105.512 47.7427 105.249C48.8524 105.037 49.7518 104.278 49.9435 103.347H49.9872V103.038V96.4977L55.486 95.6741Z"
        fill={frameColor}
        stroke={frameColor}
        stroke-width="0.618813"
      />
      <foreignObject
        width="48%"
        height="46px"
        style={{ borderRadius: "3px" }}
        x="25"
        y="40">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="15">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
