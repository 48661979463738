import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const WhiteFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <g>
        <title>Layer 1</title>
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          stroke="null"
          id="svg_10"
          fill="white"
          rx="4"
          height="1200"
          width="900"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          stroke="null"
          id="svg_11"
          fill={frameColor}
          d="m862.5,18.75l-825,0c-10.35537,0 -18.75,8.39463 -18.75,18.75l0,825c0,10.355 8.39463,18.75 18.75,18.75l825,0c10.355,0 18.75,-8.395 18.75,-18.75l0,-825c0,-10.35537 -8.395,-18.75 -18.75,-18.75zm-825,-18.75c-20.71063,0 -37.5,16.78937 -37.5,37.5l0,825c0,20.71125 16.78937,37.5 37.5,37.5l825,0c20.71125,0 37.5,-16.78875 37.5,-37.5l0,-825c0,-20.71063 -16.78875,-37.5 -37.5,-37.5l-825,0z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
        <rect
          xmlns="http://www.w3.org/2000/svg"
          stroke={frameBackgroundColor}
          strokeWidth="34"
          id="svg_12"
          fill="white"
          height="755.39996"
          width="755.39996"
          y="72.50003"
          x="71.50002"
        />
        <g
          id="svg_8"
          transform="matrix(6.13963 0 0 6.13963 3.24696 22.7919)"
          stroke="null">
          <g
            id="svg_16"
            stroke="null"
            dangerouslySetInnerHTML={{ __html: qrSvg }}></g>
        </g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="260"
          x="0"
          y="940">
          <style>
            {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
          </style>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            strokeWidth="3px"
            fill="none"
          />
          <text
            x="50%"
            y="55%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={textColor}
            fontFamily="Nunito Sans"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </g>
    </svg>
  );
};
