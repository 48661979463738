import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const handFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />

      <rect
        x="18"
        y="34"
        width="60"
        height="60"
        rx="2"
        fill={frameBackgroundColor}
      />

      <foreignObject
        style={{ borderRadius: "3px" }}
        width="52%"
        height="51px"
        x="22.800"
        y="38.800">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",

            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="15">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.13661 128H8C3.58172 128 0 124.418 0 120V113.707C6.69091 108.746 13.1168 104.301 18.1126 101.461C28.2488 95.6969 36.1613 92.8923 44.0051 94.4052C44.7653 94.5519 45.4922 94.692 46.1875 94.8258C46.2326 94.7556 46.3186 94.7187 46.4029 94.7399C50.2594 95.7116 56.4425 96.6523 60.9561 97.3385C63.479 97.7221 65.6571 98.0533 66.7401 98.2837C67.9545 98.5421 68.6659 99.0886 68.6903 99.7828C68.7195 100.622 67.733 101.432 66.1163 101.895C63.1489 102.746 60.746 102.908 58.2012 103.079L58.201 103.079L58.2009 103.079C55.7576 103.243 53.2356 103.413 49.8538 104.167C51.4613 104.775 54.1253 105.263 56.496 105.585C57.9759 105.627 59.588 105.522 61.2674 105.172C66.5461 104.073 69.6831 100.77 71.0396 99.3414C71.2644 99.1047 71.4403 98.9195 71.5689 98.8043C74.4331 96.2377 77.1245 96.8676 76.4229 97.8296C76.4301 97.8323 76.4372 97.8354 76.4441 97.8389L76.4713 97.8146C79.7365 94.9233 83.9304 93.4083 84.8745 94.8663C85.4401 95.742 83.102 97.5392 83.102 97.5392C83.102 97.5392 88.8328 94.5537 89.9309 97.6299C90.3459 98.795 86.135 100.986 85.3882 101.104C86.516 100.926 88.8719 100.941 89.7973 101.712C91.7253 103.319 88.92 104.37 87.2577 104.992L87.1556 105.03C84.7347 105.937 81.7611 107.191 78.6626 108.498C73.8831 110.513 68.8062 112.654 65.0012 113.841C55.9678 116.66 54.2692 116.888 44.4661 113.444C37.1066 110.858 30.6892 109.531 26.0616 112.059C19.0337 115.898 13.2264 121.646 8.13661 128Z"
        fill={frameColor}
      />
    </svg>
  );
};
