import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const CupFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 88 0.5 C 92.142188 0.5 95.5 3.857813 95.5 8 L 95.5 120 C 95.5 124.142188 92.142188 127.5 88 127.5 L 8 127.5 C 3.857813 127.5 0.5 124.142188 0.5 120 L 0.5 8 C 0.5 3.857813 3.857813 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />

        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameColor}
          d="M 1025 300.980469 C 1025 271.519531 1001.328125 247.628906 972.140625 247.628906 C 819.667969 247.628906 380.332031 247.628906 227.859375 247.628906 C 198.671875 247.628906 175 271.519531 175 300.980469 C 175 317.683594 175 336.101562 175 352.78125 C 175 382.265625 198.671875 406.15625 227.859375 406.15625 C 380.332031 406.15625 819.667969 406.15625 972.140625 406.15625 C 1001.328125 406.15625 1025 382.265625 1025 352.78125 C 1025 336.101562 1025 317.683594 1025 300.980469 Z M 1025 300.980469 "
        />
        <path
          fill="none"
          fillOpacity="1"
          strokeWidth="5.01602"
          stroke={frameColor}
          strokeLinecap="round"
          d="M 77.039375 32.4925 L 18.960625 32.4925 C 18.960625 32.4925 23.668437 84.08875 25.01125 98.809687 C 25.213125 101.006875 27.039062 102.688125 29.225 102.688125 L 66.775 102.688125 C 68.960938 102.688125 70.786563 101.006875 70.98875 98.809687 C 72.331563 84.08875 77.039375 32.4925 77.039375 32.4925 Z M 77.039375 32.4925 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />
        <path
          fill="none"
          fillOpacity="1"
          strokeWidth="5.01602"
          stroke={frameColor}
          strokeLinecap="round"
          d="M 82 24.078437 C 82 21.721563 80.10625 19.810313 77.77125 19.810313 C 65.573437 19.810313 30.426562 19.810313 18.22875 19.810313 C 15.89375 19.810313 14 21.721563 14 24.078437 C 14 25.414687 14 26.888125 14 28.2225 C 14 30.58125 15.89375 32.4925 18.22875 32.4925 C 30.426562 32.4925 65.573437 32.4925 77.77125 32.4925 C 80.10625 32.4925 82 30.58125 82 28.2225 C 82 26.888125 82 25.414687 82 24.078437 Z M 82 24.078437 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />
        <path
          fill="none"
          fillOpacity="1"
          strokeWidth="5.01602"
          stroke={frameColor}
          strokeLinecap="round"
          d="M 71.34125 19.810313 L 24.65875 19.810313 L 24.65875 15.5825 C 24.65875 13.22375 26.5525 11.3125 28.8875 11.3125 C 37.761875 11.3125 58.238125 11.3125 67.1125 11.3125 C 69.4475 11.3125 71.34125 13.22375 71.34125 15.5825 C 71.34125 17.699063 71.34125 19.810313 71.34125 19.810313 Z M 71.34125 19.810313 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />

        <path
          stroke="none"
          fillRule="evenodd"
          fill={frameBackgroundColor}
          d="M 257.441406 449.101562 C 255.964844 433.519531 268.226562 420.046875 283.890625 420.046875 L 916.109375 420.046875 C 931.773438 420.046875 944.035156 433.519531 942.558594 449.101562 L 867.019531 1245.480469 C 865.730469 1259.109375 854.273438 1269.515625 840.578125 1269.515625 L 359.421875 1269.515625 C 345.726562 1269.515625 334.269531 1259.109375 332.980469 1245.480469 Z M 257.441406 449.101562 "
        />
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="600"
          y="1450.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
