import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const waiterFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.8758 76C76.0286 76 76.0109 76.1032 75.9763 76.1319C75.9232 76.1777 75.3594 76.5735 75.3594 76.5735C75.3594 76.5796 75.353 76.5852 75.353 76.5912C75.0715 77.1134 73.7509 77.1647 73.6101 77.1647C73.5804 77.4977 73.2933 77.756 72.941 77.756H24.0549C23.7082 77.756 23.4207 77.4977 23.3857 77.1647C23.245 77.1647 21.9239 77.1134 21.6477 76.5912C21.6421 76.5852 21.6421 76.5796 21.6421 76.5735C21.6421 76.5735 21.0785 76.1777 21.0259 76.1319C20.9903 76.1032 20.9672 76 21.1259 76H75.8758Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.5772 91.8465C60.8316 90.3378 60.6141 89.2072 60.7494 87.4004C60.8847 85.587 60.8847 83.688 61.1836 82.0877C61.4535 80.6646 61.8703 79.6775 62.4457 79.1211C62.7337 78.8401 63.1556 78.7711 63.3258 78.404C63.496 78.0424 63.1432 77.7156 62.4107 77.6466C61.084 77.5317 60.1209 77.9795 59.5281 78.9952C58.1424 81.3761 58.9586 85.7537 54.4029 83.9061C51.7502 82.9196 51.9611 81.7487 50.3005 79.8729C49.3847 78.8345 48.2631 77.8017 47.4295 77.4115C47.0425 77.2276 46.7195 77.1818 46.502 77.3424C45.9092 77.7901 46.5144 78.4443 46.7894 78.8572C47.9292 80.5442 48.3103 80.8942 48.9562 82.1103C47.6411 81.0603 43.914 77.6637 42.8396 77.3711C42.3821 77.2452 42.0112 77.2452 41.7828 77.423C41.7588 77.4401 41.742 77.4572 41.718 77.4804C41.7122 77.4915 41.7006 77.4976 41.6947 77.5091C41.5245 77.7101 41.478 77.8702 41.5245 78.123L41.4831 78.1053C41.0372 77.916 40.5557 77.7388 40.0807 77.6521C39.57 77.5605 39.1758 77.6124 38.9765 77.8647C38.4309 78.5762 39.3525 79.1498 39.8101 79.5401C43.2564 82.4774 46.3027 85.7879 49.4902 88.9604L49.5134 88.9837L49.5192 88.9892L49.5251 88.9953L49.5433 89.0123L49.5491 89.0179C51.0868 90.5669 52.7481 92.6271 54.0742 94.5774C54.2742 94.8705 54.4684 95.1631 54.6502 95.4496C55.0961 96.138 55.0016 96.0806 55.6482 95.8167C58.3781 94.7099 60.1326 93.7399 61.084 93.1609C62.0754 92.5465 62.0114 92.7248 61.5772 91.8465Z"
        fill={frameColor}
      />
      <rect
        x="19"
        y="13"
        width="60"
        height="60"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="59%"
        height="59px"
        style={{ borderRadius: "3px" }}
        x="20"
        y="13">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "5px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
