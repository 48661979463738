import React from "react";

import { QRFRameProps } from "../types";
import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";
import "../styles.scss";

export const ticket2Frame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M27.2781 120.835L31.423 116.669L35.5877 120.815C35.9378 121.164 36.4134 121.36 36.9087 121.359C37.4041 121.359 37.8778 121.16 38.2271 120.809L42.372 116.643L46.5376 120.788C47.2678 121.515 48.4503 121.512 49.177 120.782L53.3219 116.616L57.4875 120.761C58.2177 121.488 59.4002 121.485 60.1268 120.755L64.2709 116.59L68.4338 120.732C69.1641 121.459 70.3465 121.456 71.0732 120.726L76.5346 115.237C76.883 114.887 77.0789 114.411 77.0785 113.916L76.8338 15.4472C76.8335 14.9572 76.6388 14.4861 76.293 14.1368L70.8044 8.60328C70.4533 8.24871 69.9742 8.0493 69.4754 8.05141C68.9757 8.05265 68.4995 8.31777 68.1483 8.61073L64.0149 12.8211L59.858 8.63214C59.507 8.27757 59.0279 8.07816 58.529 8.08027C58.0346 8.0815 57.56 8.27974 57.2107 8.63077L57.2019 8.63959L53.0641 12.8491L48.9099 8.65925C48.5588 8.30468 48.0806 8.10615 47.5809 8.10739C47.0865 8.10861 46.6118 8.30686 46.2626 8.65788L46.2538 8.6667L42.1177 12.878L37.9582 8.68636C37.6072 8.33179 37.1281 8.13239 36.6293 8.1345C36.1313 8.13573 35.6841 8.38758 35.303 8.69293L31.1678 12.9033L27.0127 8.71434C26.6617 8.35977 26.1826 8.16036 25.6838 8.16248C25.1893 8.1637 24.7147 8.36195 24.3654 8.71297L24.3567 8.72179L18.8946 14.2833C18.5506 14.6343 18.3582 15.1046 18.3594 15.5955L18.6032 114.064C18.6044 114.558 18.8027 115.033 19.1537 115.382L24.6422 120.843C25.369 121.568 26.5514 121.565 27.2781 120.835Z"
        fill={frameColor}
      />
      <path
        d="M22.3939 17.7765L25.9946 14.1103L30.1497 18.2993C30.5008 18.6538 30.9799 18.8532 31.4787 18.8511C31.9731 18.8499 32.4477 18.6517 32.797 18.3006L32.8058 18.2918L36.941 14.0814L41.1005 18.273C41.4515 18.6276 41.9306 18.827 42.4295 18.8249C42.9274 18.8237 43.4046 18.5999 43.7557 18.2665L47.8918 14.0552L52.046 18.2451C52.3961 18.5988 52.8752 18.7982 53.375 18.7969C53.8694 18.7957 54.344 18.5974 54.6933 18.2464L54.7021 18.2376L58.8399 14.0281L62.9976 18.2179C63.3487 18.5725 63.8286 18.771 64.3266 18.7698C64.8254 18.7677 65.2895 18.58 65.6537 18.2105L69.7871 14.0001L73.4042 17.6467L73.5767 87.3244L22.5647 87.4525L22.3939 17.7765Z"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="49%"
        height="46px"
        style={{ borderRadius: "3px" }}
        x="24"
        y="30">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="92">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
