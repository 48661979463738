export const socials = [
  {
    id: "facebook",
    name: "Facebook",
    value: "",
    placeholder: "https://facebook.com/profile",
  },
  {
    id: "instagram",
    name: "Instagram",
    value: "",
    placeholder: "https://instagram.com/profile",
  },
  {
    id: "twitter",
    name: "Twitter",
    value: "",
    placeholder: "https://twitter.com/profile",
  },
  {
    id: "dribbble",
    name: "Dribbble",
    value: "",
    placeholder: "https://dribbble.com/profile",
  },
  {
    id: "flickr",
    name: "Flickr",
    value: "",
    placeholder: "https://flickr.com/profile",
  },
  {
    id: "github",
    name: "Github",
    value: "",
    placeholder: "https://github.com/profile",
  },
  {
    id: "line",
    name: "Line",
    value: "",
    placeholder: "https://line.me/profile",
  },
  {
    id: "linkedin",
    name: "Linkedin",
    value: "",
    placeholder: "https://linkedin.com/profile",
  },
  {
    id: "reddit",
    name: "Reddit",
    value: "",
    placeholder: "https://reddit.com/profile",
  },
  {
    id: "skype",
    name: "Skype",
    value: "",
    placeholder: "https://skype.com/profile",
  },
  {
    id: "snapchat",
    name: "Snapchat",
    value: "",
    placeholder: "https://snapchat.com/profile",
  },
  {
    id: "tiktok",
    name: "Tiktok",
    value: "",
    placeholder: "https://tiktok.com/profile",
  },
  {
    id: "tripadvisor",
    name: "Tripadvisor",
    value: "",
    placeholder: "https://tripadvisor.com/profile",
  },
  {
    id: "tumblr",
    name: "Tumblr",
    value: "",
    placeholder: "https://tumblr.com/profile",
  },
  {
    id: "vimeo",
    name: "Vimeo",
    value: "",
    placeholder: "https://vimeo.com/profile",
  },
  {
    id: "vkontakte",
    name: "Vkontakte",
    value: "",
    placeholder: "https://vk.com/profile",
  },
  { id: "web", name: "Web", value: "", placeholder: "https://web.com/profile" },
  {
    id: "xing",
    name: "Xing",
    value: "",
    placeholder: "https://xing.com/profile",
  },
];
