import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const restaurantFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M31.6739 41.792C31.8821 41.2862 32.2885 40.8878 32.7983 40.6895L49.2751 34.2819C49.7414 34.1006 50.2586 34.1006 50.7249 34.2819L67.1246 40.6596C67.6791 40.8752 68.1089 41.3266 68.297 41.8911L73.7892 58.3675C73.926 58.7781 73.926 59.2219 73.7892 59.6325L68.3472 75.9585C68.1309 76.6072 67.5983 77.1004 66.9349 77.2663L50.4851 81.3787C50.1666 81.4584 49.8334 81.4584 49.5149 81.3787L33.026 77.2565C32.3841 77.096 31.8631 76.6285 31.6344 76.0076L25.2696 58.7318C25.0962 58.2612 25.106 57.7426 25.2969 57.2789L31.6739 41.792Z"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="34%"
        height="32px"
        style={{ borderRadius: "3px" }}
        x="33"
        y="42">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",

            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="94">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.9725 80.4694C62.3215 80.4694 72.3344 70.5575 72.3344 58.3244C72.3344 46.0915 62.3215 36.1728 49.9725 36.1728C37.6235 36.1728 27.6175 46.0915 27.6175 58.3244C27.6175 70.5575 37.6235 80.4694 49.9725 80.4694ZM49.9725 86C65.4029 86 77.9177 73.6102 77.9177 58.3244C77.9177 43.0391 65.4029 30.6498 49.9725 30.6498C34.5421 30.6498 22.0342 43.0391 22.0342 58.3244C22.0342 73.6102 34.5421 86 49.9725 86Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.877 27.463C13.877 27.2091 13.6659 27 13.4087 27C13.025 27 12.9498 27.4029 12.9421 27.4554L12.2867 41.315C12.2645 41.4276 12.1961 41.5241 12.0902 41.5842C11.956 41.6663 11.7894 41.6663 11.6535 41.5842C11.5484 41.5165 11.4732 41.4123 11.4501 41.293C11.4655 36.7626 11.2843 32.0976 11.2767 27.5671C11.2767 27.2616 10.9989 27.0296 10.7041 27.0296C10.411 27.0296 10.147 27.2539 10.1248 27.5528L9.0096 44.8305C8.91132 46.3609 9.57444 48.0395 10.6973 49.1078C12.649 50.9582 13.3643 50.3318 13.4395 52.3913C13.6283 57.907 13.4617 62.1463 12.9114 67.684C12.6712 70.1024 12.4226 72.5285 12.2568 74.9537C12.1132 77.0665 12.0158 79.2378 12.1517 81.3578V81.395C12.1517 82.2309 12.385 83.0594 13.0028 83.6492C13.4924 84.1267 14.1624 84.3805 14.8477 84.3805C15.5416 84.3805 16.2124 84.1267 16.7021 83.6492C17.3199 83.0594 17.5532 82.2309 17.5532 81.395V81.3578C17.689 79.2378 17.5916 77.0665 17.4481 74.9537C17.2823 72.5285 17.0336 70.1024 16.7926 67.684C16.2423 62.1463 16.0765 57.907 16.2645 52.3913C16.3406 50.3318 17.0567 50.9582 19.0076 49.1078C20.1304 48.0395 20.7935 46.3609 20.6953 44.8305L19.5801 27.5528C19.557 27.2539 19.2938 27.0296 18.9999 27.0296C18.7059 27.0296 18.4197 27.2616 18.4197 27.5671C18.4197 32.0976 18.2385 36.7626 18.2539 41.293C18.2317 41.4123 18.1565 41.5165 18.0505 41.5842C17.9146 41.6663 17.7497 41.6663 17.6138 41.5842C17.5079 41.5241 17.4404 41.4276 17.4173 41.315L16.755 27.4554C16.755 27.4029 16.679 27 16.2953 27C16.0381 27 15.8279 27.2091 15.8279 27.463L15.2707 41.293C15.2476 41.4123 15.1801 41.5165 15.0742 41.5842C15.0067 41.6214 14.9315 41.6434 14.8477 41.6434C14.7734 41.6434 14.6982 41.6214 14.6298 41.5842C14.5247 41.5165 14.4495 41.4123 14.4341 41.293L13.877 27.463Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M80.9108 51.8539C80.8586 51.6295 80.8429 51.3617 80.821 51.1373C80.7834 50.6968 80.7605 50.2563 80.7375 49.8158C80.6853 48.637 80.6696 47.4499 80.6696 46.2783C80.678 45.0322 80.7072 43.7707 80.8053 42.5247V42.5019C81.0016 37.9417 81.7929 32.881 84.3399 28.9702L84.3472 28.963C84.5654 28.6197 84.8744 28.1864 85.2063 27.9475C85.2742 27.9031 85.4475 27.7914 85.5372 27.8203C85.651 27.8503 85.7564 28.0747 85.793 28.1636C85.9443 28.5369 85.9892 29.0229 85.9892 29.418C85.9892 36.7701 86.0049 44.1212 86.1103 51.4734C86.1249 52.705 85.9443 53.9138 85.7564 55.1226C85.3869 57.504 85.4015 59.9516 85.5445 62.3477C85.7032 64.9002 86.0122 67.4453 86.3055 69.9904C86.7273 73.6849 87.1646 77.5361 86.9381 81.2532V81.2905C86.9381 82.119 86.6824 82.925 86.1103 83.537C85.651 84.0223 85.0174 84.3431 84.3472 84.3506C83.7439 84.3655 83.2021 84.1266 82.7803 83.7011C82.1613 83.0816 81.8597 82.1637 81.7323 81.3128C81.6112 79.5217 81.6645 77.7153 81.7772 75.9239C81.9056 74.0208 82.1091 72.1177 82.3273 70.2144C82.606 67.7215 82.915 65.2137 83.081 62.7059C83.2386 60.3922 83.2919 58.0107 82.9902 55.7048C82.795 54.2126 82.8931 53.9738 81.7772 52.8683C81.6498 52.7267 81.5214 52.5923 81.3857 52.4661C81.3022 52.3906 81.2124 52.3162 81.1373 52.2272C81.0246 52.1145 80.963 51.9956 80.9108 51.8539Z"
        fill={frameColor}
      />
    </svg>
  );
};
