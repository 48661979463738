import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const Ticket2FrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 7.999875 0.5 L 88.000125 0.5 C 92.142 0.5 95.500125 3.857839 95.500125 8 L 95.500125 120 C 95.500125 124.142161 92.142 127.5 88.000125 127.5 L 7.999875 127.5 C 3.858 127.5 0.499875 124.142161 0.499875 120 L 0.499875 8 C 0.499875 3.857839 3.858 0.5 7.999875 0.5 Z M 7.999875 0.5 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 284.148438 1258.382812 L 327.324219 1215 L 370.707031 1258.175781 C 374.351562 1261.808594 379.304688 1263.851562 384.464844 1263.839844 C 389.625 1263.839844 394.558594 1261.769531 398.199219 1258.113281 L 441.375 1214.726562 L 484.765625 1257.894531 C 492.371094 1265.464844 504.691406 1265.433594 512.261719 1257.832031 L 555.4375 1214.445312 L 598.828125 1257.613281 C 606.433594 1265.183594 618.753906 1265.152344 626.320312 1257.550781 L 669.488281 1214.175781 L 712.851562 1257.3125 C 720.460938 1264.882812 732.777344 1264.851562 740.347656 1257.25 L 797.234375 1200.085938 C 800.863281 1196.441406 802.90625 1191.484375 802.902344 1186.328125 L 800.351562 160.867188 C 800.347656 155.765625 798.320312 150.859375 794.71875 147.222656 L 737.546875 89.59375 C 733.886719 85.902344 728.898438 83.824219 723.703125 83.847656 C 718.496094 83.859375 713.535156 86.621094 709.878906 89.671875 L 666.820312 133.519531 L 623.519531 89.894531 C 619.863281 86.203125 614.875 84.125 609.675781 84.148438 C 604.527344 84.160156 599.582031 86.226562 595.945312 89.882812 L 595.851562 89.972656 L 552.75 133.8125 L 509.476562 90.179688 C 505.820312 86.484375 500.839844 84.417969 495.632812 84.429688 C 490.484375 84.445312 485.539062 86.507812 481.902344 90.164062 L 481.808594 90.253906 L 438.726562 134.113281 L 395.398438 90.460938 C 391.742188 86.769531 386.75 84.691406 381.554688 84.714844 C 376.367188 84.726562 371.710938 87.347656 367.738281 90.527344 L 324.664062 134.375 L 281.382812 90.75 C 277.726562 87.058594 272.734375 84.984375 267.539062 85.003906 C 262.386719 85.015625 257.445312 87.082031 253.804688 90.738281 L 253.714844 90.828125 L 196.820312 148.746094 C 193.234375 152.402344 191.230469 157.300781 191.242188 162.414062 L 193.785156 1187.871094 C 193.796875 1193.015625 195.863281 1197.960938 199.519531 1201.59375 L 256.691406 1258.464844 C 264.261719 1266.015625 276.578125 1265.984375 284.148438 1258.382812 Z M 284.148438 1258.382812 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 233.269531 185.125 L 270.777344 146.945312 L 314.058594 190.570312 C 317.714844 194.261719 322.707031 196.339844 327.902344 196.316406 C 333.054688 196.304688 337.996094 194.238281 341.636719 190.582031 L 341.726562 190.492188 L 384.800781 146.644531 L 428.128906 190.296875 C 431.785156 193.988281 436.777344 196.066406 441.972656 196.042969 C 447.160156 196.03125 452.132812 193.699219 455.789062 190.226562 L 498.871094 146.371094 L 542.144531 190.003906 C 545.792969 193.6875 550.785156 195.765625 555.988281 195.753906 C 561.140625 195.738281 566.082031 193.675781 569.722656 190.019531 L 569.8125 189.925781 L 612.914062 146.089844 L 656.226562 189.722656 C 659.882812 193.414062 664.882812 195.480469 670.070312 195.46875 C 675.265625 195.449219 680.097656 193.492188 683.894531 189.644531 L 726.949219 145.796875 L 764.628906 183.773438 L 766.425781 909.402344 L 235.050781 910.734375 Z M 233.269531 185.125 "
        />
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="500"
          y="1100.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
