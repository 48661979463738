import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const giftboxFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M77.4803 96H18.5197C17.1281 96 16 94.5172 16 92.688V40H80V92.688C80 94.5172 78.872 96 77.4803 96Z"
        fill={frameBackgroundColor}
      />
      <path
        d="M48.0001 38.636H15.2728V93.0346C15.2728 94.9233 16.4266 96.4542 17.8498 96.4542H78.1506C79.5738 96.4542 80.7275 94.9233 80.7275 93.0346V38.636H48.0001ZM48.0001 38.636L82.3719 38.6364C83.2712 38.6364 84 37.9612 84 37.1283V29.2356C84 28.4029 83.2712 27.7273 82.3719 27.7273H13.6286C12.7291 27.7273 12 28.4029 12 29.2356V37.1283C12 37.9612 12.7291 38.6364 13.6286 38.6364L48.0001 38.636Z"
        stroke={frameColor}
        stroke-width="3.24103"
        stroke-miterlimit="10"
      />
      <path
        d="M42.5457 28.9883C42.5457 28.9883 39.7318 21.7944 27.6295 19.1446C23.4549 18.2304 21.2142 21.8498 21.7114 24.4072C22.2937 27.4031 25.1773 28.9171 21.4473 35.5896C20.0992 38.0015 20.7768 40.4443 22.3877 41.1476C27.3107 43.2964 37.5997 40.6684 42.5255 36.1374L42.5457 28.9883Z"
        fill="white"
      />
      <path
        d="M53.4548 28.9875C53.4548 28.9875 56.4094 21.7941 69.1169 19.1446C73.5 18.2306 75.8524 21.8491 75.331 24.4073C74.7197 27.403 71.692 28.9163 75.6081 35.5897C77.0234 38.0009 76.3126 40.4443 74.6209 41.1476C69.4517 43.2965 58.6476 40.6678 53.4757 36.1373L53.4548 28.9875Z"
        fill="white"
      />
      <path
        d="M51.305 38.6364H44.6956C43.5078 38.6364 42.5457 37.5431 42.5457 36.1951V29.0776C42.5457 27.7296 43.5078 26.6364 44.6956 26.6364H51.305C52.4927 26.6364 53.4548 27.7296 53.4548 29.0776V36.1951C53.4548 37.5431 52.4927 38.6364 51.305 38.6364Z"
        fill="white"
      />
      <path
        d="M42.5457 28.9883C42.5457 28.9883 39.7318 21.7944 27.6295 19.1446C23.4549 18.2304 21.2142 21.8498 21.7114 24.4072C22.2937 27.4031 25.1773 28.9171 21.4473 35.5896C20.0992 38.0015 20.7768 40.4443 22.3877 41.1476C27.3107 43.2964 37.5997 40.6684 42.5255 36.1374L42.5457 28.9883Z"
        stroke={frameColor}
        stroke-width="3.24103"
        stroke-miterlimit="10"
      />
      <path
        d="M42.5457 28.9883C42.5457 28.9883 39.7318 21.7944 27.6295 19.1446C23.4549 18.2304 21.2142 21.8498 21.7114 24.4072C22.2937 27.4031 25.1773 28.9171 21.4473 35.5896C20.0992 38.0015 20.7768 40.4443 22.3877 41.1476C27.3107 43.2964 37.5997 40.6684 42.5255 36.1374L42.5457 28.9883Z"
        stroke={frameColor}
        stroke-width="3.24103"
        stroke-miterlimit="10"
      />
      <path
        d="M53.4548 28.9875C53.4548 28.9875 56.4094 21.7941 69.1169 19.1446C73.5 18.2306 75.8524 21.8491 75.331 24.4073C74.7197 27.403 71.692 28.9163 75.6081 35.5897C77.0234 38.0009 76.3126 40.4443 74.6209 41.1476C69.4517 43.2965 58.6476 40.6678 53.4757 36.1373L53.4548 28.9875Z"
        stroke={frameColor}
        stroke-width="3.24103"
        stroke-miterlimit="10"
      />
      <path
        d="M53.4548 28.9875C53.4548 28.9875 56.4094 21.7941 69.1169 19.1446C73.5 18.2306 75.8524 21.8491 75.331 24.4073C74.7197 27.403 71.692 28.9163 75.6081 35.5897C77.0234 38.0009 76.3126 40.4443 74.6209 41.1476C69.4517 43.2965 58.6476 40.6678 53.4757 36.1373L53.4548 28.9875Z"
        stroke={frameColor}
        stroke-width="3.24103"
        stroke-miterlimit="10"
      />
      <foreignObject
        width="48%"
        height="45px"
        style={{ borderRadius: "3px" }}
        x="25"
        y="45">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
