import { useContext, useEffect, useState } from "react";
import { find } from "lodash";
import { FiRefreshCw } from "react-icons/fi";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "../Input";
import { Button } from "../Button";

import { ConnectForm } from "@/utils/formHelper";

import { FormValidationMethods } from "../Form/types";
import { ThemePickerProps } from "./types";

import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { QREditorContext } from "@/contexts/QREditorContext";
import "./styles.scss";

export const themePickerInitialColors = [
  { key: "blue", primary: "#1466b8", secondary: "#d1e5fa" },
  { key: "green", primary: "#0e8b70", secondary: "#e8fce8" },
  { key: "yellow", primary: "#998600", secondary: "#fff9cc" },
  { key: "red", primary: "#b00223", secondary: "#fecdd6" },
];

export let themePickerCustomColors = [];

export function ThemePicker({
  dataQA,
  labelPrimary,
  labelSecondary,
  defaultValue,
  onClick,
}: ThemePickerProps) {
  const methods: FormValidationMethods = useFormContext();
  const { t } = useTranslation();
  const { qrData } = useContext<QREditorContextProps<any>>(QREditorContext);

  const isDefaultColorSelected = !!find(themePickerInitialColors, {
    primary: qrData.data.themeColor.buttonColor,
  });

  const getPrimaryColorFromQR = (color: string) => {
    const isPrimary = color === "primary";

    if (!isDefaultColorSelected) {
      if (isPrimary)
        return qrData?.data?.customButtonColor
          ? qrData?.data?.customButtonColor
          : qrData.data.themeColor.buttonColor;
      else
        return qrData?.data?.customBackgroundColor
          ? qrData?.data?.customBackgroundColor
          : qrData.data.themeColor.backgroundColor;
    }
  };

  const [colors] = useState(themePickerInitialColors);
  const [customColors, setCustomColors] = useState({
    key: "custom",
    hidden: isDefaultColorSelected,
    primary: getPrimaryColorFromQR("primary") || "#a260ab",
    secondary: getPrimaryColorFromQR("secondary") || "#fce8fa",
  });

  const initialColorSelectedKey =
    find(themePickerInitialColors, {
      primary: defaultValue?.buttonColor,
      secondary: defaultValue?.backgroundColor,
    })?.key || colors[0].key;

  const [selectedColor, setSelectedColor] = useState(
    !isDefaultColorSelected ? "custom" : initialColorSelectedKey
  );

  const onSwitchColors = () => {
    setCustomColors({
      ...customColors,
      primary: customColors.secondary,
      secondary: customColors.primary,
    });
    const primaryColorInput = document.getElementById("customBackgroundColor") as HTMLInputElement;
    const secondaryColorInput = document.getElementById("customButtonColor") as HTMLInputElement;

    primaryColorInput.value = qrData?.data?.themeColor.buttonColor;
    secondaryColorInput.value = qrData?.data?.themeColor.backgroundColor;
  };

  useEffect(() => {
    if (!defaultValue?.backgroundColor) {
      setSelectedColor(colors[0].key);
      onClick && onClick(colors[0].key);
    }
  }, []);

  useEffect(() => {
    const selectedCustomColors = Object.assign({}, customColors);
    delete selectedCustomColors.hidden;

    if (!customColors.hidden) {
      themePickerCustomColors = [selectedCustomColors];
      onClick(customColors.key);
    }
  }, [customColors]);

  const commonRules = {
    required: {
      value: true,
      message: t("_requiredMessage"),
    },
    pattern: {
      value: /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/g,
      message: t("_invalidColor"),
    },
  };

  return (
    <ConnectForm>
      <div
        data-testid="ThemePicker"
        data-qa={dataQA}
        className="ThemePicker">
        <div className="ThemePicker__wrapper">
          <div className="ThemePicker__item ThemePicker__labels">
            <span className="ThemePicker__label ThemePicker__label--first">{labelPrimary}</span>
            <span className="ThemePicker__label">{labelSecondary}</span>
          </div>
          {colors.map((item) => (
            <div
              className={`ThemePicker__item ${
                selectedColor === item.key ? "ThemePicker--active" : ""
              }`}
              key={item.key}
              data-qa={`theme-picker-color-${item.key}-button`}
              onClick={() => (setSelectedColor(item.key), onClick && onClick(item.key))}>
              <span
                className="ThemePicker__swatch ThemePicker__swatch--first"
                style={{ backgroundColor: item.secondary }}></span>
              <span
                className="ThemePicker__swatch"
                style={{ backgroundColor: item.primary }}></span>
            </div>
          ))}
          {!customColors.hidden ? (
            <div
              className={` ThemePicker__item ${
                selectedColor === customColors.key ? "ThemePicker--active" : ""
              }`}
              data-qa={`theme-picker-color-custom-button`}
              onClick={() => (
                setSelectedColor(customColors.key), onClick && onClick(customColors.key)
              )}>
              <span
                className="ThemePicker__swatch ThemePicker__swatch--first"
                style={{ backgroundColor: customColors.secondary }}></span>
              <span
                className="ThemePicker__swatch "
                style={{ backgroundColor: customColors.primary }}></span>
            </div>
          ) : (
            <div className={`ThemePicker__item ThemePicker__item--center`}>
              <span className="ThemePicker__swatch">
                <div
                  id="theme-picker-color-custom-button"
                  className="ThemePicker__add"
                  data-qa={`theme-picker-color-add-button`}
                  onClick={() => (
                    setCustomColors({ ...customColors, hidden: false }),
                    setSelectedColor(customColors.key)
                  )}>
                  <div>+</div>
                </div>
              </span>
            </div>
          )}
        </div>
        {selectedColor === customColors.key ? (
          <div className="u-box u-box--small u-flex u-flex--wrap u-grey-bg u-spacing-5-top u-flex--space-evenly ThemePicker__custom">
            <div className="u-flex-half">
              <Controller
                control={methods?.control}
                name="customBackgroundColor"
                render={() => (
                  <Input
                    type="text"
                    id="customBackgroundColor"
                    noMb
                    colorPicker={customColors.secondary}
                    colorPickerWithDebounce={true}
                    defaultValue={customColors.secondary}
                    placeholder="#FFFFFF"
                    name={"customBackgroundColor"}
                    onChange={(e) => {
                      setCustomColors({
                        ...customColors,
                        secondary: e,
                      });
                    }}
                    error={methods.formState.errors["customBackgroundColor"]}
                    errorMessage={methods.formState.errors["customBackgroundColor"]?.message}
                  />
                )}
                rules={{ ...commonRules }}
              />
            </div>
            <Button
              ghost
              type="button"
              icon={<FiRefreshCw />}
              onClick={() => onSwitchColors()}
            />
            <div className="u-flex-half">
              <Controller
                control={methods?.control}
                name="customButtonColor"
                render={() => (
                  <Input
                    type="text"
                    id="customButtonColor"
                    noMb
                    colorPicker={customColors.primary}
                    colorPickerWithDebounce={true}
                    defaultValue={customColors.primary}
                    placeholder="#FFFFFF"
                    name={"customButtonColor"}
                    onChange={(e) =>
                      setCustomColors({
                        ...customColors,
                        primary: e,
                      })
                    }
                    error={methods.formState.errors["customButtonColor"]}
                    errorMessage={methods.formState.errors["customButtonColor"]?.message}
                  />
                )}
                rules={{ ...commonRules }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </ConnectForm>
  );
}
