import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const weddingFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.7206 33.4364C45.164 35.2821 42.1254 35.8115 39.626 34.5409C39.0938 34.2704 38.6129 33.9319 38.1854 33.5362C38.2716 33.5876 38.3598 33.6363 38.4501 33.6822C40.5784 34.7641 43.307 34.0122 45.6395 31.9918C43.6473 30.2297 42.5508 27.8758 42.8722 25.4596C43.0626 24.0288 43.727 22.7505 44.732 21.7024C44.1453 22.4115 43.7652 23.2243 43.6468 24.114C43.3431 26.3979 44.835 28.6485 47.347 30.1867C47.9155 29.4598 48.4345 28.6476 48.8828 27.762C49.8381 25.8754 50.3359 23.9275 50.4035 22.1484C50.4032 22.1485 50.4029 22.1485 50.4028 22.1485C50.4355 21.1474 50.341 20.2066 50.1159 19.3601C50.1162 19.36 50.1165 19.36 50.1168 19.3599C49.6899 17.7639 48.8037 16.4978 47.4893 15.8297C46.6891 15.4229 45.8042 15.2754 44.8874 15.357C46.3208 15.1229 47.7502 15.3 49.0371 15.954C50.3862 16.6398 51.4056 17.7625 52.062 19.1471C52.8136 19.1223 53.5921 19.1603 54.385 19.267C59.5304 19.9599 63.3394 23.2926 62.8856 26.7047C62.8066 27.2986 62.6035 27.8561 62.2949 28.368C61.8085 25.429 58.3255 22.7942 53.8161 22.1869C53.4977 22.1439 53.1814 22.1122 52.8683 22.0911C53.0977 24.2372 52.6752 26.6371 51.5268 28.9052C51.0662 29.8147 50.52 30.6421 49.9104 31.3769C50.779 31.6721 51.7108 31.8932 52.688 32.0247C58.1606 32.7618 62.9956 30.4137 63.4783 26.7846C63.4917 26.6842 63.5015 26.584 63.508 26.4838C63.5917 27.0603 63.5982 27.6483 63.5195 28.2401C62.9319 32.6561 57.8292 35.6183 52.1314 34.8511C50.5115 34.6329 49.0168 34.1365 47.7206 33.4364ZM40.4253 33.6046C39.8286 33.5686 39.2544 33.42 38.7203 33.1484C35.6519 31.5886 35.0648 26.5616 37.41 21.9297C39.7553 17.2977 44.1508 14.8036 47.219 16.3634C47.5983 16.5562 47.9395 16.802 48.2422 17.0934C45.4108 16.9226 42.0751 19.2901 40.1382 23.1158C38.0828 27.1753 38.2797 31.5382 40.4253 33.6046ZM49.6598 19.4565C49.9082 20.2817 50.0236 21.2109 49.9999 22.2027C47.3914 22.5971 45.2664 23.7827 44.2613 25.4505C44.1928 25.036 44.1837 24.6156 44.2398 24.1938C44.5454 21.8955 46.7081 20.1435 49.6598 19.4565Z"
        fill={frameColor}
      />
      <rect
        x="18"
        y="38"
        width="60"
        height="60"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="54%"
        height="51px"
        style={{ borderRadius: "3px" }}
        x="22"
        y="42">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="99">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
