import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const ShoppingFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
      </style>
      <g
        id="svg_13"
        stroke="null">
        <path
          d="M14.680866073072107,267.5153306022644 C11.642744456727653,207.3603752038956 59.59741537835851,156.922945022583 119.82922660997167,156.922945022583 L782.1706918702866,156.922945022583 C842.6109613893295,156.922945022583 890.6426987081315,207.69727748661043 887.2905209951189,268.0448988779068 L841.0833201011444,1099.7755677890777 C837.9838191001679,1155.5644801673889 791.8397873842026,1199.2143821716309 735.9634909763123,1199.2143821716309 L161.83567716634525,1199.2143821716309 C105.75376508403555,1199.2143821716309 59.51613770265356,1155.2486342773436 56.6873166294457,1099.238629776001 L14.680866073072107,267.5153306022644 z"
          fill={frameColor}
          id="svg_2"
          stroke="null"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M528.183398042276,315.845890045166 C589.3648525832916,315.845890045166 637.6619004497315,263.87344883823397 633.1832057288912,202.85518134040834 L625.4534039798524,97.5737444018364 C621.4158406854416,42.56676116531372 575.6097640718247,0 520.4546491128708,0 L374.5633268617417,0 C319.7030014001633,0 274.0380026174332,42.126050866737366 269.6224770746018,96.80760755455017 L261.1209585342194,202.08978146686553 C256.17165343721166,263.3765179712296 304.5739832669999,315.845890045166 366.06075550172585,315.845890045166 L528.183398042276,315.845890045166 zM510.2644078803803,263.2049083709717 C546.9983377122666,263.2049083709717 575.9856206809785,231.98143650074007 573.2598706498886,195.34857735366822 L566.992435371759,111.12300667495728 C564.5393656257417,78.1451161674881 537.0670901096131,52.640981674194336 503.9980254218842,52.640981674194336 L390.5472345372941,52.640981674194336 C357.658202006891,52.640981674194336 330.2712048810746,77.87738413469314 327.58862045495766,110.6576603969574 L320.6958103145386,194.88323107566833 C317.6836933431413,231.67927726593018 346.73414548986216,263.2049083709717 383.65442439687513,263.2049083709717 L510.2644078803803,263.2049083709717 z"
          fill={frameColor}
          id="svg_3"
          stroke="null"></path>
        <rect
          x="113.37688429208532"
          y="242.14851570129395"
          width="673.8045654296875"
          height="673.8045654296875"
          fill="white"
          id="svg_12"
          stroke={frameBackgroundColor}
          strokeWidth="37"></rect>
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 5 152)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="1091.3999691545966"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
