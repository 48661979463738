import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";

export interface Filter {
  id: string;
  data: FilterData[];
  isLoading?: boolean;
  requestHandler: (data?: any) => Promise<any>;
}

export interface FilterData {
  field: string;
  value: any;
  operator?: string;
}

export type FilterNextPrevType = "next" | "prev" | undefined;

export enum FilterNextPrev {
  NEXT = "next",
  PREV = "prev",
}

export enum FilterOrderByValue {
  MOST_RECENT = "mostRecent",
  LAST_UPDATED = "lastUpdated",
  NAME = "name",
}

export enum FilterOrderTypeValue {
  ASC = "asc",
  DESC = "desc",
}

export enum FilterActionType {
  ALL = "All",
  PAUSED = "Paused",
  ACTIVE = "Active",
}

export enum FilterOperator {
  EQ = "EQ",
  IN = "IN",
  GT = "GT",
  LT = "LT",
  CONTAINS = "CONTAINS",
  BETWEEN = "BETWEEN",
}

export interface Data {
  id: string;
  data: any | any[];
  isLoading?: boolean;
}

export interface FilterDispatcherOptions {
  dataId?: string;
  request?: (data?: any) => Promise<any>;
}

export interface FiltersContextProps {
  data: Data[];
  filters: Filter[];
  isLoading: boolean;
  setInitialState: (initialState: FiltersInitialStateProps) => void;
  addFilter: (filter: Filter) => void;
  removeFilter: (ids: string[] | "all") => void;
  updateFilter: (id: string, filter: Filter) => void;
  getFilter: (id: string) => Filter | undefined;
  getData: (id: string) => Data | undefined;
  removeData: (id: string) => void;
  updateData: (id: string, data: Data) => void;
  dispatchFilterRequest: (filterId: string, opts?: FilterDispatcherOptions) => Promise<void>;
  resetFilterData: (id: string) => void;
}

export interface FiltersInitialStateProps {
  filters: Filter[];
  data?: Data[];
}

export interface FiltersRequestResponseProps {
  total: number;
  qrCodes: QrCodeResponse[];
}
