import React from "react";

import { Skeleton } from "../Skeleton";

import { LazyProps, LazyTypesString } from "./types";

export const Lazy = (props: LazyProps) => {
  const { children, placeholderClass, preventLazy, isLoaded } = props;

  const [isElementLoaded, setIsElementLoaded] = React.useState(isLoaded || false);

  const getElementType = () => {
    let elementType: LazyTypesString;

    if (React.isValidElement(children) && children.type === "img") {
      elementType = "img";
    } else if (React.isValidElement(children) && children.type === "video") {
      // TODO: Develop when needed
      elementType = "video";
    } else {
      elementType = "component";
    }

    return elementType;
  };

  const getLazyElement = React.useCallback(() => {
    const elementType = getElementType();
    let lazyElement = <Skeleton />;

    if (elementType === "img") {
      const Image = React.cloneElement(children, {
        onLoad: () => setIsElementLoaded(true),
        id: isElementLoaded ? undefined : "placeholderImage",
        style: isElementLoaded ? undefined : { display: "none" },
      });

      lazyElement = (
        <React.Fragment>
          {!isElementLoaded ? <Skeleton className={placeholderClass} /> : undefined}
          {Image}
        </React.Fragment>
      );
    } else {
      // TODO: Add video element when needed
      lazyElement = (
        <React.Fragment>
          {!isElementLoaded ? <Skeleton className={placeholderClass} /> : children}
        </React.Fragment>
      );
    }

    return lazyElement;
  }, [isElementLoaded, children]);

  React.useEffect(() => {
    if (isLoaded) {
      setIsElementLoaded(isLoaded);
    }
  }, [isLoaded]);

  return <React.Fragment>{preventLazy ? children : getLazyElement()}</React.Fragment>;
};
