import { ImageCropper } from "@/components/ImageCropper";
import { ImageCropperModalProps } from "@/components/ImageCropper/types";
import { ModalProps } from "@/contexts/ModalContext/types";
import React from "react";

const ImageCropperComponent = ({
  aspectRatio,
  image,
  onConfirmButton,
  getFile,
  fileType,
}: ImageCropperModalProps) => {
  const [croppedImage, setCroppedImage] = React.useState<Blob>(null);
  const onCroppedImage = (image: Blob) => {
    setCroppedImage(image);
  };

  React.useEffect(() => {
    if (croppedImage && onConfirmButton) {
      onConfirmButton(croppedImage);
    }
  }, [croppedImage]);

  return (
    <ImageCropper
      getFile={getFile}
      fileType={fileType}
      image={image}
      onCroppedImage={(image: Blob) => onCroppedImage(image)}
      aspectRatio={aspectRatio}
    />
  );
};

export const getImageCropperModal = (props: ModalProps<ImageCropperModalProps>) => {
  const { data, onConfirmButton } = props;

  const { aspectRatio, image, getFile, fileType } = data;

  return (
    <React.Fragment>
      <ImageCropperComponent
        image={image}
        getFile={getFile}
        fileType={fileType}
        aspectRatio={aspectRatio}
        onConfirmButton={onConfirmButton}
      />
    </React.Fragment>
  );
};
