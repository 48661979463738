import React from "react";

import { QRFRameProps } from "../types";
import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const shoppingbagFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        opacity="0.945"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.948 12.0316C44.1183 11.9464 51.2736 12.0336 58.4139 12.293C60.4426 14.2445 62.4074 16.249 64.3082 18.3065C64.3961 22.4894 64.4255 26.6727 64.3961 30.8563C68.9126 30.8272 73.4285 30.8563 77.9443 30.9435C78.4249 31.0128 78.7475 31.2743 78.912 31.7278C79.0293 51.5984 79.0293 71.469 78.912 91.3396C78.4308 93.1527 77.2871 94.3439 75.481 94.9128C56.8889 95.0291 38.297 95.0291 19.705 94.9128C18.0089 94.511 16.8652 93.4943 16.2739 91.8625C15.9821 71.8251 15.9234 51.7802 16.098 31.7278C16.2625 31.2743 16.5851 31.0128 17.0657 30.9435C21.5815 30.8563 26.0974 30.8272 30.6138 30.8563C30.5844 26.6727 30.6138 22.4894 30.7018 18.3065C32.7016 16.122 34.7838 14.0304 36.948 12.0316ZM38.7075 14.6461C44.5724 14.6461 50.4375 14.6461 56.3025 14.6461C56.3025 15.6338 56.3025 16.6215 56.3025 17.6093C50.4375 17.6093 44.5724 17.6093 38.7075 17.6093C38.7075 16.6215 38.7075 15.6338 38.7075 14.6461ZM58.7658 16.3891C59.7565 17.2834 60.7242 18.213 61.6689 19.178C61.7569 23.0703 61.7863 26.9631 61.7569 30.8563C60.7598 30.8563 59.7629 30.8563 58.7658 30.8563C58.7658 26.0339 58.7658 21.2115 58.7658 16.3891ZM35.8923 16.5634C36.0679 21.2978 36.1265 26.0621 36.0683 30.8563C35.0712 30.8563 34.0742 30.8563 33.0771 30.8563C33.0477 27.0211 33.0771 23.1865 33.1651 19.3523C34.1099 18.4456 35.0189 17.516 35.8923 16.5634ZM38.7075 20.0495C44.5724 20.0495 50.4375 20.0495 56.3025 20.0495C56.3025 23.7099 56.3025 27.3703 56.3025 31.0306C50.437 31.0047 44.5721 30.9464 38.7075 30.8563C38.7075 27.254 38.7075 23.6518 38.7075 20.0495Z"
        fill={frameColor}
      />
      <path
        d="M20 36H75V84C75 87.3137 72.3137 90 69 90H26C22.6863 90 20 87.3137 20 84V36Z"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="47%"
        height="45px"
        style={{ borderRadius: "3px" }}
        x="25"
        y="41">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="97">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
