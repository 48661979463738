import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const beautyFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } =
    props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" stroke="#BFBFBF" />
      <path
        d="M73.86 78.86V27.14H22.14V78.86H73.86ZM74.2946 26.7054V79.2946H21.7054V26.7054H74.2946Z"
        fill={frameColor}
      />
      <path
        d="M73.1981 78.1981V27.8019H22.8019V78.1981H73.1981ZM73.4182 27.5818V78.4182H22.5818V27.5818H73.4182Z"
        fill={frameColor}
      />
      <path
        d="M20.9102 25.3702C22.6917 26.6975 22.9765 24.5361 25.9137 24.6084C23.3976 23.6344 21.5756 23.9366 20.9102 25.3702Z"
        fill={frameColor}
      />
      <path
        d="M50.2951 19.9185C51.0208 20.7924 52.7854 19.221 51.3577 17.9705C50.2445 16.9952 48.1013 18.2776 48.1519 20.1428C49.5441 17.4259 51.7127 19.3662 50.2951 19.9185Z"
        fill={frameColor}
      />
      <path
        d="M45.7049 19.9185C44.9792 20.7924 43.2146 19.221 44.6422 17.9705C45.7555 16.9952 47.8987 18.2776 47.8481 20.1428C46.4559 17.4259 44.2873 19.3662 45.7049 19.9185Z"
        fill={frameColor}
      />
      <path
        d="M46.6753 16.9785C47.9916 17.9657 48 19.7628 48 19.7628C48 19.7628 48.0084 17.9657 49.3247 16.9785C49.561 15.949 48.4641 16.3706 48 15C47.5359 16.3706 46.439 15.949 46.6753 16.9785Z"
        fill={frameColor}
      />
      <path
        d="M48 20.9452C48.0227 20.5805 49.3753 19.9449 50.2243 20.6021C48.392 21.0564 48.0681 23.2676 48 23.7164C47.9319 23.2676 47.608 21.0564 45.7757 20.6021C46.6247 19.9449 47.9773 20.5805 48 20.9452Z"
        fill={frameColor}
      />
      <path
        d="M56.9936 19.6517C60.0214 20.204 61.1563 22.3749 63.6071 23.5545C66.3249 24.8619 69.4109 24.1297 70.4134 21.8178C71.026 20.4048 70.2783 18.7055 68.6727 18.4131C66.424 18.0032 65.5216 21.0237 67.2497 21.4412C68.2997 21.6948 68.4499 20.1137 67.6211 20.288C68.3155 19.353 69.7365 20.7299 68.8919 21.8859C67.8527 23.3086 64.6462 22.3895 65.2446 19.66C65.6141 17.9754 67.4263 17.4669 68.5915 17.6885C71.5253 18.2463 72.335 22.791 68.5672 24.2131C69.6244 24.7154 69.4038 25.6531 69.0671 25.9956C68.3387 24.6327 66.2722 25.6181 63.1544 23.9887C61.1442 22.9383 60.6304 21.2085 56.9936 19.6517Z"
        fill={frameColor}
      />
      <path
        d="M80.2382 20.9814C79.3427 21.9081 77.6483 21.8657 76.0512 20.8792L76.1638 20.7848C77.4434 21.5455 78.8286 21.7254 79.7915 20.7549C80.8974 19.6406 79.449 17.8469 78.5521 18.6757C77.7873 19.3822 78.9287 20.3381 79.317 19.6204C79.4282 20.3263 78.2548 20.5687 77.872 19.6621C77.3059 18.3214 79.0238 16.9431 80.3667 18.3158C81.1955 19.1626 80.8044 20.3957 80.2382 20.9814Z"
        fill={frameColor}
      />
      <path
        d="M64.588 17.2814C65.0762 17.8385 66.0499 17.8615 66.9941 17.3397L66.9325 17.2828C66.1766 17.6829 65.3761 17.7475 64.8508 17.164C64.2471 16.4929 65.1285 15.5037 65.6203 16.0045C66.0397 16.4318 65.3574 16.9486 65.1547 16.5256C65.071 16.9278 65.738 17.1001 65.983 16.5902C66.3454 15.8364 65.398 14.9972 64.5888 15.7475C64.0894 16.2109 64.2794 16.9292 64.588 17.2814Z"
        fill={frameColor}
      />
      <path
        d="M63.7919 17.594C64.2199 17.1279 68.4562 16.1205 70.557 19.0202H70.4709C68.3935 16.9299 65.3108 17.0396 63.8711 17.6961C63.8711 17.6961 63.8862 17.569 63.7919 17.594Z"
        fill={frameColor}
      />
      <path
        d="M71.6745 18.9056C70.8092 18.0699 72.362 17.089 73.0862 18.627C73.5214 19.551 73.2487 20.4666 72.8956 21.1259C73.2355 20.7841 73.6216 20.527 74.0796 20.395C75.708 19.9268 76.6521 20.9619 76.8404 21.94C77.0627 23.1023 75.9554 24.4201 74.6609 23.8991C73.8487 23.5719 73.5753 22.0067 74.8162 21.881C74.4272 22.7772 75.9227 23.1655 76.0179 21.9956C76.0811 21.2287 75.0832 20.443 74.0835 20.8306C72.5978 21.4058 71.9276 24.3548 69.1211 24.268C73.3089 23.1815 73.0815 18.8931 71.6745 18.9056Z"
        fill={frameColor}
      />
      <path
        d="M48.4869 23.9477C48.8846 23.1717 50.9621 20.4867 52.693 20.7674C53.6089 20.9154 53.4883 22.0213 53.1113 22.0929C54.6847 22.8619 55.492 20.6326 53.8871 20.1046C59.5817 18.675 60.7356 24.5597 57.6107 25.42C55.4061 26.0263 54.317 22.9932 55.9255 22.5284C57.2089 22.1582 57.2406 23.963 56.3996 23.8623C57.2688 24.6591 58.6006 23.1537 57.6574 22.0755C56.4959 20.75 53.2482 21.8136 54.086 24.4798C54.6021 26.1262 56.4525 26.4718 57.5934 26.148C58.643 25.8513 59.3623 25.1419 59.6643 24.1902C59.8381 24.9807 60.2467 25.5265 61.0432 25.4526C62.2785 25.3374 62.5382 23.699 61.2159 23.5872C61.4387 23.8998 61.3802 24.5424 60.7758 24.5146C59.5579 24.4597 60.8544 20.7292 56.9936 19.6517C58.0019 19.0584 57.6987 18.1435 57.3337 17.8316C56.7285 19.2536 54.3315 18.2415 51.4011 20.1873C49.1052 21.7115 48.5119 23.8546 48.4869 23.9477Z"
        fill={frameColor}
      />
      <path
        d="M73.7896 19.7865C74.3507 19.5399 75.187 19.2349 75.8623 19.9164C76.2714 19.1293 75.8366 18.8382 75.5633 18.759C75.7907 18.4797 76.1631 17.8309 75.856 17.3314C75.3115 17.114 74.736 17.5912 74.4991 17.8635C74.3744 17.6079 74.0136 17.2286 73.3081 17.7663C74.095 18.3158 73.9373 19.1918 73.7896 19.7865Z"
        fill={frameColor}
      />
      <path
        d="M62.2244 18.5603C62.2244 18.5603 62.2207 18.4936 62.2169 18.386C62.1912 18.4825 62.1741 18.5416 62.1741 18.5416C62.1741 18.5416 62.157 18.4825 62.1313 18.386C62.1275 18.4936 62.1238 18.5603 62.1238 18.5603C62.1238 18.5603 61.0665 16.9688 61.6676 16.5033C61.7802 16.4165 61.8666 16.4339 61.9327 16.52C61.9761 16.3936 62.0523 16.3151 62.1741 16.3151C62.2959 16.3151 62.3721 16.3936 62.4155 16.52C62.4816 16.4339 62.568 16.4165 62.6806 16.5033C63.2817 16.9688 62.2244 18.5603 62.2244 18.5603Z"
        fill={frameColor}
      />
      <path
        d="M62.2244 19.4482C62.2244 19.4482 62.2207 19.5142 62.2169 19.6225C62.1912 19.5253 62.1741 19.4669 62.1741 19.4669C62.1741 19.4669 62.157 19.5253 62.1313 19.6225C62.1275 19.5142 62.1238 19.4482 62.1238 19.4482C62.1238 19.4482 61.0665 21.0397 61.6676 21.5052C61.7802 21.592 61.8666 21.5746 61.9327 21.4878C61.9761 21.6149 62.0523 21.6934 62.1741 21.6934C62.2959 21.6934 62.3721 21.6149 62.4155 21.4878C62.4816 21.5746 62.568 21.592 62.6806 21.5052C63.2817 21.0397 62.2244 19.4482 62.2244 19.4482Z"
        fill={frameColor}
      />
      <path
        d="M62.539 18.6562C62.5639 18.6423 62.5987 18.6222 62.6422 18.5972C62.577 18.6659 62.5357 18.7125 62.5259 18.7243C62.5683 18.7173 63.2572 18.5721 63.7646 18.2922C64.0873 18.1136 64.3372 17.8816 64.3003 17.5933C64.296 17.5558 64.2873 17.5238 64.2731 17.4988C64.2405 17.4308 64.1797 17.4078 64.1014 17.4189C64.1601 17.2981 64.1612 17.189 64.0754 17.1022C63.9895 17.0167 63.8798 17.0174 63.7603 17.0772C63.769 17.0077 63.7526 16.9535 63.7016 16.9174C63.6734 16.8979 63.6343 16.884 63.5842 16.8778C62.8301 16.7819 62.4521 18.6548 62.4521 18.6548C62.4521 18.6548 62.4977 18.6145 62.5705 18.5464C62.5737 18.543 62.577 18.5402 62.5814 18.5367C62.576 18.5464 62.5705 18.5548 62.565 18.5638C62.5238 18.6347 62.5009 18.677 62.5009 18.677C62.5009 18.677 62.514 18.6694 62.539 18.6562Z"
        fill={frameColor}
      />
      <path
        d="M61.8957 19.3537C61.8957 19.3537 61.8462 19.3981 61.767 19.4718C61.8176 19.3849 61.8469 19.3314 61.8469 19.3314C61.8469 19.3314 61.7934 19.3606 61.7065 19.4113C61.7803 19.3321 61.8245 19.2828 61.8245 19.2828C61.8245 19.2828 59.9515 19.6607 60.0477 20.4145C60.0657 20.5555 60.1393 20.6041 60.247 20.5895C60.1879 20.7104 60.1865 20.8195 60.2728 20.9056C60.3589 20.9918 60.468 20.9904 60.5884 20.9313C60.5742 21.039 60.6227 21.1127 60.7639 21.1307C61.5179 21.2266 61.8957 19.3537 61.8957 19.3537Z"
        fill={frameColor}
      />
      <path
        d="M62.6181 19.0543C62.6181 19.0543 62.6844 19.0508 62.7926 19.0473C62.6954 19.0209 62.6368 19.0042 62.6368 19.0042C62.6368 19.0042 62.6954 18.9869 62.7926 18.9612C62.6844 18.9577 62.6181 18.9535 62.6181 18.9535C62.6181 18.9535 64.2098 17.8969 64.6749 18.4978C64.762 18.6104 64.7442 18.6965 64.6581 18.7625C64.785 18.8063 64.863 18.882 64.863 19.0042C64.863 19.1258 64.785 19.2022 64.6581 19.2453C64.7442 19.312 64.762 19.3981 64.6749 19.5107C64.2098 20.1116 62.6181 19.0543 62.6181 19.0543Z"
        fill={frameColor}
      />
      <path
        d="M61.7301 19.0543C61.7301 19.0543 61.6637 19.0508 61.5556 19.0473C61.6528 19.0209 61.7114 19.0042 61.7114 19.0042C61.7114 19.0042 61.6528 18.9869 61.5556 18.9612C61.6637 18.9577 61.7301 18.9535 61.7301 18.9535C61.7301 18.9535 60.1384 17.8969 59.6733 18.4978C59.5862 18.6104 59.6039 18.6965 59.6901 18.7625C59.5633 18.8063 59.4852 18.882 59.4852 19.0042C59.4852 19.1258 59.5633 19.2022 59.6901 19.2453C59.6039 19.312 59.5862 19.3981 59.6733 19.5107C60.1384 20.1116 61.7301 19.0543 61.7301 19.0543Z"
        fill={frameColor}
      />
      <path
        d="M62.4525 19.3537C62.4525 19.3537 62.502 19.3981 62.5812 19.4718C62.5307 19.3849 62.5013 19.3314 62.5013 19.3314C62.5013 19.3314 62.5548 19.3606 62.6417 19.4113C62.5679 19.3321 62.5236 19.2828 62.5236 19.2828C62.5236 19.2828 64.3968 19.6607 64.3005 20.4145C64.2826 20.5555 64.2089 20.6041 64.1012 20.5895C64.1603 20.7104 64.1616 20.8195 64.0755 20.9056C63.9893 20.9918 63.8803 20.9904 63.7598 20.9313C63.774 21.039 63.7255 21.1127 63.5843 21.1307C62.8304 21.2266 62.4525 19.3537 62.4525 19.3537Z"
        fill={frameColor}
      />
      <path
        d="M61.8245 18.7257C61.8245 18.7257 61.7803 18.6763 61.7065 18.5971C61.7934 18.6479 61.8469 18.677 61.8469 18.677C61.8469 18.677 61.8176 18.6235 61.767 18.5367C61.8462 18.6103 61.8957 18.6548 61.8957 18.6548C61.8957 18.6548 61.5179 16.7812 60.7639 16.8778C60.6227 16.8958 60.5742 16.9695 60.5884 17.0771C60.468 17.0181 60.3589 17.0167 60.2728 17.1029C60.1865 17.189 60.1879 17.2981 60.247 17.4182C60.1392 17.4043 60.0657 17.453 60.0477 17.594C59.9515 18.3478 61.8245 18.7257 61.8245 18.7257Z"
        fill={frameColor}
      />
      <path
        d="M61.9535 19.0042C61.9535 19.1258 62.0523 19.2245 62.1741 19.2245C62.2959 19.2245 62.3947 19.1258 62.3947 19.0042C62.3947 18.882 62.2959 18.7833 62.1741 18.7833C62.0523 18.7833 61.9535 18.882 61.9535 19.0042Z"
        fill={frameColor}
      />
      <path
        d="M53.1802 25.1655C53.3892 25.1586 53.5647 25.3218 53.5722 25.5308C53.5797 25.7398 53.4163 25.9154 53.2072 25.9229C52.9981 25.9304 52.8226 25.7669 52.8152 25.5579C52.8077 25.3488 52.9711 25.1732 53.1802 25.1655Z"
        fill={frameColor}
      />
      <path
        d="M53.2271 24.9377C53.0368 24.9446 52.8769 24.7952 52.8701 24.6049C52.8633 24.4146 53.0121 24.2548 53.2025 24.2478C53.3929 24.2416 53.5527 24.3902 53.5595 24.5806C53.5663 24.7709 53.4175 24.9307 53.2271 24.9377Z"
        fill={frameColor}
      />
      <path
        d="M52.6732 23.9818C52.5015 23.9873 52.3574 23.8532 52.3512 23.6816C52.3451 23.5101 52.4793 23.3656 52.6509 23.36C52.8227 23.3538 52.9668 23.4878 52.9729 23.6594C52.9791 23.831 52.8449 23.9755 52.6732 23.9818Z"
        fill={frameColor}
      />
      <path
        d="M51.7366 23.3927C51.5835 23.3982 51.4551 23.2787 51.4496 23.1259C51.4442 22.9724 51.5637 22.8438 51.7167 22.839C51.8697 22.8334 51.9982 22.9529 52.0037 23.1057C52.0091 23.2586 51.8895 23.3871 51.7366 23.3927Z"
        fill={frameColor}
      />
      <path
        d="M50.7597 23.244C50.6253 23.2488 50.5126 23.1439 50.5078 23.0099C50.503 22.8758 50.608 22.7626 50.7423 22.7577C50.8766 22.7535 50.9894 22.8584 50.9941 22.9925C50.9989 23.1266 50.894 23.2398 50.7597 23.244Z"
        fill={frameColor}
      />
      <path
        d="M49.7837 23.4079C49.668 23.4121 49.571 23.3218 49.5668 23.2058C49.5627 23.0905 49.6531 22.9932 49.7687 22.989C49.8843 22.9849 49.9814 23.0752 49.9855 23.1912C49.9897 23.3065 49.8993 23.4038 49.7837 23.4079Z"
        fill={frameColor}
      />
      <path
        d="M49.1273 23.8644C49.0304 23.8678 48.949 23.7921 48.9455 23.6948C48.942 23.5976 49.0178 23.5163 49.1147 23.5128C49.2117 23.5094 49.293 23.5851 49.2965 23.6823C49.3 23.7789 49.2242 23.8609 49.1273 23.8644Z"
        fill={frameColor}
      />
      <path
        d="M78.1548 20.6083C78.0374 20.7618 78.0658 20.9814 78.2194 21.0988C78.3729 21.2162 78.5924 21.187 78.7098 21.0335C78.8279 20.8799 78.7988 20.6604 78.6452 20.543C78.4917 20.4256 78.2722 20.4548 78.1548 20.6083Z"
        fill={frameColor}
      />
      <path
        d="M77.8373 20.6041C77.9443 20.4645 77.9179 20.2644 77.7783 20.1574C77.6379 20.0505 77.4385 20.0769 77.3309 20.2165C77.2239 20.3561 77.251 20.5562 77.3906 20.6632C77.5302 20.7702 77.7303 20.7438 77.8373 20.6041Z"
        fill={frameColor}
      />
      <path
        d="M77.4135 19.6753C77.5101 19.5489 77.4865 19.369 77.36 19.2724C77.2343 19.1758 77.0537 19.2002 76.9571 19.3259C76.8605 19.4516 76.8849 19.6323 77.0106 19.7288C77.137 19.8254 77.317 19.8011 77.4135 19.6753Z"
        fill={frameColor}
      />
      <path
        d="M77.4712 18.6548C77.5573 18.5423 77.5365 18.3818 77.424 18.2957C77.3114 18.2095 77.1509 18.231 77.0648 18.3436C76.9787 18.4554 77.0002 18.6166 77.1127 18.7027C77.2246 18.7882 77.3857 18.7674 77.4712 18.6548Z"
        fill={frameColor}
      />
      <path
        d="M77.9992 17.8899C78.0749 17.7913 78.0561 17.6503 77.9575 17.5746C77.8588 17.4988 77.7178 17.5176 77.6428 17.6162C77.567 17.7149 77.5858 17.8559 77.6844 17.9316C77.7831 18.0074 77.9241 17.9886 77.9992 17.8899Z"
        fill={frameColor}
      />
      <path
        d="M78.8251 17.4995C78.8904 17.4148 78.8745 17.2932 78.7897 17.2279C78.705 17.1633 78.5834 17.1793 78.5181 17.264C78.4535 17.3488 78.4695 17.4703 78.5542 17.5356C78.639 17.6003 78.7605 17.5843 78.8251 17.4995Z"
        fill={frameColor}
      />
      <path
        d="M79.5907 17.4683C79.6449 17.3974 79.6317 17.2953 79.5602 17.2411C79.4893 17.1862 79.3872 17.2001 79.333 17.271C79.2788 17.3425 79.292 17.4439 79.3629 17.4988C79.4344 17.553 79.5358 17.5398 79.5907 17.4683Z"
        fill={frameColor}
      />
      <path
        d="M48.231 25.8578C48.6878 24.0276 51.4665 23.4656 51.8365 24.8098C51.465 24.4771 50.6847 25.2621 51.343 25.7698C52.1382 26.383 53.2604 25.087 52.0776 24.063C50.7796 22.939 48.231 24.0158 48.231 25.8578Z"
        fill={frameColor}
      />
      <path
        d="M39.0064 19.6517C35.9786 20.204 34.8437 22.3749 32.3929 23.5545C29.6751 24.8619 26.5891 24.1297 25.5866 21.8178C24.974 20.4048 25.7217 18.7055 27.3273 18.4131C29.576 18.0032 30.4784 21.0237 28.7503 21.4412C27.7003 21.6948 27.5501 20.1137 28.3789 20.288C27.6845 19.353 26.2635 20.7299 27.108 21.8859C28.1472 23.3086 31.3538 22.3895 30.7554 19.66C30.3859 17.9754 28.5737 17.4669 27.4085 17.6885C24.4747 18.2463 23.665 22.791 27.4328 24.2131C26.3756 24.7154 26.5962 25.6531 26.9329 25.9956C27.6612 24.6327 29.7278 25.6181 32.8456 23.9887C34.8558 22.9383 35.3695 21.2085 39.0064 19.6517Z"
        fill={frameColor}
      />
      <path
        d="M15.762 20.9814C16.6575 21.9081 18.3519 21.8657 19.9486 20.8792L19.8366 20.7848C18.5569 21.5455 17.1712 21.7254 16.2088 20.7549C15.1028 19.6406 16.5509 17.8469 17.4481 18.6757C18.2129 19.3822 17.0711 20.3381 16.6829 19.6204C16.5715 20.3263 17.7453 20.5687 18.1278 19.6621C18.6938 18.3214 16.9763 16.9431 15.6337 18.3158C14.8047 19.1626 15.1958 20.3957 15.762 20.9814Z"
        fill={frameColor}
      />
      <path
        d="M31.412 17.2814C30.9238 17.8385 29.9501 17.8615 29.0059 17.3397L29.0675 17.2828C29.8234 17.6829 30.6239 17.7475 31.1492 17.164C31.753 16.4929 30.8715 15.5037 30.3797 16.0045C29.9604 16.4318 30.6426 16.9486 30.8453 16.5256C30.929 16.9278 30.262 17.1001 30.017 16.5902C29.6546 15.8364 30.602 14.9972 31.4112 15.7475C31.9107 16.2109 31.7207 16.9292 31.412 17.2814Z"
        fill={frameColor}
      />
      <path
        d="M32.2081 17.594C31.7801 17.1279 27.5438 16.1205 25.443 19.0202H25.5291C27.6065 16.9299 30.6892 17.0396 32.1289 17.6961C32.1289 17.6961 32.1138 17.569 32.2081 17.594Z"
        fill={frameColor}
      />
      <path
        d="M24.3255 18.9056C25.1908 18.0699 23.638 17.089 22.9138 18.627C22.4787 19.551 22.7513 20.4666 23.1044 21.1259C22.7645 20.7841 22.3784 20.527 21.9204 20.395C20.292 19.9268 19.3476 20.9619 19.1599 21.94C18.9369 23.1023 20.0444 24.4201 21.3391 23.8991C22.1513 23.5719 22.4247 22.0067 21.1838 21.881C21.5728 22.7772 20.0776 23.1655 19.9819 21.9956C19.9192 21.2287 20.9168 20.443 21.9165 20.8306C23.4023 21.4058 24.0724 24.3548 26.8789 24.268C22.6911 23.1815 22.9185 18.8931 24.3255 18.9056Z"
        fill={frameColor}
      />
      <path
        d="M47.5131 23.9477C47.1154 23.1717 45.0379 20.4867 43.307 20.7674C42.3911 20.9154 42.5117 22.0213 42.8887 22.0929C41.3153 22.8619 40.508 20.6326 42.1129 20.1046C36.4183 18.675 35.2644 24.5597 38.3893 25.42C40.5939 26.0263 41.683 22.9932 40.0745 22.5284C38.7911 22.1582 38.7594 23.963 39.6004 23.8623C38.7312 24.6591 37.3994 23.1537 38.3426 22.0755C39.504 20.75 42.7518 21.8136 41.914 24.4798C41.3979 26.1262 39.5475 26.4718 38.4066 26.148C37.357 25.8513 36.6377 25.1419 36.3357 24.1902C36.1618 24.9807 35.7533 25.5265 34.9568 25.4526C33.7214 25.3374 33.4618 23.699 34.784 23.5872C34.5613 23.8998 34.6198 24.5424 35.2241 24.5146C36.4421 24.4597 35.1455 20.7292 39.0064 19.6517C37.9981 19.0584 38.3012 18.1435 38.6663 17.8316C39.2715 19.2536 41.6685 18.2415 44.5989 20.1873C46.8948 21.7115 47.4881 23.8546 47.5131 23.9477Z"
        fill={frameColor}
      />
      <path
        d="M22.2104 19.7865C21.6493 19.5399 20.813 19.2349 20.1375 19.9164C19.7282 19.1293 20.1634 18.8382 20.4367 18.759C20.209 18.4797 19.837 17.8309 20.144 17.3314C20.6885 17.114 21.264 17.5912 21.5009 17.8635C21.6256 17.6079 21.9864 17.2286 22.6919 17.7663C21.905 18.3158 22.0627 19.1918 22.2104 19.7865Z"
        fill={frameColor}
      />
      <path
        d="M33.7756 18.5603C33.7756 18.5603 33.7793 18.4936 33.7831 18.386C33.8088 18.4825 33.8259 18.5416 33.8259 18.5416C33.8259 18.5416 33.843 18.4825 33.8687 18.386C33.8725 18.4936 33.8762 18.5603 33.8762 18.5603C33.8762 18.5603 34.9336 16.9688 34.3324 16.5033C34.2198 16.4165 34.1334 16.4339 34.0673 16.52C34.0239 16.3936 33.9478 16.3151 33.8259 16.3151C33.7041 16.3151 33.6279 16.3936 33.5845 16.52C33.5184 16.4339 33.432 16.4165 33.3194 16.5033C32.7183 16.9688 33.7756 18.5603 33.7756 18.5603Z"
        fill={frameColor}
      />
      <path
        d="M33.7756 19.4482C33.7756 19.4482 33.7793 19.5142 33.7831 19.6225C33.8088 19.5253 33.8259 19.4669 33.8259 19.4669C33.8259 19.4669 33.843 19.5253 33.8687 19.6225C33.8725 19.5142 33.8762 19.4482 33.8762 19.4482C33.8762 19.4482 34.9336 21.0397 34.3324 21.5052C34.2198 21.592 34.1334 21.5746 34.0673 21.4878C34.0239 21.6149 33.9478 21.6934 33.8259 21.6934C33.7041 21.6934 33.6279 21.6149 33.5845 21.4878C33.5184 21.5746 33.432 21.592 33.3194 21.5052C32.7183 21.0397 33.7756 19.4482 33.7756 19.4482Z"
        fill={frameColor}
      />
      <path
        d="M33.461 18.6562C33.4361 18.6423 33.4013 18.6222 33.3578 18.5972C33.423 18.6659 33.4643 18.7125 33.4741 18.7243C33.4317 18.7173 32.7428 18.5721 32.2354 18.2922C31.9127 18.1136 31.6628 17.8816 31.6997 17.5933C31.704 17.5558 31.7127 17.5238 31.7269 17.4988C31.7595 17.4308 31.8203 17.4078 31.8986 17.4189C31.8399 17.2981 31.8388 17.189 31.9246 17.1022C32.0105 17.0167 32.1202 17.0174 32.2397 17.0772C32.231 17.0077 32.2474 16.9535 32.2984 16.9174C32.3266 16.8979 32.3657 16.884 32.4158 16.8778C33.1699 16.7819 33.5479 18.6548 33.5479 18.6548C33.5479 18.6548 33.5023 18.6145 33.4295 18.5464C33.4263 18.543 33.423 18.5402 33.4186 18.5367C33.424 18.5464 33.4295 18.5548 33.4349 18.5638C33.4762 18.6347 33.4991 18.677 33.4991 18.677C33.4991 18.677 33.486 18.6694 33.461 18.6562Z"
        fill={frameColor}
      />
      <path
        d="M34.1043 19.3537C34.1043 19.3537 34.1538 19.3981 34.233 19.4718C34.1825 19.3849 34.1531 19.3314 34.1531 19.3314C34.1531 19.3314 34.2066 19.3606 34.2935 19.4113C34.2197 19.3321 34.1755 19.2828 34.1755 19.2828C34.1755 19.2828 36.0485 19.6607 35.9523 20.4145C35.9343 20.5555 35.8608 20.6041 35.753 20.5895C35.8121 20.7104 35.8135 20.8195 35.7273 20.9056C35.6411 20.9918 35.532 20.9904 35.4116 20.9313C35.4258 21.039 35.3773 21.1127 35.2361 21.1307C34.4821 21.2266 34.1043 19.3537 34.1043 19.3537Z"
        fill={frameColor}
      />
      <path
        d="M33.3819 19.0543C33.3819 19.0543 33.3156 19.0508 33.2074 19.0473C33.3046 19.0209 33.3632 19.0042 33.3632 19.0042C33.3632 19.0042 33.3046 18.9869 33.2074 18.9612C33.3156 18.9577 33.3819 18.9535 33.3819 18.9535C33.3819 18.9535 31.7902 17.8969 31.3251 18.4978C31.238 18.6104 31.2558 18.6965 31.3419 18.7625C31.2151 18.8063 31.137 18.882 31.137 19.0042C31.137 19.1258 31.2151 19.2022 31.3419 19.2453C31.2558 19.312 31.238 19.3981 31.3251 19.5107C31.7902 20.1116 33.3819 19.0543 33.3819 19.0543Z"
        fill={frameColor}
      />
      <path
        d="M34.2699 19.0543C34.2699 19.0543 34.3362 19.0508 34.4444 19.0473C34.3472 19.0209 34.2886 19.0042 34.2886 19.0042C34.2886 19.0042 34.3472 18.9869 34.4444 18.9612C34.3362 18.9577 34.2699 18.9535 34.2699 18.9535C34.2699 18.9535 35.8616 17.8969 36.3267 18.4978C36.4137 18.6104 36.3961 18.6965 36.3099 18.7625C36.4367 18.8063 36.5148 18.882 36.5148 19.0042C36.5148 19.1258 36.4367 19.2022 36.3099 19.2453C36.3961 19.312 36.4137 19.3981 36.3267 19.5107C35.8616 20.1116 34.2699 19.0543 34.2699 19.0543Z"
        fill={frameColor}
      />
      <path
        d="M33.5475 19.3537C33.5475 19.3537 33.498 19.3981 33.4188 19.4718C33.4693 19.3849 33.4987 19.3314 33.4987 19.3314C33.4987 19.3314 33.4452 19.3606 33.3583 19.4113C33.4321 19.3321 33.4764 19.2828 33.4764 19.2828C33.4764 19.2828 31.6032 19.6607 31.6995 20.4145C31.7174 20.5555 31.7911 20.6041 31.8988 20.5895C31.8397 20.7104 31.8384 20.8195 31.9245 20.9056C32.0107 20.9918 32.1197 20.9904 32.2402 20.9313C32.226 21.039 32.2745 21.1127 32.4157 21.1307C33.1696 21.2266 33.5475 19.3537 33.5475 19.3537Z"
        fill={frameColor}
      />
      <path
        d="M34.1755 18.7257C34.1755 18.7257 34.2197 18.6763 34.2935 18.5971C34.2066 18.6479 34.1531 18.677 34.1531 18.677C34.1531 18.677 34.1825 18.6235 34.233 18.5367C34.1538 18.6103 34.1043 18.6548 34.1043 18.6548C34.1043 18.6548 34.4821 16.7812 35.2361 16.8778C35.3773 16.8958 35.4258 16.9695 35.4116 17.0771C35.532 17.0181 35.6411 17.0167 35.7273 17.1029C35.8135 17.189 35.8121 17.2981 35.753 17.4182C35.8608 17.4043 35.9343 17.453 35.9523 17.594C36.0485 18.3478 34.1755 18.7257 34.1755 18.7257Z"
        fill={frameColor}
      />
      <path
        d="M34.0465 19.0042C34.0465 19.1258 33.9477 19.2245 33.8259 19.2245C33.7041 19.2245 33.6053 19.1258 33.6053 19.0042C33.6053 18.882 33.7041 18.7833 33.8259 18.7833C33.9477 18.7833 34.0465 18.882 34.0465 19.0042Z"
        fill={frameColor}
      />
      <path
        d="M42.8198 25.1655C42.6108 25.1586 42.4353 25.3218 42.4278 25.5308C42.4203 25.7398 42.5837 25.9154 42.7928 25.9229C43.0019 25.9304 43.1774 25.7669 43.1849 25.5579C43.1923 25.3488 43.0289 25.1732 42.8198 25.1655Z"
        fill={frameColor}
      />
      <path
        d="M42.7729 24.9377C42.9632 24.9446 43.1231 24.7952 43.1299 24.6049C43.1367 24.4146 42.9879 24.2548 42.7975 24.2478C42.6071 24.2416 42.4473 24.3902 42.4405 24.5806C42.4337 24.7709 42.5825 24.9307 42.7729 24.9377Z"
        fill={frameColor}
      />
      <path
        d="M43.3268 23.9818C43.4985 23.9873 43.6426 23.8532 43.6488 23.6816C43.6549 23.5101 43.5207 23.3656 43.3491 23.36C43.1773 23.3538 43.0332 23.4878 43.0271 23.6594C43.0209 23.831 43.1551 23.9755 43.3268 23.9818Z"
        fill={frameColor}
      />
      <path
        d="M44.2634 23.3927C44.4165 23.3982 44.5449 23.2787 44.5504 23.1259C44.5558 22.9724 44.4363 22.8438 44.2833 22.839C44.1302 22.8334 44.0018 22.9529 43.9963 23.1057C43.9909 23.2586 44.1104 23.3871 44.2634 23.3927Z"
        fill={frameColor}
      />
      <path
        d="M45.2403 23.244C45.3747 23.2488 45.4874 23.1439 45.4922 23.0099C45.497 22.8758 45.392 22.7626 45.2577 22.7577C45.1234 22.7535 45.0106 22.8584 45.0059 22.9925C45.0011 23.1266 45.106 23.2398 45.2403 23.244Z"
        fill={frameColor}
      />
      <path
        d="M46.2163 23.4079C46.332 23.4121 46.429 23.3218 46.4332 23.2058C46.4373 23.0905 46.3469 22.9932 46.2313 22.989C46.1157 22.9849 46.0186 23.0752 46.0145 23.1912C46.0103 23.3065 46.1007 23.4038 46.2163 23.4079Z"
        fill={frameColor}
      />
      <path
        d="M46.8727 23.8644C46.9696 23.8678 47.051 23.7921 47.0545 23.6948C47.058 23.5976 46.9822 23.5163 46.8853 23.5128C46.7883 23.5094 46.707 23.5851 46.7035 23.6823C46.7 23.7789 46.7758 23.8609 46.8727 23.8644Z"
        fill={frameColor}
      />
      <path
        d="M17.8454 20.6083C17.963 20.7618 17.9338 20.9814 17.7804 21.0988C17.627 21.2162 17.4074 21.187 17.2898 21.0335C17.1723 20.8799 17.2014 20.6604 17.3549 20.543C17.5084 20.4256 17.728 20.4548 17.8454 20.6083Z"
        fill={frameColor}
      />
      <path
        d="M18.1628 20.6041C18.0559 20.4645 18.0824 20.2644 18.2221 20.1574C18.3618 20.0505 18.5618 20.0769 18.6688 20.2165C18.7758 20.3561 18.7492 20.5562 18.6095 20.6632C18.4698 20.7702 18.2698 20.7438 18.1628 20.6041Z"
        fill={frameColor}
      />
      <path
        d="M18.5864 19.6753C18.4899 19.5489 18.5138 19.369 18.6398 19.2724C18.7658 19.1758 18.9462 19.2002 19.0427 19.3259C19.1392 19.4516 19.1152 19.6323 18.9893 19.7288C18.8632 19.8254 18.6828 19.8011 18.5864 19.6753Z"
        fill={frameColor}
      />
      <path
        d="M18.5285 18.6548C18.4425 18.5423 18.4638 18.3818 18.5761 18.2957C18.6884 18.2095 18.8491 18.231 18.9351 18.3436C19.0211 18.4554 18.9998 18.6166 18.8875 18.7027C18.7752 18.7882 18.6145 18.7674 18.5285 18.6548Z"
        fill={frameColor}
      />
      <path
        d="M18.0006 17.8899C17.9251 17.7913 17.9438 17.6503 18.0424 17.5746C18.141 17.4988 18.2821 17.5176 18.3575 17.6162C18.433 17.7149 18.4143 17.8559 18.3158 17.9316C18.2172 18.0074 18.0761 17.9886 18.0006 17.8899Z"
        fill={frameColor}
      />
      <path
        d="M17.1745 17.4995C17.1095 17.4148 17.1257 17.2932 17.2105 17.2279C17.2954 17.1633 17.4168 17.1793 17.4818 17.264C17.5468 17.3488 17.5307 17.4703 17.4458 17.5356C17.361 17.6003 17.2395 17.5843 17.1745 17.4995Z"
        fill={frameColor}
      />
      <path
        d="M16.4094 17.4683C16.3549 17.3974 16.3684 17.2953 16.4396 17.2411C16.5107 17.1862 16.6125 17.2001 16.667 17.271C16.7215 17.3425 16.7079 17.4439 16.6368 17.4988C16.5657 17.553 16.4639 17.5398 16.4094 17.4683Z"
        fill={frameColor}
      />
      <path
        d="M47.769 25.8578C47.3122 24.0276 44.5335 23.4656 44.1635 24.8098C44.5351 24.4771 45.3153 25.2621 44.657 25.7698C43.8618 26.383 42.7396 25.087 43.9223 24.063C45.2204 22.939 47.769 24.0158 47.769 25.8578Z"
        fill={frameColor}
      />
      <path
        d="M16.9851 21.9852L17.8943 22.277L18.7872 22.0401L18.551 22.9335L18.8425 23.8428L17.9334 23.551L17.0404 23.7872L17.2767 22.8946L16.9851 21.9852Z"
        fill={frameColor}
      />
      <path
        d="M75.0898 25.3702C73.3083 26.6975 73.0235 24.5361 70.0863 24.6084C72.6024 23.6344 74.4244 23.9366 75.0898 25.3702Z"
        fill={frameColor}
      />
      <path
        d="M79.0148 21.9852L78.1054 22.277L77.2128 22.0401L77.449 22.9335L77.1572 23.8428L78.0666 23.551L78.9592 23.7872L78.723 22.8946L79.0148 21.9852Z"
        fill={frameColor}
      />
      <path
        d="M20.9102 80.6298C22.6917 79.3025 22.9765 81.4639 25.9137 81.3916C23.3976 82.3656 21.5756 82.0634 20.9102 80.6298Z"
        fill={frameColor}
      />
      <path
        d="M50.2951 86.0815C51.0208 85.2076 52.7854 86.779 51.3577 88.0295C50.2445 89.0048 48.1013 87.7224 48.1519 85.8571C49.5441 88.5741 51.7127 86.6338 50.2951 86.0815Z"
        fill={frameColor}
      />
      <path
        d="M45.7049 86.0815C44.9792 85.2076 43.2146 86.779 44.6422 88.0295C45.7555 89.0048 47.8987 87.7224 47.8481 85.8571C46.4559 88.5741 44.2873 86.6338 45.7049 86.0815Z"
        fill={frameColor}
      />
      <path
        d="M46.6753 89.0215C47.9916 88.0343 48 86.2372 48 86.2372C48 86.2372 48.0084 88.0343 49.3247 89.0215C49.561 90.051 48.4641 89.6294 48 91C47.5359 89.6294 46.439 90.051 46.6753 89.0215Z"
        fill={frameColor}
      />
      <path
        d="M48 85.0548C48.0227 85.4195 49.3753 86.0551 50.2243 85.398C48.392 84.9436 48.0681 82.7324 48 82.2836C47.9319 82.7324 47.608 84.9436 45.7757 85.398C46.6247 86.0551 47.9773 85.4195 48 85.0548Z"
        fill={frameColor}
      />
      <path
        d="M56.9936 86.3483C60.0214 85.796 61.1563 83.6251 63.6071 82.4455C66.3249 81.1381 69.4109 81.8703 70.4134 84.1822C71.026 85.5953 70.2783 87.2945 68.6727 87.587C66.424 87.9968 65.5216 84.9763 67.2497 84.5588C68.2997 84.3052 68.4499 85.8863 67.6211 85.712C68.3155 86.647 69.7365 85.2701 68.8919 84.1142C67.8527 82.6914 64.6462 83.6105 65.2446 86.34C65.6141 88.0246 67.4263 88.5331 68.5915 88.3115C71.5253 87.7537 72.335 83.209 68.5672 81.7869C69.6244 81.2846 69.4038 80.3469 69.0671 80.0044C68.3387 81.3673 66.2722 80.3818 63.1544 82.0113C61.1442 83.061 60.6304 84.7915 56.9936 86.3483Z"
        fill={frameColor}
      />
      <path
        d="M80.2382 85.0187C79.3427 84.0919 77.6483 84.1343 76.0512 85.1208L76.1638 85.2152C77.4434 84.4546 78.8286 84.2746 79.7915 85.2451C80.8974 86.3594 79.449 88.1531 78.5521 87.3244C77.7873 86.6178 78.9287 85.6619 79.317 86.3796C79.4282 85.6738 78.2548 85.4313 77.872 86.3379C77.3059 87.6786 79.0238 89.0562 80.3667 87.6842C81.1955 86.8374 80.8044 85.6043 80.2382 85.0187Z"
        fill={frameColor}
      />
      <path
        d="M64.588 88.7186C65.0762 88.1615 66.0499 88.1386 66.9941 88.6603L66.9325 88.7172C66.1766 88.3171 65.3761 88.2525 64.8508 88.836C64.2471 89.5071 65.1285 90.4963 65.6203 89.9955C66.0397 89.5682 65.3574 89.0514 65.1547 89.4744C65.071 89.0722 65.738 88.8999 65.983 89.4098C66.3454 90.1636 65.398 91.0028 64.5888 90.2525C64.0894 89.7891 64.2794 89.0708 64.588 88.7186Z"
        fill={frameColor}
      />
      <path
        d="M63.7919 88.406C64.2199 88.8721 68.4562 89.8794 70.557 86.9798H70.4709C68.3935 89.0701 65.3108 88.9604 63.8711 88.3039C63.8711 88.3039 63.8862 88.431 63.7919 88.406Z"
        fill={frameColor}
      />
      <path
        d="M71.6745 87.0944C70.8092 87.9301 72.362 88.9111 73.0862 87.373C73.5214 86.449 73.2487 85.5334 72.8956 84.8742C73.2355 85.2159 73.6216 85.473 74.0796 85.6043C75.708 86.0732 76.6521 85.0381 76.8404 84.06C77.0627 82.8977 75.9554 81.5799 74.6609 82.1009C73.8487 82.4281 73.5753 83.9933 74.8162 84.119C74.4272 83.2229 75.9227 82.8345 76.0179 84.0044C76.0811 84.7713 75.0832 85.557 74.0835 85.1694C72.5978 84.5942 71.9276 81.6452 69.1211 81.732C73.3089 82.8185 73.0815 87.1069 71.6745 87.0944Z"
        fill={frameColor}
      />
      <path
        d="M48.4869 82.0523C48.8846 82.8283 50.9621 85.5133 52.693 85.2326C53.6089 85.0846 53.4883 83.9787 53.1113 83.9071C54.6847 83.1381 55.492 85.3674 53.8871 85.8954C59.5817 87.3251 60.7356 81.4403 57.6107 80.58C55.4061 79.9737 54.317 83.0068 55.9255 83.4716C57.2089 83.8418 57.2406 82.037 56.3996 82.1377C57.2688 81.3409 58.6006 82.8463 57.6574 83.9245C56.4959 85.25 53.2482 84.1864 54.086 81.5201C54.6021 79.8738 56.4525 79.5282 57.5934 79.852C58.643 80.1487 59.3623 80.8581 59.6643 81.8098C59.8381 81.0193 60.2467 80.4735 61.0432 80.5474C62.2785 80.6626 62.5382 82.301 61.2159 82.4128C61.4387 82.1002 61.3802 81.4576 60.7758 81.4854C59.5579 81.5403 60.8544 85.2708 56.9936 86.3483C58.0019 86.9416 57.6987 87.8565 57.3337 88.1684C56.7285 86.7464 54.3315 87.7585 51.4011 85.8127C49.1052 84.2885 48.5119 82.1461 48.4869 82.0523Z"
        fill={frameColor}
      />
      <path
        d="M73.7896 86.2135C74.3507 86.4601 75.187 86.7651 75.8623 86.0836C76.2714 86.8707 75.8366 87.1618 75.5633 87.241C75.7907 87.5203 76.1631 88.1691 75.856 88.6686C75.3115 88.886 74.736 88.4088 74.4991 88.1365C74.3744 88.3921 74.0136 88.7714 73.3081 88.2337C74.095 87.6842 73.9373 86.8082 73.7896 86.2135Z"
        fill={frameColor}
      />
      <path
        d="M62.2244 87.4397C62.2244 87.4397 62.2207 87.5064 62.2169 87.614C62.1912 87.5168 62.1741 87.4584 62.1741 87.4584C62.1741 87.4584 62.157 87.5168 62.1313 87.614C62.1275 87.5064 62.1238 87.4397 62.1238 87.4397C62.1238 87.4397 61.0665 89.0312 61.6676 89.4967C61.7802 89.5835 61.8666 89.5661 61.9327 89.48C61.9761 89.6064 62.0523 89.6849 62.1741 89.6849C62.2959 89.6849 62.3721 89.6064 62.4155 89.48C62.4816 89.5661 62.568 89.5835 62.6806 89.4967C63.2817 89.0312 62.2244 87.4397 62.2244 87.4397Z"
        fill={frameColor}
      />
      <path
        d="M62.2244 86.5518C62.2244 86.5518 62.2207 86.4852 62.2169 86.3775C62.1912 86.4747 62.1741 86.5331 62.1741 86.5331C62.1741 86.5331 62.157 86.4747 62.1313 86.3775C62.1275 86.4852 62.1238 86.5518 62.1238 86.5518C62.1238 86.5518 61.0665 84.9603 61.6676 84.4948C61.7802 84.408 61.8666 84.4254 61.9327 84.5115C61.9761 84.3851 62.0523 84.3066 62.1741 84.3066C62.2959 84.3066 62.3721 84.3851 62.4155 84.5115C62.4816 84.4254 62.568 84.408 62.6806 84.4948C63.2817 84.9603 62.2244 86.5518 62.2244 86.5518Z"
        fill={frameColor}
      />
      <path
        d="M62.539 87.3438C62.5639 87.3577 62.5987 87.3772 62.6422 87.4022C62.577 87.3341 62.5357 87.2875 62.5259 87.275C62.5683 87.2827 63.2572 87.4272 63.7646 87.7078C64.0873 87.8857 64.3372 88.1184 64.3003 88.4067C64.296 88.4442 64.2873 88.4762 64.2731 88.5012C64.2405 88.5692 64.1797 88.5922 64.1014 88.5811C64.1601 88.7019 64.1612 88.8103 64.0754 88.8978C63.9895 88.9833 63.8798 88.9826 63.7603 88.9229C63.769 88.9923 63.7526 89.0465 63.7016 89.0826C63.6734 89.1021 63.6343 89.116 63.5842 89.1222C62.8301 89.2181 62.4521 87.3452 62.4521 87.3452C62.4521 87.3452 62.4977 87.3848 62.5705 87.4536C62.5737 87.457 62.577 87.4598 62.5814 87.4633C62.576 87.4536 62.5705 87.4445 62.565 87.4362C62.5238 87.3653 62.5009 87.323 62.5009 87.323C62.5009 87.323 62.514 87.3306 62.539 87.3438Z"
        fill={frameColor}
      />
      <path
        d="M61.8957 86.6463C61.8957 86.6463 61.8462 86.6019 61.767 86.5282C61.8176 86.6151 61.8469 86.6686 61.8469 86.6686C61.8469 86.6686 61.7934 86.6394 61.7065 86.5887C61.7803 86.6679 61.8245 86.7172 61.8245 86.7172C61.8245 86.7172 59.9515 86.3393 60.0477 85.5855C60.0657 85.4445 60.1393 85.3959 60.247 85.4098C60.1879 85.2896 60.1865 85.1805 60.2728 85.0944C60.3589 85.0082 60.468 85.0096 60.5884 85.0687C60.5742 84.961 60.6227 84.8874 60.7639 84.8693C61.5179 84.7734 61.8957 86.6463 61.8957 86.6463Z"
        fill={frameColor}
      />
      <path
        d="M62.6181 86.9457C62.6181 86.9457 62.6844 86.9492 62.7926 86.9534C62.6954 86.9791 62.6368 86.9958 62.6368 86.9958C62.6368 86.9958 62.6954 87.0131 62.7926 87.0388C62.6844 87.0423 62.6181 87.0465 62.6181 87.0465C62.6181 87.0465 64.2098 88.1031 64.6749 87.5022C64.762 87.3897 64.7442 87.3035 64.6581 87.2375C64.785 87.1938 64.863 87.1173 64.863 86.9958C64.863 86.8742 64.785 86.7978 64.6581 86.7547C64.7442 86.688 64.762 86.6019 64.6749 86.4893C64.2098 85.8884 62.6181 86.9457 62.6181 86.9457Z"
        fill={frameColor}
      />
      <path
        d="M61.7301 86.9457C61.7301 86.9457 61.6637 86.9492 61.5556 86.9534C61.6528 86.9791 61.7114 86.9958 61.7114 86.9958C61.7114 86.9958 61.6528 87.0131 61.5556 87.0388C61.6637 87.0423 61.7301 87.0465 61.7301 87.0465C61.7301 87.0465 60.1384 88.1031 59.6733 87.5022C59.5862 87.3897 59.6039 87.3035 59.6901 87.2375C59.5633 87.1938 59.4852 87.1173 59.4852 86.9958C59.4852 86.8742 59.5633 86.7978 59.6901 86.7547C59.6039 86.688 59.5862 86.6019 59.6733 86.4893C60.1384 85.8884 61.7301 86.9457 61.7301 86.9457Z"
        fill={frameColor}
      />
      <path
        d="M62.4525 86.6463C62.4525 86.6463 62.502 86.6019 62.5812 86.5282C62.5307 86.6151 62.5013 86.6686 62.5013 86.6686C62.5013 86.6686 62.5548 86.6394 62.6417 86.5887C62.5679 86.6679 62.5236 86.7172 62.5236 86.7172C62.5236 86.7172 64.3968 86.3393 64.3005 85.5855C64.2826 85.4445 64.2089 85.3959 64.1012 85.4098C64.1603 85.2896 64.1616 85.1805 64.0755 85.0944C63.9893 85.0082 63.8803 85.0096 63.7598 85.0687C63.774 84.961 63.7255 84.8874 63.5843 84.8693C62.8304 84.7734 62.4525 86.6463 62.4525 86.6463Z"
        fill={frameColor}
      />
      <path
        d="M61.8245 87.2743C61.8245 87.2743 61.7803 87.3237 61.7065 87.4029C61.7934 87.3521 61.8469 87.323 61.8469 87.323C61.8469 87.323 61.8176 87.3765 61.767 87.4633C61.8462 87.3897 61.8957 87.3452 61.8957 87.3452C61.8957 87.3452 61.5179 89.2188 60.7639 89.1222C60.6227 89.1042 60.5742 89.0305 60.5884 88.9229C60.468 88.9819 60.3589 88.9833 60.2728 88.8972C60.1865 88.811 60.1879 88.7019 60.247 88.5817C60.1392 88.5956 60.0657 88.547 60.0477 88.406C59.9515 87.6523 61.8245 87.2743 61.8245 87.2743Z"
        fill={frameColor}
      />
      <path
        d="M61.9535 86.9958C61.9535 86.8742 62.0523 86.7755 62.1741 86.7755C62.2959 86.7755 62.3947 86.8742 62.3947 86.9958C62.3947 87.1173 62.2959 87.2167 62.1741 87.2167C62.0523 87.2167 61.9535 87.1173 61.9535 86.9958Z"
        fill={frameColor}
      />
      <path
        d="M53.1802 80.8345C53.3892 80.8414 53.5647 80.6782 53.5722 80.4692C53.5797 80.2602 53.4163 80.0846 53.2072 80.0771C52.9981 80.0696 52.8226 80.2331 52.8152 80.4421C52.8077 80.6512 52.9711 80.8268 53.1802 80.8345Z"
        fill={frameColor}
      />
      <path
        d="M53.2271 81.0623C53.0368 81.0554 52.8769 81.2048 52.8701 81.3951C52.8633 81.5854 53.0121 81.7452 53.2025 81.7522C53.3929 81.7584 53.5527 81.6098 53.5595 81.4194C53.5663 81.2291 53.4175 81.0693 53.2271 81.0623Z"
        fill={frameColor}
      />
      <path
        d="M52.6732 82.0182C52.5015 82.0127 52.3574 82.1468 52.3512 82.3184C52.3451 82.49 52.4793 82.6338 52.6509 82.64C52.8227 82.6463 52.9668 82.5122 52.9729 82.3406C52.9791 82.169 52.8449 82.0245 52.6732 82.0182Z"
        fill={frameColor}
      />
      <path
        d="M51.7366 82.6074C51.5835 82.6018 51.4551 82.7213 51.4496 82.8741C51.4442 83.0276 51.5637 83.1555 51.7167 83.161C51.8697 83.1666 51.9982 83.0471 52.0037 82.8943C52.0091 82.7414 51.8895 82.6129 51.7366 82.6074Z"
        fill={frameColor}
      />
      <path
        d="M50.7597 82.7553C50.6253 82.7512 50.5126 82.8561 50.5078 82.9901C50.503 83.1242 50.608 83.2374 50.7423 83.2423C50.8766 83.2472 50.9894 83.1416 50.9941 83.0075C50.9989 82.8734 50.894 82.7602 50.7597 82.7553Z"
        fill={frameColor}
      />
      <path
        d="M49.7837 82.5921C49.668 82.5879 49.571 82.6782 49.5668 82.7942C49.5627 82.9095 49.6531 83.0068 49.7687 83.011C49.8843 83.0151 49.9814 82.9248 49.9855 82.8088C49.9897 82.6935 49.8993 82.5962 49.7837 82.5921Z"
        fill={frameColor}
      />
      <path
        d="M49.1273 82.1357C49.0304 82.1322 48.949 82.2086 48.9455 82.3052C48.942 82.4024 49.0178 82.4837 49.1147 82.4872C49.2117 82.4906 49.293 82.4149 49.2965 82.3177C49.3 82.2211 49.2242 82.1391 49.1273 82.1357Z"
        fill={frameColor}
      />
      <path
        d="M78.1548 85.3917C78.0374 85.2382 78.0658 85.0186 78.2194 84.9012C78.3729 84.7838 78.5924 84.813 78.7098 84.9666C78.8279 85.1201 78.7988 85.3396 78.6452 85.457C78.4917 85.5744 78.2722 85.5452 78.1548 85.3917Z"
        fill={frameColor}
      />
      <path
        d="M77.8373 85.3959C77.9443 85.5355 77.9179 85.7356 77.7783 85.8426C77.6379 85.9495 77.4385 85.9231 77.3309 85.7835C77.2239 85.6439 77.251 85.4438 77.3906 85.3368C77.5302 85.2298 77.7303 85.2562 77.8373 85.3959Z"
        fill={frameColor}
      />
      <path
        d="M77.4135 86.3247C77.5101 86.4511 77.4865 86.631 77.36 86.7276C77.2343 86.8242 77.0537 86.8006 76.9571 86.6741C76.8605 86.5484 76.8849 86.3678 77.0106 86.2712C77.137 86.1746 77.317 86.1989 77.4135 86.3247Z"
        fill={frameColor}
      />
      <path
        d="M77.4712 87.3452C77.5573 87.4577 77.5365 87.6182 77.424 87.7044C77.3114 87.7905 77.1509 87.7689 77.0648 87.6564C76.9787 87.5446 77.0002 87.3834 77.1127 87.2973C77.2246 87.2118 77.3857 87.2327 77.4712 87.3452Z"
        fill={frameColor}
      />
      <path
        d="M77.9992 88.1101C78.0749 88.2087 78.0561 88.3497 77.9575 88.4254C77.8588 88.5012 77.7178 88.4824 77.6428 88.3838C77.567 88.2851 77.5858 88.1441 77.6844 88.0684C77.7831 87.9926 77.9241 88.0114 77.9992 88.1101Z"
        fill={frameColor}
      />
      <path
        d="M78.8251 88.5005C78.8904 88.5852 78.8745 88.7068 78.7897 88.7721C78.705 88.8367 78.5834 88.8207 78.5181 88.736C78.4535 88.6512 78.4695 88.5296 78.5542 88.4644C78.639 88.3997 78.7605 88.4157 78.8251 88.5005Z"
        fill={frameColor}
      />
      <path
        d="M79.5907 88.5317C79.6449 88.6026 79.6317 88.7047 79.5602 88.7589C79.4893 88.8131 79.3872 88.7999 79.333 88.729C79.2788 88.6575 79.292 88.556 79.3629 88.5012C79.4344 88.447 79.5358 88.4602 79.5907 88.5317Z"
        fill={frameColor}
      />
      <path
        d="M48.231 80.1422C48.6878 81.9724 51.4665 82.5344 51.8365 81.1902C51.465 81.5229 50.6847 80.7379 51.343 80.2302C52.1382 79.617 53.2604 80.913 52.0776 81.937C50.7796 83.061 48.231 81.9835 48.231 80.1422Z"
        fill={frameColor}
      />
      <path
        d="M39.0064 86.3483C35.9786 85.796 34.8437 83.6251 32.3929 82.4455C29.6751 81.1381 26.5891 81.8703 25.5866 84.1822C24.974 85.5953 25.7217 87.2945 27.3273 87.587C29.576 87.9968 30.4784 84.9763 28.7503 84.5588C27.7003 84.3052 27.5501 85.8863 28.3789 85.712C27.6845 86.647 26.2635 85.2701 27.108 84.1142C28.1472 82.6914 31.3538 83.6105 30.7554 86.34C30.3859 88.0246 28.5737 88.5331 27.4085 88.3115C24.4747 87.7537 23.665 83.209 27.4328 81.7869C26.3756 81.2846 26.5962 80.3469 26.9329 80.0044C27.6612 81.3673 29.7278 80.3818 32.8456 82.0113C34.8558 83.061 35.3695 84.7915 39.0064 86.3483Z"
        fill={frameColor}
      />
      <path
        d="M15.762 85.0187C16.6575 84.0919 18.3519 84.1343 19.9486 85.1208L19.8366 85.2152C18.5569 84.4546 17.1712 84.2746 16.2088 85.2451C15.1028 86.3594 16.5509 88.1531 17.4481 87.3244C18.2129 86.6178 17.0711 85.6619 16.6829 86.3796C16.5715 85.6738 17.7453 85.4313 18.1278 86.3379C18.6938 87.6786 16.9763 89.0562 15.6337 87.6842C14.8047 86.8374 15.1958 85.6043 15.762 85.0187Z"
        fill={frameColor}
      />
      <path
        d="M31.412 88.7186C30.9238 88.1615 29.9501 88.1386 29.0059 88.6603L29.0675 88.7172C29.8234 88.3171 30.6239 88.2525 31.1492 88.836C31.753 89.5071 30.8715 90.4963 30.3797 89.9955C29.9604 89.5682 30.6426 89.0514 30.8453 89.4744C30.929 89.0722 30.262 88.8999 30.017 89.4098C29.6546 90.1636 30.602 91.0028 31.4112 90.2525C31.9107 89.7891 31.7207 89.0708 31.412 88.7186Z"
        fill={frameColor}
      />
      <path
        d="M32.2081 88.406C31.7801 88.8721 27.5438 89.8794 25.443 86.9798H25.5291C27.6065 89.0701 30.6892 88.9604 32.1289 88.3039C32.1289 88.3039 32.1138 88.431 32.2081 88.406Z"
        fill={frameColor}
      />
      <path
        d="M24.3255 87.0944C25.1908 87.9301 23.638 88.9111 22.9138 87.373C22.4787 86.449 22.7513 85.5334 23.1044 84.8742C22.7645 85.2159 22.3784 85.473 21.9204 85.6043C20.292 86.0732 19.3476 85.0381 19.1599 84.06C18.9369 82.8977 20.0444 81.5799 21.3391 82.1009C22.1513 82.4281 22.4247 83.9933 21.1838 84.119C21.5728 83.2229 20.0776 82.8345 19.9819 84.0044C19.9192 84.7713 20.9168 85.557 21.9165 85.1694C23.4023 84.5942 24.0724 81.6452 26.8789 81.732C22.6911 82.8185 22.9185 87.1069 24.3255 87.0944Z"
        fill={frameColor}
      />
      <path
        d="M47.5131 82.0523C47.1154 82.8283 45.0379 85.5133 43.307 85.2326C42.3911 85.0846 42.5117 83.9787 42.8887 83.9071C41.3153 83.1381 40.508 85.3674 42.1129 85.8954C36.4183 87.3251 35.2644 81.4403 38.3893 80.58C40.5939 79.9737 41.683 83.0068 40.0745 83.4716C38.7911 83.8418 38.7594 82.037 39.6004 82.1377C38.7312 81.3409 37.3994 82.8463 38.3426 83.9245C39.504 85.25 42.7518 84.1864 41.914 81.5201C41.3979 79.8738 39.5475 79.5282 38.4066 79.852C37.357 80.1487 36.6377 80.8581 36.3357 81.8098C36.1618 81.0193 35.7533 80.4735 34.9568 80.5474C33.7214 80.6626 33.4618 82.301 34.784 82.4128C34.5613 82.1002 34.6198 81.4576 35.2241 81.4854C36.4421 81.5403 35.1455 85.2708 39.0064 86.3483C37.9981 86.9416 38.3012 87.8565 38.6663 88.1684C39.2715 86.7464 41.6685 87.7585 44.5989 85.8127C46.8948 84.2885 47.4881 82.1461 47.5131 82.0523Z"
        fill={frameColor}
      />
      <path
        d="M22.2104 86.2135C21.6493 86.4601 20.813 86.7651 20.1375 86.0836C19.7282 86.8707 20.1634 87.1618 20.4367 87.241C20.209 87.5203 19.837 88.1691 20.144 88.6686C20.6885 88.886 21.264 88.4088 21.5009 88.1365C21.6256 88.3921 21.9864 88.7714 22.6919 88.2337C21.905 87.6842 22.0627 86.8082 22.2104 86.2135Z"
        fill={frameColor}
      />
      <path
        d="M33.7756 87.4397C33.7756 87.4397 33.7793 87.5064 33.7831 87.614C33.8088 87.5168 33.8259 87.4584 33.8259 87.4584C33.8259 87.4584 33.843 87.5168 33.8687 87.614C33.8725 87.5064 33.8762 87.4397 33.8762 87.4397C33.8762 87.4397 34.9336 89.0312 34.3324 89.4967C34.2198 89.5835 34.1334 89.5661 34.0673 89.48C34.0239 89.6064 33.9478 89.6849 33.8259 89.6849C33.7041 89.6849 33.6279 89.6064 33.5845 89.48C33.5184 89.5661 33.432 89.5835 33.3194 89.4967C32.7183 89.0312 33.7756 87.4397 33.7756 87.4397Z"
        fill={frameColor}
      />
      <path
        d="M33.7756 86.5518C33.7756 86.5518 33.7793 86.4852 33.7831 86.3775C33.8088 86.4747 33.8259 86.5331 33.8259 86.5331C33.8259 86.5331 33.843 86.4747 33.8687 86.3775C33.8725 86.4852 33.8762 86.5518 33.8762 86.5518C33.8762 86.5518 34.9336 84.9603 34.3324 84.4948C34.2198 84.408 34.1334 84.4254 34.0673 84.5115C34.0239 84.3851 33.9478 84.3066 33.8259 84.3066C33.7041 84.3066 33.6279 84.3851 33.5845 84.5115C33.5184 84.4254 33.432 84.408 33.3194 84.4948C32.7183 84.9603 33.7756 86.5518 33.7756 86.5518Z"
        fill={frameColor}
      />
      <path
        d="M33.461 87.3438C33.4361 87.3577 33.4013 87.3772 33.3578 87.4022C33.423 87.3341 33.4643 87.2875 33.4741 87.275C33.4317 87.2827 32.7428 87.4272 32.2354 87.7078C31.9127 87.8857 31.6628 88.1184 31.6997 88.4067C31.704 88.4442 31.7127 88.4762 31.7269 88.5012C31.7595 88.5692 31.8203 88.5922 31.8986 88.5811C31.8399 88.7019 31.8388 88.8103 31.9246 88.8978C32.0105 88.9833 32.1202 88.9826 32.2397 88.9229C32.231 88.9923 32.2474 89.0465 32.2984 89.0826C32.3266 89.1021 32.3657 89.116 32.4158 89.1222C33.1699 89.2181 33.5479 87.3452 33.5479 87.3452C33.5479 87.3452 33.5023 87.3848 33.4295 87.4536C33.4263 87.457 33.423 87.4598 33.4186 87.4633C33.424 87.4536 33.4295 87.4445 33.4349 87.4362C33.4762 87.3653 33.4991 87.323 33.4991 87.323C33.4991 87.323 33.486 87.3306 33.461 87.3438Z"
        fill={frameColor}
      />
      <path
        d="M34.1043 86.6463C34.1043 86.6463 34.1538 86.6019 34.233 86.5282C34.1825 86.6151 34.1531 86.6686 34.1531 86.6686C34.1531 86.6686 34.2066 86.6394 34.2935 86.5887C34.2197 86.6679 34.1755 86.7172 34.1755 86.7172C34.1755 86.7172 36.0485 86.3393 35.9523 85.5855C35.9343 85.4445 35.8608 85.3959 35.753 85.4098C35.8121 85.2896 35.8135 85.1805 35.7273 85.0944C35.6411 85.0082 35.532 85.0096 35.4116 85.0687C35.4258 84.961 35.3773 84.8874 35.2361 84.8693C34.4821 84.7734 34.1043 86.6463 34.1043 86.6463Z"
        fill={frameColor}
      />
      <path
        d="M33.3819 86.9457C33.3819 86.9457 33.3156 86.9492 33.2074 86.9534C33.3046 86.9791 33.3632 86.9958 33.3632 86.9958C33.3632 86.9958 33.3046 87.0131 33.2074 87.0388C33.3156 87.0423 33.3819 87.0465 33.3819 87.0465C33.3819 87.0465 31.7902 88.1031 31.3251 87.5022C31.238 87.3897 31.2558 87.3035 31.3419 87.2375C31.2151 87.1938 31.137 87.1173 31.137 86.9958C31.137 86.8742 31.2151 86.7978 31.3419 86.7547C31.2558 86.688 31.238 86.6019 31.3251 86.4893C31.7902 85.8884 33.3819 86.9457 33.3819 86.9457Z"
        fill={frameColor}
      />
      <path
        d="M34.2699 86.9457C34.2699 86.9457 34.3362 86.9492 34.4444 86.9534C34.3472 86.9791 34.2886 86.9958 34.2886 86.9958C34.2886 86.9958 34.3472 87.0131 34.4444 87.0388C34.3362 87.0423 34.2699 87.0465 34.2699 87.0465C34.2699 87.0465 35.8616 88.1031 36.3267 87.5022C36.4137 87.3897 36.3961 87.3035 36.3099 87.2375C36.4367 87.1938 36.5148 87.1173 36.5148 86.9958C36.5148 86.8742 36.4367 86.7978 36.3099 86.7547C36.3961 86.688 36.4137 86.6019 36.3267 86.4893C35.8616 85.8884 34.2699 86.9457 34.2699 86.9457Z"
        fill={frameColor}
      />
      <path
        d="M33.5475 86.6463C33.5475 86.6463 33.498 86.6019 33.4188 86.5282C33.4693 86.6151 33.4987 86.6686 33.4987 86.6686C33.4987 86.6686 33.4452 86.6394 33.3583 86.5887C33.4321 86.6679 33.4764 86.7172 33.4764 86.7172C33.4764 86.7172 31.6032 86.3393 31.6995 85.5855C31.7174 85.4445 31.7911 85.3959 31.8988 85.4098C31.8397 85.2896 31.8384 85.1805 31.9245 85.0944C32.0107 85.0082 32.1197 85.0096 32.2402 85.0687C32.226 84.961 32.2745 84.8874 32.4157 84.8693C33.1696 84.7734 33.5475 86.6463 33.5475 86.6463Z"
        fill={frameColor}
      />
      <path
        d="M34.1755 87.2743C34.1755 87.2743 34.2197 87.3237 34.2935 87.4029C34.2066 87.3521 34.1531 87.323 34.1531 87.323C34.1531 87.323 34.1825 87.3765 34.233 87.4633C34.1538 87.3897 34.1043 87.3452 34.1043 87.3452C34.1043 87.3452 34.4821 89.2188 35.2361 89.1222C35.3773 89.1042 35.4258 89.0305 35.4116 88.9229C35.532 88.9819 35.6411 88.9833 35.7273 88.8972C35.8135 88.811 35.8121 88.7019 35.753 88.5817C35.8608 88.5956 35.9343 88.547 35.9523 88.406C36.0485 87.6523 34.1755 87.2743 34.1755 87.2743Z"
        fill={frameColor}
      />
      <path
        d="M34.0465 86.9958C34.0465 86.8742 33.9477 86.7755 33.8259 86.7755C33.7041 86.7755 33.6053 86.8742 33.6053 86.9958C33.6053 87.1173 33.7041 87.2167 33.8259 87.2167C33.9477 87.2167 34.0465 87.1173 34.0465 86.9958Z"
        fill={frameColor}
      />
      <path
        d="M42.8198 80.8345C42.6108 80.8414 42.4353 80.6782 42.4278 80.4692C42.4203 80.2602 42.5837 80.0846 42.7928 80.0771C43.0019 80.0696 43.1774 80.2331 43.1849 80.4421C43.1923 80.6512 43.0289 80.8268 42.8198 80.8345Z"
        fill={frameColor}
      />
      <path
        d="M42.7729 81.0623C42.9632 81.0554 43.1231 81.2048 43.1299 81.3951C43.1367 81.5854 42.9879 81.7452 42.7975 81.7522C42.6071 81.7584 42.4473 81.6098 42.4405 81.4194C42.4337 81.2291 42.5825 81.0693 42.7729 81.0623Z"
        fill={frameColor}
      />
      <path
        d="M43.3268 82.0182C43.4985 82.0127 43.6426 82.1468 43.6488 82.3184C43.6549 82.49 43.5207 82.6338 43.3491 82.64C43.1773 82.6463 43.0332 82.5122 43.0271 82.3406C43.0209 82.169 43.1551 82.0245 43.3268 82.0182Z"
        fill={frameColor}
      />
      <path
        d="M44.2634 82.6074C44.4165 82.6018 44.5449 82.7213 44.5504 82.8741C44.5558 83.0276 44.4363 83.1555 44.2833 83.161C44.1302 83.1666 44.0018 83.0471 43.9963 82.8943C43.9909 82.7414 44.1104 82.6129 44.2634 82.6074Z"
        fill={frameColor}
      />
      <path
        d="M45.2403 82.7553C45.3747 82.7512 45.4874 82.8561 45.4922 82.9901C45.497 83.1242 45.392 83.2374 45.2577 83.2423C45.1234 83.2472 45.0106 83.1416 45.0059 83.0075C45.0011 82.8734 45.106 82.7602 45.2403 82.7553Z"
        fill={frameColor}
      />
      <path
        d="M46.2163 82.5921C46.332 82.5879 46.429 82.6782 46.4332 82.7942C46.4373 82.9095 46.3469 83.0068 46.2313 83.011C46.1157 83.0151 46.0186 82.9248 46.0145 82.8088C46.0103 82.6935 46.1007 82.5962 46.2163 82.5921Z"
        fill={frameColor}
      />
      <path
        d="M46.8727 82.1357C46.9696 82.1322 47.051 82.2086 47.0545 82.3052C47.058 82.4024 46.9822 82.4837 46.8853 82.4872C46.7883 82.4906 46.707 82.4149 46.7035 82.3177C46.7 82.2211 46.7758 82.1391 46.8727 82.1357Z"
        fill={frameColor}
      />
      <path
        d="M17.8454 85.3917C17.963 85.2382 17.9338 85.0186 17.7804 84.9012C17.627 84.7838 17.4074 84.813 17.2898 84.9666C17.1723 85.1201 17.2014 85.3396 17.3549 85.457C17.5084 85.5744 17.728 85.5452 17.8454 85.3917Z"
        fill={frameColor}
      />
      <path
        d="M18.1628 85.3959C18.0559 85.5355 18.0824 85.7356 18.2221 85.8426C18.3618 85.9495 18.5618 85.9231 18.6688 85.7835C18.7758 85.6439 18.7492 85.4438 18.6095 85.3368C18.4698 85.2298 18.2698 85.2562 18.1628 85.3959Z"
        fill={frameColor}
      />
      <path
        d="M18.5864 86.3247C18.4899 86.4511 18.5138 86.631 18.6398 86.7276C18.7658 86.8242 18.9462 86.8006 19.0427 86.6741C19.1392 86.5484 19.1152 86.3678 18.9893 86.2712C18.8632 86.1746 18.6828 86.1989 18.5864 86.3247Z"
        fill={frameColor}
      />
      <path
        d="M18.5285 87.3452C18.4425 87.4577 18.4638 87.6182 18.5761 87.7044C18.6884 87.7905 18.8491 87.7689 18.9351 87.6564C19.0211 87.5446 18.9998 87.3834 18.8875 87.2973C18.7752 87.2118 18.6145 87.2327 18.5285 87.3452Z"
        fill={frameColor}
      />
      <path
        d="M18.0006 88.1101C17.9251 88.2087 17.9438 88.3497 18.0424 88.4254C18.141 88.5012 18.2821 88.4824 18.3575 88.3838C18.433 88.2851 18.4143 88.1441 18.3158 88.0684C18.2172 87.9926 18.0761 88.0114 18.0006 88.1101Z"
        fill={frameColor}
      />
      <path
        d="M17.1745 88.5005C17.1095 88.5852 17.1257 88.7068 17.2105 88.7721C17.2954 88.8367 17.4168 88.8207 17.4818 88.736C17.5468 88.6512 17.5307 88.5296 17.4458 88.4644C17.361 88.3997 17.2395 88.4157 17.1745 88.5005Z"
        fill={frameColor}
      />
      <path
        d="M16.4094 88.5317C16.3549 88.6026 16.3684 88.7047 16.4396 88.7589C16.5107 88.8131 16.6125 88.7999 16.667 88.729C16.7215 88.6575 16.7079 88.556 16.6368 88.5012C16.5657 88.447 16.4639 88.4602 16.4094 88.5317Z"
        fill={frameColor}
      />
      <path
        d="M47.769 80.1422C47.3122 81.9724 44.5335 82.5344 44.1635 81.1902C44.5351 81.5229 45.3153 80.7379 44.657 80.2302C43.8618 79.617 42.7396 80.913 43.9223 81.937C45.2204 83.061 47.769 81.9835 47.769 80.1422Z"
        fill={frameColor}
      />
      <path
        d="M16.9851 84.0148L17.8943 83.723L18.7872 83.9592L18.551 83.0666L18.8425 82.1572L17.9334 82.449L17.0404 82.2128L17.2767 83.1054L16.9851 84.0148Z"
        fill={frameColor}
      />
      <path
        d="M75.0898 80.6298C73.3083 79.3025 73.0235 81.4639 70.0863 81.3916C72.6024 82.3656 74.4244 82.0634 75.0898 80.6298Z"
        fill={frameColor}
      />
      <path
        d="M79.0148 84.0148L78.1054 83.723L77.2128 83.9592L77.449 83.0666L77.1572 82.1572L78.0666 82.449L78.9592 82.2128L78.723 83.1054L79.0148 84.0148Z"
        fill={frameColor}
      />
      <path
        d="M20.3702 80.0898C21.6975 78.3083 19.5363 78.0235 19.6084 75.0863C18.6343 77.6024 18.9367 79.4244 20.3702 80.0898Z"
        fill={frameColor}
      />
      <path
        d="M14.9182 50.7049C15.7924 49.9792 14.221 48.2146 12.9704 49.6422C11.9953 50.7555 13.2779 52.8987 15.1426 52.8481C12.4257 51.4559 14.3664 49.2873 14.9182 50.7049Z"
        fill={frameColor}
      />
      <path
        d="M14.9182 55.2951C15.7924 56.0208 14.221 57.7854 12.9704 56.3578C11.9953 55.2445 13.2779 53.1013 15.1426 53.1519C12.4257 54.5441 14.3664 56.7127 14.9182 55.2951Z"
        fill={frameColor}
      />
      <path
        d="M11.9785 54.3247C12.9657 53.0084 14.763 53 14.763 53C14.763 53 12.9657 52.9916 11.9785 51.6753C10.949 51.439 11.3709 52.5359 10 53C11.3709 53.4641 10.949 54.561 11.9785 54.3247Z"
        fill={frameColor}
      />
      <path
        d="M15.9454 53C15.5803 52.9773 14.9447 51.6247 15.6022 50.7757C16.0564 52.608 18.2674 52.9319 18.7162 53C18.2674 53.0681 16.0564 53.392 15.6022 55.2243C14.9447 54.3753 15.5803 53.0227 15.9454 53Z"
        fill={frameColor}
      />
      <path
        d="M14.6515 44.0064C15.2042 40.9786 17.3753 39.8437 18.5544 37.3929C19.862 34.6751 19.13 31.5891 16.8178 30.5866C15.405 29.974 13.7054 30.7217 13.4129 32.3273C13.0032 34.576 16.0236 35.4784 16.4412 33.7503C16.6949 32.7003 15.1135 32.5501 15.2878 33.3789C14.353 32.6845 15.7297 31.2635 16.886 32.1081C18.3087 33.1473 17.3896 36.3538 14.6602 35.7554C12.9751 35.3859 12.4672 33.5737 12.6886 32.4085C13.2461 29.4747 17.7908 28.665 19.2133 32.4328C19.7152 31.3756 20.6531 31.5962 20.9956 31.9329C19.6325 32.6612 20.6181 34.7278 18.989 37.8456C17.9387 39.8558 16.2089 40.3696 14.6515 44.0064Z"
        fill={frameColor}
      />
      <path
        d="M15.9814 20.7618C16.9081 21.6573 16.8655 23.3517 15.8791 24.9488L15.7851 24.8362C16.5454 23.5566 16.7252 22.1714 15.7552 21.2085C14.6403 20.1026 12.8466 21.551 13.6754 22.4479C14.3821 23.2127 15.3383 22.0713 14.6204 21.683C15.3265 21.5712 15.5686 22.7452 14.6623 23.128C13.3211 23.6935 11.9434 21.9762 13.3157 20.6333C14.1629 19.8045 15.3954 20.1957 15.9814 20.7618Z"
        fill={frameColor}
      />
      <path
        d="M12.2814 36.412C12.8384 35.9238 12.8613 34.9501 12.3397 34.0059L12.2826 34.0675C12.6832 34.8234 12.7477 35.6239 12.164 36.1492C11.4931 36.753 10.5039 35.8715 11.0048 35.3797C11.4318 34.9604 11.9488 35.6426 11.5258 35.8453C11.928 35.929 12.0999 35.262 11.5903 35.017C10.8362 34.6546 9.99734 35.602 10.7475 36.4112C11.2107 36.9107 11.9291 36.7207 12.2814 36.412Z"
        fill={frameColor}
      />
      <path
        d="M12.5941 37.2081C12.1282 36.7801 11.1202 32.5439 14.0199 30.443V30.5291C11.9295 32.6065 12.0399 35.6892 12.696 37.1289C12.696 37.1289 12.5692 37.1138 12.5941 37.2081Z"
        fill={frameColor}
      />
      <path
        d="M13.9054 29.3255C13.0699 30.1908 12.0892 28.638 13.6269 27.9138C14.5511 27.4787 15.4666 27.7513 16.1262 28.1044C15.7843 27.7645 15.5272 27.3784 15.3954 26.9204C14.9267 25.292 15.9618 24.3479 16.94 24.1596C18.1021 23.9366 19.42 25.0446 18.899 26.3391C18.5722 27.1513 17.0064 27.4247 16.8808 26.1838C17.7769 26.5728 18.1656 25.0773 16.9956 24.9821C16.2289 24.9189 15.4432 25.9168 15.8303 26.9165C16.4057 28.4023 19.3546 29.0724 19.268 31.8789C18.1815 27.6911 13.8929 27.9185 13.9054 29.3255Z"
        fill={frameColor}
      />
      <path
        d="M18.9477 52.5131C18.1719 52.1154 15.487 50.0379 15.7674 48.307C15.9151 47.3911 17.0212 47.5117 17.0929 47.8887C17.8622 46.3153 15.6326 45.508 15.1046 47.1129C13.6747 41.4183 19.5595 40.2644 20.42 43.3893C21.0263 45.5939 17.993 46.683 17.5287 45.0745C17.1582 43.7911 18.9633 43.7594 18.8623 44.6004C19.659 43.7312 18.1535 42.3994 17.0756 43.3426C15.7499 44.5041 16.8137 47.7518 19.4801 46.914C21.1262 46.3979 21.4718 44.5475 21.148 43.4066C20.8513 42.357 20.1419 41.6377 19.19 41.3357C19.9811 41.1619 20.5265 40.7533 20.4526 39.9568C20.3374 38.7215 18.6989 38.4618 18.587 39.7841C18.8999 39.5613 19.5422 39.6198 19.5149 40.2242C19.4598 41.4421 15.7293 40.1455 14.6515 44.0064C14.0582 42.9981 13.1433 43.3012 12.8315 43.6663C14.2538 44.2715 13.2411 46.6685 15.1872 49.5989C16.7115 51.8948 18.8543 52.4881 18.9477 52.5131Z"
        fill={frameColor}
      />
      <path
        d="M14.7867 27.2104C14.5396 26.6493 14.2346 25.813 14.9162 25.1377C14.1293 24.7279 13.8385 25.1634 13.7594 25.4367C13.4795 25.2093 12.8308 24.8369 12.3314 25.144C12.1142 25.6885 12.5915 26.2641 12.8636 26.5009C12.6078 26.6256 12.2287 26.9864 12.7664 27.6919C13.3155 26.905 14.1917 27.0627 14.7867 27.2104Z"
        fill={frameColor}
      />
      <path
        d="M13.5601 38.7756C13.5601 38.7756 13.4938 38.7793 13.3857 38.7831C13.4829 38.8088 13.5414 38.8259 13.5414 38.8259C13.5414 38.8259 13.4829 38.843 13.3857 38.8687C13.4938 38.8725 13.5601 38.8762 13.5601 38.8762C13.5601 38.8762 11.9685 39.9336 11.5034 39.3324C11.4163 39.2198 11.434 39.1334 11.5202 39.0673C11.3933 39.0239 11.3152 38.9478 11.3152 38.8259C11.3152 38.7041 11.3933 38.6279 11.5202 38.5845C11.434 38.5184 11.4163 38.432 11.5034 38.3194C11.9685 37.7183 13.5601 38.7756 13.5601 38.7756Z"
        fill={frameColor}
      />
      <path
        d="M14.4482 38.7756C14.4482 38.7756 14.5145 38.7793 14.6227 38.7831C14.5255 38.8088 14.4669 38.8259 14.4669 38.8259C14.4669 38.8259 14.5255 38.843 14.6227 38.8687C14.5145 38.8725 14.4482 38.8762 14.4482 38.8762C14.4482 38.8762 16.0399 39.9336 16.5049 39.3324C16.5921 39.2198 16.5743 39.1334 16.4881 39.0673C16.615 39.0239 16.6931 38.9478 16.6931 38.8259C16.6931 38.7041 16.615 38.6279 16.4881 38.5845C16.5743 38.5184 16.5921 38.432 16.5049 38.3194C16.0399 37.7183 14.4482 38.7756 14.4482 38.7756Z"
        fill={frameColor}
      />
      <path
        d="M13.6562 38.461C13.6421 38.4361 13.6225 38.4013 13.5975 38.3578C13.666 38.423 13.7127 38.4643 13.7246 38.4741C13.717 38.4317 13.5725 37.7428 13.2922 37.2354C13.114 36.9127 12.8815 36.6628 12.5935 36.6997C12.5555 36.704 12.524 36.7127 12.499 36.7269C12.4305 36.7595 12.4077 36.8203 12.4186 36.8986C12.298 36.8399 12.1893 36.8388 12.1024 36.9246C12.0166 37.0105 12.0177 37.1202 12.0774 37.2397C12.0079 37.231 11.9536 37.2474 11.9177 37.2984C11.8981 37.3266 11.884 37.3657 11.8775 37.4158C11.7819 38.1699 13.6551 38.5479 13.6551 38.5479C13.6551 38.5479 13.6149 38.5023 13.5464 38.4295C13.5432 38.4263 13.5399 38.423 13.5366 38.4186C13.5464 38.424 13.5551 38.4295 13.5638 38.435C13.6344 38.4762 13.6768 38.4991 13.6768 38.4991C13.6768 38.4991 13.6692 38.486 13.6562 38.461Z"
        fill={frameColor}
      />
      <path
        d="M14.3537 39.1043C14.3537 39.1043 14.3979 39.1538 14.4718 39.233C14.3849 39.1824 14.3314 39.1531 14.3314 39.1531C14.3314 39.1531 14.3607 39.2066 14.4113 39.2935C14.3321 39.2197 14.2825 39.1755 14.2825 39.1755C14.2825 39.1755 14.6604 41.0485 15.4144 40.9523C15.5556 40.9343 15.6041 40.8608 15.5899 40.753C15.7103 40.8121 15.8194 40.8135 15.9056 40.7273C15.9917 40.6411 15.9904 40.532 15.9313 40.4117C16.039 40.4258 16.1126 40.3773 16.1306 40.2361C16.2269 39.4821 14.3537 39.1043 14.3537 39.1043Z"
        fill={frameColor}
      />
      <path
        d="M14.0545 38.3819C14.0545 38.3819 14.0507 38.3156 14.047 38.2074C14.0213 38.3046 14.0042 38.3632 14.0042 38.3632C14.0042 38.3632 13.9871 38.3046 13.9614 38.2074C13.9576 38.3156 13.9539 38.3819 13.9539 38.3819C13.9539 38.3819 12.8966 36.7902 13.4977 36.3251C13.6103 36.238 13.6967 36.2558 13.7628 36.3419C13.8062 36.2151 13.8823 36.137 14.0042 36.137C14.126 36.137 14.2022 36.2151 14.2456 36.3419C14.3117 36.2558 14.3981 36.238 14.5107 36.3251C15.1118 36.7902 14.0545 38.3819 14.0545 38.3819Z"
        fill={frameColor}
      />
      <path
        d="M14.0545 39.2699C14.0545 39.2699 14.0507 39.3363 14.047 39.4444C14.0213 39.3472 14.0042 39.2886 14.0042 39.2886C14.0042 39.2886 13.9871 39.3472 13.9614 39.4444C13.9576 39.3363 13.9539 39.2699 13.9539 39.2699C13.9539 39.2699 12.8966 40.8616 13.4977 41.3267C13.6103 41.4138 13.6967 41.3961 13.7628 41.3099C13.8062 41.4367 13.8823 41.5148 14.0042 41.5148C14.126 41.5148 14.2022 41.4367 14.2456 41.3099C14.3117 41.3961 14.3981 41.4138 14.5107 41.3267C15.1118 40.8616 14.0545 39.2699 14.0545 39.2699Z"
        fill={frameColor}
      />
      <path
        d="M14.3537 38.5475C14.3537 38.5475 14.3979 38.498 14.4718 38.4188C14.3849 38.4693 14.3314 38.4987 14.3314 38.4987C14.3314 38.4987 14.3607 38.4452 14.4113 38.3583C14.3321 38.4321 14.2825 38.4764 14.2825 38.4764C14.2825 38.4764 14.6604 36.6032 15.4144 36.6995C15.5556 36.7175 15.6041 36.7911 15.5899 36.8988C15.7103 36.8397 15.8194 36.8384 15.9056 36.9245C15.9917 37.0107 15.9904 37.1198 15.9313 37.2402C16.039 37.226 16.1126 37.2745 16.1306 37.4157C16.2269 38.1697 14.3537 38.5475 14.3537 38.5475Z"
        fill={frameColor}
      />
      <path
        d="M13.7258 39.1755C13.7258 39.1755 13.6763 39.2197 13.5971 39.2935C13.6476 39.2066 13.677 39.1531 13.677 39.1531C13.677 39.1531 13.6235 39.1824 13.5366 39.233C13.6104 39.1538 13.6546 39.1043 13.6546 39.1043C13.6546 39.1043 11.7815 39.4821 11.8777 40.2361C11.8957 40.3773 11.9693 40.4258 12.077 40.4117C12.018 40.532 12.0167 40.6411 12.1028 40.7273C12.1889 40.8135 12.298 40.8121 12.4184 40.753C12.4042 40.8608 12.4528 40.9343 12.594 40.9523C13.3479 41.0485 13.7258 39.1755 13.7258 39.1755Z"
        fill={frameColor}
      />
      <path
        d="M14.0042 39.0465C14.126 39.0465 14.2248 38.9477 14.2248 38.8259C14.2248 38.7041 14.126 38.6053 14.0042 38.6053C13.8823 38.6053 13.7836 38.7041 13.7836 38.8259C13.7836 38.9477 13.8823 39.0465 14.0042 39.0465Z"
        fill={frameColor}
      />
      <path
        d="M20.1658 47.8199C20.1583 47.6108 20.3218 47.4353 20.5308 47.4278C20.7398 47.4203 20.9154 47.5837 20.9229 47.7928C20.9304 48.0019 20.7669 48.1774 20.5579 48.1849C20.3488 48.1923 20.1732 48.029 20.1658 47.8199Z"
        fill={frameColor}
      />
      <path
        d="M19.9375 47.7729C19.9443 47.9632 19.7955 48.1231 19.6051 48.1299C19.4148 48.1367 19.2549 47.9879 19.2481 47.7975C19.2413 47.6071 19.3901 47.4473 19.5805 47.4405C19.7709 47.4337 19.9307 47.5825 19.9375 47.7729Z"
        fill={frameColor}
      />
      <path
        d="M18.9815 48.3268C18.9877 48.4985 18.8534 48.6427 18.6817 48.6488C18.5101 48.6549 18.3659 48.5207 18.3598 48.3491C18.3536 48.1773 18.4878 48.0332 18.6595 48.0271C18.8312 48.0209 18.9754 48.1551 18.9815 48.3268Z"
        fill={frameColor}
      />
      <path
        d="M18.3928 49.2634C18.3983 49.4165 18.2787 49.5449 18.1257 49.5504C17.9727 49.5558 17.8442 49.4363 17.8388 49.2833C17.8333 49.1303 17.9528 49.0018 18.1059 48.9963C18.2589 48.9909 18.3873 49.1105 18.3928 49.2634Z"
        fill={frameColor}
      />
      <path
        d="M18.2443 50.2403C18.2491 50.3747 18.1442 50.4874 18.0099 50.4922C17.8755 50.497 17.7628 50.392 17.758 50.2577C17.7532 50.1234 17.8581 50.0106 17.9924 50.0059C18.1268 50.0011 18.2395 50.106 18.2443 50.2403Z"
        fill={frameColor}
      />
      <path
        d="M18.4078 51.2163C18.4119 51.332 18.3215 51.429 18.2059 51.4332C18.0903 51.4373 17.9932 51.3469 17.9891 51.2313C17.9849 51.1157 18.0753 51.0186 18.1909 51.0145C18.3066 51.0103 18.4036 51.1007 18.4078 51.2163Z"
        fill={frameColor}
      />
      <path
        d="M18.8641 51.8727C18.8675 51.9696 18.7918 52.051 18.6948 52.0545C18.5979 52.058 18.5165 51.9822 18.513 51.8853C18.5096 51.7883 18.5854 51.707 18.6823 51.7035C18.7792 51.7 18.8606 51.7758 18.8641 51.8727Z"
        fill={frameColor}
      />
      <path
        d="M15.6082 22.8452C15.7615 22.9626 15.9812 22.9342 16.0987 22.7806C16.2162 22.6271 16.187 22.4076 16.0337 22.2895C15.8802 22.1721 15.6605 22.2012 15.5431 22.3548C15.4256 22.5083 15.4547 22.7278 15.6082 22.8452Z"
        fill={frameColor}
      />
      <path
        d="M15.6039 23.1627C15.4641 23.0557 15.2641 23.0821 15.1572 23.2224C15.0502 23.3621 15.0767 23.5615 15.2164 23.6684C15.3561 23.7754 15.5561 23.749 15.6632 23.6094C15.7702 23.4698 15.7436 23.2697 15.6039 23.1627Z"
        fill={frameColor}
      />
      <path
        d="M14.6752 23.5865C14.5492 23.4899 14.3688 23.5135 14.2723 23.64C14.1758 23.7657 14.1998 23.9463 14.3257 24.0429C14.4518 24.1395 14.6321 24.1151 14.7286 23.9894C14.8251 23.863 14.8012 23.683 14.6752 23.5865Z"
        fill={frameColor}
      />
      <path
        d="M13.6548 23.5288C13.5425 23.4427 13.3818 23.4635 13.2959 23.576C13.2099 23.6886 13.2312 23.8491 13.3434 23.9352C13.4558 24.0214 13.6165 23.9998 13.7025 23.8873C13.7885 23.7754 13.7671 23.6143 13.6548 23.5288Z"
        fill={frameColor}
      />
      <path
        d="M12.8898 23.0009C12.7912 22.9251 12.6501 22.9439 12.5746 23.0425C12.4991 23.1412 12.5178 23.2822 12.6164 23.3572C12.715 23.433 12.8561 23.4142 12.9315 23.3155C13.007 23.2169 12.9883 23.0759 12.8898 23.0009Z"
        fill={frameColor}
      />
      <path
        d="M12.4993 22.1742C12.4145 22.1096 12.293 22.1255 12.2281 22.2103C12.1631 22.295 12.1792 22.4166 12.264 22.4819C12.3489 22.5465 12.4704 22.5305 12.5353 22.4458C12.6003 22.361 12.5842 22.2395 12.4993 22.1742Z"
        fill={frameColor}
      />
      <path
        d="M12.4685 21.4093C12.3974 21.3551 12.2955 21.3683 12.241 21.4399C12.1866 21.5107 12.2001 21.6121 12.2712 21.667C12.3423 21.7212 12.4442 21.708 12.4987 21.6365C12.5531 21.5656 12.5396 21.4642 12.4685 21.4093Z"
        fill={frameColor}
      />
      <path
        d="M20.8578 52.769C19.0275 52.3122 18.4655 49.5335 19.8098 49.1635C19.4771 49.5351 20.2621 50.3153 20.7698 49.657C21.383 48.8618 20.0871 47.7396 19.063 48.9223C17.9392 50.2204 19.0161 52.769 20.8578 52.769Z"
        fill={frameColor}
      />
      <path
        d="M14.6515 61.9936C15.2042 65.0214 17.3753 66.1563 18.5544 68.6071C19.862 71.3249 19.13 74.4109 16.8178 75.4134C15.405 76.026 13.7054 75.2783 13.4129 73.6727C13.0032 71.424 16.0236 70.5216 16.4412 72.2497C16.6949 73.2997 15.1135 73.4499 15.2878 72.6211C14.353 73.3155 15.7297 74.7365 16.886 73.8919C18.3087 72.8527 17.3896 69.6462 14.6602 70.2446C12.9751 70.6141 12.4672 72.4263 12.6886 73.5915C13.2461 76.5253 17.7908 77.335 19.2133 73.5672C19.7152 74.6244 20.6531 74.4038 20.9956 74.0671C19.6325 73.3388 20.6181 71.2722 18.989 68.1544C17.9387 66.1442 16.2089 65.6305 14.6515 61.9936Z"
        fill={frameColor}
      />
      <path
        d="M15.9814 85.2382C16.9081 84.3427 16.8655 82.6483 15.8791 81.0512L15.7851 81.1638C16.5454 82.4434 16.7252 83.8286 15.7552 84.7915C14.6403 85.8974 12.8466 84.449 13.6754 83.5521C14.3821 82.7873 15.3383 83.9287 14.6204 84.317C15.3265 84.4282 15.5686 83.2548 14.6623 82.872C13.3211 82.3059 11.9434 84.0238 13.3157 85.3667C14.1629 86.1955 15.3954 85.8044 15.9814 85.2382Z"
        fill={frameColor}
      />
      <path
        d="M12.2814 69.588C12.8384 70.0762 12.8613 71.0499 12.3397 71.9941L12.2826 71.9325C12.6832 71.1766 12.7477 70.3761 12.164 69.8508C11.4931 69.2471 10.5039 70.1285 11.0048 70.6203C11.4318 71.0397 11.9488 70.3574 11.5258 70.1547C11.928 70.071 12.0999 70.738 11.5903 70.983C10.8362 71.3454 9.99734 70.398 10.7475 69.5888C11.2107 69.0894 11.9291 69.2794 12.2814 69.588Z"
        fill={frameColor}
      />
      <path
        d="M12.5941 68.7919C12.1282 69.2199 11.1202 73.4562 14.0199 75.557V75.4709C11.9295 73.3935 12.0399 70.3108 12.696 68.8711C12.696 68.8711 12.5692 68.8862 12.5941 68.7919Z"
        fill={frameColor}
      />
      <path
        d="M13.9054 76.6745C13.0699 75.8092 12.0892 77.362 13.6269 78.0862C14.5511 78.5213 15.4666 78.2487 16.1262 77.8956C15.7843 78.2355 15.5272 78.6216 15.3954 79.0796C14.9267 80.708 15.9618 81.6521 16.94 81.8404C18.1021 82.0627 19.42 80.9554 18.899 79.6609C18.5722 78.8487 17.0064 78.5753 16.8808 79.8162C17.7769 79.4272 18.1656 80.9227 16.9956 81.0179C16.2289 81.0811 15.4432 80.0832 15.8303 79.0835C16.4057 77.5977 19.3546 76.9276 19.268 74.1211C18.1815 78.3089 13.8929 78.0815 13.9054 76.6745Z"
        fill={frameColor}
      />
      <path
        d="M18.9477 53.4869C18.1719 53.8846 15.487 55.9621 15.7674 57.693C15.9151 58.6089 17.0212 58.4883 17.0929 58.1113C17.8622 59.6847 15.6326 60.492 15.1046 58.8871C13.6747 64.5817 19.5595 65.7356 20.42 62.6107C21.0263 60.4061 17.993 59.317 17.5287 60.9255C17.1582 62.2089 18.9633 62.2406 18.8623 61.3996C19.659 62.2688 18.1535 63.6006 17.0756 62.6574C15.7499 61.496 16.8137 58.2482 19.4801 59.086C21.1262 59.6021 21.4718 61.4525 21.148 62.5934C20.8513 63.643 20.1419 64.3623 19.19 64.6643C19.9811 64.8382 20.5265 65.2467 20.4526 66.0432C20.3374 67.2786 18.6989 67.5382 18.587 66.2159C18.8999 66.4387 19.5422 66.3802 19.5149 65.7759C19.4598 64.5579 15.7293 65.8544 14.6515 61.9936C14.0582 63.0019 13.1433 62.6988 12.8315 62.3337C14.2538 61.7285 13.2411 59.3315 15.1872 56.4011C16.7115 54.1052 18.8543 53.5119 18.9477 53.4869Z"
        fill={frameColor}
      />
      <path
        d="M14.7867 78.7896C14.5396 79.3507 14.2346 80.187 14.9162 80.8623C14.1293 81.2714 13.8385 80.8366 13.7594 80.5633C13.4795 80.7907 12.8308 81.1631 12.3314 80.856C12.1142 80.3115 12.5915 79.736 12.8636 79.4991C12.6078 79.3744 12.2287 79.0136 12.7664 78.3081C13.3155 79.095 14.1917 78.9373 14.7867 78.7896Z"
        fill={frameColor}
      />
      <path
        d="M13.5601 67.2244C13.5601 67.2244 13.4938 67.2207 13.3857 67.2169C13.4829 67.1912 13.5414 67.1741 13.5414 67.1741C13.5414 67.1741 13.4829 67.1571 13.3857 67.1313C13.4938 67.1275 13.5601 67.1238 13.5601 67.1238C13.5601 67.1238 11.9685 66.0665 11.5034 66.6676C11.4163 66.7802 11.434 66.8666 11.5202 66.9327C11.3933 66.9761 11.3152 67.0523 11.3152 67.1741C11.3152 67.2959 11.3933 67.3721 11.5202 67.4156C11.434 67.4816 11.4163 67.568 11.5034 67.6806C11.9685 68.2817 13.5601 67.2244 13.5601 67.2244Z"
        fill={frameColor}
      />
      <path
        d="M14.4482 67.2244C14.4482 67.2244 14.5145 67.2207 14.6227 67.2169C14.5255 67.1912 14.4669 67.1741 14.4669 67.1741C14.4669 67.1741 14.5255 67.1571 14.6227 67.1313C14.5145 67.1275 14.4482 67.1238 14.4482 67.1238C14.4482 67.1238 16.0399 66.0665 16.5049 66.6676C16.5921 66.7802 16.5743 66.8666 16.4881 66.9327C16.615 66.9761 16.6931 67.0523 16.6931 67.1741C16.6931 67.2959 16.615 67.3721 16.4881 67.4156C16.5743 67.4816 16.5921 67.568 16.5049 67.6806C16.0399 68.2817 14.4482 67.2244 14.4482 67.2244Z"
        fill={frameColor}
      />
      <path
        d="M13.6562 67.539C13.6421 67.5639 13.6225 67.5987 13.5975 67.6422C13.666 67.577 13.7127 67.5357 13.7246 67.5259C13.717 67.5683 13.5725 68.2572 13.2922 68.7646C13.114 69.0873 12.8815 69.3372 12.5935 69.3003C12.5555 69.296 12.524 69.2873 12.499 69.2731C12.4305 69.2405 12.4077 69.1797 12.4186 69.1014C12.298 69.1601 12.1893 69.1612 12.1024 69.0754C12.0166 68.9895 12.0177 68.8798 12.0774 68.7603C12.0079 68.769 11.9536 68.7526 11.9177 68.7016C11.8981 68.6734 11.884 68.6343 11.8775 68.5842C11.7819 67.8301 13.6551 67.4521 13.6551 67.4521C13.6551 67.4521 13.6149 67.4977 13.5464 67.5705C13.5432 67.5737 13.5399 67.577 13.5366 67.5814C13.5464 67.576 13.5551 67.5705 13.5638 67.565C13.6344 67.5238 13.6768 67.5009 13.6768 67.5009C13.6768 67.5009 13.6692 67.514 13.6562 67.539Z"
        fill={frameColor}
      />
      <path
        d="M14.3537 66.8957C14.3537 66.8957 14.3979 66.8462 14.4718 66.767C14.3849 66.8176 14.3314 66.8469 14.3314 66.8469C14.3314 66.8469 14.3607 66.7934 14.4113 66.7065C14.3321 66.7803 14.2825 66.8245 14.2825 66.8245C14.2825 66.8245 14.6604 64.9515 15.4144 65.0477C15.5556 65.0657 15.6041 65.1393 15.5899 65.247C15.7103 65.1879 15.8194 65.1865 15.9056 65.2728C15.9917 65.3589 15.9904 65.468 15.9313 65.5884C16.039 65.5742 16.1126 65.6227 16.1306 65.7639C16.2269 66.5179 14.3537 66.8957 14.3537 66.8957Z"
        fill={frameColor}
      />
      <path
        d="M14.0545 67.6181C14.0545 67.6181 14.0507 67.6844 14.047 67.7926C14.0213 67.6954 14.0042 67.6368 14.0042 67.6368C14.0042 67.6368 13.9871 67.6954 13.9614 67.7926C13.9576 67.6844 13.9539 67.6181 13.9539 67.6181C13.9539 67.6181 12.8966 69.2098 13.4977 69.6749C13.6103 69.762 13.6967 69.7442 13.7628 69.6581C13.8062 69.785 13.8823 69.863 14.0042 69.863C14.126 69.863 14.2022 69.785 14.2456 69.6581C14.3117 69.7442 14.3981 69.762 14.5107 69.6749C15.1118 69.2098 14.0545 67.6181 14.0545 67.6181Z"
        fill={frameColor}
      />
      <path
        d="M14.0545 66.7301C14.0545 66.7301 14.0507 66.6637 14.047 66.5556C14.0213 66.6528 14.0042 66.7114 14.0042 66.7114C14.0042 66.7114 13.9871 66.6528 13.9614 66.5556C13.9576 66.6637 13.9539 66.7301 13.9539 66.7301C13.9539 66.7301 12.8966 65.1384 13.4977 64.6733C13.6103 64.5863 13.6967 64.6039 13.7628 64.6901C13.8062 64.5633 13.8823 64.4852 14.0042 64.4852C14.126 64.4852 14.2022 64.5633 14.2456 64.6901C14.3117 64.6039 14.3981 64.5863 14.5107 64.6733C15.1118 65.1384 14.0545 66.7301 14.0545 66.7301Z"
        fill={frameColor}
      />
      <path
        d="M14.3537 67.4525C14.3537 67.4525 14.3979 67.502 14.4718 67.5812C14.3849 67.5307 14.3314 67.5013 14.3314 67.5013C14.3314 67.5013 14.3607 67.5548 14.4113 67.6417C14.3321 67.5679 14.2825 67.5236 14.2825 67.5236C14.2825 67.5236 14.6604 69.3968 15.4144 69.3005C15.5556 69.2826 15.6041 69.2089 15.5899 69.1012C15.7103 69.1603 15.8194 69.1616 15.9056 69.0755C15.9917 68.9893 15.9904 68.8803 15.9313 68.7598C16.039 68.774 16.1126 68.7255 16.1306 68.5843C16.2269 67.8304 14.3537 67.4525 14.3537 67.4525Z"
        fill={frameColor}
      />
      <path
        d="M13.7258 66.8245C13.7258 66.8245 13.6763 66.7803 13.5971 66.7065C13.6476 66.7934 13.677 66.8469 13.677 66.8469C13.677 66.8469 13.6235 66.8176 13.5366 66.767C13.6104 66.8462 13.6546 66.8957 13.6546 66.8957C13.6546 66.8957 11.7815 66.5179 11.8777 65.7639C11.8957 65.6227 11.9693 65.5742 12.077 65.5884C12.018 65.468 12.0167 65.3589 12.1028 65.2728C12.1889 65.1865 12.298 65.1879 12.4184 65.247C12.4042 65.1392 12.4528 65.0657 12.594 65.0477C13.3479 64.9515 13.7258 66.8245 13.7258 66.8245Z"
        fill={frameColor}
      />
      <path
        d="M14.0042 66.9535C14.126 66.9535 14.2248 67.0523 14.2248 67.1741C14.2248 67.2959 14.126 67.3947 14.0042 67.3947C13.8823 67.3947 13.7836 67.2959 13.7836 67.1741C13.7836 67.0523 13.8823 66.9535 14.0042 66.9535Z"
        fill={frameColor}
      />
      <path
        d="M20.1658 58.1802C20.1583 58.3892 20.3218 58.5647 20.5308 58.5722C20.7398 58.5797 20.9154 58.4163 20.9229 58.2072C20.9304 57.9981 20.7669 57.8226 20.5579 57.8152C20.3488 57.8077 20.1732 57.9711 20.1658 58.1802Z"
        fill={frameColor}
      />
      <path
        d="M19.9375 58.2271C19.9443 58.0368 19.7955 57.8769 19.6051 57.8701C19.4148 57.8633 19.2549 58.0121 19.2481 58.2025C19.2413 58.3929 19.3901 58.5527 19.5805 58.5595C19.7709 58.5663 19.9307 58.4175 19.9375 58.2271Z"
        fill={frameColor}
      />
      <path
        d="M18.9815 57.6732C18.9877 57.5015 18.8534 57.3574 18.6817 57.3512C18.5101 57.3451 18.3659 57.4793 18.3598 57.6509C18.3536 57.8227 18.4878 57.9668 18.6595 57.9729C18.8312 57.9791 18.9754 57.8449 18.9815 57.6732Z"
        fill={frameColor}
      />
      <path
        d="M18.3928 56.7366C18.3983 56.5835 18.2787 56.4551 18.1257 56.4496C17.9727 56.4442 17.8442 56.5637 17.8388 56.7167C17.8333 56.8697 17.9528 56.9982 18.1059 57.0037C18.2589 57.0091 18.3873 56.8896 18.3928 56.7366Z"
        fill={frameColor}
      />
      <path
        d="M18.2443 55.7597C18.2491 55.6253 18.1442 55.5126 18.0099 55.5078C17.8755 55.503 17.7628 55.608 17.758 55.7423C17.7532 55.8766 17.8581 55.9894 17.9924 55.9942C18.1268 55.9989 18.2395 55.894 18.2443 55.7597Z"
        fill={frameColor}
      />
      <path
        d="M18.4078 54.7837C18.4119 54.668 18.3215 54.571 18.2059 54.5668C18.0903 54.5627 17.9932 54.6531 17.9891 54.7687C17.9849 54.8843 18.0753 54.9814 18.1909 54.9855C18.3066 54.9897 18.4036 54.8993 18.4078 54.7837Z"
        fill={frameColor}
      />
      <path
        d="M18.8641 54.1273C18.8675 54.0304 18.7918 53.949 18.6948 53.9455C18.5979 53.942 18.5165 54.0178 18.513 54.1147C18.5096 54.2117 18.5854 54.293 18.6823 54.2965C18.7792 54.3 18.8606 54.2242 18.8641 54.1273Z"
        fill={frameColor}
      />
      <path
        d="M15.6082 83.1548C15.7615 83.0374 15.9812 83.0659 16.0987 83.2194C16.2162 83.3729 16.187 83.5924 16.0337 83.7098C15.8802 83.8279 15.6605 83.7988 15.5431 83.6452C15.4256 83.4917 15.4547 83.2722 15.6082 83.1548Z"
        fill={frameColor}
      />
      <path
        d="M15.6039 82.8373C15.4641 82.9443 15.2641 82.9179 15.1572 82.7783C15.0502 82.6379 15.0767 82.4385 15.2164 82.3309C15.3561 82.2239 15.5561 82.251 15.6632 82.3906C15.7702 82.5302 15.7436 82.7303 15.6039 82.8373Z"
        fill={frameColor}
      />
      <path
        d="M14.6752 82.4135C14.5492 82.5101 14.3688 82.4865 14.2723 82.36C14.1758 82.2343 14.1998 82.0537 14.3257 81.9571C14.4518 81.8605 14.6321 81.8849 14.7286 82.0106C14.8251 82.137 14.8012 82.317 14.6752 82.4135Z"
        fill={frameColor}
      />
      <path
        d="M13.6548 82.4712C13.5425 82.5573 13.3818 82.5365 13.2959 82.424C13.2099 82.3114 13.2312 82.1509 13.3434 82.0648C13.4558 81.9787 13.6165 82.0002 13.7025 82.1127C13.7885 82.2246 13.7671 82.3857 13.6548 82.4712Z"
        fill={frameColor}
      />
      <path
        d="M12.8898 82.9992C12.7912 83.0749 12.6501 83.0561 12.5746 82.9575C12.4991 82.8588 12.5178 82.7178 12.6164 82.6428C12.715 82.5671 12.8561 82.5858 12.9315 82.6845C13.007 82.7831 12.9883 82.9241 12.8898 82.9992Z"
        fill={frameColor}
      />
      <path
        d="M12.4993 83.8252C12.4145 83.8905 12.293 83.8745 12.2281 83.7897C12.1631 83.705 12.1792 83.5834 12.264 83.5181C12.3489 83.4535 12.4704 83.4695 12.5353 83.5542C12.6003 83.639 12.5842 83.7606 12.4993 83.8252Z"
        fill={frameColor}
      />
      <path
        d="M12.4685 84.5907C12.3974 84.6449 12.2955 84.6317 12.241 84.5602C12.1866 84.4893 12.2001 84.3872 12.2712 84.333C12.3423 84.2788 12.4442 84.292 12.4987 84.3629C12.5531 84.4344 12.5396 84.5358 12.4685 84.5907Z"
        fill={frameColor}
      />
      <path
        d="M20.8578 53.231C19.0275 53.6878 18.4655 56.4665 19.8098 56.8365C19.4771 56.4649 20.2621 55.6847 20.7698 56.343C21.383 57.1382 20.0871 58.2604 19.063 57.0776C17.9392 55.7796 19.0161 53.231 20.8578 53.231Z"
        fill={frameColor}
      />
      <path
        d="M20.3702 25.9102C21.6975 27.6917 19.5363 27.9765 19.6084 30.9137C18.6343 28.3976 18.9367 26.5756 20.3702 25.9102Z"
        fill={frameColor}
      />
      <path
        d="M75.6298 80.0898C74.3025 78.3083 76.4639 78.0235 76.3916 75.0863C77.3656 77.6024 77.0634 79.4244 75.6298 80.0898Z"
        fill={frameColor}
      />
      <path
        d="M81.0815 50.7049C80.2076 49.9792 81.779 48.2146 83.0295 49.6422C84.0048 50.7555 82.7224 52.8987 80.8571 52.8481C83.5741 51.4559 81.6338 49.2873 81.0815 50.7049Z"
        fill={frameColor}
      />
      <path
        d="M81.0815 55.2951C80.2076 56.0208 81.779 57.7854 83.0295 56.3578C84.0048 55.2445 82.7224 53.1013 80.8571 53.1519C83.5741 54.5441 81.6338 56.7127 81.0815 55.2951Z"
        fill={frameColor}
      />
      <path
        d="M84.0215 54.3247C83.0343 53.0084 81.2372 53 81.2372 53C81.2372 53 83.0343 52.9916 84.0215 51.6753C85.051 51.439 84.6294 52.5359 86 53C84.6294 53.4641 85.051 54.561 84.0215 54.3247Z"
        fill={frameColor}
      />
      <path
        d="M80.0548 53C80.4195 52.9773 81.0551 51.6247 80.398 50.7757C79.9436 52.608 77.7324 52.9319 77.2836 53C77.7324 53.0681 79.9436 53.392 80.398 55.2243C81.0551 54.3753 80.4195 53.0227 80.0548 53Z"
        fill={frameColor}
      />
      <path
        d="M81.3483 44.0064C80.796 40.9786 78.6251 39.8437 77.4455 37.3929C76.1381 34.6751 76.8703 31.5891 79.1822 30.5866C80.5953 29.974 82.2945 30.7217 82.587 32.3273C82.9968 34.576 79.9763 35.4784 79.5588 33.7503C79.3052 32.7003 80.8863 32.5501 80.712 33.3789C81.647 32.6845 80.2701 31.2635 79.1142 32.1081C77.6914 33.1473 78.6105 36.3538 81.34 35.7554C83.0246 35.3859 83.5331 33.5737 83.3115 32.4085C82.7537 29.4747 78.209 28.665 76.7869 32.4328C76.2847 31.3756 75.3469 31.5962 75.0044 31.9329C76.3673 32.6612 75.3818 34.7278 77.0113 37.8456C78.061 39.8558 79.7915 40.3696 81.3483 44.0064Z"
        fill={frameColor}
      />
      <path
        d="M80.0186 20.7618C79.0919 21.6573 79.1343 23.3517 80.1208 24.9488L80.2152 24.8362C79.4546 23.5566 79.2746 22.1714 80.2451 21.2085C81.3594 20.1026 83.1531 21.551 82.3244 22.4479C81.6178 23.2127 80.6619 22.0713 81.3796 21.683C80.6738 21.5712 80.4313 22.7452 81.3379 23.128C82.6786 23.6935 84.0562 21.9762 82.6842 20.6333C81.8374 19.8045 80.6043 20.1957 80.0186 20.7618Z"
        fill={frameColor}
      />
      <path
        d="M83.7186 36.412C83.1615 35.9238 83.1385 34.9501 83.6603 34.0059L83.7172 34.0675C83.3171 34.8234 83.2525 35.6239 83.836 36.1492C84.5071 36.753 85.4963 35.8715 84.9955 35.3797C84.5682 34.9604 84.0514 35.6426 84.4744 35.8453C84.0722 35.929 83.8999 35.262 84.4098 35.017C85.1636 34.6546 86.0028 35.602 85.2525 36.4112C84.7891 36.9107 84.0708 36.7207 83.7186 36.412Z"
        fill={frameColor}
      />
      <path
        d="M83.406 37.2081C83.8721 36.7801 84.8795 32.5439 81.9798 30.443V30.5291C84.0701 32.6065 83.9604 35.6892 83.3039 37.1289C83.3039 37.1289 83.431 37.1138 83.406 37.2081Z"
        fill={frameColor}
      />
      <path
        d="M82.0944 29.3255C82.9301 30.1908 83.911 28.638 82.373 27.9138C81.449 27.4787 80.5334 27.7513 79.8741 28.1044C80.2159 27.7645 80.473 27.3784 80.6043 26.9204C81.0732 25.292 80.0381 24.3479 79.06 24.1596C77.8977 23.9366 76.5799 25.0446 77.1009 26.3391C77.4281 27.1513 78.9933 27.4247 79.119 26.1838C78.2229 26.5728 77.8345 25.0773 79.0044 24.9821C79.7713 24.9189 80.557 25.9168 80.1694 26.9165C79.5942 28.4023 76.6452 29.0724 76.732 31.8789C77.8185 27.6911 82.1069 27.9185 82.0944 29.3255Z"
        fill={frameColor}
      />
      <path
        d="M77.0523 52.5131C77.8283 52.1154 80.5133 50.0379 80.2326 48.307C80.0846 47.3911 78.9787 47.5117 78.9071 47.8887C78.1381 46.3153 80.3674 45.508 80.8954 47.1129C82.3251 41.4183 76.4403 40.2644 75.58 43.3893C74.9737 45.5939 78.0068 46.683 78.4716 45.0745C78.8418 43.7911 77.037 43.7594 77.1377 44.6004C76.3409 43.7312 77.8463 42.3994 78.9245 43.3426C80.25 44.5041 79.1864 47.7518 76.5202 46.914C74.8738 46.3979 74.5282 44.5475 74.852 43.4066C75.1487 42.357 75.8581 41.6377 76.8098 41.3357C76.0193 41.1619 75.4735 40.7533 75.5474 39.9568C75.6626 38.7215 77.301 38.4618 77.4128 39.7841C77.1002 39.5613 76.4576 39.6198 76.4854 40.2242C76.5403 41.4421 80.2708 40.1455 81.3483 44.0064C81.9416 42.9981 82.8565 43.3012 83.1684 43.6663C81.7464 44.2715 82.7585 46.6685 80.8127 49.5989C79.2885 51.8948 77.1461 52.4881 77.0523 52.5131Z"
        fill={frameColor}
      />
      <path
        d="M81.2135 27.2104C81.4601 26.6493 81.7651 25.813 81.0836 25.1377C81.8707 24.7279 82.1618 25.1634 82.241 25.4367C82.5203 25.2093 83.1691 24.8369 83.6686 25.144C83.886 25.6885 83.4088 26.2641 83.1365 26.5009C83.3921 26.6256 83.7714 26.9864 83.2337 27.6919C82.6842 26.905 81.8082 27.0627 81.2135 27.2104Z"
        fill={frameColor}
      />
      <path
        d="M82.4397 38.7756C82.4397 38.7756 82.5064 38.7793 82.614 38.7831C82.5168 38.8088 82.4584 38.8259 82.4584 38.8259C82.4584 38.8259 82.5168 38.843 82.614 38.8687C82.5064 38.8725 82.4397 38.8762 82.4397 38.8762C82.4397 38.8762 84.0312 39.9336 84.4967 39.3324C84.5835 39.2198 84.5661 39.1334 84.48 39.0673C84.6064 39.0239 84.6849 38.9478 84.6849 38.8259C84.6849 38.7041 84.6064 38.6279 84.48 38.5845C84.5661 38.5184 84.5835 38.432 84.4967 38.3194C84.0312 37.7183 82.4397 38.7756 82.4397 38.7756Z"
        fill={frameColor}
      />
      <path
        d="M81.5519 38.7756C81.5519 38.7756 81.4852 38.7793 81.3775 38.7831C81.4747 38.8088 81.5331 38.8259 81.5331 38.8259C81.5331 38.8259 81.4747 38.843 81.3775 38.8687C81.4852 38.8725 81.5519 38.8762 81.5519 38.8762C81.5519 38.8762 79.9603 39.9336 79.4949 39.3324C79.408 39.2198 79.4254 39.1334 79.5115 39.0673C79.3851 39.0239 79.3066 38.9478 79.3066 38.8259C79.3066 38.7041 79.3851 38.6279 79.5115 38.5845C79.4254 38.5184 79.408 38.432 79.4949 38.3194C79.9603 37.7183 81.5519 38.7756 81.5519 38.7756Z"
        fill={frameColor}
      />
      <path
        d="M82.3438 38.461C82.3577 38.4361 82.3771 38.4013 82.4022 38.3578C82.3341 38.423 82.2875 38.4643 82.275 38.4741C82.2827 38.4317 82.4272 37.7428 82.7078 37.2354C82.8857 36.9127 83.1184 36.6628 83.4067 36.6997C83.4442 36.704 83.4762 36.7127 83.5012 36.7269C83.5692 36.7595 83.5922 36.8203 83.5811 36.8986C83.7019 36.8399 83.8103 36.8388 83.8978 36.9246C83.9833 37.0105 83.9826 37.1202 83.9229 37.2397C83.9923 37.231 84.0465 37.2474 84.0826 37.2984C84.1021 37.3266 84.116 37.3657 84.1222 37.4158C84.2181 38.1699 82.3452 38.5479 82.3452 38.5479C82.3452 38.5479 82.3848 38.5023 82.4536 38.4295C82.457 38.4263 82.4598 38.423 82.4633 38.4186C82.4536 38.424 82.4445 38.4295 82.4362 38.435C82.3653 38.4762 82.323 38.4991 82.323 38.4991C82.323 38.4991 82.3306 38.486 82.3438 38.461Z"
        fill={frameColor}
      />
      <path
        d="M81.6463 39.1043C81.6463 39.1043 81.6019 39.1538 81.5282 39.233C81.6151 39.1824 81.6685 39.1531 81.6685 39.1531C81.6685 39.1531 81.6394 39.2066 81.5887 39.2935C81.6679 39.2197 81.7172 39.1755 81.7172 39.1755C81.7172 39.1755 81.3393 41.0485 80.5855 40.9523C80.4445 40.9343 80.3959 40.8608 80.4098 40.753C80.2896 40.8121 80.1805 40.8135 80.0944 40.7273C80.0082 40.6411 80.0096 40.532 80.0687 40.4117C79.961 40.4258 79.8873 40.3773 79.8693 40.2361C79.7734 39.4821 81.6463 39.1043 81.6463 39.1043Z"
        fill={frameColor}
      />
      <path
        d="M81.9457 38.3819C81.9457 38.3819 81.9492 38.3156 81.9534 38.2074C81.9791 38.3046 81.9958 38.3632 81.9958 38.3632C81.9958 38.3632 82.0131 38.3046 82.0388 38.2074C82.0423 38.3156 82.0465 38.3819 82.0465 38.3819C82.0465 38.3819 83.1031 36.7902 82.5022 36.3251C82.3897 36.238 82.3035 36.2558 82.2375 36.3419C82.1938 36.2151 82.1173 36.137 81.9958 36.137C81.8742 36.137 81.7978 36.2151 81.7547 36.3419C81.688 36.2558 81.6019 36.238 81.4893 36.3251C80.8884 36.7902 81.9457 38.3819 81.9457 38.3819Z"
        fill={frameColor}
      />
      <path
        d="M81.9457 39.2699C81.9457 39.2699 81.9492 39.3363 81.9534 39.4444C81.9791 39.3472 81.9958 39.2886 81.9958 39.2886C81.9958 39.2886 82.0131 39.3472 82.0388 39.4444C82.0423 39.3363 82.0465 39.2699 82.0465 39.2699C82.0465 39.2699 83.1031 40.8616 82.5022 41.3267C82.3897 41.4138 82.3035 41.3961 82.2375 41.3099C82.1938 41.4367 82.1173 41.5148 81.9958 41.5148C81.8742 41.5148 81.7978 41.4367 81.7547 41.3099C81.688 41.3961 81.6019 41.4138 81.4893 41.3267C80.8884 40.8616 81.9457 39.2699 81.9457 39.2699Z"
        fill={frameColor}
      />
      <path
        d="M81.6463 38.5475C81.6463 38.5475 81.6019 38.498 81.5282 38.4188C81.6151 38.4693 81.6685 38.4987 81.6685 38.4987C81.6685 38.4987 81.6394 38.4452 81.5887 38.3583C81.6679 38.4321 81.7172 38.4764 81.7172 38.4764C81.7172 38.4764 81.3393 36.6032 80.5855 36.6995C80.4445 36.7175 80.3959 36.7911 80.4098 36.8988C80.2896 36.8397 80.1805 36.8384 80.0944 36.9245C80.0082 37.0107 80.0096 37.1198 80.0687 37.2402C79.961 37.226 79.8873 37.2745 79.8693 37.4157C79.7734 38.1697 81.6463 38.5475 81.6463 38.5475Z"
        fill={frameColor}
      />
      <path
        d="M82.2743 39.1755C82.2743 39.1755 82.3237 39.2197 82.4029 39.2935C82.3521 39.2066 82.323 39.1531 82.323 39.1531C82.323 39.1531 82.3765 39.1824 82.4633 39.233C82.3897 39.1538 82.3452 39.1043 82.3452 39.1043C82.3452 39.1043 84.2188 39.4821 84.1222 40.2361C84.1042 40.3773 84.0305 40.4258 83.9229 40.4117C83.9819 40.532 83.9833 40.6411 83.8971 40.7273C83.811 40.8135 83.7019 40.8121 83.5818 40.753C83.5957 40.8608 83.547 40.9343 83.406 40.9523C82.6523 41.0485 82.2743 39.1755 82.2743 39.1755Z"
        fill={frameColor}
      />
      <path
        d="M81.9958 39.0465C81.8742 39.0465 81.7756 38.9477 81.7756 38.8259C81.7756 38.7041 81.8742 38.6053 81.9958 38.6053C82.1173 38.6053 82.2167 38.7041 82.2167 38.8259C82.2167 38.9477 82.1173 39.0465 81.9958 39.0465Z"
        fill={frameColor}
      />
      <path
        d="M75.8345 47.8199C75.8414 47.6108 75.6782 47.4353 75.4692 47.4278C75.2602 47.4203 75.0846 47.5837 75.0771 47.7928C75.0696 48.0019 75.2331 48.1774 75.4421 48.1849C75.6512 48.1923 75.8268 48.029 75.8345 47.8199Z"
        fill={frameColor}
      />
      <path
        d="M76.0623 47.7729C76.0554 47.9632 76.2048 48.1231 76.3951 48.1299C76.5855 48.1367 76.7452 47.9879 76.7522 47.7975C76.7584 47.6071 76.6098 47.4473 76.4194 47.4405C76.2291 47.4337 76.0693 47.5825 76.0623 47.7729Z"
        fill={frameColor}
      />
      <path
        d="M77.0182 48.3268C77.0127 48.4985 77.1468 48.6427 77.3184 48.6488C77.4899 48.6549 77.6337 48.5207 77.64 48.3491C77.6462 48.1773 77.5122 48.0332 77.3406 48.0271C77.169 48.0209 77.0245 48.1551 77.0182 48.3268Z"
        fill={frameColor}
      />
      <path
        d="M77.6074 49.2634C77.6018 49.4165 77.7213 49.5449 77.8741 49.5504C78.0276 49.5558 78.1555 49.4363 78.161 49.2833C78.1666 49.1303 78.0471 49.0018 77.8943 48.9963C77.7414 48.9909 77.6129 49.1105 77.6074 49.2634Z"
        fill={frameColor}
      />
      <path
        d="M77.7553 50.2403C77.7511 50.3747 77.856 50.4874 77.9901 50.4922C78.1242 50.497 78.2374 50.392 78.2423 50.2577C78.2472 50.1234 78.1416 50.0106 78.0075 50.0059C77.8734 50.0011 77.7602 50.106 77.7553 50.2403Z"
        fill={frameColor}
      />
      <path
        d="M77.5921 51.2163C77.5879 51.332 77.6782 51.429 77.7942 51.4332C77.9095 51.4373 78.0068 51.3469 78.011 51.2313C78.0151 51.1157 77.9248 51.0186 77.8088 51.0145C77.6935 51.0103 77.5962 51.1007 77.5921 51.2163Z"
        fill={frameColor}
      />
      <path
        d="M77.1357 51.8727C77.1322 51.9696 77.2086 52.051 77.3052 52.0545C77.4024 52.058 77.4837 51.9822 77.4872 51.8853C77.4906 51.7883 77.4149 51.707 77.3177 51.7035C77.2211 51.7 77.1391 51.7758 77.1357 51.8727Z"
        fill={frameColor}
      />
      <path
        d="M80.3917 22.8452C80.2382 22.9626 80.0186 22.9342 79.9012 22.7806C79.7838 22.6271 79.813 22.4076 79.9665 22.2895C80.1201 22.1721 80.3396 22.2012 80.457 22.3548C80.5744 22.5083 80.5452 22.7278 80.3917 22.8452Z"
        fill={frameColor}
      />
      <path
        d="M80.3959 23.1627C80.5355 23.0557 80.7356 23.0821 80.8426 23.2224C80.9495 23.3621 80.9231 23.5615 80.7835 23.6684C80.6439 23.7754 80.4438 23.749 80.3368 23.6094C80.2298 23.4698 80.2562 23.2697 80.3959 23.1627Z"
        fill={frameColor}
      />
      <path
        d="M81.3247 23.5865C81.4511 23.4899 81.631 23.5135 81.7276 23.64C81.8242 23.7657 81.8005 23.9463 81.6741 24.0429C81.5484 24.1395 81.3677 24.1151 81.2712 23.9894C81.1746 23.863 81.1989 23.683 81.3247 23.5865Z"
        fill={frameColor}
      />
      <path
        d="M82.3452 23.5288C82.4577 23.4427 82.6182 23.4635 82.7043 23.576C82.7905 23.6886 82.769 23.8491 82.6564 23.9352C82.5446 24.0214 82.3834 23.9998 82.2973 23.8873C82.2118 23.7754 82.2327 23.6143 82.3452 23.5288Z"
        fill={frameColor}
      />
      <path
        d="M83.1101 23.0009C83.2087 22.9251 83.3497 22.9439 83.4254 23.0425C83.5012 23.1412 83.4824 23.2822 83.3838 23.3572C83.2851 23.433 83.1441 23.4142 83.0684 23.3155C82.9926 23.2169 83.0114 23.0759 83.1101 23.0009Z"
        fill={frameColor}
      />
      <path
        d="M83.5005 22.1742C83.5852 22.1096 83.7068 22.1255 83.7721 22.2103C83.8367 22.295 83.8207 22.4166 83.736 22.4819C83.6512 22.5465 83.5297 22.5305 83.4644 22.4458C83.3997 22.361 83.4157 22.2395 83.5005 22.1742Z"
        fill={frameColor}
      />
      <path
        d="M83.5317 21.4093C83.6026 21.3551 83.7047 21.3683 83.7589 21.4399C83.8131 21.5107 83.7999 21.6121 83.729 21.667C83.6575 21.7212 83.5561 21.708 83.5012 21.6365C83.447 21.5656 83.4602 21.4642 83.5317 21.4093Z"
        fill={frameColor}
      />
      <path
        d="M75.1422 52.769C76.9724 52.3122 77.5344 49.5335 76.1902 49.1635C76.5229 49.5351 75.7379 50.3153 75.2302 49.657C74.617 48.8618 75.913 47.7396 76.937 48.9223C78.061 50.2204 76.9835 52.769 75.1422 52.769Z"
        fill={frameColor}
      />
      <path
        d="M81.3483 61.9936C80.796 65.0214 78.6251 66.1563 77.4455 68.6071C76.1381 71.3249 76.8703 74.4109 79.1822 75.4134C80.5953 76.026 82.2945 75.2783 82.587 73.6727C82.9968 71.424 79.9763 70.5216 79.5588 72.2497C79.3052 73.2997 80.8863 73.4499 80.712 72.6211C81.647 73.3155 80.2701 74.7365 79.1142 73.8919C77.6914 72.8527 78.6105 69.6462 81.34 70.2446C83.0246 70.6141 83.5331 72.4263 83.3115 73.5915C82.7537 76.5253 78.209 77.335 76.7869 73.5672C76.2847 74.6244 75.3469 74.4038 75.0044 74.0671C76.3673 73.3388 75.3818 71.2722 77.0113 68.1544C78.061 66.1442 79.7915 65.6305 81.3483 61.9936Z"
        fill={frameColor}
      />
      <path
        d="M80.0186 85.2382C79.0919 84.3427 79.1343 82.6483 80.1208 81.0512L80.2152 81.1638C79.4546 82.4434 79.2746 83.8286 80.2451 84.7915C81.3594 85.8974 83.1531 84.449 82.3244 83.5521C81.6178 82.7873 80.6619 83.9287 81.3796 84.317C80.6738 84.4282 80.4313 83.2548 81.3379 82.872C82.6786 82.3059 84.0562 84.0238 82.6842 85.3667C81.8374 86.1955 80.6043 85.8044 80.0186 85.2382Z"
        fill={frameColor}
      />
      <path
        d="M83.7186 69.588C83.1615 70.0762 83.1385 71.0499 83.6603 71.9941L83.7172 71.9325C83.3171 71.1766 83.2525 70.3761 83.836 69.8508C84.5071 69.2471 85.4963 70.1285 84.9955 70.6203C84.5682 71.0397 84.0514 70.3574 84.4744 70.1547C84.0722 70.071 83.8999 70.738 84.4098 70.983C85.1636 71.3454 86.0028 70.398 85.2525 69.5888C84.7891 69.0894 84.0708 69.2794 83.7186 69.588Z"
        fill={frameColor}
      />
      <path
        d="M83.406 68.7919C83.8721 69.2199 84.8795 73.4562 81.9798 75.557V75.4709C84.0701 73.3935 83.9604 70.3108 83.3039 68.8711C83.3039 68.8711 83.431 68.8862 83.406 68.7919Z"
        fill={frameColor}
      />
      <path
        d="M82.0944 76.6745C82.9301 75.8092 83.911 77.362 82.373 78.0862C81.449 78.5213 80.5334 78.2487 79.8741 77.8956C80.2159 78.2355 80.473 78.6216 80.6043 79.0796C81.0732 80.708 80.0381 81.6521 79.06 81.8404C77.8977 82.0627 76.5799 80.9554 77.1009 79.6609C77.4281 78.8487 78.9933 78.5753 79.119 79.8162C78.2229 79.4272 77.8345 80.9227 79.0044 81.0179C79.7713 81.0811 80.557 80.0832 80.1694 79.0835C79.5942 77.5977 76.6452 76.9276 76.732 74.1211C77.8185 78.3089 82.1069 78.0815 82.0944 76.6745Z"
        fill={frameColor}
      />
      <path
        d="M77.0523 53.4869C77.8283 53.8846 80.5133 55.9621 80.2326 57.693C80.0846 58.6089 78.9787 58.4883 78.9071 58.1113C78.1381 59.6847 80.3674 60.492 80.8954 58.8871C82.3251 64.5817 76.4403 65.7356 75.58 62.6107C74.9737 60.4061 78.0068 59.317 78.4716 60.9255C78.8418 62.2089 77.037 62.2406 77.1377 61.3996C76.3409 62.2688 77.8463 63.6006 78.9245 62.6574C80.25 61.496 79.1864 58.2482 76.5202 59.086C74.8738 59.6021 74.5282 61.4525 74.852 62.5934C75.1487 63.643 75.8581 64.3623 76.8098 64.6643C76.0193 64.8382 75.4735 65.2467 75.5474 66.0432C75.6626 67.2786 77.301 67.5382 77.4128 66.2159C77.1002 66.4387 76.4576 66.3802 76.4854 65.7759C76.5403 64.5579 80.2708 65.8544 81.3483 61.9936C81.9416 63.0019 82.8565 62.6988 83.1684 62.3337C81.7464 61.7285 82.7585 59.3315 80.8127 56.4011C79.2885 54.1052 77.1461 53.5119 77.0523 53.4869Z"
        fill={frameColor}
      />
      <path
        d="M81.2135 78.7896C81.4601 79.3507 81.7651 80.187 81.0836 80.8623C81.8707 81.2714 82.1618 80.8366 82.241 80.5633C82.5203 80.7907 83.1691 81.1631 83.6686 80.856C83.886 80.3115 83.4088 79.736 83.1365 79.4991C83.3921 79.3744 83.7714 79.0136 83.2337 78.3081C82.6842 79.095 81.8082 78.9373 81.2135 78.7896Z"
        fill={frameColor}
      />
      <path
        d="M82.4397 67.2244C82.4397 67.2244 82.5064 67.2207 82.614 67.2169C82.5168 67.1912 82.4584 67.1741 82.4584 67.1741C82.4584 67.1741 82.5168 67.1571 82.614 67.1313C82.5064 67.1275 82.4397 67.1238 82.4397 67.1238C82.4397 67.1238 84.0312 66.0665 84.4967 66.6676C84.5835 66.7802 84.5661 66.8666 84.48 66.9327C84.6064 66.9761 84.6849 67.0523 84.6849 67.1741C84.6849 67.2959 84.6064 67.3721 84.48 67.4156C84.5661 67.4816 84.5835 67.568 84.4967 67.6806C84.0312 68.2817 82.4397 67.2244 82.4397 67.2244Z"
        fill={frameColor}
      />
      <path
        d="M81.5519 67.2244C81.5519 67.2244 81.4852 67.2207 81.3775 67.2169C81.4747 67.1912 81.5331 67.1741 81.5331 67.1741C81.5331 67.1741 81.4747 67.1571 81.3775 67.1313C81.4852 67.1275 81.5519 67.1238 81.5519 67.1238C81.5519 67.1238 79.9603 66.0665 79.4949 66.6676C79.408 66.7802 79.4254 66.8666 79.5115 66.9327C79.3851 66.9761 79.3066 67.0523 79.3066 67.1741C79.3066 67.2959 79.3851 67.3721 79.5115 67.4156C79.4254 67.4816 79.408 67.568 79.4949 67.6806C79.9603 68.2817 81.5519 67.2244 81.5519 67.2244Z"
        fill={frameColor}
      />
      <path
        d="M82.3438 67.539C82.3577 67.5639 82.3771 67.5987 82.4022 67.6422C82.3341 67.577 82.2875 67.5357 82.275 67.5259C82.2827 67.5683 82.4272 68.2572 82.7078 68.7646C82.8857 69.0873 83.1184 69.3372 83.4067 69.3003C83.4442 69.296 83.4762 69.2873 83.5012 69.2731C83.5692 69.2405 83.5922 69.1797 83.5811 69.1014C83.7019 69.1601 83.8103 69.1612 83.8978 69.0754C83.9833 68.9895 83.9826 68.8798 83.9229 68.7603C83.9923 68.769 84.0465 68.7526 84.0826 68.7016C84.1021 68.6734 84.116 68.6343 84.1222 68.5842C84.2181 67.8301 82.3452 67.4521 82.3452 67.4521C82.3452 67.4521 82.3848 67.4977 82.4536 67.5705C82.457 67.5737 82.4598 67.577 82.4633 67.5814C82.4536 67.576 82.4445 67.5705 82.4362 67.565C82.3653 67.5238 82.323 67.5009 82.323 67.5009C82.323 67.5009 82.3306 67.514 82.3438 67.539Z"
        fill={frameColor}
      />
      <path
        d="M81.6463 66.8957C81.6463 66.8957 81.6019 66.8462 81.5282 66.767C81.6151 66.8176 81.6685 66.8469 81.6685 66.8469C81.6685 66.8469 81.6394 66.7934 81.5887 66.7065C81.6679 66.7803 81.7172 66.8245 81.7172 66.8245C81.7172 66.8245 81.3393 64.9515 80.5855 65.0477C80.4445 65.0657 80.3959 65.1393 80.4098 65.247C80.2896 65.1879 80.1805 65.1865 80.0944 65.2728C80.0082 65.3589 80.0096 65.468 80.0687 65.5884C79.961 65.5742 79.8873 65.6227 79.8693 65.7639C79.7734 66.5179 81.6463 66.8957 81.6463 66.8957Z"
        fill={frameColor}
      />
      <path
        d="M81.9457 67.6181C81.9457 67.6181 81.9492 67.6844 81.9534 67.7926C81.9791 67.6954 81.9958 67.6368 81.9958 67.6368C81.9958 67.6368 82.0131 67.6954 82.0388 67.7926C82.0423 67.6844 82.0465 67.6181 82.0465 67.6181C82.0465 67.6181 83.1031 69.2098 82.5022 69.6749C82.3897 69.762 82.3035 69.7442 82.2375 69.6581C82.1938 69.785 82.1173 69.863 81.9958 69.863C81.8742 69.863 81.7978 69.785 81.7547 69.6581C81.688 69.7442 81.6019 69.762 81.4893 69.6749C80.8884 69.2098 81.9457 67.6181 81.9457 67.6181Z"
        fill={frameColor}
      />
      <path
        d="M81.9457 66.7301C81.9457 66.7301 81.9492 66.6637 81.9534 66.5556C81.9791 66.6528 81.9958 66.7114 81.9958 66.7114C81.9958 66.7114 82.0131 66.6528 82.0388 66.5556C82.0423 66.6637 82.0465 66.7301 82.0465 66.7301C82.0465 66.7301 83.1031 65.1384 82.5022 64.6733C82.3897 64.5863 82.3035 64.6039 82.2375 64.6901C82.1938 64.5633 82.1173 64.4852 81.9958 64.4852C81.8742 64.4852 81.7978 64.5633 81.7547 64.6901C81.688 64.6039 81.6019 64.5863 81.4893 64.6733C80.8884 65.1384 81.9457 66.7301 81.9457 66.7301Z"
        fill={frameColor}
      />
      <path
        d="M81.6463 67.4525C81.6463 67.4525 81.6019 67.502 81.5282 67.5812C81.6151 67.5307 81.6685 67.5013 81.6685 67.5013C81.6685 67.5013 81.6394 67.5548 81.5887 67.6417C81.6679 67.5679 81.7172 67.5236 81.7172 67.5236C81.7172 67.5236 81.3393 69.3968 80.5855 69.3005C80.4445 69.2826 80.3959 69.2089 80.4098 69.1012C80.2896 69.1603 80.1805 69.1616 80.0944 69.0755C80.0082 68.9893 80.0096 68.8803 80.0687 68.7598C79.961 68.774 79.8873 68.7255 79.8693 68.5843C79.7734 67.8304 81.6463 67.4525 81.6463 67.4525Z"
        fill={frameColor}
      />
      <path
        d="M82.2743 66.8245C82.2743 66.8245 82.3237 66.7803 82.4029 66.7065C82.3521 66.7934 82.323 66.8469 82.323 66.8469C82.323 66.8469 82.3765 66.8176 82.4633 66.767C82.3897 66.8462 82.3452 66.8957 82.3452 66.8957C82.3452 66.8957 84.2188 66.5179 84.1222 65.7639C84.1042 65.6227 84.0305 65.5742 83.9229 65.5884C83.9819 65.468 83.9833 65.3589 83.8971 65.2728C83.811 65.1865 83.7019 65.1879 83.5818 65.247C83.5957 65.1392 83.547 65.0657 83.406 65.0477C82.6523 64.9515 82.2743 66.8245 82.2743 66.8245Z"
        fill={frameColor}
      />
      <path
        d="M81.9958 66.9535C81.8742 66.9535 81.7756 67.0523 81.7756 67.1741C81.7756 67.2959 81.8742 67.3947 81.9958 67.3947C82.1173 67.3947 82.2167 67.2959 82.2167 67.1741C82.2167 67.0523 82.1173 66.9535 81.9958 66.9535Z"
        fill={frameColor}
      />
      <path
        d="M75.8345 58.1802C75.8414 58.3892 75.6782 58.5647 75.4692 58.5722C75.2602 58.5797 75.0846 58.4163 75.0771 58.2072C75.0696 57.9981 75.2331 57.8226 75.4421 57.8152C75.6512 57.8077 75.8268 57.9711 75.8345 58.1802Z"
        fill={frameColor}
      />
      <path
        d="M76.0623 58.2271C76.0554 58.0368 76.2048 57.8769 76.3951 57.8701C76.5855 57.8633 76.7452 58.0121 76.7522 58.2025C76.7584 58.3929 76.6098 58.5527 76.4194 58.5595C76.2291 58.5663 76.0693 58.4175 76.0623 58.2271Z"
        fill={frameColor}
      />
      <path
        d="M77.0182 57.6732C77.0127 57.5015 77.1468 57.3574 77.3184 57.3512C77.4899 57.3451 77.6337 57.4793 77.64 57.6509C77.6462 57.8227 77.5122 57.9668 77.3406 57.9729C77.169 57.9791 77.0245 57.8449 77.0182 57.6732Z"
        fill={frameColor}
      />
      <path
        d="M77.6074 56.7366C77.6018 56.5835 77.7213 56.4551 77.8741 56.4496C78.0276 56.4442 78.1555 56.5637 78.161 56.7167C78.1666 56.8697 78.0471 56.9982 77.8943 57.0037C77.7414 57.0091 77.6129 56.8896 77.6074 56.7366Z"
        fill={frameColor}
      />
      <path
        d="M77.7553 55.7597C77.7511 55.6253 77.856 55.5126 77.9901 55.5078C78.1242 55.503 78.2374 55.608 78.2423 55.7423C78.2472 55.8766 78.1416 55.9894 78.0075 55.9942C77.8734 55.9989 77.7602 55.894 77.7553 55.7597Z"
        fill={frameColor}
      />
      <path
        d="M77.5921 54.7837C77.5879 54.668 77.6782 54.571 77.7942 54.5668C77.9095 54.5627 78.0068 54.6531 78.011 54.7687C78.0151 54.8843 77.9248 54.9814 77.8088 54.9855C77.6935 54.9897 77.5962 54.8993 77.5921 54.7837Z"
        fill={frameColor}
      />
      <path
        d="M77.1357 54.1273C77.1322 54.0304 77.2086 53.949 77.3052 53.9455C77.4024 53.942 77.4837 54.0178 77.4872 54.1147C77.4906 54.2117 77.4149 54.293 77.3177 54.2965C77.2211 54.3 77.1391 54.2242 77.1357 54.1273Z"
        fill={frameColor}
      />
      <path
        d="M80.3917 83.1548C80.2382 83.0374 80.0186 83.0659 79.9012 83.2194C79.7838 83.3729 79.813 83.5924 79.9665 83.7098C80.1201 83.8279 80.3396 83.7988 80.457 83.6452C80.5744 83.4917 80.5452 83.2722 80.3917 83.1548Z"
        fill={frameColor}
      />
      <path
        d="M80.3959 82.8373C80.5355 82.9443 80.7356 82.9179 80.8426 82.7783C80.9495 82.6379 80.9231 82.4385 80.7835 82.3309C80.6439 82.2239 80.4438 82.251 80.3368 82.3906C80.2298 82.5302 80.2562 82.7303 80.3959 82.8373Z"
        fill={frameColor}
      />
      <path
        d="M81.3247 82.4135C81.4511 82.5101 81.631 82.4865 81.7276 82.36C81.8242 82.2343 81.8005 82.0537 81.6741 81.9571C81.5484 81.8605 81.3677 81.8849 81.2712 82.0106C81.1746 82.137 81.1989 82.317 81.3247 82.4135Z"
        fill={frameColor}
      />
      <path
        d="M82.3452 82.4712C82.4577 82.5573 82.6182 82.5365 82.7043 82.424C82.7905 82.3114 82.769 82.1509 82.6564 82.0648C82.5446 81.9787 82.3834 82.0002 82.2973 82.1127C82.2118 82.2246 82.2327 82.3857 82.3452 82.4712Z"
        fill={frameColor}
      />
      <path
        d="M83.1101 82.9992C83.2087 83.0749 83.3497 83.0561 83.4254 82.9575C83.5012 82.8588 83.4824 82.7178 83.3838 82.6428C83.2851 82.5671 83.1441 82.5858 83.0684 82.6845C82.9926 82.7831 83.0114 82.9241 83.1101 82.9992Z"
        fill={frameColor}
      />
      <path
        d="M83.5005 83.8252C83.5852 83.8905 83.7068 83.8745 83.7721 83.7897C83.8367 83.705 83.8207 83.5834 83.736 83.5181C83.6512 83.4535 83.5297 83.4695 83.4644 83.5542C83.3997 83.639 83.4157 83.7606 83.5005 83.8252Z"
        fill={frameColor}
      />
      <path
        d="M83.5317 84.5907C83.6026 84.6449 83.7047 84.6317 83.7589 84.5602C83.8131 84.4893 83.7999 84.3872 83.729 84.333C83.6575 84.2788 83.5561 84.292 83.5012 84.3629C83.447 84.4344 83.4602 84.5358 83.5317 84.5907Z"
        fill={frameColor}
      />
      <path
        d="M75.1422 53.231C76.9724 53.6878 77.5344 56.4665 76.1902 56.8365C76.5229 56.4649 75.7379 55.6847 75.2302 56.343C74.617 57.1382 75.913 58.2604 76.937 57.0776C78.061 55.7796 76.9835 53.231 75.1422 53.231Z"
        fill={frameColor}
      />
      <path
        d="M75.6298 25.9102C74.3025 27.6917 76.4639 27.9765 76.3916 30.9137C77.3656 28.3976 77.0634 26.5756 75.6298 25.9102Z"
        fill={frameColor}
      />
      <rect x="23" y="28" width="50" height="50" fill={frameBackgroundColor} />

      <foreignObject
        width="50%"
        height="50px"
        style={{ borderRadius: "3px" }}
        x="24"
        y="28"
      >
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "4px",
          }}
        >
          {urlQr ? <img src={urlQr} width="100%" height="auto" /> : children}
        </div>
      </foreignObject>
      <foreignObject width="100%" height="18px" y="93">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}
        >
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
           fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
