import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const ButtonFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <g>
        <title>Layer 1</title>
        <path
          stroke={frameBackgroundColor}
          id="svg_928"
          d="m76,75.4l740.99994,0l0,741.99997l-740.99994,0l0,-741.99997z"
          strokeWidth="39"
          fill="#ffffff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m0,50.00846c0,-27.61892 22.38954,-50.00846 50.00846,-50.00846l800.1353,0c27.61842,0 50.00846,22.38954 50.00846,50.00846l0,800.1353c0,27.61842 -22.39004,50.00846 -50.00846,50.00846l-800.1353,0c-27.61892,0 -50.00846,-22.39004 -50.00846,-50.00846l0,-800.1353zm0,937.65856c0,-27.61842 22.38954,-50.00846 50.00846,-50.00846l800.1353,0c27.61842,0 50.00846,22.39004 50.00846,50.00846l0,162.52748c0,27.61842 -22.39004,50.00846 -50.00846,50.00846l-800.1353,0c-27.61892,0 -50.00846,-22.39004 -50.00846,-50.00846l0,-162.52748zm836.39143,-931.4075l-780.13192,0l0,780.13192l780.13192,0l0,-780.13192z"
          fill={frameColor}
          id="svg_17"
          strokeWidth="0"
          stroke="null"
        />
        <g
          id="svg_8"
          transform="matrix(6.13963 0 0 6.13963 3.24696 22.7919)"
          stroke="null">
          <g
            id="svg_16"
            stroke="null"
            dangerouslySetInnerHTML={{ __html: qrSvg }}></g>
        </g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="260"
          x="0"
          y="940">
          <style>
            {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
          </style>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            strokeWidth="3px"
            fill="none"
          />
          <text
            x="50%"
            y="55%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={textColor}
            fontFamily="Nunito Sans"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </g>
    </svg>
  );
};
