import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const BeautyFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1333,
    qrSvg,
    width = 1000,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 87.999997 0.5 C 92.141872 0.5 95.499997 3.857724 95.499997 8.000001 L 95.499997 120.000011 C 95.499997 124.142288 92.141872 127.500012 87.999997 127.500012 L 8 127.500012 C 3.858125 127.500012 0.5 124.142288 0.5 120.000011 L 0.5 8.000001 C 0.5 3.857724 3.858125 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(12.5,0,0,12.492186,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 923.25 985.136719 L 923.25 339.035156 L 276.75 339.035156 L 276.75 985.136719 Z M 928.683594 333.609375 L 928.683594 990.5625 L 271.316406 990.5625 L 271.316406 333.609375 Z M 928.683594 333.609375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 914.976562 976.863281 L 914.976562 347.308594 L 285.023438 347.308594 L 285.023438 976.863281 Z M 917.726562 344.558594 L 917.726562 979.613281 L 282.273438 979.613281 L 282.273438 344.558594 Z M 917.726562 344.558594 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 261.378906 316.929688 C 283.644531 333.511719 287.207031 306.507812 323.921875 307.414062 C 292.472656 295.242188 269.695312 299.019531 261.378906 316.929688 Z M 261.378906 316.929688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 628.6875 248.828125 C 637.757812 259.746094 659.816406 240.113281 641.972656 224.488281 C 628.054688 212.304688 601.265625 228.328125 601.898438 251.628906 C 619.304688 217.683594 646.410156 241.925781 628.6875 248.828125 Z M 628.6875 248.828125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 571.3125 248.828125 C 562.242188 259.746094 540.183594 240.113281 558.027344 224.488281 C 571.945312 212.304688 598.734375 228.328125 598.101562 251.628906 C 580.695312 217.683594 553.589844 241.925781 571.3125 248.828125 Z M 571.3125 248.828125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 583.4375 212.101562 C 599.898438 224.433594 600 246.882812 600 246.882812 C 600 246.882812 600.101562 224.433594 616.5625 212.101562 C 619.515625 199.238281 605.804688 204.503906 600 187.382812 C 594.195312 204.503906 580.484375 199.238281 583.4375 212.101562 Z M 583.4375 212.101562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 600 261.652344 C 600.285156 257.097656 617.1875 249.15625 627.800781 257.363281 C 604.898438 263.039062 600.851562 290.660156 600 296.269531 C 599.148438 290.660156 595.101562 263.039062 572.199219 257.363281 C 582.8125 249.15625 599.714844 257.097656 600 261.652344 Z M 600 261.652344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 712.421875 245.492188 C 750.265625 252.394531 764.453125 279.507812 795.089844 294.246094 C 829.058594 310.582031 867.636719 301.433594 880.167969 272.550781 C 887.828125 254.898438 878.480469 233.671875 858.40625 230.019531 C 830.300781 224.902344 819.019531 262.632812 840.625 267.847656 C 853.75 271.015625 855.625 251.261719 845.265625 253.441406 C 853.945312 241.761719 871.707031 258.960938 861.148438 273.402344 C 848.160156 291.175781 808.078125 279.691406 815.558594 245.597656 C 820.175781 224.550781 842.828125 218.199219 857.394531 220.96875 C 894.066406 227.9375 904.1875 284.710938 857.089844 302.472656 C 870.304688 308.75 867.546875 320.460938 863.339844 324.742188 C 854.234375 307.71875 828.402344 320.027344 789.429688 299.671875 C 764.300781 286.550781 757.878906 264.941406 712.421875 245.492188 Z M 712.421875 245.492188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1002.980469 262.101562 C 991.785156 273.679688 970.601562 273.152344 950.640625 260.828125 L 952.046875 259.644531 C 968.042969 269.148438 985.359375 271.398438 997.390625 259.277344 C 1011.214844 245.355469 993.113281 222.949219 981.898438 233.296875 C 972.34375 242.125 986.609375 254.066406 991.460938 245.101562 C 992.855469 253.921875 978.183594 256.949219 973.402344 245.621094 C 966.324219 228.875 987.796875 211.65625 1004.582031 228.804688 C 1014.941406 239.378906 1010.054688 254.789062 1002.980469 262.101562 Z M 1002.980469 262.101562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 807.351562 215.882812 C 813.453125 222.839844 825.625 223.128906 837.425781 216.613281 L 836.65625 215.898438 C 827.207031 220.898438 817.199219 221.707031 810.632812 214.414062 C 803.089844 206.03125 814.105469 193.675781 820.25 199.929688 C 825.496094 205.269531 816.964844 211.726562 814.433594 206.441406 C 813.390625 211.46875 821.722656 213.617188 824.789062 207.246094 C 829.316406 197.832031 817.476562 187.351562 807.359375 196.722656 C 801.117188 202.507812 803.492188 211.480469 807.351562 215.882812 Z M 807.351562 215.882812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 797.398438 219.789062 C 802.75 213.964844 855.703125 201.378906 881.960938 237.605469 L 880.882812 237.605469 C 854.921875 211.492188 816.382812 212.863281 798.390625 221.0625 C 798.390625 221.0625 798.578125 219.476562 797.398438 219.789062 Z M 797.398438 219.789062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 895.929688 236.171875 C 885.117188 225.730469 904.523438 213.476562 913.578125 232.695312 C 919.015625 244.234375 915.609375 255.671875 911.195312 263.910156 C 915.445312 259.636719 920.269531 256.425781 925.996094 254.777344 C 946.351562 248.929688 958.152344 261.859375 960.507812 274.078125 C 963.285156 288.597656 949.445312 305.058594 933.261719 298.550781 C 923.109375 294.464844 919.691406 274.914062 935.203125 273.34375 C 930.34375 284.535156 949.035156 289.386719 950.222656 274.773438 C 951.015625 265.195312 938.539062 255.378906 926.042969 260.21875 C 907.472656 267.40625 899.09375 304.246094 864.015625 303.15625 C 916.359375 289.589844 913.519531 236.015625 895.929688 236.171875 Z M 895.929688 236.171875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 606.085938 299.160156 C 611.058594 289.464844 637.027344 255.925781 658.664062 259.429688 C 670.109375 261.277344 668.601562 275.09375 663.890625 275.988281 C 683.558594 285.597656 693.652344 257.75 673.589844 251.152344 C 744.773438 233.292969 759.195312 306.804688 720.136719 317.550781 C 692.578125 325.125 678.960938 287.234375 699.070312 281.429688 C 715.109375 276.804688 715.507812 299.347656 704.996094 298.09375 C 715.859375 308.046875 732.507812 289.242188 720.71875 275.769531 C 706.199219 259.214844 665.601562 272.5 676.074219 305.804688 C 682.527344 326.371094 705.65625 330.691406 719.914062 326.648438 C 733.035156 322.941406 742.027344 314.074219 745.804688 302.1875 C 747.976562 312.066406 753.085938 318.882812 763.039062 317.960938 C 778.480469 316.523438 781.730469 296.054688 765.195312 294.652344 C 767.984375 298.5625 767.253906 306.589844 759.699219 306.242188 C 744.472656 305.558594 760.679688 258.953125 712.421875 245.492188 C 725.023438 238.082031 721.234375 226.652344 716.671875 222.753906 C 709.105469 240.519531 679.144531 227.878906 642.515625 252.183594 C 613.8125 271.226562 606.398438 297.996094 606.085938 299.160156 Z M 606.085938 299.160156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 922.367188 247.175781 C 929.386719 244.09375 939.839844 240.285156 948.277344 248.800781 C 953.390625 238.96875 947.957031 235.332031 944.539062 234.339844 C 947.386719 230.851562 952.039062 222.746094 948.203125 216.507812 C 941.394531 213.792969 934.199219 219.75 931.238281 223.152344 C 929.679688 219.960938 925.171875 215.222656 916.351562 221.941406 C 926.1875 228.804688 924.214844 239.746094 922.367188 247.175781 Z M 922.367188 247.175781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 777.804688 231.859375 C 777.804688 231.859375 777.757812 231.027344 777.710938 229.679688 C 777.390625 230.886719 777.179688 231.625 777.179688 231.625 C 777.179688 231.625 776.960938 230.886719 776.640625 229.679688 C 776.59375 231.027344 776.546875 231.859375 776.546875 231.859375 C 776.546875 231.859375 763.332031 211.976562 770.84375 206.164062 C 772.25 205.078125 773.335938 205.296875 774.160156 206.371094 C 774.703125 204.789062 775.65625 203.8125 777.179688 203.8125 C 778.695312 203.8125 779.652344 204.789062 780.191406 206.371094 C 781.023438 205.296875 782.101562 205.078125 783.507812 206.164062 C 791.019531 211.976562 777.804688 231.859375 777.804688 231.859375 Z M 777.804688 231.859375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 777.804688 242.949219 C 777.804688 242.949219 777.757812 243.777344 777.710938 245.128906 C 777.390625 243.910156 777.179688 243.183594 777.179688 243.183594 C 777.179688 243.183594 776.960938 243.910156 776.640625 245.128906 C 776.59375 243.777344 776.546875 242.949219 776.546875 242.949219 C 776.546875 242.949219 763.332031 262.832031 770.84375 268.648438 C 772.25 269.730469 773.335938 269.515625 774.160156 268.425781 C 774.703125 270.015625 775.65625 271 777.179688 271 C 778.695312 271 779.652344 270.015625 780.191406 268.425781 C 781.023438 269.515625 782.101562 269.730469 783.507812 268.648438 C 791.019531 262.832031 777.804688 242.949219 777.804688 242.949219 Z M 777.804688 242.949219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 781.738281 233.054688 C 782.046875 232.882812 782.484375 232.632812 783.027344 232.320312 C 782.210938 233.175781 781.695312 233.761719 781.574219 233.90625 C 782.105469 233.820312 790.714844 232.003906 797.058594 228.507812 C 801.089844 226.277344 804.214844 223.378906 803.75 219.78125 C 803.699219 219.3125 803.59375 218.910156 803.414062 218.597656 C 803.007812 217.75 802.246094 217.460938 801.265625 217.601562 C 802.003906 216.09375 802.015625 214.730469 800.945312 213.640625 C 799.871094 212.578125 798.496094 212.582031 797 213.332031 C 797.113281 212.464844 796.90625 211.785156 796.269531 211.335938 C 795.917969 211.09375 795.425781 210.917969 794.804688 210.839844 C 785.375 209.644531 780.652344 233.039062 780.652344 233.039062 C 780.652344 233.039062 781.222656 232.535156 782.132812 231.6875 C 782.171875 231.644531 782.210938 231.605469 782.269531 231.566406 C 782.199219 231.6875 782.132812 231.789062 782.0625 231.902344 C 781.546875 232.789062 781.261719 233.316406 781.261719 233.316406 C 781.261719 233.316406 781.425781 233.222656 781.738281 233.054688 Z M 781.738281 233.054688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 773.695312 241.769531 C 773.695312 241.769531 773.078125 242.324219 772.085938 243.246094 C 772.71875 242.160156 773.085938 241.492188 773.085938 241.492188 C 773.085938 241.492188 772.414062 241.855469 771.332031 242.492188 C 772.25 241.5 772.804688 240.882812 772.804688 240.882812 C 772.804688 240.882812 749.394531 245.601562 750.59375 255.023438 C 750.820312 256.785156 751.738281 257.390625 753.089844 257.210938 C 752.347656 258.71875 752.328125 260.082031 753.40625 261.15625 C 754.484375 262.234375 755.851562 262.214844 757.355469 261.476562 C 757.175781 262.824219 757.785156 263.742188 759.546875 263.964844 C 768.976562 265.167969 773.695312 241.769531 773.695312 241.769531 Z M 773.695312 241.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 782.726562 238.03125 C 782.726562 238.03125 783.554688 237.984375 784.90625 237.941406 C 783.695312 237.613281 782.957031 237.402344 782.957031 237.402344 C 782.957031 237.402344 783.695312 237.1875 784.90625 236.867188 C 783.554688 236.820312 782.726562 236.769531 782.726562 236.769531 C 782.726562 236.769531 802.621094 223.570312 808.433594 231.078125 C 809.527344 232.484375 809.300781 233.5625 808.226562 234.386719 C 809.8125 234.933594 810.789062 235.875 810.789062 237.402344 C 810.789062 238.921875 809.8125 239.878906 808.226562 240.417969 C 809.300781 241.25 809.527344 242.324219 808.433594 243.734375 C 802.621094 251.238281 782.726562 238.03125 782.726562 238.03125 Z M 782.726562 238.03125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 771.628906 238.03125 C 771.628906 238.03125 770.796875 237.984375 769.445312 237.941406 C 770.65625 237.613281 771.394531 237.402344 771.394531 237.402344 C 771.394531 237.402344 770.65625 237.1875 769.445312 236.867188 C 770.796875 236.820312 771.628906 236.769531 771.628906 236.769531 C 771.628906 236.769531 751.730469 223.570312 745.917969 231.078125 C 744.828125 232.484375 745.050781 233.5625 746.128906 234.386719 C 744.539062 234.933594 743.5625 235.875 743.5625 237.402344 C 743.5625 238.921875 744.539062 239.878906 746.128906 240.417969 C 745.050781 241.25 744.828125 242.324219 745.917969 243.734375 C 751.730469 251.238281 771.628906 238.03125 771.628906 238.03125 Z M 771.628906 238.03125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 780.65625 241.769531 C 780.65625 241.769531 781.273438 242.324219 782.265625 243.246094 C 781.636719 242.160156 781.265625 241.492188 781.265625 241.492188 C 781.265625 241.492188 781.9375 241.855469 783.023438 242.492188 C 782.101562 241.5 781.546875 240.882812 781.546875 240.882812 C 781.546875 240.882812 804.960938 245.601562 803.757812 255.023438 C 803.53125 256.785156 802.613281 257.390625 801.265625 257.210938 C 802.003906 258.71875 802.023438 260.082031 800.945312 261.15625 C 799.867188 262.234375 798.5 262.214844 796.996094 261.476562 C 797.175781 262.824219 796.570312 263.742188 794.804688 263.964844 C 785.382812 265.167969 780.65625 241.769531 780.65625 241.769531 Z M 780.65625 241.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 772.804688 233.925781 C 772.804688 233.925781 772.25 233.308594 771.332031 232.320312 C 772.414062 232.953125 773.085938 233.316406 773.085938 233.316406 C 773.085938 233.316406 772.71875 232.648438 772.085938 231.566406 C 773.078125 232.484375 773.695312 233.039062 773.695312 233.039062 C 773.695312 233.039062 768.976562 209.636719 759.546875 210.839844 C 757.785156 211.066406 757.175781 211.988281 757.355469 213.332031 C 755.851562 212.59375 754.484375 212.578125 753.40625 213.652344 C 752.328125 214.730469 752.347656 216.09375 753.089844 217.589844 C 751.738281 217.417969 750.820312 218.027344 750.59375 219.789062 C 749.394531 229.203125 772.804688 233.925781 772.804688 233.925781 Z M 772.804688 233.925781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 774.417969 237.402344 C 774.417969 238.921875 775.65625 240.160156 777.179688 240.160156 C 778.695312 240.160156 779.933594 238.921875 779.933594 237.402344 C 779.933594 235.875 778.695312 234.644531 777.179688 234.644531 C 775.65625 234.644531 774.417969 235.875 774.417969 237.402344 Z M 774.417969 237.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 664.753906 314.371094 C 667.363281 314.285156 669.558594 316.324219 669.652344 318.933594 C 669.746094 321.546875 667.703125 323.738281 665.089844 323.832031 C 662.476562 323.925781 660.28125 321.886719 660.191406 319.273438 C 660.097656 316.664062 662.136719 314.46875 664.753906 314.371094 Z M 664.753906 314.371094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 665.339844 311.527344 C 662.957031 311.609375 660.960938 309.746094 660.875 307.371094 C 660.792969 304.992188 662.652344 302.992188 665.03125 302.910156 C 667.410156 302.832031 669.40625 304.6875 669.492188 307.066406 C 669.578125 309.441406 667.71875 311.4375 665.339844 311.527344 Z M 665.339844 311.527344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 658.414062 299.582031 C 656.269531 299.652344 654.46875 297.980469 654.390625 295.835938 C 654.3125 293.691406 655.992188 291.890625 658.132812 291.820312 C 660.285156 291.738281 662.085938 293.410156 662.160156 295.558594 C 662.242188 297.699219 660.5625 299.507812 658.414062 299.582031 Z M 658.414062 299.582031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 646.707031 292.226562 C 644.792969 292.296875 643.191406 290.800781 643.121094 288.894531 C 643.054688 286.972656 644.546875 285.371094 646.457031 285.308594 C 648.371094 285.238281 649.976562 286.730469 650.046875 288.640625 C 650.113281 290.546875 648.617188 292.15625 646.707031 292.226562 Z M 646.707031 292.226562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 634.496094 290.371094 C 632.816406 290.425781 631.40625 289.121094 631.34375 287.441406 C 631.289062 285.769531 632.601562 284.355469 634.28125 284.292969 C 635.957031 284.242188 637.367188 285.550781 637.425781 287.226562 C 637.484375 288.902344 636.171875 290.316406 634.496094 290.371094 Z M 634.496094 290.371094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 622.296875 292.414062 C 620.851562 292.46875 619.636719 291.339844 619.585938 289.894531 C 619.53125 288.449219 620.664062 287.234375 622.109375 287.183594 C 623.554688 287.132812 624.769531 288.257812 624.820312 289.710938 C 624.871094 291.148438 623.742188 292.367188 622.296875 292.414062 Z M 622.296875 292.414062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 614.089844 298.117188 C 612.882812 298.164062 611.863281 297.214844 611.816406 296 C 611.773438 294.785156 612.722656 293.769531 613.9375 293.726562 C 615.144531 293.683594 616.164062 294.628906 616.203125 295.84375 C 616.25 297.050781 615.304688 298.074219 614.089844 298.117188 Z M 614.089844 298.117188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 976.9375 257.445312 C 975.46875 259.359375 975.820312 262.101562 977.742188 263.566406 C 979.660156 265.035156 982.40625 264.675781 983.875 262.753906 C 985.351562 260.835938 984.984375 258.09375 983.066406 256.628906 C 981.144531 255.160156 978.402344 255.523438 976.9375 257.445312 Z M 976.9375 257.445312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 972.964844 257.390625 C 974.300781 255.644531 973.972656 253.148438 972.230469 251.8125 C 970.472656 250.476562 967.984375 250.804688 966.636719 252.546875 C 965.296875 254.289062 965.640625 256.792969 967.382812 258.128906 C 969.125 259.464844 971.628906 259.136719 972.964844 257.390625 Z M 972.964844 257.390625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 967.667969 245.785156 C 968.878906 244.207031 968.582031 241.960938 967 240.753906 C 965.429688 239.550781 963.171875 239.855469 961.964844 241.421875 C 960.753906 242.992188 961.058594 245.25 962.632812 246.457031 C 964.214844 247.664062 966.464844 247.359375 967.667969 245.785156 Z M 967.667969 245.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 968.390625 233.039062 C 969.464844 231.636719 969.207031 229.628906 967.800781 228.550781 C 966.394531 227.480469 964.386719 227.746094 963.308594 229.152344 C 962.234375 230.546875 962.503906 232.5625 963.910156 233.636719 C 965.304688 234.703125 967.320312 234.445312 968.390625 233.039062 Z M 968.390625 233.039062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 974.992188 223.480469 C 975.9375 222.253906 975.703125 220.492188 974.46875 219.546875 C 973.234375 218.597656 971.46875 218.832031 970.53125 220.066406 C 969.585938 221.296875 969.820312 223.058594 971.054688 224.007812 C 972.289062 224.953125 974.054688 224.71875 974.992188 223.480469 Z M 974.992188 223.480469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 985.3125 218.609375 C 986.128906 217.550781 985.929688 216.03125 984.871094 215.214844 C 983.8125 214.40625 982.292969 214.605469 981.476562 215.664062 C 980.667969 216.726562 980.867188 218.242188 981.929688 219.058594 C 982.988281 219.863281 984.507812 219.667969 985.3125 218.609375 Z M 985.3125 218.609375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 994.882812 218.214844 C 995.558594 217.328125 995.394531 216.054688 994.503906 215.378906 C 993.613281 214.691406 992.339844 214.863281 991.664062 215.75 C 990.984375 216.644531 991.148438 217.910156 992.035156 218.597656 C 992.929688 219.273438 994.195312 219.109375 994.882812 218.214844 Z M 994.882812 218.214844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 602.886719 323.019531 C 608.597656 300.160156 643.332031 293.136719 647.957031 309.929688 C 643.3125 305.773438 633.558594 315.578125 641.789062 321.917969 C 651.726562 329.582031 665.757812 313.390625 650.972656 300.601562 C 634.742188 286.554688 602.886719 300.007812 602.886719 323.019531 Z M 602.886719 323.019531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 487.578125 245.492188 C 449.734375 252.394531 435.546875 279.507812 404.910156 294.246094 C 370.941406 310.582031 332.363281 301.433594 319.832031 272.550781 C 312.171875 254.898438 321.519531 233.671875 341.59375 230.019531 C 369.699219 224.902344 380.980469 262.632812 359.375 267.847656 C 346.25 271.015625 344.375 251.261719 354.734375 253.441406 C 346.054688 241.761719 328.292969 258.960938 338.851562 273.402344 C 351.839844 291.175781 391.921875 279.691406 384.441406 245.597656 C 379.824219 224.550781 357.171875 218.199219 342.605469 220.96875 C 305.933594 227.9375 295.8125 284.710938 342.910156 302.472656 C 329.695312 308.75 332.453125 320.460938 336.660156 324.742188 C 345.765625 307.71875 371.597656 320.027344 410.570312 299.671875 C 435.699219 286.550781 442.121094 264.941406 487.578125 245.492188 Z M 487.578125 245.492188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 197.023438 262.101562 C 208.21875 273.679688 229.398438 273.152344 249.355469 260.828125 L 247.960938 259.644531 C 231.960938 269.148438 214.640625 271.398438 202.609375 259.277344 C 188.785156 245.355469 206.886719 222.949219 218.101562 233.296875 C 227.664062 242.125 213.390625 254.066406 208.539062 245.101562 C 207.144531 253.921875 221.816406 256.949219 226.597656 245.621094 C 233.671875 228.875 212.203125 211.65625 195.421875 228.804688 C 185.058594 239.378906 189.945312 254.789062 197.023438 262.101562 Z M 197.023438 262.101562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 392.648438 215.882812 C 386.546875 222.839844 374.375 223.128906 362.574219 216.613281 L 363.34375 215.898438 C 372.792969 220.898438 382.800781 221.707031 389.367188 214.414062 C 396.914062 206.03125 385.894531 193.675781 379.75 199.929688 C 374.503906 205.269531 383.035156 211.726562 385.566406 206.441406 C 386.609375 211.46875 378.277344 213.617188 375.210938 207.246094 C 370.683594 197.832031 382.523438 187.351562 392.640625 196.722656 C 398.882812 202.507812 396.511719 211.480469 392.648438 215.882812 Z M 392.648438 215.882812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 402.601562 219.789062 C 397.25 213.964844 344.296875 201.378906 318.039062 237.605469 L 319.117188 237.605469 C 345.078125 211.492188 383.617188 212.863281 401.609375 221.0625 C 401.609375 221.0625 401.421875 219.476562 402.601562 219.789062 Z M 402.601562 219.789062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 304.070312 236.171875 C 314.882812 225.730469 295.476562 213.476562 286.421875 232.695312 C 280.984375 244.234375 284.390625 255.671875 288.804688 263.910156 C 284.554688 259.636719 279.730469 256.425781 274.003906 254.777344 C 253.648438 248.929688 241.847656 261.859375 239.5 274.078125 C 236.710938 288.597656 250.554688 305.058594 266.738281 298.550781 C 276.890625 294.464844 280.308594 274.914062 264.796875 273.34375 C 269.65625 284.535156 250.96875 289.386719 249.773438 274.773438 C 248.992188 265.195312 261.460938 255.378906 273.957031 260.21875 C 292.527344 267.40625 300.90625 304.246094 335.984375 303.15625 C 283.640625 289.589844 286.480469 236.015625 304.070312 236.171875 Z M 304.070312 236.171875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 593.914062 299.160156 C 588.941406 289.464844 562.972656 255.925781 541.335938 259.429688 C 529.890625 261.277344 531.398438 275.09375 536.109375 275.988281 C 516.441406 285.597656 506.347656 257.75 526.410156 251.152344 C 455.226562 233.292969 440.804688 306.804688 479.863281 317.550781 C 507.421875 325.125 521.039062 287.234375 500.929688 281.429688 C 484.890625 276.804688 484.492188 299.347656 495.003906 298.09375 C 484.140625 308.046875 467.492188 289.242188 479.28125 275.769531 C 493.800781 259.214844 534.398438 272.5 523.925781 305.804688 C 517.472656 326.371094 494.34375 330.691406 480.085938 326.648438 C 466.964844 322.941406 457.972656 314.074219 454.195312 302.1875 C 452.019531 312.066406 446.914062 318.882812 436.960938 317.960938 C 421.519531 316.523438 418.269531 296.054688 434.796875 294.652344 C 432.015625 298.5625 432.746094 306.589844 440.300781 306.242188 C 455.527344 305.558594 439.316406 258.953125 487.578125 245.492188 C 474.976562 238.082031 478.765625 226.652344 483.328125 222.753906 C 490.894531 240.519531 520.855469 227.878906 557.484375 252.183594 C 586.1875 271.226562 593.601562 297.996094 593.914062 299.160156 Z M 593.914062 299.160156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 277.632812 247.175781 C 270.613281 244.09375 260.160156 240.285156 251.71875 248.800781 C 246.605469 238.96875 252.042969 235.332031 255.460938 234.339844 C 252.613281 230.851562 247.964844 222.746094 251.796875 216.507812 C 258.605469 213.792969 265.800781 219.75 268.761719 223.152344 C 270.320312 219.960938 274.828125 215.222656 283.648438 221.941406 C 273.8125 228.804688 275.785156 239.746094 277.632812 247.175781 Z M 277.632812 247.175781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 422.195312 231.859375 C 422.195312 231.859375 422.242188 231.027344 422.289062 229.679688 C 422.609375 230.886719 422.820312 231.625 422.820312 231.625 C 422.820312 231.625 423.039062 230.886719 423.359375 229.679688 C 423.40625 231.027344 423.453125 231.859375 423.453125 231.859375 C 423.453125 231.859375 436.667969 211.976562 429.15625 206.164062 C 427.75 205.078125 426.664062 205.296875 425.839844 206.371094 C 425.296875 204.789062 424.34375 203.8125 422.820312 203.8125 C 421.304688 203.8125 420.347656 204.789062 419.808594 206.371094 C 418.976562 205.296875 417.898438 205.078125 416.492188 206.164062 C 408.980469 211.976562 422.195312 231.859375 422.195312 231.859375 Z M 422.195312 231.859375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 422.195312 242.949219 C 422.195312 242.949219 422.242188 243.777344 422.289062 245.128906 C 422.609375 243.910156 422.820312 243.183594 422.820312 243.183594 C 422.820312 243.183594 423.039062 243.910156 423.359375 245.128906 C 423.40625 243.777344 423.453125 242.949219 423.453125 242.949219 C 423.453125 242.949219 436.667969 262.832031 429.15625 268.648438 C 427.75 269.730469 426.664062 269.515625 425.839844 268.425781 C 425.296875 270.015625 424.34375 271 422.820312 271 C 421.304688 271 420.347656 270.015625 419.808594 268.425781 C 418.976562 269.515625 417.898438 269.730469 416.492188 268.648438 C 408.980469 262.832031 422.195312 242.949219 422.195312 242.949219 Z M 422.195312 242.949219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 418.261719 233.054688 C 417.953125 232.882812 417.515625 232.632812 416.972656 232.320312 C 417.789062 233.175781 418.304688 233.761719 418.425781 233.90625 C 417.894531 233.820312 409.285156 232.003906 402.941406 228.507812 C 398.910156 226.277344 395.785156 223.378906 396.25 219.78125 C 396.300781 219.3125 396.40625 218.910156 396.585938 218.597656 C 396.992188 217.75 397.753906 217.460938 398.734375 217.601562 C 397.996094 216.09375 397.984375 214.730469 399.054688 213.640625 C 400.128906 212.578125 401.503906 212.582031 403 213.332031 C 402.886719 212.464844 403.09375 211.785156 403.730469 211.335938 C 404.082031 211.09375 404.574219 210.917969 405.195312 210.839844 C 414.625 209.644531 419.347656 233.039062 419.347656 233.039062 C 419.347656 233.039062 418.777344 232.535156 417.867188 231.6875 C 417.828125 231.644531 417.789062 231.605469 417.730469 231.566406 C 417.800781 231.6875 417.867188 231.789062 417.9375 231.902344 C 418.453125 232.789062 418.738281 233.316406 418.738281 233.316406 C 418.738281 233.316406 418.574219 233.222656 418.261719 233.054688 Z M 418.261719 233.054688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 426.304688 241.769531 C 426.304688 241.769531 426.921875 242.324219 427.914062 243.246094 C 427.28125 242.160156 426.914062 241.492188 426.914062 241.492188 C 426.914062 241.492188 427.585938 241.855469 428.667969 242.492188 C 427.75 241.5 427.195312 240.882812 427.195312 240.882812 C 427.195312 240.882812 450.605469 245.601562 449.40625 255.023438 C 449.179688 256.785156 448.261719 257.390625 446.910156 257.210938 C 447.652344 258.71875 447.671875 260.082031 446.59375 261.15625 C 445.515625 262.234375 444.148438 262.214844 442.644531 261.476562 C 442.824219 262.824219 442.214844 263.742188 440.453125 263.964844 C 431.023438 265.167969 426.304688 241.769531 426.304688 241.769531 Z M 426.304688 241.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 417.273438 238.03125 C 417.273438 238.03125 416.445312 237.984375 415.09375 237.941406 C 416.304688 237.613281 417.042969 237.402344 417.042969 237.402344 C 417.042969 237.402344 416.304688 237.1875 415.09375 236.867188 C 416.445312 236.820312 417.273438 236.769531 417.273438 236.769531 C 417.273438 236.769531 397.378906 223.570312 391.566406 231.078125 C 390.472656 232.484375 390.699219 233.5625 391.773438 234.386719 C 390.1875 234.933594 389.210938 235.875 389.210938 237.402344 C 389.210938 238.921875 390.1875 239.878906 391.773438 240.417969 C 390.699219 241.25 390.472656 242.324219 391.566406 243.734375 C 397.378906 251.238281 417.273438 238.03125 417.273438 238.03125 Z M 417.273438 238.03125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 428.371094 238.03125 C 428.371094 238.03125 429.203125 237.984375 430.554688 237.941406 C 429.34375 237.613281 428.605469 237.402344 428.605469 237.402344 C 428.605469 237.402344 429.34375 237.1875 430.554688 236.867188 C 429.203125 236.820312 428.371094 236.769531 428.371094 236.769531 C 428.371094 236.769531 448.269531 223.570312 454.082031 231.078125 C 455.171875 232.484375 454.949219 233.5625 453.871094 234.386719 C 455.460938 234.933594 456.4375 235.875 456.4375 237.402344 C 456.4375 238.921875 455.460938 239.878906 453.871094 240.417969 C 454.949219 241.25 455.171875 242.324219 454.082031 243.734375 C 448.269531 251.238281 428.371094 238.03125 428.371094 238.03125 Z M 428.371094 238.03125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 419.34375 241.769531 C 419.34375 241.769531 418.726562 242.324219 417.734375 243.246094 C 418.363281 242.160156 418.734375 241.492188 418.734375 241.492188 C 418.734375 241.492188 418.0625 241.855469 416.976562 242.492188 C 417.898438 241.5 418.453125 240.882812 418.453125 240.882812 C 418.453125 240.882812 395.039062 245.601562 396.242188 255.023438 C 396.46875 256.785156 397.386719 257.390625 398.734375 257.210938 C 397.996094 258.71875 397.976562 260.082031 399.054688 261.15625 C 400.132812 262.234375 401.5 262.214844 403.003906 261.476562 C 402.824219 262.824219 403.429688 263.742188 405.195312 263.964844 C 414.617188 265.167969 419.34375 241.769531 419.34375 241.769531 Z M 419.34375 241.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 427.195312 233.925781 C 427.195312 233.925781 427.75 233.308594 428.667969 232.320312 C 427.585938 232.953125 426.914062 233.316406 426.914062 233.316406 C 426.914062 233.316406 427.28125 232.648438 427.914062 231.566406 C 426.921875 232.484375 426.304688 233.039062 426.304688 233.039062 C 426.304688 233.039062 431.023438 209.636719 440.453125 210.839844 C 442.214844 211.066406 442.824219 211.988281 442.644531 213.332031 C 444.148438 212.59375 445.515625 212.578125 446.59375 213.652344 C 447.671875 214.730469 447.652344 216.09375 446.910156 217.589844 C 448.261719 217.417969 449.179688 218.027344 449.40625 219.789062 C 450.605469 229.203125 427.195312 233.925781 427.195312 233.925781 Z M 427.195312 233.925781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 425.582031 237.402344 C 425.582031 238.921875 424.34375 240.160156 422.820312 240.160156 C 421.304688 240.160156 420.066406 238.921875 420.066406 237.402344 C 420.066406 235.875 421.304688 234.644531 422.820312 234.644531 C 424.34375 234.644531 425.582031 235.875 425.582031 237.402344 Z M 425.582031 237.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 535.246094 314.371094 C 532.636719 314.285156 530.441406 316.324219 530.347656 318.933594 C 530.253906 321.546875 532.296875 323.738281 534.910156 323.832031 C 537.523438 323.925781 539.71875 321.886719 539.8125 319.273438 C 539.902344 316.664062 537.863281 314.46875 535.246094 314.371094 Z M 535.246094 314.371094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 534.660156 311.527344 C 537.042969 311.609375 539.039062 309.746094 539.125 307.371094 C 539.207031 304.992188 537.347656 302.992188 534.96875 302.910156 C 532.589844 302.832031 530.59375 304.6875 530.507812 307.066406 C 530.421875 309.441406 532.28125 311.4375 534.660156 311.527344 Z M 534.660156 311.527344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 541.585938 299.582031 C 543.730469 299.652344 545.53125 297.980469 545.609375 295.835938 C 545.6875 293.691406 544.007812 291.890625 541.867188 291.820312 C 539.714844 291.738281 537.914062 293.410156 537.839844 295.558594 C 537.757812 297.699219 539.4375 299.507812 541.585938 299.582031 Z M 541.585938 299.582031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 553.292969 292.226562 C 555.207031 292.296875 556.808594 290.800781 556.878906 288.894531 C 556.945312 286.972656 555.453125 285.371094 553.542969 285.308594 C 551.628906 285.238281 550.023438 286.730469 549.953125 288.640625 C 549.886719 290.546875 551.382812 292.15625 553.292969 292.226562 Z M 553.292969 292.226562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 565.503906 290.371094 C 567.183594 290.425781 568.59375 289.121094 568.65625 287.441406 C 568.710938 285.769531 567.398438 284.355469 565.71875 284.292969 C 564.042969 284.242188 562.632812 285.550781 562.574219 287.226562 C 562.515625 288.902344 563.828125 290.316406 565.503906 290.371094 Z M 565.503906 290.371094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 577.703125 292.414062 C 579.148438 292.46875 580.363281 291.339844 580.414062 289.894531 C 580.46875 288.449219 579.335938 287.234375 577.890625 287.183594 C 576.445312 287.132812 575.230469 288.257812 575.179688 289.710938 C 575.128906 291.148438 576.257812 292.367188 577.703125 292.414062 Z M 577.703125 292.414062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 585.910156 298.117188 C 587.117188 298.164062 588.136719 297.214844 588.183594 296 C 588.226562 294.785156 587.277344 293.769531 586.0625 293.726562 C 584.855469 293.683594 583.835938 294.628906 583.796875 295.84375 C 583.75 297.050781 584.695312 298.074219 585.910156 298.117188 Z M 585.910156 298.117188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 223.070312 257.445312 C 224.535156 259.359375 224.171875 262.101562 222.253906 263.566406 C 220.335938 265.035156 217.59375 264.675781 216.121094 262.753906 C 214.65625 260.835938 215.015625 258.09375 216.9375 256.628906 C 218.855469 255.160156 221.601562 255.523438 223.070312 257.445312 Z M 223.070312 257.445312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 227.035156 257.390625 C 225.699219 255.644531 226.03125 253.148438 227.773438 251.8125 C 229.523438 250.476562 232.023438 250.804688 233.359375 252.546875 C 234.699219 254.289062 234.367188 256.792969 232.617188 258.128906 C 230.875 259.464844 228.371094 259.136719 227.035156 257.390625 Z M 227.035156 257.390625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 232.332031 245.785156 C 231.121094 244.207031 231.421875 241.960938 232.996094 240.753906 C 234.570312 239.550781 236.828125 239.855469 238.035156 241.421875 C 239.242188 242.992188 238.941406 245.25 237.367188 246.457031 C 235.789062 247.664062 233.535156 247.359375 232.332031 245.785156 Z M 232.332031 245.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 231.605469 233.039062 C 230.53125 231.636719 230.796875 229.628906 232.199219 228.550781 C 233.605469 227.480469 235.613281 227.746094 236.691406 229.152344 C 237.765625 230.546875 237.496094 232.5625 236.09375 233.636719 C 234.6875 234.703125 232.683594 234.445312 231.605469 233.039062 Z M 231.605469 233.039062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 225.007812 223.480469 C 224.0625 222.253906 224.296875 220.492188 225.53125 219.546875 C 226.761719 218.597656 228.523438 218.832031 229.46875 220.066406 C 230.414062 221.296875 230.179688 223.058594 228.945312 224.007812 C 227.714844 224.953125 225.953125 224.71875 225.007812 223.480469 Z M 225.007812 223.480469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 214.683594 218.609375 C 213.867188 217.550781 214.074219 216.03125 215.132812 215.214844 C 216.191406 214.40625 217.710938 214.605469 218.523438 215.664062 C 219.332031 216.726562 219.136719 218.242188 218.070312 219.058594 C 217.011719 219.863281 215.492188 219.667969 214.683594 218.609375 Z M 214.683594 218.609375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 205.117188 218.214844 C 204.4375 217.328125 204.605469 216.054688 205.496094 215.378906 C 206.386719 214.691406 207.65625 214.863281 208.335938 215.75 C 209.019531 216.644531 208.847656 217.910156 207.960938 218.597656 C 207.070312 219.273438 205.800781 219.109375 205.117188 218.214844 Z M 205.117188 218.214844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 597.113281 323.019531 C 591.402344 300.160156 556.667969 293.136719 552.042969 309.929688 C 556.6875 305.773438 566.441406 315.578125 558.210938 321.917969 C 548.273438 329.582031 534.242188 313.390625 549.027344 300.601562 C 565.257812 286.554688 597.113281 300.007812 597.113281 323.019531 Z M 597.113281 323.019531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 212.316406 274.644531 L 223.679688 278.285156 L 234.839844 275.328125 L 231.886719 286.492188 L 235.53125 297.847656 L 224.164062 294.203125 L 213.003906 297.15625 L 215.957031 286.003906 Z M 212.316406 274.644531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 938.621094 316.929688 C 916.355469 333.511719 912.792969 306.507812 876.078125 307.414062 C 907.527344 295.242188 930.304688 299.019531 938.621094 316.929688 Z M 938.621094 316.929688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 987.683594 274.644531 L 976.316406 278.285156 L 965.160156 275.328125 L 968.113281 286.492188 L 964.46875 297.847656 L 975.835938 294.203125 L 986.992188 297.15625 L 984.039062 286.003906 Z M 987.683594 274.644531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 261.378906 1007.242188 C 283.644531 990.660156 287.207031 1017.664062 323.921875 1016.757812 C 292.472656 1028.929688 269.695312 1025.152344 261.378906 1007.242188 Z M 261.378906 1007.242188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 628.6875 1075.34375 C 637.757812 1064.425781 659.816406 1084.058594 641.972656 1099.683594 C 628.054688 1111.867188 601.265625 1095.84375 601.898438 1072.542969 C 619.304688 1106.488281 646.410156 1082.246094 628.6875 1075.34375 Z M 628.6875 1075.34375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 571.3125 1075.34375 C 562.242188 1064.425781 540.183594 1084.058594 558.027344 1099.683594 C 571.945312 1111.867188 598.734375 1095.84375 598.101562 1072.542969 C 580.695312 1106.488281 553.589844 1082.246094 571.3125 1075.34375 Z M 571.3125 1075.34375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 583.4375 1112.070312 C 599.898438 1099.738281 600 1077.289062 600 1077.289062 C 600 1077.289062 600.101562 1099.738281 616.5625 1112.070312 C 619.515625 1124.933594 605.804688 1119.667969 600 1136.789062 C 594.195312 1119.667969 580.484375 1124.933594 583.4375 1112.070312 Z M 583.4375 1112.070312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 600 1062.519531 C 600.285156 1067.074219 617.1875 1075.015625 627.800781 1066.808594 C 604.898438 1061.132812 600.851562 1033.511719 600 1027.902344 C 599.148438 1033.511719 595.101562 1061.132812 572.199219 1066.808594 C 582.8125 1075.015625 599.714844 1067.074219 600 1062.519531 Z M 600 1062.519531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 712.421875 1078.679688 C 750.265625 1071.777344 764.453125 1044.664062 795.089844 1029.925781 C 829.058594 1013.589844 867.636719 1022.738281 880.167969 1051.621094 C 887.828125 1069.273438 878.480469 1090.5 858.40625 1094.152344 C 830.300781 1099.269531 819.019531 1061.539062 840.625 1056.324219 C 853.75 1053.15625 855.625 1072.910156 845.265625 1070.730469 C 853.945312 1082.410156 871.707031 1065.210938 861.148438 1050.769531 C 848.160156 1032.996094 808.078125 1044.480469 815.558594 1078.574219 C 820.175781 1099.621094 842.828125 1105.972656 857.394531 1103.203125 C 894.066406 1096.234375 904.1875 1039.460938 857.089844 1021.699219 C 870.304688 1015.421875 867.546875 1003.710938 863.339844 999.429688 C 854.234375 1016.453125 828.402344 1004.144531 789.429688 1024.5 C 764.300781 1037.617188 757.878906 1059.230469 712.421875 1078.679688 Z M 712.421875 1078.679688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1002.980469 1062.070312 C 991.785156 1050.492188 970.601562 1051.019531 950.640625 1063.34375 L 952.046875 1064.527344 C 968.042969 1055.023438 985.359375 1052.773438 997.390625 1064.894531 C 1011.214844 1078.816406 993.113281 1101.222656 981.898438 1090.875 C 972.34375 1082.046875 986.609375 1070.105469 991.460938 1079.070312 C 992.855469 1070.25 978.183594 1067.222656 973.402344 1078.550781 C 966.324219 1095.296875 987.796875 1112.507812 1004.582031 1095.367188 C 1014.941406 1084.792969 1010.054688 1069.382812 1002.980469 1062.070312 Z M 1002.980469 1062.070312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 807.351562 1108.289062 C 813.453125 1101.332031 825.625 1101.046875 837.425781 1107.558594 L 836.65625 1108.273438 C 827.207031 1103.273438 817.199219 1102.464844 810.632812 1109.757812 C 803.089844 1118.140625 814.105469 1130.496094 820.25 1124.242188 C 825.496094 1118.902344 816.964844 1112.445312 814.433594 1117.730469 C 813.390625 1112.703125 821.722656 1110.554688 824.789062 1116.925781 C 829.316406 1126.339844 817.476562 1136.820312 807.359375 1127.449219 C 801.117188 1121.664062 803.492188 1112.691406 807.351562 1108.289062 Z M 807.351562 1108.289062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 797.398438 1104.382812 C 802.75 1110.207031 855.703125 1122.789062 881.960938 1086.566406 L 880.882812 1086.566406 C 854.921875 1112.679688 816.382812 1111.308594 798.390625 1103.109375 C 798.390625 1103.109375 798.578125 1104.695312 797.398438 1104.382812 Z M 797.398438 1104.382812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 895.929688 1088 C 885.117188 1098.441406 904.523438 1110.695312 913.578125 1091.476562 C 919.015625 1079.9375 915.609375 1068.5 911.195312 1060.265625 C 915.445312 1064.535156 920.269531 1067.746094 925.996094 1069.382812 C 946.351562 1075.242188 958.152344 1062.3125 960.507812 1050.09375 C 963.285156 1035.574219 949.445312 1019.113281 933.261719 1025.621094 C 923.109375 1029.707031 919.691406 1049.257812 935.203125 1050.828125 C 930.34375 1039.636719 949.035156 1034.785156 950.222656 1049.398438 C 951.015625 1058.976562 938.539062 1068.792969 926.042969 1063.953125 C 907.472656 1056.765625 899.09375 1019.925781 864.015625 1021.015625 C 916.359375 1034.582031 913.519531 1088.15625 895.929688 1088 Z M 895.929688 1088 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 606.085938 1025.011719 C 611.058594 1034.707031 637.027344 1068.246094 658.664062 1064.742188 C 670.109375 1062.894531 668.601562 1049.078125 663.890625 1048.183594 C 683.558594 1038.574219 693.652344 1066.421875 673.589844 1073.019531 C 744.773438 1090.882812 759.195312 1017.367188 720.136719 1006.621094 C 692.578125 999.046875 678.960938 1036.9375 699.070312 1042.742188 C 715.109375 1047.367188 715.507812 1024.824219 704.996094 1026.078125 C 715.859375 1016.125 732.507812 1034.929688 720.71875 1048.402344 C 706.199219 1064.957031 665.601562 1051.671875 676.074219 1018.367188 C 682.527344 997.800781 705.65625 993.480469 719.914062 997.523438 C 733.035156 1001.230469 742.027344 1010.097656 745.804688 1021.984375 C 747.976562 1012.105469 753.085938 1005.289062 763.039062 1006.210938 C 778.480469 1007.648438 781.730469 1028.117188 765.195312 1029.519531 C 767.984375 1025.609375 767.253906 1017.582031 759.699219 1017.929688 C 744.472656 1018.613281 760.679688 1065.21875 712.421875 1078.679688 C 725.023438 1086.089844 721.234375 1097.519531 716.671875 1101.417969 C 709.105469 1083.652344 679.144531 1096.292969 642.515625 1071.988281 C 613.8125 1052.945312 606.398438 1026.1875 606.085938 1025.011719 Z M 606.085938 1025.011719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 922.367188 1076.996094 C 929.386719 1080.078125 939.839844 1083.886719 948.277344 1075.371094 C 953.390625 1085.203125 947.957031 1088.839844 944.539062 1089.832031 C 947.386719 1093.320312 952.039062 1101.425781 948.203125 1107.664062 C 941.394531 1110.378906 934.199219 1104.421875 931.238281 1101.019531 C 929.679688 1104.210938 925.171875 1108.949219 916.351562 1102.230469 C 926.1875 1095.367188 924.214844 1084.425781 922.367188 1076.996094 Z M 922.367188 1076.996094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 777.804688 1092.3125 C 777.804688 1092.3125 777.757812 1093.144531 777.710938 1094.492188 C 777.390625 1093.277344 777.179688 1092.546875 777.179688 1092.546875 C 777.179688 1092.546875 776.960938 1093.277344 776.640625 1094.492188 C 776.59375 1093.144531 776.546875 1092.3125 776.546875 1092.3125 C 776.546875 1092.3125 763.332031 1112.195312 770.84375 1118.007812 C 772.25 1119.09375 773.335938 1118.875 774.160156 1117.800781 C 774.703125 1119.382812 775.65625 1120.359375 777.179688 1120.359375 C 778.695312 1120.359375 779.652344 1119.382812 780.191406 1117.800781 C 781.023438 1118.875 782.101562 1119.09375 783.507812 1118.007812 C 791.019531 1112.195312 777.804688 1092.3125 777.804688 1092.3125 Z M 777.804688 1092.3125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 777.804688 1081.222656 C 777.804688 1081.222656 777.757812 1080.390625 777.710938 1079.042969 C 777.390625 1080.261719 777.179688 1080.988281 777.179688 1080.988281 C 777.179688 1080.988281 776.960938 1080.261719 776.640625 1079.042969 C 776.59375 1080.390625 776.546875 1081.222656 776.546875 1081.222656 C 776.546875 1081.222656 763.332031 1061.339844 770.84375 1055.523438 C 772.25 1054.441406 773.335938 1054.65625 774.160156 1055.734375 C 774.703125 1054.15625 775.65625 1053.171875 777.179688 1053.171875 C 778.695312 1053.171875 779.652344 1054.15625 780.191406 1055.734375 C 781.023438 1054.65625 782.101562 1054.441406 783.507812 1055.523438 C 791.019531 1061.339844 777.804688 1081.222656 777.804688 1081.222656 Z M 777.804688 1081.222656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 781.738281 1091.117188 C 782.046875 1091.289062 782.484375 1091.535156 783.027344 1091.84375 C 782.210938 1090.996094 781.695312 1090.410156 781.574219 1090.253906 C 782.105469 1090.351562 790.714844 1092.15625 797.058594 1095.664062 C 801.089844 1097.882812 804.214844 1100.792969 803.75 1104.390625 C 803.699219 1104.859375 803.59375 1105.261719 803.414062 1105.574219 C 803.007812 1106.421875 802.246094 1106.710938 801.265625 1106.570312 C 802.003906 1108.078125 802.015625 1109.433594 800.945312 1110.53125 C 799.871094 1111.59375 798.496094 1111.589844 797 1110.839844 C 797.113281 1111.707031 796.90625 1112.386719 796.269531 1112.835938 C 795.917969 1113.078125 795.425781 1113.253906 794.804688 1113.332031 C 785.375 1114.527344 780.652344 1091.132812 780.652344 1091.132812 C 780.652344 1091.132812 781.222656 1091.628906 782.132812 1092.484375 C 782.171875 1092.527344 782.210938 1092.566406 782.269531 1092.605469 C 782.199219 1092.484375 782.132812 1092.371094 782.0625 1092.269531 C 781.546875 1091.382812 781.261719 1090.855469 781.261719 1090.855469 C 781.261719 1090.855469 781.425781 1090.949219 781.738281 1091.117188 Z M 781.738281 1091.117188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 773.695312 1082.402344 C 773.695312 1082.402344 773.078125 1081.847656 772.085938 1080.925781 C 772.71875 1082.011719 773.085938 1082.679688 773.085938 1082.679688 C 773.085938 1082.679688 772.414062 1082.316406 771.332031 1081.679688 C 772.25 1082.671875 772.804688 1083.289062 772.804688 1083.289062 C 772.804688 1083.289062 749.394531 1078.570312 750.59375 1069.148438 C 750.820312 1067.386719 751.738281 1066.78125 753.089844 1066.957031 C 752.347656 1065.453125 752.328125 1064.089844 753.40625 1063.015625 C 754.484375 1061.9375 755.851562 1061.957031 757.355469 1062.695312 C 757.175781 1061.347656 757.785156 1060.429688 759.546875 1060.207031 C 768.976562 1059.003906 773.695312 1082.402344 773.695312 1082.402344 Z M 773.695312 1082.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 782.726562 1086.140625 C 782.726562 1086.140625 783.554688 1086.1875 784.90625 1086.238281 C 783.695312 1086.558594 782.957031 1086.769531 782.957031 1086.769531 C 782.957031 1086.769531 783.695312 1086.984375 784.90625 1087.304688 C 783.554688 1087.351562 782.726562 1087.402344 782.726562 1087.402344 C 782.726562 1087.402344 802.621094 1100.601562 808.433594 1093.09375 C 809.527344 1091.6875 809.300781 1090.609375 808.226562 1089.785156 C 809.8125 1089.242188 810.789062 1088.285156 810.789062 1086.769531 C 810.789062 1085.25 809.8125 1084.292969 808.226562 1083.753906 C 809.300781 1082.921875 809.527344 1081.847656 808.433594 1080.4375 C 802.621094 1072.933594 782.726562 1086.140625 782.726562 1086.140625 Z M 782.726562 1086.140625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 771.628906 1086.140625 C 771.628906 1086.140625 770.796875 1086.1875 769.445312 1086.238281 C 770.65625 1086.558594 771.394531 1086.769531 771.394531 1086.769531 C 771.394531 1086.769531 770.65625 1086.984375 769.445312 1087.304688 C 770.796875 1087.351562 771.628906 1087.402344 771.628906 1087.402344 C 771.628906 1087.402344 751.730469 1100.601562 745.917969 1093.09375 C 744.828125 1091.6875 745.050781 1090.609375 746.128906 1089.785156 C 744.539062 1089.242188 743.5625 1088.285156 743.5625 1086.769531 C 743.5625 1085.25 744.539062 1084.292969 746.128906 1083.753906 C 745.050781 1082.921875 744.828125 1081.847656 745.917969 1080.4375 C 751.730469 1072.933594 771.628906 1086.140625 771.628906 1086.140625 Z M 771.628906 1086.140625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 780.65625 1082.402344 C 780.65625 1082.402344 781.273438 1081.847656 782.265625 1080.925781 C 781.636719 1082.011719 781.265625 1082.679688 781.265625 1082.679688 C 781.265625 1082.679688 781.9375 1082.316406 783.023438 1081.679688 C 782.101562 1082.671875 781.546875 1083.289062 781.546875 1083.289062 C 781.546875 1083.289062 804.960938 1078.570312 803.757812 1069.148438 C 803.53125 1067.386719 802.613281 1066.78125 801.265625 1066.957031 C 802.003906 1065.453125 802.023438 1064.089844 800.945312 1063.015625 C 799.867188 1061.9375 798.5 1061.957031 796.996094 1062.695312 C 797.175781 1061.347656 796.570312 1060.429688 794.804688 1060.207031 C 785.382812 1059.003906 780.65625 1082.402344 780.65625 1082.402344 Z M 780.65625 1082.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 772.804688 1090.246094 C 772.804688 1090.246094 772.25 1090.863281 771.332031 1091.851562 C 772.414062 1091.21875 773.085938 1090.855469 773.085938 1090.855469 C 773.085938 1090.855469 772.71875 1091.523438 772.085938 1092.605469 C 773.078125 1091.6875 773.695312 1091.132812 773.695312 1091.132812 C 773.695312 1091.132812 768.976562 1114.535156 759.546875 1113.332031 C 757.785156 1113.105469 757.175781 1112.183594 757.355469 1110.839844 C 755.851562 1111.578125 754.484375 1111.59375 753.40625 1110.519531 C 752.328125 1109.441406 752.347656 1108.078125 753.089844 1106.582031 C 751.738281 1106.753906 750.820312 1106.144531 750.59375 1104.382812 C 749.394531 1094.96875 772.804688 1090.246094 772.804688 1090.246094 Z M 772.804688 1090.246094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 774.417969 1086.769531 C 774.417969 1085.25 775.65625 1084.011719 777.179688 1084.011719 C 778.695312 1084.011719 779.933594 1085.25 779.933594 1086.769531 C 779.933594 1088.285156 778.695312 1089.527344 777.179688 1089.527344 C 775.65625 1089.527344 774.417969 1088.285156 774.417969 1086.769531 Z M 774.417969 1086.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 664.753906 1009.800781 C 667.363281 1009.886719 669.558594 1007.847656 669.652344 1005.238281 C 669.746094 1002.625 667.703125 1000.433594 665.089844 1000.339844 C 662.476562 1000.246094 660.28125 1002.285156 660.191406 1004.898438 C 660.097656 1007.507812 662.136719 1009.703125 664.753906 1009.800781 Z M 664.753906 1009.800781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 665.339844 1012.644531 C 662.957031 1012.5625 660.960938 1014.425781 660.875 1016.800781 C 660.792969 1019.179688 662.652344 1021.179688 665.03125 1021.261719 C 667.410156 1021.339844 669.40625 1019.484375 669.492188 1017.105469 C 669.578125 1014.730469 667.71875 1012.734375 665.339844 1012.644531 Z M 665.339844 1012.644531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 658.414062 1024.589844 C 656.269531 1024.519531 654.46875 1026.191406 654.390625 1028.335938 C 654.3125 1030.480469 655.992188 1032.277344 658.132812 1032.351562 C 660.285156 1032.433594 662.085938 1030.761719 662.160156 1028.613281 C 662.242188 1026.472656 660.5625 1024.664062 658.414062 1024.589844 Z M 658.414062 1024.589844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 646.707031 1031.945312 C 644.792969 1031.875 643.191406 1033.371094 643.121094 1035.277344 C 643.054688 1037.199219 644.546875 1038.796875 646.457031 1038.863281 C 648.371094 1038.933594 649.976562 1037.441406 650.046875 1035.53125 C 650.113281 1033.625 648.617188 1032.015625 646.707031 1031.945312 Z M 646.707031 1031.945312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 634.496094 1033.796875 C 632.816406 1033.746094 631.40625 1035.050781 631.34375 1036.730469 C 631.289062 1038.402344 632.601562 1039.816406 634.28125 1039.878906 C 635.957031 1039.941406 637.367188 1038.621094 637.425781 1036.945312 C 637.484375 1035.269531 636.171875 1033.855469 634.496094 1033.796875 Z M 634.496094 1033.796875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 622.296875 1031.757812 C 620.851562 1031.703125 619.636719 1032.832031 619.585938 1034.277344 C 619.53125 1035.722656 620.664062 1036.9375 622.109375 1036.988281 C 623.554688 1037.039062 624.769531 1035.914062 624.820312 1034.460938 C 624.871094 1033.023438 623.742188 1031.804688 622.296875 1031.757812 Z M 622.296875 1031.757812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 614.089844 1026.054688 C 612.882812 1026.007812 611.863281 1026.964844 611.816406 1028.171875 C 611.773438 1029.386719 612.722656 1030.402344 613.9375 1030.445312 C 615.144531 1030.488281 616.164062 1029.542969 616.203125 1028.328125 C 616.25 1027.121094 615.304688 1026.097656 614.089844 1026.054688 Z M 614.089844 1026.054688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 976.9375 1066.726562 C 975.46875 1064.8125 975.820312 1062.070312 977.742188 1060.605469 C 979.660156 1059.136719 982.40625 1059.496094 983.875 1061.417969 C 985.351562 1063.335938 984.984375 1066.078125 983.066406 1067.542969 C 981.144531 1069.011719 978.402344 1068.648438 976.9375 1066.726562 Z M 976.9375 1066.726562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 972.964844 1066.78125 C 974.300781 1068.527344 973.972656 1071.023438 972.230469 1072.359375 C 970.472656 1073.695312 967.984375 1073.367188 966.636719 1071.625 C 965.296875 1069.882812 965.640625 1067.378906 967.382812 1066.042969 C 969.125 1064.707031 971.628906 1065.035156 972.964844 1066.78125 Z M 972.964844 1066.78125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 967.667969 1078.386719 C 968.878906 1079.964844 968.582031 1082.210938 967 1083.417969 C 965.429688 1084.621094 963.171875 1084.328125 961.964844 1082.75 C 960.753906 1081.179688 961.058594 1078.925781 962.632812 1077.714844 C 964.214844 1076.507812 966.464844 1076.8125 967.667969 1078.386719 Z M 967.667969 1078.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 968.390625 1091.132812 C 969.464844 1092.535156 969.207031 1094.542969 967.800781 1095.621094 C 966.394531 1096.691406 964.386719 1096.425781 963.308594 1095.019531 C 962.234375 1093.625 962.503906 1091.609375 963.910156 1090.535156 C 965.304688 1089.46875 967.320312 1089.726562 968.390625 1091.132812 Z M 968.390625 1091.132812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 974.992188 1100.691406 C 975.9375 1101.917969 975.703125 1103.679688 974.46875 1104.625 C 973.234375 1105.574219 971.46875 1105.339844 970.53125 1104.105469 C 969.585938 1102.875 969.820312 1101.113281 971.054688 1100.164062 C 972.289062 1099.21875 974.054688 1099.453125 974.992188 1100.691406 Z M 974.992188 1100.691406 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 985.3125 1105.5625 C 986.128906 1106.621094 985.929688 1108.140625 984.871094 1108.957031 C 983.8125 1109.765625 982.292969 1109.566406 981.476562 1108.507812 C 980.667969 1107.445312 980.867188 1105.929688 981.929688 1105.113281 C 982.988281 1104.308594 984.507812 1104.503906 985.3125 1105.5625 Z M 985.3125 1105.5625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 994.882812 1105.957031 C 995.558594 1106.84375 995.394531 1108.117188 994.503906 1108.792969 C 993.613281 1109.472656 992.339844 1109.308594 991.664062 1108.421875 C 990.984375 1107.527344 991.148438 1106.257812 992.035156 1105.574219 C 992.929688 1104.898438 994.195312 1105.0625 994.882812 1105.957031 Z M 994.882812 1105.957031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 602.886719 1001.152344 C 608.597656 1024.011719 643.332031 1031.035156 647.957031 1014.242188 C 643.3125 1018.398438 633.558594 1008.59375 641.789062 1002.253906 C 651.726562 994.589844 665.757812 1010.78125 650.972656 1023.570312 C 634.742188 1037.617188 602.886719 1024.152344 602.886719 1001.152344 Z M 602.886719 1001.152344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 487.578125 1078.679688 C 449.734375 1071.777344 435.546875 1044.664062 404.910156 1029.925781 C 370.941406 1013.589844 332.363281 1022.738281 319.832031 1051.621094 C 312.171875 1069.273438 321.519531 1090.5 341.59375 1094.152344 C 369.699219 1099.269531 380.980469 1061.539062 359.375 1056.324219 C 346.25 1053.15625 344.375 1072.910156 354.734375 1070.730469 C 346.054688 1082.410156 328.292969 1065.210938 338.851562 1050.769531 C 351.839844 1032.996094 391.921875 1044.480469 384.441406 1078.574219 C 379.824219 1099.621094 357.171875 1105.972656 342.605469 1103.203125 C 305.933594 1096.234375 295.8125 1039.460938 342.910156 1021.699219 C 329.695312 1015.421875 332.453125 1003.710938 336.660156 999.429688 C 345.765625 1016.453125 371.597656 1004.144531 410.570312 1024.5 C 435.699219 1037.617188 442.121094 1059.230469 487.578125 1078.679688 Z M 487.578125 1078.679688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 197.023438 1062.070312 C 208.21875 1050.492188 229.398438 1051.019531 249.355469 1063.34375 L 247.960938 1064.527344 C 231.960938 1055.023438 214.640625 1052.773438 202.609375 1064.894531 C 188.785156 1078.816406 206.886719 1101.222656 218.101562 1090.875 C 227.664062 1082.046875 213.390625 1070.105469 208.539062 1079.070312 C 207.144531 1070.25 221.816406 1067.222656 226.597656 1078.550781 C 233.671875 1095.296875 212.203125 1112.507812 195.421875 1095.367188 C 185.058594 1084.792969 189.945312 1069.382812 197.023438 1062.070312 Z M 197.023438 1062.070312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 392.648438 1108.289062 C 386.546875 1101.332031 374.375 1101.046875 362.574219 1107.558594 L 363.34375 1108.273438 C 372.792969 1103.273438 382.800781 1102.464844 389.367188 1109.757812 C 396.914062 1118.140625 385.894531 1130.496094 379.75 1124.242188 C 374.503906 1118.902344 383.035156 1112.445312 385.566406 1117.730469 C 386.609375 1112.703125 378.277344 1110.554688 375.210938 1116.925781 C 370.683594 1126.339844 382.523438 1136.820312 392.640625 1127.449219 C 398.882812 1121.664062 396.511719 1112.691406 392.648438 1108.289062 Z M 392.648438 1108.289062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 402.601562 1104.382812 C 397.25 1110.207031 344.296875 1122.789062 318.039062 1086.566406 L 319.117188 1086.566406 C 345.078125 1112.679688 383.617188 1111.308594 401.609375 1103.109375 C 401.609375 1103.109375 401.421875 1104.695312 402.601562 1104.382812 Z M 402.601562 1104.382812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 304.070312 1088 C 314.882812 1098.441406 295.476562 1110.695312 286.421875 1091.476562 C 280.984375 1079.9375 284.390625 1068.5 288.804688 1060.265625 C 284.554688 1064.535156 279.730469 1067.746094 274.003906 1069.382812 C 253.648438 1075.242188 241.847656 1062.3125 239.5 1050.09375 C 236.710938 1035.574219 250.554688 1019.113281 266.738281 1025.621094 C 276.890625 1029.707031 280.308594 1049.257812 264.796875 1050.828125 C 269.65625 1039.636719 250.96875 1034.785156 249.773438 1049.398438 C 248.992188 1058.976562 261.460938 1068.792969 273.957031 1063.953125 C 292.527344 1056.765625 300.90625 1019.925781 335.984375 1021.015625 C 283.640625 1034.582031 286.480469 1088.15625 304.070312 1088 Z M 304.070312 1088 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 593.914062 1025.011719 C 588.941406 1034.707031 562.972656 1068.246094 541.335938 1064.742188 C 529.890625 1062.894531 531.398438 1049.078125 536.109375 1048.183594 C 516.441406 1038.574219 506.347656 1066.421875 526.410156 1073.019531 C 455.226562 1090.882812 440.804688 1017.367188 479.863281 1006.621094 C 507.421875 999.046875 521.039062 1036.9375 500.929688 1042.742188 C 484.890625 1047.367188 484.492188 1024.824219 495.003906 1026.078125 C 484.140625 1016.125 467.492188 1034.929688 479.28125 1048.402344 C 493.800781 1064.957031 534.398438 1051.671875 523.925781 1018.367188 C 517.472656 997.800781 494.34375 993.480469 480.085938 997.523438 C 466.964844 1001.230469 457.972656 1010.097656 454.195312 1021.984375 C 452.019531 1012.105469 446.914062 1005.289062 436.960938 1006.210938 C 421.519531 1007.648438 418.269531 1028.117188 434.796875 1029.519531 C 432.015625 1025.609375 432.746094 1017.582031 440.300781 1017.929688 C 455.527344 1018.613281 439.316406 1065.21875 487.578125 1078.679688 C 474.976562 1086.089844 478.765625 1097.519531 483.328125 1101.417969 C 490.894531 1083.652344 520.855469 1096.292969 557.484375 1071.988281 C 586.1875 1052.945312 593.601562 1026.1875 593.914062 1025.011719 Z M 593.914062 1025.011719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 277.632812 1076.996094 C 270.613281 1080.078125 260.160156 1083.886719 251.71875 1075.371094 C 246.605469 1085.203125 252.042969 1088.839844 255.460938 1089.832031 C 252.613281 1093.320312 247.964844 1101.425781 251.796875 1107.664062 C 258.605469 1110.378906 265.800781 1104.421875 268.761719 1101.019531 C 270.320312 1104.210938 274.828125 1108.949219 283.648438 1102.230469 C 273.8125 1095.367188 275.785156 1084.425781 277.632812 1076.996094 Z M 277.632812 1076.996094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 422.195312 1092.3125 C 422.195312 1092.3125 422.242188 1093.144531 422.289062 1094.492188 C 422.609375 1093.277344 422.820312 1092.546875 422.820312 1092.546875 C 422.820312 1092.546875 423.039062 1093.277344 423.359375 1094.492188 C 423.40625 1093.144531 423.453125 1092.3125 423.453125 1092.3125 C 423.453125 1092.3125 436.667969 1112.195312 429.15625 1118.007812 C 427.75 1119.09375 426.664062 1118.875 425.839844 1117.800781 C 425.296875 1119.382812 424.34375 1120.359375 422.820312 1120.359375 C 421.304688 1120.359375 420.347656 1119.382812 419.808594 1117.800781 C 418.976562 1118.875 417.898438 1119.09375 416.492188 1118.007812 C 408.980469 1112.195312 422.195312 1092.3125 422.195312 1092.3125 Z M 422.195312 1092.3125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 422.195312 1081.222656 C 422.195312 1081.222656 422.242188 1080.390625 422.289062 1079.042969 C 422.609375 1080.261719 422.820312 1080.988281 422.820312 1080.988281 C 422.820312 1080.988281 423.039062 1080.261719 423.359375 1079.042969 C 423.40625 1080.390625 423.453125 1081.222656 423.453125 1081.222656 C 423.453125 1081.222656 436.667969 1061.339844 429.15625 1055.523438 C 427.75 1054.441406 426.664062 1054.65625 425.839844 1055.734375 C 425.296875 1054.15625 424.34375 1053.171875 422.820312 1053.171875 C 421.304688 1053.171875 420.347656 1054.15625 419.808594 1055.734375 C 418.976562 1054.65625 417.898438 1054.441406 416.492188 1055.523438 C 408.980469 1061.339844 422.195312 1081.222656 422.195312 1081.222656 Z M 422.195312 1081.222656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 418.261719 1091.117188 C 417.953125 1091.289062 417.515625 1091.535156 416.972656 1091.84375 C 417.789062 1090.996094 418.304688 1090.410156 418.425781 1090.253906 C 417.894531 1090.351562 409.285156 1092.15625 402.941406 1095.664062 C 398.910156 1097.882812 395.785156 1100.792969 396.25 1104.390625 C 396.300781 1104.859375 396.40625 1105.261719 396.585938 1105.574219 C 396.992188 1106.421875 397.753906 1106.710938 398.734375 1106.570312 C 397.996094 1108.078125 397.984375 1109.433594 399.054688 1110.53125 C 400.128906 1111.59375 401.503906 1111.589844 403 1110.839844 C 402.886719 1111.707031 403.09375 1112.386719 403.730469 1112.835938 C 404.082031 1113.078125 404.574219 1113.253906 405.195312 1113.332031 C 414.625 1114.527344 419.347656 1091.132812 419.347656 1091.132812 C 419.347656 1091.132812 418.777344 1091.628906 417.867188 1092.484375 C 417.828125 1092.527344 417.789062 1092.566406 417.730469 1092.605469 C 417.800781 1092.484375 417.867188 1092.371094 417.9375 1092.269531 C 418.453125 1091.382812 418.738281 1090.855469 418.738281 1090.855469 C 418.738281 1090.855469 418.574219 1090.949219 418.261719 1091.117188 Z M 418.261719 1091.117188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 426.304688 1082.402344 C 426.304688 1082.402344 426.921875 1081.847656 427.914062 1080.925781 C 427.28125 1082.011719 426.914062 1082.679688 426.914062 1082.679688 C 426.914062 1082.679688 427.585938 1082.316406 428.667969 1081.679688 C 427.75 1082.671875 427.195312 1083.289062 427.195312 1083.289062 C 427.195312 1083.289062 450.605469 1078.570312 449.40625 1069.148438 C 449.179688 1067.386719 448.261719 1066.78125 446.910156 1066.957031 C 447.652344 1065.453125 447.671875 1064.089844 446.59375 1063.015625 C 445.515625 1061.9375 444.148438 1061.957031 442.644531 1062.695312 C 442.824219 1061.347656 442.214844 1060.429688 440.453125 1060.207031 C 431.023438 1059.003906 426.304688 1082.402344 426.304688 1082.402344 Z M 426.304688 1082.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 417.273438 1086.140625 C 417.273438 1086.140625 416.445312 1086.1875 415.09375 1086.238281 C 416.304688 1086.558594 417.042969 1086.769531 417.042969 1086.769531 C 417.042969 1086.769531 416.304688 1086.984375 415.09375 1087.304688 C 416.445312 1087.351562 417.273438 1087.402344 417.273438 1087.402344 C 417.273438 1087.402344 397.378906 1100.601562 391.566406 1093.09375 C 390.472656 1091.6875 390.699219 1090.609375 391.773438 1089.785156 C 390.1875 1089.242188 389.210938 1088.285156 389.210938 1086.769531 C 389.210938 1085.25 390.1875 1084.292969 391.773438 1083.753906 C 390.699219 1082.921875 390.472656 1081.847656 391.566406 1080.4375 C 397.378906 1072.933594 417.273438 1086.140625 417.273438 1086.140625 Z M 417.273438 1086.140625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 428.371094 1086.140625 C 428.371094 1086.140625 429.203125 1086.1875 430.554688 1086.238281 C 429.34375 1086.558594 428.605469 1086.769531 428.605469 1086.769531 C 428.605469 1086.769531 429.34375 1086.984375 430.554688 1087.304688 C 429.203125 1087.351562 428.371094 1087.402344 428.371094 1087.402344 C 428.371094 1087.402344 448.269531 1100.601562 454.082031 1093.09375 C 455.171875 1091.6875 454.949219 1090.609375 453.871094 1089.785156 C 455.460938 1089.242188 456.4375 1088.285156 456.4375 1086.769531 C 456.4375 1085.25 455.460938 1084.292969 453.871094 1083.753906 C 454.949219 1082.921875 455.171875 1081.847656 454.082031 1080.4375 C 448.269531 1072.933594 428.371094 1086.140625 428.371094 1086.140625 Z M 428.371094 1086.140625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 419.34375 1082.402344 C 419.34375 1082.402344 418.726562 1081.847656 417.734375 1080.925781 C 418.363281 1082.011719 418.734375 1082.679688 418.734375 1082.679688 C 418.734375 1082.679688 418.0625 1082.316406 416.976562 1081.679688 C 417.898438 1082.671875 418.453125 1083.289062 418.453125 1083.289062 C 418.453125 1083.289062 395.039062 1078.570312 396.242188 1069.148438 C 396.46875 1067.386719 397.386719 1066.78125 398.734375 1066.957031 C 397.996094 1065.453125 397.976562 1064.089844 399.054688 1063.015625 C 400.132812 1061.9375 401.5 1061.957031 403.003906 1062.695312 C 402.824219 1061.347656 403.429688 1060.429688 405.195312 1060.207031 C 414.617188 1059.003906 419.34375 1082.402344 419.34375 1082.402344 Z M 419.34375 1082.402344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 427.195312 1090.246094 C 427.195312 1090.246094 427.75 1090.863281 428.667969 1091.851562 C 427.585938 1091.21875 426.914062 1090.855469 426.914062 1090.855469 C 426.914062 1090.855469 427.28125 1091.523438 427.914062 1092.605469 C 426.921875 1091.6875 426.304688 1091.132812 426.304688 1091.132812 C 426.304688 1091.132812 431.023438 1114.535156 440.453125 1113.332031 C 442.214844 1113.105469 442.824219 1112.183594 442.644531 1110.839844 C 444.148438 1111.578125 445.515625 1111.59375 446.59375 1110.519531 C 447.671875 1109.441406 447.652344 1108.078125 446.910156 1106.582031 C 448.261719 1106.753906 449.179688 1106.144531 449.40625 1104.382812 C 450.605469 1094.96875 427.195312 1090.246094 427.195312 1090.246094 Z M 427.195312 1090.246094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 425.582031 1086.769531 C 425.582031 1085.25 424.34375 1084.011719 422.820312 1084.011719 C 421.304688 1084.011719 420.066406 1085.25 420.066406 1086.769531 C 420.066406 1088.285156 421.304688 1089.527344 422.820312 1089.527344 C 424.34375 1089.527344 425.582031 1088.285156 425.582031 1086.769531 Z M 425.582031 1086.769531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 535.246094 1009.800781 C 532.636719 1009.886719 530.441406 1007.847656 530.347656 1005.238281 C 530.253906 1002.625 532.296875 1000.433594 534.910156 1000.339844 C 537.523438 1000.246094 539.71875 1002.285156 539.8125 1004.898438 C 539.902344 1007.507812 537.863281 1009.703125 535.246094 1009.800781 Z M 535.246094 1009.800781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 534.660156 1012.644531 C 537.042969 1012.5625 539.039062 1014.425781 539.125 1016.800781 C 539.207031 1019.179688 537.347656 1021.179688 534.96875 1021.261719 C 532.589844 1021.339844 530.59375 1019.484375 530.507812 1017.105469 C 530.421875 1014.730469 532.28125 1012.734375 534.660156 1012.644531 Z M 534.660156 1012.644531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 541.585938 1024.589844 C 543.730469 1024.519531 545.53125 1026.191406 545.609375 1028.335938 C 545.6875 1030.480469 544.007812 1032.277344 541.867188 1032.351562 C 539.714844 1032.433594 537.914062 1030.761719 537.839844 1028.613281 C 537.757812 1026.472656 539.4375 1024.664062 541.585938 1024.589844 Z M 541.585938 1024.589844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 553.292969 1031.945312 C 555.207031 1031.875 556.808594 1033.371094 556.878906 1035.277344 C 556.945312 1037.199219 555.453125 1038.796875 553.542969 1038.863281 C 551.628906 1038.933594 550.023438 1037.441406 549.953125 1035.53125 C 549.886719 1033.625 551.382812 1032.015625 553.292969 1031.945312 Z M 553.292969 1031.945312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 565.503906 1033.796875 C 567.183594 1033.746094 568.59375 1035.050781 568.65625 1036.730469 C 568.710938 1038.402344 567.398438 1039.816406 565.71875 1039.878906 C 564.042969 1039.941406 562.632812 1038.621094 562.574219 1036.945312 C 562.515625 1035.269531 563.828125 1033.855469 565.503906 1033.796875 Z M 565.503906 1033.796875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 577.703125 1031.757812 C 579.148438 1031.703125 580.363281 1032.832031 580.414062 1034.277344 C 580.46875 1035.722656 579.335938 1036.9375 577.890625 1036.988281 C 576.445312 1037.039062 575.230469 1035.914062 575.179688 1034.460938 C 575.128906 1033.023438 576.257812 1031.804688 577.703125 1031.757812 Z M 577.703125 1031.757812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 585.910156 1026.054688 C 587.117188 1026.007812 588.136719 1026.964844 588.183594 1028.171875 C 588.226562 1029.386719 587.277344 1030.402344 586.0625 1030.445312 C 584.855469 1030.488281 583.835938 1029.542969 583.796875 1028.328125 C 583.75 1027.121094 584.695312 1026.097656 585.910156 1026.054688 Z M 585.910156 1026.054688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 223.070312 1066.726562 C 224.535156 1064.8125 224.171875 1062.070312 222.253906 1060.605469 C 220.335938 1059.136719 217.59375 1059.496094 216.121094 1061.417969 C 214.65625 1063.335938 215.015625 1066.078125 216.9375 1067.542969 C 218.855469 1069.011719 221.601562 1068.648438 223.070312 1066.726562 Z M 223.070312 1066.726562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 227.035156 1066.78125 C 225.699219 1068.527344 226.03125 1071.023438 227.773438 1072.359375 C 229.523438 1073.695312 232.023438 1073.367188 233.359375 1071.625 C 234.699219 1069.882812 234.367188 1067.378906 232.617188 1066.042969 C 230.875 1064.707031 228.371094 1065.035156 227.035156 1066.78125 Z M 227.035156 1066.78125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 232.332031 1078.386719 C 231.121094 1079.964844 231.421875 1082.210938 232.996094 1083.417969 C 234.570312 1084.621094 236.828125 1084.328125 238.035156 1082.75 C 239.242188 1081.179688 238.941406 1078.925781 237.367188 1077.714844 C 235.789062 1076.507812 233.535156 1076.8125 232.332031 1078.386719 Z M 232.332031 1078.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 231.605469 1091.132812 C 230.53125 1092.535156 230.796875 1094.542969 232.199219 1095.621094 C 233.605469 1096.691406 235.613281 1096.425781 236.691406 1095.019531 C 237.765625 1093.625 237.496094 1091.609375 236.09375 1090.535156 C 234.6875 1089.46875 232.683594 1089.726562 231.605469 1091.132812 Z M 231.605469 1091.132812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 225.007812 1100.691406 C 224.0625 1101.917969 224.296875 1103.679688 225.53125 1104.625 C 226.761719 1105.574219 228.523438 1105.339844 229.46875 1104.105469 C 230.414062 1102.875 230.179688 1101.113281 228.945312 1100.164062 C 227.714844 1099.21875 225.953125 1099.453125 225.007812 1100.691406 Z M 225.007812 1100.691406 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 214.683594 1105.5625 C 213.867188 1106.621094 214.074219 1108.140625 215.132812 1108.957031 C 216.191406 1109.765625 217.710938 1109.566406 218.523438 1108.507812 C 219.332031 1107.445312 219.136719 1105.929688 218.070312 1105.113281 C 217.011719 1104.308594 215.492188 1104.503906 214.683594 1105.5625 Z M 214.683594 1105.5625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 205.117188 1105.957031 C 204.4375 1106.84375 204.605469 1108.117188 205.496094 1108.792969 C 206.386719 1109.472656 207.65625 1109.308594 208.335938 1108.421875 C 209.019531 1107.527344 208.847656 1106.257812 207.960938 1105.574219 C 207.070312 1104.898438 205.800781 1105.0625 205.117188 1105.957031 Z M 205.117188 1105.957031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 597.113281 1001.152344 C 591.402344 1024.011719 556.667969 1031.035156 552.042969 1014.242188 C 556.6875 1018.398438 566.441406 1008.59375 558.210938 1002.253906 C 548.273438 994.589844 534.242188 1010.78125 549.027344 1023.570312 C 565.257812 1037.617188 597.113281 1024.152344 597.113281 1001.152344 Z M 597.113281 1001.152344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 212.316406 1049.527344 L 223.679688 1045.886719 L 234.839844 1048.832031 L 231.886719 1037.6875 L 235.53125 1026.324219 L 224.164062 1029.96875 L 213.003906 1027.015625 L 215.957031 1038.167969 Z M 212.316406 1049.527344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 938.621094 1007.242188 C 916.355469 990.660156 912.792969 1017.664062 876.078125 1016.757812 C 907.527344 1028.929688 930.304688 1025.152344 938.621094 1007.242188 Z M 938.621094 1007.242188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 987.683594 1049.527344 L 976.316406 1045.886719 L 965.160156 1048.832031 L 968.113281 1037.6875 L 964.46875 1026.324219 L 975.835938 1029.96875 L 986.992188 1027.015625 L 984.039062 1038.167969 Z M 987.683594 1049.527344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 254.628906 1000.496094 C 271.21875 978.242188 244.203125 974.6875 245.105469 937.992188 C 232.925781 969.421875 236.710938 992.183594 254.628906 1000.496094 Z M 254.628906 1000.496094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 186.476562 633.414062 C 197.40625 624.351562 177.765625 602.304688 162.132812 620.140625 C 149.9375 634.046875 165.976562 660.820312 189.28125 660.1875 C 155.320312 642.792969 179.578125 615.707031 186.476562 633.414062 Z M 186.476562 633.414062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 186.476562 690.757812 C 197.40625 699.820312 177.765625 721.867188 162.132812 704.03125 C 149.9375 690.125 165.976562 663.351562 189.28125 663.984375 C 155.320312 681.378906 179.578125 708.464844 186.476562 690.757812 Z M 186.476562 690.757812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 149.734375 678.636719 C 162.070312 662.1875 184.539062 662.085938 184.539062 662.085938 C 184.539062 662.085938 162.070312 661.984375 149.734375 645.535156 C 136.859375 642.582031 142.132812 656.289062 125 662.085938 C 142.132812 667.882812 136.859375 681.589844 149.734375 678.636719 Z M 149.734375 678.636719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 199.316406 662.085938 C 194.75 661.800781 186.8125 644.902344 195.027344 634.300781 C 200.703125 657.1875 228.34375 661.234375 233.953125 662.085938 C 228.34375 662.9375 200.703125 666.984375 195.027344 689.871094 C 186.8125 679.269531 194.75 662.371094 199.316406 662.085938 Z M 199.316406 662.085938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 183.144531 549.734375 C 190.054688 511.914062 217.191406 497.734375 231.929688 467.117188 C 248.273438 433.167969 239.125 394.617188 210.21875 382.09375 C 192.5625 374.441406 171.320312 383.78125 167.664062 403.839844 C 162.539062 431.933594 200.296875 443.199219 205.515625 421.613281 C 208.6875 408.5 188.921875 406.625 191.09375 416.976562 C 179.414062 408.300781 196.621094 390.546875 211.074219 401.097656 C 228.859375 414.082031 217.367188 454.136719 183.253906 446.664062 C 162.1875 442.046875 155.839844 419.40625 158.605469 404.851562 C 165.578125 368.207031 222.382812 358.089844 240.164062 405.15625 C 246.441406 391.949219 258.164062 394.703125 262.445312 398.910156 C 245.40625 408.011719 257.726562 433.824219 237.359375 472.773438 C 224.234375 497.886719 202.613281 504.304688 183.144531 549.734375 Z M 183.144531 549.734375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 199.765625 259.359375 C 211.351562 270.546875 210.820312 291.714844 198.488281 311.667969 L 197.316406 310.257812 C 206.816406 294.273438 209.066406 276.96875 196.941406 264.941406 C 183.003906 251.128906 160.585938 269.21875 170.945312 280.421875 C 179.773438 289.976562 191.726562 275.71875 182.757812 270.867188 C 191.582031 269.472656 194.605469 284.140625 183.277344 288.917969 C 166.515625 295.984375 149.292969 274.53125 166.449219 257.753906 C 177.039062 247.402344 192.441406 252.289062 199.765625 259.359375 Z M 199.765625 259.359375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 153.515625 454.863281 C 160.480469 448.765625 160.765625 436.601562 154.246094 424.808594 L 153.535156 425.578125 C 158.539062 435.019531 159.347656 445.019531 152.046875 451.582031 C 143.664062 459.121094 131.296875 448.113281 137.558594 441.96875 C 142.898438 436.730469 149.359375 445.253906 144.070312 447.789062 C 149.101562 448.832031 151.246094 440.496094 144.875 437.4375 C 135.453125 432.910156 124.96875 444.746094 134.34375 454.855469 C 140.132812 461.097656 149.113281 458.71875 153.515625 454.863281 Z M 153.515625 454.863281 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 157.425781 464.8125 C 151.601562 459.464844 139.003906 406.542969 175.246094 380.300781 L 175.246094 381.378906 C 149.117188 407.328125 150.496094 445.835938 158.699219 463.824219 C 158.699219 463.824219 157.117188 463.628906 157.425781 464.8125 Z M 157.425781 464.8125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 173.816406 366.339844 C 163.375 377.152344 151.117188 357.75 170.335938 348.703125 C 181.890625 343.269531 193.332031 346.675781 201.578125 351.082031 C 197.300781 346.839844 194.089844 342.015625 192.441406 336.296875 C 186.585938 315.949219 199.523438 304.160156 211.75 301.804688 C 226.273438 299.019531 242.75 312.863281 236.234375 329.03125 C 232.152344 339.175781 212.578125 342.59375 211.007812 327.09375 C 222.210938 331.953125 227.070312 313.269531 212.445312 312.078125 C 202.859375 311.292969 193.039062 323.757812 197.878906 336.246094 C 205.074219 354.808594 241.929688 363.179688 240.847656 398.238281 C 227.269531 345.921875 173.664062 348.765625 173.816406 366.339844 Z M 173.816406 366.339844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 236.84375 656.003906 C 227.148438 651.035156 193.589844 625.082031 197.089844 603.457031 C 198.9375 592.019531 212.765625 593.523438 213.660156 598.234375 C 223.28125 578.582031 195.40625 568.492188 188.808594 588.542969 C 170.933594 517.402344 244.496094 502.992188 255.25 542.027344 C 262.828125 569.566406 224.910156 583.171875 219.109375 563.082031 C 214.476562 547.046875 237.042969 546.652344 235.777344 557.15625 C 245.738281 546.296875 226.917969 529.664062 213.445312 541.441406 C 196.875 555.953125 210.171875 596.523438 243.5 586.058594 C 264.078125 579.613281 268.398438 556.496094 264.351562 542.242188 C 260.640625 529.132812 251.773438 520.144531 239.875 516.375 C 249.765625 514.203125 256.578125 509.097656 255.65625 499.148438 C 254.21875 483.71875 233.738281 480.472656 232.335938 496.988281 C 236.25 494.207031 244.28125 494.9375 243.9375 502.488281 C 243.25 517.703125 196.617188 501.503906 183.144531 549.734375 C 175.730469 537.140625 164.292969 540.925781 160.390625 545.484375 C 178.171875 553.046875 165.515625 582.992188 189.839844 619.601562 C 208.894531 648.28125 235.679688 655.691406 236.84375 656.003906 Z M 236.84375 656.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 184.832031 339.917969 C 181.742188 332.90625 177.933594 322.457031 186.453125 314.023438 C 176.617188 308.90625 172.984375 314.347656 171.992188 317.757812 C 168.492188 314.917969 160.382812 310.265625 154.144531 314.105469 C 151.429688 320.90625 157.390625 328.09375 160.796875 331.050781 C 157.597656 332.613281 152.859375 337.121094 159.582031 345.933594 C 166.445312 336.105469 177.398438 338.070312 184.832031 339.917969 Z M 184.832031 339.917969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 169.5 484.394531 C 169.5 484.394531 168.671875 484.441406 167.320312 484.488281 C 168.535156 484.804688 169.265625 485.019531 169.265625 485.019531 C 169.265625 485.019531 168.535156 485.230469 167.320312 485.554688 C 168.671875 485.601562 169.5 485.648438 169.5 485.648438 C 169.5 485.648438 149.605469 498.859375 143.792969 491.347656 C 142.703125 489.941406 142.925781 488.863281 144.003906 488.035156 C 142.414062 487.496094 141.441406 486.542969 141.441406 485.019531 C 141.441406 483.5 142.414062 482.546875 144.003906 482.003906 C 142.925781 481.179688 142.703125 480.101562 143.792969 478.695312 C 149.605469 471.183594 169.5 484.394531 169.5 484.394531 Z M 169.5 484.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 180.605469 484.394531 C 180.605469 484.394531 181.429688 484.441406 182.785156 484.488281 C 181.570312 484.804688 180.835938 485.019531 180.835938 485.019531 C 180.835938 485.019531 181.570312 485.230469 182.785156 485.554688 C 181.429688 485.601562 180.605469 485.648438 180.605469 485.648438 C 180.605469 485.648438 200.5 498.859375 206.3125 491.347656 C 207.402344 489.941406 207.179688 488.863281 206.101562 488.035156 C 207.6875 487.496094 208.664062 486.542969 208.664062 485.019531 C 208.664062 483.5 207.6875 482.546875 206.101562 482.003906 C 207.179688 481.179688 207.402344 480.101562 206.3125 478.695312 C 200.5 471.183594 180.605469 484.394531 180.605469 484.394531 Z M 180.605469 484.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 170.703125 480.460938 C 170.527344 480.152344 170.28125 479.71875 169.96875 479.171875 C 170.828125 479.988281 171.40625 480.503906 171.558594 480.625 C 171.464844 480.097656 169.65625 471.488281 166.152344 465.152344 C 163.925781 461.121094 161.019531 458 157.421875 458.457031 C 156.941406 458.515625 156.546875 458.621094 156.238281 458.800781 C 155.382812 459.207031 155.09375 459.964844 155.230469 460.945312 C 153.726562 460.210938 152.367188 460.195312 151.28125 461.269531 C 150.207031 462.34375 150.21875 463.710938 150.964844 465.207031 C 150.097656 465.097656 149.417969 465.304688 148.972656 465.941406 C 148.726562 466.292969 148.550781 466.78125 148.46875 467.40625 C 147.273438 476.824219 170.691406 481.550781 170.691406 481.550781 C 170.691406 481.550781 170.183594 480.976562 169.332031 480.066406 C 169.289062 480.03125 169.246094 479.988281 169.210938 479.933594 C 169.332031 479.996094 169.4375 480.066406 169.546875 480.136719 C 170.429688 480.652344 170.957031 480.941406 170.957031 480.941406 C 170.957031 480.941406 170.863281 480.777344 170.703125 480.460938 Z M 170.703125 480.460938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 179.421875 488.496094 C 179.421875 488.496094 179.972656 489.117188 180.894531 490.105469 C 179.8125 489.472656 179.140625 489.105469 179.140625 489.105469 C 179.140625 489.105469 179.507812 489.777344 180.140625 490.859375 C 179.152344 489.941406 178.53125 489.386719 178.53125 489.386719 C 178.53125 489.386719 183.253906 512.785156 192.679688 511.585938 C 194.445312 511.359375 195.050781 510.441406 194.875 509.089844 C 196.378906 509.832031 197.742188 509.851562 198.820312 508.773438 C 199.898438 507.695312 199.878906 506.332031 199.140625 504.832031 C 200.488281 505.003906 201.40625 504.402344 201.632812 502.636719 C 202.835938 493.214844 179.421875 488.496094 179.421875 488.496094 Z M 179.421875 488.496094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.683594 479.472656 C 175.683594 479.472656 175.636719 478.644531 175.589844 477.292969 C 175.265625 478.507812 175.054688 479.238281 175.054688 479.238281 C 175.054688 479.238281 174.839844 478.507812 174.515625 477.292969 C 174.46875 478.644531 174.421875 479.472656 174.421875 479.472656 C 174.421875 479.472656 161.207031 459.589844 168.722656 453.78125 C 170.128906 452.695312 171.210938 452.914062 172.035156 453.992188 C 172.578125 452.402344 173.527344 451.429688 175.054688 451.429688 C 176.574219 451.429688 177.53125 452.402344 178.070312 453.992188 C 178.898438 452.914062 179.976562 452.695312 181.382812 453.78125 C 188.898438 459.589844 175.683594 479.472656 175.683594 479.472656 Z M 175.683594 479.472656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.683594 490.570312 C 175.683594 490.570312 175.636719 491.398438 175.589844 492.746094 C 175.265625 491.535156 175.054688 490.796875 175.054688 490.796875 C 175.054688 490.796875 174.839844 491.535156 174.515625 492.746094 C 174.46875 491.398438 174.421875 490.570312 174.421875 490.570312 C 174.421875 490.570312 161.207031 510.449219 168.722656 516.261719 C 170.128906 517.347656 171.210938 517.128906 172.035156 516.050781 C 172.578125 517.632812 173.527344 518.609375 175.054688 518.609375 C 176.574219 518.609375 177.53125 517.632812 178.070312 516.050781 C 178.898438 517.128906 179.976562 517.347656 181.382812 516.261719 C 188.898438 510.449219 175.683594 490.570312 175.683594 490.570312 Z M 175.683594 490.570312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 179.421875 481.542969 C 179.421875 481.542969 179.972656 480.925781 180.894531 479.9375 C 179.8125 480.566406 179.140625 480.933594 179.140625 480.933594 C 179.140625 480.933594 179.507812 480.265625 180.140625 479.179688 C 179.152344 480.101562 178.53125 480.652344 178.53125 480.652344 C 178.53125 480.652344 183.253906 457.253906 192.679688 458.457031 C 194.445312 458.683594 195.050781 459.601562 194.875 460.945312 C 196.378906 460.210938 197.742188 460.191406 198.820312 461.269531 C 199.898438 462.347656 199.878906 463.707031 199.140625 465.210938 C 200.488281 465.035156 201.40625 465.640625 201.632812 467.402344 C 202.835938 476.820312 179.421875 481.542969 179.421875 481.542969 Z M 179.421875 481.542969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 171.570312 489.386719 C 171.570312 489.386719 170.953125 489.941406 169.964844 490.859375 C 170.597656 489.777344 170.960938 489.105469 170.960938 489.105469 C 170.960938 489.105469 170.292969 489.472656 169.210938 490.105469 C 170.128906 489.117188 170.679688 488.496094 170.679688 488.496094 C 170.679688 488.496094 147.265625 493.214844 148.472656 502.636719 C 148.695312 504.402344 149.617188 505.003906 150.960938 504.832031 C 150.226562 506.332031 150.210938 507.695312 151.285156 508.773438 C 152.363281 509.851562 153.726562 509.832031 155.230469 509.089844 C 155.054688 510.441406 155.65625 511.359375 157.425781 511.585938 C 166.847656 512.785156 171.570312 489.386719 171.570312 489.386719 Z M 171.570312 489.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.054688 487.777344 C 176.574219 487.777344 177.8125 486.542969 177.8125 485.019531 C 177.8125 483.5 176.574219 482.265625 175.054688 482.265625 C 173.527344 482.265625 172.292969 483.5 172.292969 485.019531 C 172.292969 486.542969 173.527344 487.777344 175.054688 487.777344 Z M 175.054688 487.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 252.070312 597.375 C 251.976562 594.761719 254.019531 592.574219 256.636719 592.480469 C 259.246094 592.386719 261.441406 594.421875 261.535156 597.039062 C 261.628906 599.648438 259.585938 601.839844 256.972656 601.933594 C 254.359375 602.027344 252.164062 599.984375 252.070312 597.375 Z M 252.070312 597.375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 249.21875 596.789062 C 249.304688 599.164062 247.445312 601.160156 245.0625 601.246094 C 242.6875 601.332031 240.683594 599.476562 240.601562 597.09375 C 240.515625 594.71875 242.375 592.722656 244.757812 592.632812 C 247.132812 592.550781 249.136719 594.410156 249.21875 596.789062 Z M 249.21875 596.789062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 237.265625 603.707031 C 237.347656 605.851562 235.667969 607.652344 233.523438 607.730469 C 231.375 607.804688 229.574219 606.128906 229.496094 603.988281 C 229.421875 601.839844 231.097656 600.042969 233.246094 599.960938 C 235.390625 599.886719 237.191406 601.558594 237.265625 603.707031 Z M 237.265625 603.707031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 229.90625 615.40625 C 229.976562 617.320312 228.484375 618.925781 226.570312 618.992188 C 224.65625 619.0625 223.054688 617.566406 222.984375 615.65625 C 222.914062 613.742188 224.410156 612.140625 226.320312 612.070312 C 228.234375 612.003906 229.84375 613.5 229.90625 615.40625 Z M 229.90625 615.40625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 228.050781 627.613281 C 228.113281 629.289062 226.804688 630.695312 225.121094 630.757812 C 223.445312 630.816406 222.03125 629.507812 221.976562 627.828125 C 221.914062 626.152344 223.226562 624.742188 224.90625 624.683594 C 226.585938 624.625 227.996094 625.933594 228.050781 627.613281 Z M 228.050781 627.613281 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 230.09375 639.804688 C 230.148438 641.25 229.015625 642.460938 227.574219 642.511719 C 226.128906 642.566406 224.914062 641.4375 224.863281 639.992188 C 224.8125 638.542969 225.941406 637.335938 227.386719 637.285156 C 228.835938 637.226562 230.042969 638.355469 230.09375 639.804688 Z M 230.09375 639.804688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 235.800781 648.003906 C 235.84375 649.214844 234.894531 650.230469 233.6875 650.273438 C 232.472656 650.320312 231.453125 649.375 231.414062 648.160156 C 231.371094 646.949219 232.316406 645.933594 233.527344 645.890625 C 234.742188 645.84375 235.757812 646.792969 235.800781 648.003906 Z M 235.800781 648.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 195.101562 285.386719 C 197.019531 286.851562 199.765625 286.5 201.234375 284.578125 C 202.703125 282.664062 202.335938 279.921875 200.421875 278.445312 C 198.5 276.980469 195.753906 277.339844 194.289062 279.261719 C 192.820312 281.175781 193.1875 283.917969 195.101562 285.386719 Z M 195.101562 285.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 195.046875 289.355469 C 193.304688 288.015625 190.800781 288.347656 189.464844 290.097656 C 188.128906 291.84375 188.460938 294.335938 190.203125 295.671875 C 191.953125 297.003906 194.453125 296.679688 195.792969 294.933594 C 197.128906 293.1875 196.796875 290.6875 195.046875 289.355469 Z M 195.046875 289.355469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 183.441406 294.648438 C 181.867188 293.441406 179.609375 293.734375 178.402344 295.3125 C 177.195312 296.882812 177.496094 299.140625 179.070312 300.347656 C 180.648438 301.554688 182.902344 301.25 184.105469 299.679688 C 185.316406 298.101562 185.015625 295.851562 183.441406 294.648438 Z M 183.441406 294.648438 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 170.6875 293.925781 C 169.28125 292.847656 167.273438 293.113281 166.199219 294.519531 C 165.121094 295.921875 165.390625 297.929688 166.789062 299 C 168.195312 300.078125 170.207031 299.808594 171.28125 298.40625 C 172.355469 297.003906 172.085938 294.996094 170.6875 293.925781 Z M 170.6875 293.925781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 161.125 287.328125 C 159.890625 286.382812 158.128906 286.617188 157.179688 287.851562 C 156.238281 289.082031 156.472656 290.84375 157.707031 291.78125 C 158.9375 292.726562 160.703125 292.492188 161.644531 291.261719 C 162.585938 290.027344 162.351562 288.265625 161.125 287.328125 Z M 161.125 287.328125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 156.238281 277.003906 C 155.179688 276.195312 153.660156 276.398438 152.851562 277.457031 C 152.039062 278.511719 152.242188 280.035156 153.300781 280.847656 C 154.359375 281.65625 155.878906 281.453125 156.6875 280.398438 C 157.503906 279.335938 157.304688 277.824219 156.238281 277.003906 Z M 156.238281 277.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 155.855469 267.449219 C 154.96875 266.773438 153.695312 266.9375 153.015625 267.832031 C 152.335938 268.71875 152.503906 269.984375 153.390625 270.667969 C 154.28125 271.347656 155.554688 271.183594 156.234375 270.289062 C 156.914062 269.402344 156.746094 268.136719 155.855469 267.449219 Z M 155.855469 267.449219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 260.722656 659.199219 C 237.84375 653.492188 230.816406 618.78125 247.621094 614.160156 C 243.464844 618.804688 253.273438 628.550781 259.621094 620.328125 C 267.285156 610.394531 251.089844 596.375 238.289062 611.148438 C 224.242188 627.363281 237.703125 659.199219 260.722656 659.199219 Z M 260.722656 659.199219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 183.144531 774.4375 C 190.054688 812.257812 217.191406 826.4375 231.929688 857.054688 C 248.273438 891.003906 239.125 929.554688 210.21875 942.078125 C 192.5625 949.730469 171.320312 940.390625 167.664062 920.332031 C 162.539062 892.238281 200.296875 880.972656 205.515625 902.558594 C 208.6875 915.671875 188.921875 917.546875 191.09375 907.195312 C 179.414062 915.871094 196.621094 933.625 211.074219 923.074219 C 228.859375 910.089844 217.367188 870.035156 183.253906 877.507812 C 162.1875 882.125 155.839844 904.765625 158.605469 919.320312 C 165.578125 955.964844 222.382812 966.082031 240.164062 919.015625 C 246.441406 932.222656 258.164062 929.46875 262.445312 925.261719 C 245.40625 916.160156 257.726562 890.347656 237.359375 851.398438 C 224.234375 826.285156 202.613281 819.867188 183.144531 774.4375 Z M 183.144531 774.4375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 199.765625 1064.8125 C 211.351562 1053.625 210.820312 1032.457031 198.488281 1012.503906 L 197.316406 1013.914062 C 206.816406 1029.898438 209.066406 1047.203125 196.941406 1059.230469 C 183.003906 1073.042969 160.585938 1054.953125 170.945312 1043.75 C 179.773438 1034.195312 191.726562 1048.453125 182.757812 1053.304688 C 191.582031 1054.695312 194.605469 1040.03125 183.277344 1035.253906 C 166.515625 1028.183594 149.292969 1049.640625 166.449219 1066.417969 C 177.039062 1076.769531 192.441406 1071.886719 199.765625 1064.8125 Z M 199.765625 1064.8125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 153.515625 869.308594 C 160.480469 875.40625 160.765625 887.570312 154.246094 899.363281 L 153.535156 898.59375 C 158.539062 889.152344 159.347656 879.152344 152.046875 872.589844 C 143.664062 865.050781 131.296875 876.058594 137.558594 882.203125 C 142.898438 887.441406 149.359375 878.917969 144.070312 876.382812 C 149.101562 875.339844 151.246094 883.675781 144.875 886.734375 C 135.453125 891.261719 124.96875 879.425781 134.34375 869.316406 C 140.132812 863.074219 149.113281 865.453125 153.515625 869.308594 Z M 153.515625 869.308594 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 157.425781 859.359375 C 151.601562 864.707031 139.003906 917.628906 175.246094 943.871094 L 175.246094 942.792969 C 149.117188 916.84375 150.496094 878.335938 158.699219 860.347656 C 158.699219 860.347656 157.117188 860.542969 157.425781 859.359375 Z M 157.425781 859.359375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 173.816406 957.832031 C 163.375 947.019531 151.117188 966.421875 170.335938 975.46875 C 181.890625 980.902344 193.332031 977.496094 201.578125 973.089844 C 197.300781 977.332031 194.089844 982.15625 192.441406 987.875 C 186.585938 1008.222656 199.523438 1020.011719 211.75 1022.367188 C 226.273438 1025.140625 242.75 1011.308594 236.234375 995.140625 C 232.152344 984.996094 212.578125 981.578125 211.007812 997.078125 C 222.210938 992.21875 227.070312 1010.902344 212.445312 1012.09375 C 202.859375 1012.878906 193.039062 1000.414062 197.878906 987.925781 C 205.074219 969.363281 241.929688 960.992188 240.847656 925.933594 C 227.269531 978.25 173.664062 975.40625 173.816406 957.832031 Z M 173.816406 957.832031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 236.84375 668.167969 C 227.148438 673.136719 193.589844 699.089844 197.089844 720.714844 C 198.9375 732.152344 212.765625 730.648438 213.660156 725.9375 C 223.28125 745.589844 195.40625 755.679688 188.808594 735.628906 C 170.933594 806.769531 244.496094 821.179688 255.25 782.144531 C 262.828125 754.605469 224.910156 741 219.109375 761.089844 C 214.476562 777.125 237.042969 777.519531 235.777344 767.015625 C 245.738281 777.875 226.917969 794.507812 213.445312 782.730469 C 196.875 768.21875 210.171875 727.648438 243.5 738.113281 C 264.078125 744.558594 268.398438 767.675781 264.351562 781.929688 C 260.640625 795.039062 251.773438 804.027344 239.875 807.796875 C 249.765625 809.972656 256.578125 815.074219 255.65625 825.023438 C 254.21875 840.457031 233.738281 843.699219 232.335938 827.183594 C 236.25 829.964844 244.28125 829.234375 243.9375 821.6875 C 243.25 806.46875 196.617188 822.667969 183.144531 774.4375 C 175.730469 787.03125 164.292969 783.246094 160.390625 778.6875 C 178.171875 771.125 165.515625 741.179688 189.839844 704.570312 C 208.894531 675.890625 235.679688 668.480469 236.84375 668.167969 Z M 236.84375 668.167969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 184.832031 984.253906 C 181.742188 991.265625 177.933594 1001.714844 186.453125 1010.148438 C 176.617188 1015.257812 172.984375 1009.824219 171.992188 1006.414062 C 168.492188 1009.253906 160.382812 1013.90625 154.144531 1010.066406 C 151.429688 1003.265625 157.390625 996.078125 160.796875 993.121094 C 157.597656 991.558594 152.859375 987.050781 159.582031 978.238281 C 166.445312 988.066406 177.398438 986.101562 184.832031 984.253906 Z M 184.832031 984.253906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 169.5 839.777344 C 169.5 839.777344 168.671875 839.730469 167.320312 839.683594 C 168.535156 839.367188 169.265625 839.152344 169.265625 839.152344 C 169.265625 839.152344 168.535156 838.941406 167.320312 838.617188 C 168.671875 838.570312 169.5 838.523438 169.5 838.523438 C 169.5 838.523438 149.605469 825.3125 143.792969 832.824219 C 142.703125 834.230469 142.925781 835.308594 144.003906 836.136719 C 142.414062 836.675781 141.441406 837.628906 141.441406 839.152344 C 141.441406 840.671875 142.414062 841.625 144.003906 842.167969 C 142.925781 842.992188 142.703125 844.070312 143.792969 845.476562 C 149.605469 852.988281 169.5 839.777344 169.5 839.777344 Z M 169.5 839.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 180.605469 839.777344 C 180.605469 839.777344 181.429688 839.730469 182.785156 839.683594 C 181.570312 839.367188 180.835938 839.152344 180.835938 839.152344 C 180.835938 839.152344 181.570312 838.941406 182.785156 838.617188 C 181.429688 838.570312 180.605469 838.523438 180.605469 838.523438 C 180.605469 838.523438 200.5 825.3125 206.3125 832.824219 C 207.402344 834.230469 207.179688 835.308594 206.101562 836.136719 C 207.6875 836.675781 208.664062 837.628906 208.664062 839.152344 C 208.664062 840.671875 207.6875 841.625 206.101562 842.167969 C 207.179688 842.992188 207.402344 844.070312 206.3125 845.476562 C 200.5 852.988281 180.605469 839.777344 180.605469 839.777344 Z M 180.605469 839.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 170.703125 843.710938 C 170.527344 844.019531 170.28125 844.453125 169.96875 845 C 170.828125 844.183594 171.40625 843.667969 171.558594 843.546875 C 171.464844 844.074219 169.65625 852.683594 166.152344 859.019531 C 163.925781 863.050781 161.019531 866.171875 157.421875 865.714844 C 156.941406 865.65625 156.546875 865.550781 156.238281 865.371094 C 155.382812 864.964844 155.09375 864.207031 155.230469 863.226562 C 153.726562 863.960938 152.367188 863.976562 151.28125 862.902344 C 150.207031 861.828125 150.21875 860.460938 150.964844 858.964844 C 150.097656 859.074219 149.417969 858.867188 148.972656 858.230469 C 148.726562 857.878906 148.550781 857.390625 148.46875 856.765625 C 147.273438 847.347656 170.691406 842.621094 170.691406 842.621094 C 170.691406 842.621094 170.183594 843.195312 169.332031 844.105469 C 169.289062 844.140625 169.246094 844.183594 169.210938 844.238281 C 169.332031 844.175781 169.4375 844.105469 169.546875 844.035156 C 170.429688 843.519531 170.957031 843.230469 170.957031 843.230469 C 170.957031 843.230469 170.863281 843.394531 170.703125 843.710938 Z M 170.703125 843.710938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 179.421875 835.675781 C 179.421875 835.675781 179.972656 835.054688 180.894531 834.066406 C 179.8125 834.699219 179.140625 835.066406 179.140625 835.066406 C 179.140625 835.066406 179.507812 834.394531 180.140625 833.3125 C 179.152344 834.230469 178.53125 834.785156 178.53125 834.785156 C 178.53125 834.785156 183.253906 811.386719 192.679688 812.585938 C 194.445312 812.8125 195.050781 813.730469 194.875 815.082031 C 196.378906 814.339844 197.742188 814.320312 198.820312 815.398438 C 199.898438 816.476562 199.878906 817.839844 199.140625 819.34375 C 200.488281 819.167969 201.40625 819.769531 201.632812 821.535156 C 202.835938 830.957031 179.421875 835.675781 179.421875 835.675781 Z M 179.421875 835.675781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.683594 844.699219 C 175.683594 844.699219 175.636719 845.527344 175.589844 846.878906 C 175.265625 845.664062 175.054688 844.933594 175.054688 844.933594 C 175.054688 844.933594 174.839844 845.664062 174.515625 846.878906 C 174.46875 845.527344 174.421875 844.699219 174.421875 844.699219 C 174.421875 844.699219 161.207031 864.582031 168.722656 870.390625 C 170.128906 871.476562 171.210938 871.257812 172.035156 870.179688 C 172.578125 871.769531 173.527344 872.742188 175.054688 872.742188 C 176.574219 872.742188 177.53125 871.769531 178.070312 870.179688 C 178.898438 871.257812 179.976562 871.476562 181.382812 870.390625 C 188.898438 864.582031 175.683594 844.699219 175.683594 844.699219 Z M 175.683594 844.699219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.683594 833.601562 C 175.683594 833.601562 175.636719 832.773438 175.589844 831.425781 C 175.265625 832.636719 175.054688 833.375 175.054688 833.375 C 175.054688 833.375 174.839844 832.636719 174.515625 831.425781 C 174.46875 832.773438 174.421875 833.601562 174.421875 833.601562 C 174.421875 833.601562 161.207031 813.722656 168.722656 807.910156 C 170.128906 806.824219 171.210938 807.042969 172.035156 808.121094 C 172.578125 806.539062 173.527344 805.5625 175.054688 805.5625 C 176.574219 805.5625 177.53125 806.539062 178.070312 808.121094 C 178.898438 807.042969 179.976562 806.824219 181.382812 807.910156 C 188.898438 813.722656 175.683594 833.601562 175.683594 833.601562 Z M 175.683594 833.601562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 179.421875 842.628906 C 179.421875 842.628906 179.972656 843.246094 180.894531 844.234375 C 179.8125 843.605469 179.140625 843.238281 179.140625 843.238281 C 179.140625 843.238281 179.507812 843.90625 180.140625 844.992188 C 179.152344 844.070312 178.53125 843.519531 178.53125 843.519531 C 178.53125 843.519531 183.253906 866.917969 192.679688 865.714844 C 194.445312 865.488281 195.050781 864.570312 194.875 863.226562 C 196.378906 863.960938 197.742188 863.980469 198.820312 862.902344 C 199.898438 861.824219 199.878906 860.464844 199.140625 858.960938 C 200.488281 859.136719 201.40625 858.53125 201.632812 856.769531 C 202.835938 847.351562 179.421875 842.628906 179.421875 842.628906 Z M 179.421875 842.628906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 171.570312 834.785156 C 171.570312 834.785156 170.953125 834.230469 169.964844 833.3125 C 170.597656 834.394531 170.960938 835.066406 170.960938 835.066406 C 170.960938 835.066406 170.292969 834.699219 169.210938 834.066406 C 170.128906 835.054688 170.679688 835.675781 170.679688 835.675781 C 170.679688 835.675781 147.265625 830.957031 148.472656 821.535156 C 148.695312 819.769531 149.617188 819.167969 150.960938 819.34375 C 150.226562 817.839844 150.210938 816.476562 151.285156 815.398438 C 152.363281 814.320312 153.726562 814.339844 155.230469 815.082031 C 155.054688 813.730469 155.65625 812.8125 157.425781 812.585938 C 166.847656 811.386719 171.570312 834.785156 171.570312 834.785156 Z M 171.570312 834.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 175.054688 836.394531 C 176.574219 836.394531 177.8125 837.628906 177.8125 839.152344 C 177.8125 840.671875 176.574219 841.90625 175.054688 841.90625 C 173.527344 841.90625 172.292969 840.671875 172.292969 839.152344 C 172.292969 837.628906 173.527344 836.394531 175.054688 836.394531 Z M 175.054688 836.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 252.070312 726.796875 C 251.976562 729.410156 254.019531 731.597656 256.636719 731.691406 C 259.246094 731.785156 261.441406 729.75 261.535156 727.132812 C 261.628906 724.523438 259.585938 722.332031 256.972656 722.238281 C 254.359375 722.144531 252.164062 724.1875 252.070312 726.796875 Z M 252.070312 726.796875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 249.21875 727.382812 C 249.304688 725.007812 247.445312 723.011719 245.0625 722.925781 C 242.6875 722.839844 240.683594 724.695312 240.601562 727.078125 C 240.515625 729.453125 242.375 731.449219 244.757812 731.539062 C 247.132812 731.621094 249.136719 729.761719 249.21875 727.382812 Z M 249.21875 727.382812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 237.265625 720.464844 C 237.347656 718.320312 235.667969 716.519531 233.523438 716.441406 C 231.375 716.367188 229.574219 718.042969 229.496094 720.183594 C 229.421875 722.332031 231.097656 724.128906 233.246094 724.210938 C 235.390625 724.285156 237.191406 722.613281 237.265625 720.464844 Z M 237.265625 720.464844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 229.90625 708.765625 C 229.976562 706.851562 228.484375 705.246094 226.570312 705.179688 C 224.65625 705.109375 223.054688 706.605469 222.984375 708.515625 C 222.914062 710.429688 224.410156 712.03125 226.320312 712.101562 C 228.234375 712.167969 229.84375 710.675781 229.90625 708.765625 Z M 229.90625 708.765625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 228.050781 696.558594 C 228.113281 694.882812 226.804688 693.476562 225.121094 693.414062 C 223.445312 693.355469 222.03125 694.664062 221.976562 696.34375 C 221.914062 698.019531 223.226562 699.429688 224.90625 699.492188 C 226.585938 699.546875 227.996094 698.238281 228.050781 696.558594 Z M 228.050781 696.558594 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 230.09375 684.367188 C 230.148438 682.921875 229.015625 681.710938 227.574219 681.660156 C 226.128906 681.605469 224.914062 682.734375 224.863281 684.179688 C 224.8125 685.628906 225.941406 686.835938 227.386719 686.886719 C 228.835938 686.945312 230.042969 685.816406 230.09375 684.367188 Z M 230.09375 684.367188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 235.800781 676.167969 C 235.84375 674.957031 234.894531 673.941406 233.6875 673.898438 C 232.472656 673.851562 231.453125 674.796875 231.414062 676.011719 C 231.371094 677.222656 232.316406 678.238281 233.527344 678.28125 C 234.742188 678.328125 235.757812 677.378906 235.800781 676.167969 Z M 235.800781 676.167969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 195.101562 1038.785156 C 197.019531 1037.320312 199.765625 1037.675781 201.234375 1039.59375 C 202.703125 1041.507812 202.335938 1044.25 200.421875 1045.71875 C 198.5 1047.191406 195.753906 1046.832031 194.289062 1044.910156 C 192.820312 1042.996094 193.1875 1040.253906 195.101562 1038.785156 Z M 195.101562 1038.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 195.046875 1034.816406 C 193.304688 1036.15625 190.800781 1035.824219 189.464844 1034.082031 C 188.128906 1032.328125 188.460938 1029.835938 190.203125 1028.492188 C 191.953125 1027.15625 194.453125 1027.492188 195.792969 1029.238281 C 197.128906 1030.984375 196.796875 1033.484375 195.046875 1034.816406 Z M 195.046875 1034.816406 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 183.441406 1029.523438 C 181.867188 1030.730469 179.609375 1030.4375 178.402344 1028.859375 C 177.195312 1027.289062 177.496094 1025.03125 179.070312 1023.824219 C 180.648438 1022.617188 182.902344 1022.921875 184.105469 1024.492188 C 185.316406 1026.070312 185.015625 1028.320312 183.441406 1029.523438 Z M 183.441406 1029.523438 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 170.6875 1030.246094 C 169.28125 1031.324219 167.273438 1031.058594 166.199219 1029.652344 C 165.121094 1028.25 165.390625 1026.242188 166.789062 1025.171875 C 168.195312 1024.09375 170.207031 1024.363281 171.28125 1025.765625 C 172.355469 1027.167969 172.085938 1029.175781 170.6875 1030.246094 Z M 170.6875 1030.246094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 161.125 1036.84375 C 159.890625 1037.789062 158.128906 1037.554688 157.179688 1036.320312 C 156.238281 1035.089844 156.472656 1033.328125 157.707031 1032.390625 C 158.9375 1031.445312 160.703125 1031.679688 161.644531 1032.910156 C 162.585938 1034.144531 162.351562 1035.90625 161.125 1036.84375 Z M 161.125 1036.84375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 156.238281 1047.160156 C 155.179688 1047.976562 153.660156 1047.773438 152.851562 1046.714844 C 152.039062 1045.660156 152.242188 1044.136719 153.300781 1043.324219 C 154.359375 1042.515625 155.878906 1042.71875 156.6875 1043.773438 C 157.503906 1044.835938 157.304688 1046.355469 156.238281 1047.160156 Z M 156.238281 1047.160156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 155.855469 1056.722656 C 154.96875 1057.398438 153.695312 1057.234375 153.015625 1056.339844 C 152.335938 1055.453125 152.503906 1054.179688 153.390625 1053.503906 C 154.28125 1052.824219 155.554688 1052.988281 156.234375 1053.875 C 156.914062 1054.769531 156.746094 1056.035156 155.855469 1056.722656 Z M 155.855469 1056.722656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 260.722656 664.972656 C 237.84375 670.679688 230.816406 705.390625 247.621094 710.011719 C 243.464844 705.367188 253.273438 695.621094 259.621094 703.84375 C 267.285156 713.777344 251.089844 727.796875 238.289062 713.023438 C 224.242188 696.808594 237.703125 664.972656 260.722656 664.972656 Z M 260.722656 664.972656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 254.628906 323.675781 C 271.21875 345.929688 244.203125 349.484375 245.105469 386.179688 C 232.925781 354.75 236.710938 331.988281 254.628906 323.675781 Z M 254.628906 323.675781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 945.371094 1000.496094 C 928.78125 978.242188 955.800781 974.6875 954.894531 937.992188 C 967.070312 969.421875 963.289062 992.183594 945.371094 1000.496094 Z M 945.371094 1000.496094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1013.515625 633.414062 C 1002.59375 624.351562 1022.234375 602.304688 1037.867188 620.140625 C 1050.0625 634.046875 1034.03125 660.820312 1010.714844 660.1875 C 1044.675781 642.792969 1020.421875 615.707031 1013.515625 633.414062 Z M 1013.515625 633.414062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1013.515625 690.757812 C 1002.59375 699.820312 1022.234375 721.867188 1037.867188 704.03125 C 1050.0625 690.125 1034.03125 663.351562 1010.714844 663.984375 C 1044.675781 681.378906 1020.421875 708.464844 1013.515625 690.757812 Z M 1013.515625 690.757812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1050.265625 678.636719 C 1037.929688 662.1875 1015.46875 662.085938 1015.46875 662.085938 C 1015.46875 662.085938 1037.929688 661.984375 1050.265625 645.535156 C 1063.140625 642.582031 1057.867188 656.289062 1075 662.085938 C 1057.867188 667.882812 1063.140625 681.589844 1050.265625 678.636719 Z M 1050.265625 678.636719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1000.683594 662.085938 C 1005.242188 661.800781 1013.1875 644.902344 1004.976562 634.300781 C 999.296875 657.1875 971.652344 661.234375 966.046875 662.085938 C 971.652344 662.9375 999.296875 666.984375 1004.976562 689.871094 C 1013.1875 679.269531 1005.242188 662.371094 1000.683594 662.085938 Z M 1000.683594 662.085938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1016.855469 549.734375 C 1009.949219 511.914062 982.8125 497.734375 968.066406 467.117188 C 951.726562 433.167969 960.875 394.617188 989.78125 382.09375 C 1007.441406 374.441406 1028.679688 383.78125 1032.335938 403.839844 C 1037.460938 431.933594 999.703125 443.199219 994.484375 421.613281 C 991.316406 408.5 1011.078125 406.625 1008.898438 416.976562 C 1020.585938 408.300781 1003.378906 390.546875 988.925781 401.097656 C 971.140625 414.082031 982.632812 454.136719 1016.75 446.664062 C 1037.808594 442.046875 1044.164062 419.40625 1041.394531 404.851562 C 1034.421875 368.207031 977.609375 358.089844 959.835938 405.15625 C 953.558594 391.949219 941.835938 394.703125 937.554688 398.910156 C 954.589844 408.011719 942.273438 433.824219 962.640625 472.773438 C 975.765625 497.886719 997.390625 504.304688 1016.855469 549.734375 Z M 1016.855469 549.734375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1000.234375 259.359375 C 988.648438 270.546875 989.179688 291.714844 1001.507812 311.667969 L 1002.6875 310.257812 C 993.183594 294.273438 990.933594 276.96875 1003.0625 264.941406 C 1016.992188 251.128906 1039.414062 269.21875 1029.054688 280.421875 C 1020.21875 289.976562 1008.273438 275.71875 1017.242188 270.867188 C 1008.421875 269.472656 1005.390625 284.140625 1016.722656 288.917969 C 1033.480469 295.984375 1050.703125 274.53125 1033.550781 257.753906 C 1022.96875 247.402344 1007.554688 252.289062 1000.234375 259.359375 Z M 1000.234375 259.359375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1046.484375 454.863281 C 1039.519531 448.765625 1039.234375 436.601562 1045.753906 424.808594 L 1046.464844 425.578125 C 1041.464844 435.019531 1040.65625 445.019531 1047.953125 451.582031 C 1056.335938 459.121094 1068.703125 448.113281 1062.445312 441.96875 C 1057.101562 436.730469 1050.640625 445.253906 1055.929688 447.789062 C 1050.90625 448.832031 1048.75 440.496094 1055.125 437.4375 C 1064.546875 432.910156 1075.035156 444.746094 1065.65625 454.855469 C 1059.863281 461.097656 1050.886719 458.71875 1046.484375 454.863281 Z M 1046.484375 454.863281 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1042.574219 464.8125 C 1048.402344 459.464844 1060.992188 406.542969 1024.75 380.300781 L 1024.75 381.378906 C 1050.875 407.328125 1049.503906 445.835938 1041.300781 463.824219 C 1041.300781 463.824219 1042.890625 463.628906 1042.574219 464.8125 Z M 1042.574219 464.8125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1026.179688 366.339844 C 1036.625 377.152344 1048.890625 357.75 1029.660156 348.703125 C 1018.109375 343.269531 1006.667969 346.675781 998.429688 351.082031 C 1002.699219 346.839844 1005.914062 342.015625 1007.554688 336.296875 C 1013.414062 315.949219 1000.476562 304.160156 988.25 301.804688 C 973.71875 299.019531 957.25 312.863281 963.757812 329.03125 C 967.851562 339.175781 987.417969 342.59375 988.988281 327.09375 C 977.785156 331.953125 972.933594 313.269531 987.554688 312.078125 C 997.140625 311.292969 1006.964844 323.757812 1002.117188 336.246094 C 994.925781 354.808594 958.0625 363.179688 959.152344 398.238281 C 972.730469 345.921875 1026.335938 348.765625 1026.179688 366.339844 Z M 1026.179688 366.339844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 963.15625 656.003906 C 972.851562 651.035156 1006.414062 625.082031 1002.910156 603.457031 C 1001.058594 592.019531 987.234375 593.523438 986.339844 598.234375 C 976.726562 578.582031 1004.59375 568.492188 1011.191406 588.542969 C 1029.066406 517.402344 955.503906 502.992188 944.75 542.027344 C 937.171875 569.566406 975.085938 583.171875 980.898438 563.082031 C 985.523438 547.046875 962.960938 546.652344 964.222656 557.15625 C 954.261719 546.296875 973.078125 529.664062 986.554688 541.441406 C 1003.125 555.953125 989.832031 596.523438 956.503906 586.058594 C 935.921875 579.613281 931.601562 556.496094 935.648438 542.242188 C 939.359375 529.132812 948.226562 520.144531 960.121094 516.375 C 950.242188 514.203125 943.421875 509.097656 944.34375 499.148438 C 945.78125 483.71875 966.261719 480.472656 967.660156 496.988281 C 963.753906 494.207031 955.71875 494.9375 956.066406 502.488281 C 956.75 517.703125 1003.382812 501.503906 1016.855469 549.734375 C 1024.269531 537.140625 1035.707031 540.925781 1039.601562 545.484375 C 1021.828125 553.046875 1034.480469 582.992188 1010.160156 619.601562 C 991.105469 648.28125 964.328125 655.691406 963.15625 656.003906 Z M 963.15625 656.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1015.167969 339.917969 C 1018.25 332.90625 1022.0625 322.457031 1013.546875 314.023438 C 1023.382812 308.90625 1027.023438 314.347656 1028.011719 317.757812 C 1031.503906 314.917969 1039.613281 310.265625 1045.855469 314.105469 C 1048.574219 320.90625 1042.609375 328.09375 1039.203125 331.050781 C 1042.402344 332.613281 1047.140625 337.121094 1040.421875 345.933594 C 1033.550781 336.105469 1022.601562 338.070312 1015.167969 339.917969 Z M 1015.167969 339.917969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1030.496094 484.394531 C 1030.496094 484.394531 1031.328125 484.441406 1032.675781 484.488281 C 1031.460938 484.804688 1030.730469 485.019531 1030.730469 485.019531 C 1030.730469 485.019531 1031.460938 485.230469 1032.675781 485.554688 C 1031.328125 485.601562 1030.496094 485.648438 1030.496094 485.648438 C 1030.496094 485.648438 1050.390625 498.859375 1056.210938 491.347656 C 1057.292969 489.941406 1057.078125 488.863281 1056 488.035156 C 1057.578125 487.496094 1058.558594 486.542969 1058.558594 485.019531 C 1058.558594 483.5 1057.578125 482.546875 1056 482.003906 C 1057.078125 481.179688 1057.292969 480.101562 1056.210938 478.695312 C 1050.390625 471.183594 1030.496094 484.394531 1030.496094 484.394531 Z M 1030.496094 484.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1019.398438 484.394531 C 1019.398438 484.394531 1018.566406 484.441406 1017.21875 484.488281 C 1018.433594 484.804688 1019.164062 485.019531 1019.164062 485.019531 C 1019.164062 485.019531 1018.433594 485.230469 1017.21875 485.554688 C 1018.566406 485.601562 1019.398438 485.648438 1019.398438 485.648438 C 1019.398438 485.648438 999.5 498.859375 993.683594 491.347656 C 992.601562 489.941406 992.816406 488.863281 993.894531 488.035156 C 992.316406 487.496094 991.332031 486.542969 991.332031 485.019531 C 991.332031 483.5 992.316406 482.546875 993.894531 482.003906 C 992.816406 481.179688 992.601562 480.101562 993.683594 478.695312 C 999.5 471.183594 1019.398438 484.394531 1019.398438 484.394531 Z M 1019.398438 484.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1029.296875 480.460938 C 1029.472656 480.152344 1029.710938 479.71875 1030.027344 479.171875 C 1029.179688 479.988281 1028.59375 480.503906 1028.4375 480.625 C 1028.535156 480.097656 1030.339844 471.488281 1033.847656 465.152344 C 1036.074219 461.121094 1038.980469 458 1042.585938 458.457031 C 1043.054688 458.515625 1043.453125 458.621094 1043.765625 458.800781 C 1044.613281 459.207031 1044.90625 459.964844 1044.765625 460.945312 C 1046.273438 460.210938 1047.628906 460.195312 1048.71875 461.269531 C 1049.789062 462.34375 1049.785156 463.710938 1049.035156 465.207031 C 1049.902344 465.097656 1050.582031 465.304688 1051.03125 465.941406 C 1051.273438 466.292969 1051.449219 466.78125 1051.527344 467.40625 C 1052.726562 476.824219 1029.3125 481.550781 1029.3125 481.550781 C 1029.3125 481.550781 1029.8125 480.976562 1030.667969 480.066406 C 1030.710938 480.03125 1030.75 479.988281 1030.789062 479.933594 C 1030.667969 479.996094 1030.554688 480.066406 1030.453125 480.136719 C 1029.566406 480.652344 1029.039062 480.941406 1029.039062 480.941406 C 1029.039062 480.941406 1029.132812 480.777344 1029.296875 480.460938 Z M 1029.296875 480.460938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1020.578125 488.496094 C 1020.578125 488.496094 1020.023438 489.117188 1019.105469 490.105469 C 1020.1875 489.472656 1020.859375 489.105469 1020.859375 489.105469 C 1020.859375 489.105469 1020.492188 489.777344 1019.859375 490.859375 C 1020.847656 489.941406 1021.46875 489.386719 1021.46875 489.386719 C 1021.46875 489.386719 1016.742188 512.785156 1007.320312 511.585938 C 1005.558594 511.359375 1004.949219 510.441406 1005.121094 509.089844 C 1003.621094 509.832031 1002.257812 509.851562 1001.179688 508.773438 C 1000.101562 507.695312 1000.121094 506.332031 1000.859375 504.832031 C 999.511719 505.003906 998.59375 504.402344 998.367188 502.636719 C 997.167969 493.214844 1020.578125 488.496094 1020.578125 488.496094 Z M 1020.578125 488.496094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.320312 479.472656 C 1024.320312 479.472656 1024.363281 478.644531 1024.414062 477.292969 C 1024.738281 478.507812 1024.945312 479.238281 1024.945312 479.238281 C 1024.945312 479.238281 1025.164062 478.507812 1025.484375 477.292969 C 1025.527344 478.644531 1025.582031 479.472656 1025.582031 479.472656 C 1025.582031 479.472656 1038.789062 459.589844 1031.277344 453.78125 C 1029.871094 452.695312 1028.792969 452.914062 1027.96875 453.992188 C 1027.421875 452.402344 1026.464844 451.429688 1024.945312 451.429688 C 1023.425781 451.429688 1022.46875 452.402344 1021.9375 453.992188 C 1021.101562 452.914062 1020.023438 452.695312 1018.617188 453.78125 C 1011.101562 459.589844 1024.320312 479.472656 1024.320312 479.472656 Z M 1024.320312 479.472656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.320312 490.570312 C 1024.320312 490.570312 1024.363281 491.398438 1024.414062 492.746094 C 1024.738281 491.535156 1024.945312 490.796875 1024.945312 490.796875 C 1024.945312 490.796875 1025.164062 491.535156 1025.484375 492.746094 C 1025.527344 491.398438 1025.582031 490.570312 1025.582031 490.570312 C 1025.582031 490.570312 1038.789062 510.449219 1031.277344 516.261719 C 1029.871094 517.347656 1028.792969 517.128906 1027.96875 516.050781 C 1027.421875 517.632812 1026.464844 518.609375 1024.945312 518.609375 C 1023.425781 518.609375 1022.46875 517.632812 1021.9375 516.050781 C 1021.101562 517.128906 1020.023438 517.347656 1018.617188 516.261719 C 1011.101562 510.449219 1024.320312 490.570312 1024.320312 490.570312 Z M 1024.320312 490.570312 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1020.578125 481.542969 C 1020.578125 481.542969 1020.023438 480.925781 1019.105469 479.9375 C 1020.1875 480.566406 1020.859375 480.933594 1020.859375 480.933594 C 1020.859375 480.933594 1020.492188 480.265625 1019.859375 479.179688 C 1020.847656 480.101562 1021.46875 480.652344 1021.46875 480.652344 C 1021.46875 480.652344 1016.742188 457.253906 1007.320312 458.457031 C 1005.558594 458.683594 1004.949219 459.601562 1005.121094 460.945312 C 1003.621094 460.210938 1002.257812 460.191406 1001.179688 461.269531 C 1000.101562 462.347656 1000.121094 463.707031 1000.859375 465.210938 C 999.511719 465.035156 998.59375 465.640625 998.367188 467.402344 C 997.167969 476.820312 1020.578125 481.542969 1020.578125 481.542969 Z M 1020.578125 481.542969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1028.429688 489.386719 C 1028.429688 489.386719 1029.046875 489.941406 1030.035156 490.859375 C 1029.402344 489.777344 1029.039062 489.105469 1029.039062 489.105469 C 1029.039062 489.105469 1029.707031 489.472656 1030.789062 490.105469 C 1029.871094 489.117188 1029.3125 488.496094 1029.3125 488.496094 C 1029.3125 488.496094 1052.734375 493.214844 1051.527344 502.636719 C 1051.304688 504.402344 1050.378906 505.003906 1049.035156 504.832031 C 1049.773438 506.332031 1049.789062 507.695312 1048.714844 508.773438 C 1047.636719 509.851562 1046.273438 509.832031 1044.773438 509.089844 C 1044.945312 510.441406 1044.335938 511.359375 1042.574219 511.585938 C 1033.152344 512.785156 1028.429688 489.386719 1028.429688 489.386719 Z M 1028.429688 489.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.945312 487.777344 C 1023.425781 487.777344 1022.195312 486.542969 1022.195312 485.019531 C 1022.195312 483.5 1023.425781 482.265625 1024.945312 482.265625 C 1026.464844 482.265625 1027.710938 483.5 1027.710938 485.019531 C 1027.710938 486.542969 1026.464844 487.777344 1024.945312 487.777344 Z M 1024.945312 487.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 947.929688 597.375 C 948.019531 594.761719 945.980469 592.574219 943.363281 592.480469 C 940.753906 592.386719 938.558594 594.421875 938.464844 597.039062 C 938.371094 599.648438 940.414062 601.839844 943.027344 601.933594 C 945.640625 602.027344 947.835938 599.984375 947.929688 597.375 Z M 947.929688 597.375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 950.78125 596.789062 C 950.691406 599.164062 952.5625 601.160156 954.9375 601.246094 C 957.320312 601.332031 959.316406 599.476562 959.40625 597.09375 C 959.480469 594.71875 957.625 592.722656 955.242188 592.632812 C 952.867188 592.550781 950.863281 594.410156 950.78125 596.789062 Z M 950.78125 596.789062 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 962.726562 603.707031 C 962.65625 605.851562 964.335938 607.652344 966.476562 607.730469 C 968.625 607.804688 970.421875 606.128906 970.5 603.988281 C 970.578125 601.839844 968.902344 600.042969 966.757812 599.960938 C 964.613281 599.886719 962.808594 601.558594 962.726562 603.707031 Z M 962.726562 603.707031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 970.09375 615.40625 C 970.023438 617.320312 971.515625 618.925781 973.425781 618.992188 C 975.347656 619.0625 976.945312 617.566406 977.011719 615.65625 C 977.082031 613.742188 975.589844 612.140625 973.679688 612.070312 C 971.765625 612.003906 970.164062 613.5 970.09375 615.40625 Z M 970.09375 615.40625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 971.9375 627.613281 C 971.886719 629.289062 973.199219 630.695312 974.878906 630.757812 C 976.550781 630.816406 977.96875 629.507812 978.027344 627.828125 C 978.089844 626.152344 976.773438 624.742188 975.09375 624.683594 C 973.414062 624.625 972.003906 625.933594 971.9375 627.613281 Z M 971.9375 627.613281 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 969.898438 639.804688 C 969.847656 641.25 970.976562 642.460938 972.425781 642.511719 C 973.871094 642.566406 975.085938 641.4375 975.136719 639.992188 C 975.1875 638.542969 974.058594 637.335938 972.609375 637.285156 C 971.171875 637.226562 969.953125 638.355469 969.898438 639.804688 Z M 969.898438 639.804688 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 964.195312 648.003906 C 964.152344 649.214844 965.109375 650.230469 966.3125 650.273438 C 967.527344 650.320312 968.546875 649.375 968.59375 648.160156 C 968.632812 646.949219 967.6875 645.933594 966.472656 645.890625 C 965.265625 645.84375 964.238281 646.792969 964.195312 648.003906 Z M 964.195312 648.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1004.898438 285.386719 C 1002.980469 286.851562 1000.234375 286.5 998.765625 284.578125 C 997.296875 282.664062 997.664062 279.921875 999.582031 278.445312 C 1001.503906 276.980469 1004.246094 277.339844 1005.710938 279.261719 C 1007.179688 281.175781 1006.8125 283.917969 1004.898438 285.386719 Z M 1004.898438 285.386719 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1004.949219 289.355469 C 1006.691406 288.015625 1009.195312 288.347656 1010.53125 290.097656 C 1011.867188 291.84375 1011.539062 294.335938 1009.796875 295.671875 C 1008.046875 297.003906 1005.546875 296.679688 1004.207031 294.933594 C 1002.871094 293.1875 1003.203125 290.6875 1004.949219 289.355469 Z M 1004.949219 289.355469 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1016.558594 294.648438 C 1018.140625 293.441406 1020.390625 293.734375 1021.59375 295.3125 C 1022.804688 296.882812 1022.507812 299.140625 1020.929688 300.347656 C 1019.351562 301.554688 1017.097656 301.25 1015.890625 299.679688 C 1014.683594 298.101562 1014.984375 295.851562 1016.558594 294.648438 Z M 1016.558594 294.648438 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1029.3125 293.925781 C 1030.71875 292.847656 1032.726562 293.113281 1033.804688 294.519531 C 1034.882812 295.921875 1034.609375 297.929688 1033.203125 299 C 1031.808594 300.078125 1029.792969 299.808594 1028.714844 298.40625 C 1027.644531 297.003906 1027.90625 294.996094 1029.3125 293.925781 Z M 1029.3125 293.925781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1038.875 287.328125 C 1040.109375 286.382812 1041.871094 286.617188 1042.820312 287.851562 C 1043.765625 289.082031 1043.53125 290.84375 1042.296875 291.78125 C 1041.066406 292.726562 1039.304688 292.492188 1038.355469 291.261719 C 1037.410156 290.027344 1037.644531 288.265625 1038.875 287.328125 Z M 1038.875 287.328125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1043.757812 277.003906 C 1044.816406 276.195312 1046.335938 276.398438 1047.148438 277.457031 C 1047.960938 278.511719 1047.757812 280.035156 1046.699219 280.847656 C 1045.640625 281.65625 1044.121094 281.453125 1043.304688 280.398438 C 1042.496094 279.335938 1042.695312 277.824219 1043.757812 277.003906 Z M 1043.757812 277.003906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1044.144531 267.449219 C 1045.03125 266.773438 1046.3125 266.9375 1046.984375 267.832031 C 1047.664062 268.71875 1047.496094 269.984375 1046.609375 270.667969 C 1045.71875 271.347656 1044.449219 271.183594 1043.765625 270.289062 C 1043.085938 269.402344 1043.253906 268.136719 1044.144531 267.449219 Z M 1044.144531 267.449219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 939.277344 659.199219 C 962.15625 653.492188 969.179688 618.78125 952.378906 614.160156 C 956.535156 618.804688 946.726562 628.550781 940.378906 620.328125 C 932.714844 610.394531 948.914062 596.375 961.710938 611.148438 C 975.765625 627.363281 962.292969 659.199219 939.277344 659.199219 Z M 939.277344 659.199219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1016.855469 774.4375 C 1009.949219 812.257812 982.8125 826.4375 968.066406 857.054688 C 951.726562 891.003906 960.875 929.554688 989.78125 942.078125 C 1007.441406 949.730469 1028.679688 940.390625 1032.335938 920.332031 C 1037.460938 892.238281 999.703125 880.972656 994.484375 902.558594 C 991.316406 915.671875 1011.078125 917.546875 1008.898438 907.195312 C 1020.585938 915.871094 1003.378906 933.625 988.925781 923.074219 C 971.140625 910.089844 982.632812 870.035156 1016.75 877.507812 C 1037.808594 882.125 1044.164062 904.765625 1041.394531 919.320312 C 1034.421875 955.964844 977.609375 966.082031 959.835938 919.015625 C 953.558594 932.222656 941.835938 929.46875 937.554688 925.261719 C 954.589844 916.160156 942.273438 890.347656 962.640625 851.398438 C 975.765625 826.285156 997.390625 819.867188 1016.855469 774.4375 Z M 1016.855469 774.4375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1000.234375 1064.8125 C 988.648438 1053.625 989.179688 1032.457031 1001.507812 1012.503906 L 1002.6875 1013.914062 C 993.183594 1029.898438 990.933594 1047.203125 1003.0625 1059.230469 C 1016.992188 1073.042969 1039.414062 1054.953125 1029.054688 1043.75 C 1020.21875 1034.195312 1008.273438 1048.453125 1017.242188 1053.304688 C 1008.421875 1054.695312 1005.390625 1040.03125 1016.722656 1035.253906 C 1033.480469 1028.183594 1050.703125 1049.640625 1033.550781 1066.417969 C 1022.96875 1076.769531 1007.554688 1071.886719 1000.234375 1064.8125 Z M 1000.234375 1064.8125 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1046.484375 869.308594 C 1039.519531 875.40625 1039.234375 887.570312 1045.753906 899.363281 L 1046.464844 898.59375 C 1041.464844 889.152344 1040.65625 879.152344 1047.953125 872.589844 C 1056.335938 865.050781 1068.703125 876.058594 1062.445312 882.203125 C 1057.101562 887.441406 1050.640625 878.917969 1055.929688 876.382812 C 1050.90625 875.339844 1048.75 883.675781 1055.125 886.734375 C 1064.546875 891.261719 1075.035156 879.425781 1065.65625 869.316406 C 1059.863281 863.074219 1050.886719 865.453125 1046.484375 869.308594 Z M 1046.484375 869.308594 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1042.574219 859.359375 C 1048.402344 864.707031 1060.992188 917.628906 1024.75 943.871094 L 1024.75 942.792969 C 1050.875 916.84375 1049.503906 878.335938 1041.300781 860.347656 C 1041.300781 860.347656 1042.890625 860.542969 1042.574219 859.359375 Z M 1042.574219 859.359375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1026.179688 957.832031 C 1036.625 947.019531 1048.890625 966.421875 1029.660156 975.46875 C 1018.109375 980.902344 1006.667969 977.496094 998.429688 973.089844 C 1002.699219 977.332031 1005.914062 982.15625 1007.554688 987.875 C 1013.414062 1008.222656 1000.476562 1020.011719 988.25 1022.367188 C 973.71875 1025.140625 957.25 1011.308594 963.757812 995.140625 C 967.851562 984.996094 987.417969 981.578125 988.988281 997.078125 C 977.785156 992.21875 972.933594 1010.902344 987.554688 1012.09375 C 997.140625 1012.878906 1006.964844 1000.414062 1002.117188 987.925781 C 994.925781 969.363281 958.0625 960.992188 959.152344 925.933594 C 972.730469 978.25 1026.335938 975.40625 1026.179688 957.832031 Z M 1026.179688 957.832031 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 963.15625 668.167969 C 972.851562 673.136719 1006.414062 699.089844 1002.910156 720.714844 C 1001.058594 732.152344 987.234375 730.648438 986.339844 725.9375 C 976.726562 745.589844 1004.59375 755.679688 1011.191406 735.628906 C 1029.066406 806.769531 955.503906 821.179688 944.75 782.144531 C 937.171875 754.605469 975.085938 741 980.898438 761.089844 C 985.523438 777.125 962.960938 777.519531 964.222656 767.015625 C 954.261719 777.875 973.078125 794.507812 986.554688 782.730469 C 1003.125 768.21875 989.832031 727.648438 956.503906 738.113281 C 935.921875 744.558594 931.601562 767.675781 935.648438 781.929688 C 939.359375 795.039062 948.226562 804.027344 960.121094 807.796875 C 950.242188 809.972656 943.421875 815.074219 944.34375 825.023438 C 945.78125 840.457031 966.261719 843.699219 967.660156 827.183594 C 963.753906 829.964844 955.71875 829.234375 956.066406 821.6875 C 956.75 806.46875 1003.382812 822.667969 1016.855469 774.4375 C 1024.269531 787.03125 1035.707031 783.246094 1039.601562 778.6875 C 1021.828125 771.125 1034.480469 741.179688 1010.160156 704.570312 C 991.105469 675.890625 964.328125 668.480469 963.15625 668.167969 Z M 963.15625 668.167969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1015.167969 984.253906 C 1018.25 991.265625 1022.0625 1001.714844 1013.546875 1010.148438 C 1023.382812 1015.257812 1027.023438 1009.824219 1028.011719 1006.414062 C 1031.503906 1009.253906 1039.613281 1013.90625 1045.855469 1010.066406 C 1048.574219 1003.265625 1042.609375 996.078125 1039.203125 993.121094 C 1042.402344 991.558594 1047.140625 987.050781 1040.421875 978.238281 C 1033.550781 988.066406 1022.601562 986.101562 1015.167969 984.253906 Z M 1015.167969 984.253906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1030.496094 839.777344 C 1030.496094 839.777344 1031.328125 839.730469 1032.675781 839.683594 C 1031.460938 839.367188 1030.730469 839.152344 1030.730469 839.152344 C 1030.730469 839.152344 1031.460938 838.941406 1032.675781 838.617188 C 1031.328125 838.570312 1030.496094 838.523438 1030.496094 838.523438 C 1030.496094 838.523438 1050.390625 825.3125 1056.210938 832.824219 C 1057.292969 834.230469 1057.078125 835.308594 1056 836.136719 C 1057.578125 836.675781 1058.558594 837.628906 1058.558594 839.152344 C 1058.558594 840.671875 1057.578125 841.625 1056 842.167969 C 1057.078125 842.992188 1057.292969 844.070312 1056.210938 845.476562 C 1050.390625 852.988281 1030.496094 839.777344 1030.496094 839.777344 Z M 1030.496094 839.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1019.398438 839.777344 C 1019.398438 839.777344 1018.566406 839.730469 1017.21875 839.683594 C 1018.433594 839.367188 1019.164062 839.152344 1019.164062 839.152344 C 1019.164062 839.152344 1018.433594 838.941406 1017.21875 838.617188 C 1018.566406 838.570312 1019.398438 838.523438 1019.398438 838.523438 C 1019.398438 838.523438 999.5 825.3125 993.683594 832.824219 C 992.601562 834.230469 992.816406 835.308594 993.894531 836.136719 C 992.316406 836.675781 991.332031 837.628906 991.332031 839.152344 C 991.332031 840.671875 992.316406 841.625 993.894531 842.167969 C 992.816406 842.992188 992.601562 844.070312 993.683594 845.476562 C 999.5 852.988281 1019.398438 839.777344 1019.398438 839.777344 Z M 1019.398438 839.777344 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1029.296875 843.710938 C 1029.472656 844.019531 1029.710938 844.453125 1030.027344 845 C 1029.179688 844.183594 1028.59375 843.667969 1028.4375 843.546875 C 1028.535156 844.074219 1030.339844 852.683594 1033.847656 859.019531 C 1036.074219 863.050781 1038.980469 866.171875 1042.585938 865.714844 C 1043.054688 865.65625 1043.453125 865.550781 1043.765625 865.371094 C 1044.613281 864.964844 1044.90625 864.207031 1044.765625 863.226562 C 1046.273438 863.960938 1047.628906 863.976562 1048.71875 862.902344 C 1049.789062 861.828125 1049.785156 860.460938 1049.035156 858.964844 C 1049.902344 859.074219 1050.582031 858.867188 1051.03125 858.230469 C 1051.273438 857.878906 1051.449219 857.390625 1051.527344 856.765625 C 1052.726562 847.347656 1029.3125 842.621094 1029.3125 842.621094 C 1029.3125 842.621094 1029.8125 843.195312 1030.667969 844.105469 C 1030.710938 844.140625 1030.75 844.183594 1030.789062 844.238281 C 1030.667969 844.175781 1030.554688 844.105469 1030.453125 844.035156 C 1029.566406 843.519531 1029.039062 843.230469 1029.039062 843.230469 C 1029.039062 843.230469 1029.132812 843.394531 1029.296875 843.710938 Z M 1029.296875 843.710938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1020.578125 835.675781 C 1020.578125 835.675781 1020.023438 835.054688 1019.105469 834.066406 C 1020.1875 834.699219 1020.859375 835.066406 1020.859375 835.066406 C 1020.859375 835.066406 1020.492188 834.394531 1019.859375 833.3125 C 1020.847656 834.230469 1021.46875 834.785156 1021.46875 834.785156 C 1021.46875 834.785156 1016.742188 811.386719 1007.320312 812.585938 C 1005.558594 812.8125 1004.949219 813.730469 1005.121094 815.082031 C 1003.621094 814.339844 1002.257812 814.320312 1001.179688 815.398438 C 1000.101562 816.476562 1000.121094 817.839844 1000.859375 819.34375 C 999.511719 819.167969 998.59375 819.769531 998.367188 821.535156 C 997.167969 830.957031 1020.578125 835.675781 1020.578125 835.675781 Z M 1020.578125 835.675781 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.320312 844.699219 C 1024.320312 844.699219 1024.363281 845.527344 1024.414062 846.878906 C 1024.738281 845.664062 1024.945312 844.933594 1024.945312 844.933594 C 1024.945312 844.933594 1025.164062 845.664062 1025.484375 846.878906 C 1025.527344 845.527344 1025.582031 844.699219 1025.582031 844.699219 C 1025.582031 844.699219 1038.789062 864.582031 1031.277344 870.390625 C 1029.871094 871.476562 1028.792969 871.257812 1027.96875 870.179688 C 1027.421875 871.769531 1026.464844 872.742188 1024.945312 872.742188 C 1023.425781 872.742188 1022.46875 871.769531 1021.9375 870.179688 C 1021.101562 871.257812 1020.023438 871.476562 1018.617188 870.390625 C 1011.101562 864.582031 1024.320312 844.699219 1024.320312 844.699219 Z M 1024.320312 844.699219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.320312 833.601562 C 1024.320312 833.601562 1024.363281 832.773438 1024.414062 831.425781 C 1024.738281 832.636719 1024.945312 833.375 1024.945312 833.375 C 1024.945312 833.375 1025.164062 832.636719 1025.484375 831.425781 C 1025.527344 832.773438 1025.582031 833.601562 1025.582031 833.601562 C 1025.582031 833.601562 1038.789062 813.722656 1031.277344 807.910156 C 1029.871094 806.824219 1028.792969 807.042969 1027.96875 808.121094 C 1027.421875 806.539062 1026.464844 805.5625 1024.945312 805.5625 C 1023.425781 805.5625 1022.46875 806.539062 1021.9375 808.121094 C 1021.101562 807.042969 1020.023438 806.824219 1018.617188 807.910156 C 1011.101562 813.722656 1024.320312 833.601562 1024.320312 833.601562 Z M 1024.320312 833.601562 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1020.578125 842.628906 C 1020.578125 842.628906 1020.023438 843.246094 1019.105469 844.234375 C 1020.1875 843.605469 1020.859375 843.238281 1020.859375 843.238281 C 1020.859375 843.238281 1020.492188 843.90625 1019.859375 844.992188 C 1020.847656 844.070312 1021.46875 843.519531 1021.46875 843.519531 C 1021.46875 843.519531 1016.742188 866.917969 1007.320312 865.714844 C 1005.558594 865.488281 1004.949219 864.570312 1005.121094 863.226562 C 1003.621094 863.960938 1002.257812 863.980469 1001.179688 862.902344 C 1000.101562 861.824219 1000.121094 860.464844 1000.859375 858.960938 C 999.511719 859.136719 998.59375 858.53125 998.367188 856.769531 C 997.167969 847.351562 1020.578125 842.628906 1020.578125 842.628906 Z M 1020.578125 842.628906 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1028.429688 834.785156 C 1028.429688 834.785156 1029.046875 834.230469 1030.035156 833.3125 C 1029.402344 834.394531 1029.039062 835.066406 1029.039062 835.066406 C 1029.039062 835.066406 1029.707031 834.699219 1030.789062 834.066406 C 1029.871094 835.054688 1029.3125 835.675781 1029.3125 835.675781 C 1029.3125 835.675781 1052.734375 830.957031 1051.527344 821.535156 C 1051.304688 819.769531 1050.378906 819.167969 1049.035156 819.34375 C 1049.773438 817.839844 1049.789062 816.476562 1048.714844 815.398438 C 1047.636719 814.320312 1046.273438 814.339844 1044.773438 815.082031 C 1044.945312 813.730469 1044.335938 812.8125 1042.574219 812.585938 C 1033.152344 811.386719 1028.429688 834.785156 1028.429688 834.785156 Z M 1028.429688 834.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1024.945312 836.394531 C 1023.425781 836.394531 1022.195312 837.628906 1022.195312 839.152344 C 1022.195312 840.671875 1023.425781 841.90625 1024.945312 841.90625 C 1026.464844 841.90625 1027.710938 840.671875 1027.710938 839.152344 C 1027.710938 837.628906 1026.464844 836.394531 1024.945312 836.394531 Z M 1024.945312 836.394531 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 947.929688 726.796875 C 948.019531 729.410156 945.980469 731.597656 943.363281 731.691406 C 940.753906 731.785156 938.558594 729.75 938.464844 727.132812 C 938.371094 724.523438 940.414062 722.332031 943.027344 722.238281 C 945.640625 722.144531 947.835938 724.1875 947.929688 726.796875 Z M 947.929688 726.796875 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 950.78125 727.382812 C 950.691406 725.007812 952.5625 723.011719 954.9375 722.925781 C 957.320312 722.839844 959.316406 724.695312 959.40625 727.078125 C 959.480469 729.453125 957.625 731.449219 955.242188 731.539062 C 952.867188 731.621094 950.863281 729.761719 950.78125 727.382812 Z M 950.78125 727.382812 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 962.726562 720.464844 C 962.65625 718.320312 964.335938 716.519531 966.476562 716.441406 C 968.625 716.367188 970.421875 718.042969 970.5 720.183594 C 970.578125 722.332031 968.902344 724.128906 966.757812 724.210938 C 964.613281 724.285156 962.808594 722.613281 962.726562 720.464844 Z M 962.726562 720.464844 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 970.09375 708.765625 C 970.023438 706.851562 971.515625 705.246094 973.425781 705.179688 C 975.347656 705.109375 976.945312 706.605469 977.011719 708.515625 C 977.082031 710.429688 975.589844 712.03125 973.679688 712.101562 C 971.765625 712.167969 970.164062 710.675781 970.09375 708.765625 Z M 970.09375 708.765625 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 971.9375 696.558594 C 971.886719 694.882812 973.199219 693.476562 974.878906 693.414062 C 976.550781 693.355469 977.96875 694.664062 978.027344 696.34375 C 978.089844 698.019531 976.773438 699.429688 975.09375 699.492188 C 973.414062 699.546875 972.003906 698.238281 971.9375 696.558594 Z M 971.9375 696.558594 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 969.898438 684.367188 C 969.847656 682.921875 970.976562 681.710938 972.425781 681.660156 C 973.871094 681.605469 975.085938 682.734375 975.136719 684.179688 C 975.1875 685.628906 974.058594 686.835938 972.609375 686.886719 C 971.171875 686.945312 969.953125 685.816406 969.898438 684.367188 Z M 969.898438 684.367188 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 964.195312 676.167969 C 964.152344 674.957031 965.109375 673.941406 966.3125 673.898438 C 967.527344 673.851562 968.546875 674.796875 968.59375 676.011719 C 968.632812 677.222656 967.6875 678.238281 966.472656 678.28125 C 965.265625 678.328125 964.238281 677.378906 964.195312 676.167969 Z M 964.195312 676.167969 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1004.898438 1038.785156 C 1002.980469 1037.320312 1000.234375 1037.675781 998.765625 1039.59375 C 997.296875 1041.507812 997.664062 1044.25 999.582031 1045.71875 C 1001.503906 1047.191406 1004.246094 1046.832031 1005.710938 1044.910156 C 1007.179688 1042.996094 1006.8125 1040.253906 1004.898438 1038.785156 Z M 1004.898438 1038.785156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1004.949219 1034.816406 C 1006.691406 1036.15625 1009.195312 1035.824219 1010.53125 1034.082031 C 1011.867188 1032.328125 1011.539062 1029.835938 1009.796875 1028.492188 C 1008.046875 1027.15625 1005.546875 1027.492188 1004.207031 1029.238281 C 1002.871094 1030.984375 1003.203125 1033.484375 1004.949219 1034.816406 Z M 1004.949219 1034.816406 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1016.558594 1029.523438 C 1018.140625 1030.730469 1020.390625 1030.4375 1021.59375 1028.859375 C 1022.804688 1027.289062 1022.507812 1025.03125 1020.929688 1023.824219 C 1019.351562 1022.617188 1017.097656 1022.921875 1015.890625 1024.492188 C 1014.683594 1026.070312 1014.984375 1028.320312 1016.558594 1029.523438 Z M 1016.558594 1029.523438 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1029.3125 1030.246094 C 1030.71875 1031.324219 1032.726562 1031.058594 1033.804688 1029.652344 C 1034.882812 1028.25 1034.609375 1026.242188 1033.203125 1025.171875 C 1031.808594 1024.09375 1029.792969 1024.363281 1028.714844 1025.765625 C 1027.644531 1027.167969 1027.90625 1029.175781 1029.3125 1030.246094 Z M 1029.3125 1030.246094 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1038.875 1036.84375 C 1040.109375 1037.789062 1041.871094 1037.554688 1042.820312 1036.320312 C 1043.765625 1035.089844 1043.53125 1033.328125 1042.296875 1032.390625 C 1041.066406 1031.445312 1039.304688 1031.679688 1038.355469 1032.910156 C 1037.410156 1034.144531 1037.644531 1035.90625 1038.875 1036.84375 Z M 1038.875 1036.84375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1043.757812 1047.160156 C 1044.816406 1047.976562 1046.335938 1047.773438 1047.148438 1046.714844 C 1047.960938 1045.660156 1047.757812 1044.136719 1046.699219 1043.324219 C 1045.640625 1042.515625 1044.121094 1042.71875 1043.304688 1043.773438 C 1042.496094 1044.835938 1042.695312 1046.355469 1043.757812 1047.160156 Z M 1043.757812 1047.160156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 1044.144531 1056.722656 C 1045.03125 1057.398438 1046.3125 1057.234375 1046.984375 1056.339844 C 1047.664062 1055.453125 1047.496094 1054.179688 1046.609375 1053.503906 C 1045.71875 1052.824219 1044.449219 1052.988281 1043.765625 1053.875 C 1043.085938 1054.769531 1043.253906 1056.035156 1044.144531 1056.722656 Z M 1044.144531 1056.722656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 939.277344 664.972656 C 962.15625 670.679688 969.179688 705.390625 952.378906 710.011719 C 956.535156 705.367188 946.726562 695.621094 940.378906 703.84375 C 932.714844 713.777344 948.914062 727.796875 961.710938 713.023438 C 975.765625 696.808594 962.292969 664.972656 939.277344 664.972656 Z M 939.277344 664.972656 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 945.371094 323.675781 C 928.78125 345.929688 955.800781 349.484375 954.894531 386.179688 C 967.070312 354.75 963.289062 331.988281 945.371094 323.675781 Z M 945.371094 323.675781 "
        />

        {/*  */}

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 287.5 349.78125 L 912.5 349.78125 L 912.5 974.390625 L 287.5 974.390625 Z M 287.5 349.78125 "
        />

        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="600"
          y="1290.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
