import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const cardsFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } =
    props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" stroke="#BFBFBF" />
      <g opacity="0.95">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0034 16.012C19.8071 15.9609 20.5886 16.0727 21.348 16.3474C32.0395 19.2221 42.731 22.0968 53.4225 24.9715C55.373 25.5562 56.264 26.8818 56.0954 28.9482C54.7532 33.9862 53.4402 39.0329 52.1564 44.0884C52.1252 49.4865 52.0938 54.8846 52.0626 60.2826C51.6812 61.8862 50.6964 62.8285 49.1084 63.1094C37.0727 63.1734 25.0368 63.1734 13.0011 63.1094C11.3286 62.806 10.3439 61.7998 10.0469 60.091C9.98437 52.1695 9.98437 44.2481 10.0469 36.3267C10.2201 35.1613 10.7985 34.2828 11.7819 33.6915C11.9836 33.5563 12.2024 33.4764 12.4384 33.452C13.752 28.371 15.0806 23.2923 16.4243 18.216C16.9314 17.0424 17.7911 16.3078 19.0034 16.012ZM19.4723 18.1201C30.7676 21.1251 42.053 24.1755 53.3287 27.2713C53.781 27.5718 54.0312 28.003 54.079 28.5649C53.8837 29.1312 53.7274 29.7061 53.6101 30.2898C41.7306 27.1276 29.8512 23.9654 17.9717 20.8032C18.0702 20.1131 18.2422 19.4424 18.4875 18.7909C18.7409 18.4314 19.0691 18.2078 19.4723 18.1201ZM17.409 22.8155C29.2538 25.9927 41.1019 29.1549 52.9536 32.3021C52.7014 33.5565 52.4044 34.8022 52.0626 36.0392C51.9012 35.2682 51.5417 34.6134 50.9841 34.0748C50.471 33.8147 49.9395 33.591 49.3897 33.404C46.6107 33.2809 43.8284 33.217 41.0429 33.2124C32.8876 30.9935 24.7283 28.7896 16.565 26.6005C16.516 26.5468 16.4847 26.4829 16.4712 26.4089C16.7755 25.2053 17.0881 24.0074 17.409 22.8155ZM15.9085 28.7566C21.5412 30.2114 27.1683 31.6967 32.7898 33.2124C26.7876 33.2603 20.7854 33.2763 14.783 33.2603C15.1145 31.7459 15.4896 30.2447 15.9085 28.7566ZM13.0949 35.4643C25.0994 35.4483 37.1039 35.4643 49.1084 35.5122C49.5705 35.6968 49.8675 36.0322 49.9993 36.5183C50.0306 36.9655 50.0619 37.4126 50.0931 37.8599C50.1557 45.2064 50.1557 52.5528 50.0931 59.8993C49.8995 60.5445 49.4775 60.9118 48.827 61.0013C37.0413 61.0652 25.2557 61.0652 13.47 61.0013C12.6416 60.9215 12.1883 60.4583 12.1102 59.6119C12.0476 52.0418 12.0476 44.4717 12.1102 36.9016C12.166 36.2226 12.4942 35.7435 13.0949 35.4643Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9534 37.9321C21.185 37.938 22.2182 38.3969 23.053 39.309C24.6162 37.8262 26.3382 37.5967 28.219 38.6206C29.7058 39.8116 30.2299 41.3569 29.7913 43.2563C28.7291 45.7492 26.9173 46.5906 24.3557 45.7807C23.8436 45.5499 23.3944 45.2286 23.0081 44.8169C20.8722 46.6021 18.8208 46.5103 16.8538 44.5415C15.8104 42.807 15.8403 41.0934 16.9436 39.4008C17.7643 38.4995 18.7675 38.0099 19.9534 37.9321ZM19.594 40.0434C18.6953 40.367 18.2161 41.0249 18.1565 42.017C18.2848 43.2345 18.9437 43.9229 20.1331 44.0825C21.3224 43.9229 21.9813 43.2345 22.1096 42.017C21.86 40.5847 21.0215 39.9268 19.594 40.0434ZM25.5237 40.0434C24.6009 40.3474 24.0918 41.0052 23.9963 42.017C24.1299 43.255 24.8037 43.9435 26.0178 44.0825C27.0095 43.9561 27.6384 43.4053 27.9045 42.4301C27.9337 40.7924 27.1401 39.9968 25.5237 40.0434Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.1799 53.0786C17.6474 53.0786 19.1149 53.0786 20.5823 53.0786C20.5823 53.7517 20.5823 54.425 20.5823 55.0981C19.1149 55.0981 17.6474 55.0981 16.1799 55.0981C16.1799 54.425 16.1799 53.7517 16.1799 53.0786Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.7151 53.0786C26.1825 53.0786 27.65 53.0786 29.1174 53.0786C29.1174 53.7517 29.1174 54.425 29.1174 55.0981C27.65 55.0981 26.1825 55.0981 24.7151 55.0981C24.7151 54.425 24.7151 53.7517 24.7151 53.0786Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.2503 53.0786C34.7177 53.0786 36.1852 53.0786 37.6526 53.0786C37.6526 53.7517 37.6526 54.425 37.6526 55.0981C36.1852 55.0981 34.7177 55.0981 33.2503 55.0981C33.2503 54.425 33.2503 53.7517 33.2503 53.0786Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.7854 53.0786C43.2528 53.0786 44.7203 53.0786 46.1878 53.0786C46.1878 53.7517 46.1878 54.425 46.1878 55.0981C44.7203 55.0981 43.2528 55.0981 41.7854 55.0981C41.7854 54.425 41.7854 53.7517 41.7854 53.0786Z"
          fill={frameColor}
        />
      </g>
      <rect
        x="33"
        y="43"
        width="50"
        height="50"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="44%"
        height="42px"
        style={{ borderRadius: "3px" }}
        x="37"
        y="47"
      >
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}
        >
          {urlQr ? <img src={urlQr} width="100%" height="auto" /> : children}
        </div>
      </foreignObject>
      <foreignObject width="100%" height="18px" y="99">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}
        >
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
