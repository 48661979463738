import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const TicketFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <g>
        <title>Layer 1</title>
        <rect
          stroke={frameBackgroundColor}
          strokeWidth="38"
          id="svg_487"
          height="752.99999"
          width="753.99997"
          y="283.80001"
          x="72.99999"
          fill="#ffffff"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          stroke="null"
          id="svg_9"
          fill={frameColor}
          d="m899.99875,100l0.00125,0l0,1000l-0.00125,0c-55.22875,0 -100,44.77125 -100,100l-699.99975,0c0,-55.22875 -44.771,-100 -99.999,-100l0,-1000c55.228,-0.00062 99.999,-44.77187 99.999,-100l699.99975,0c0,55.2285 44.77125,100 100,100zm-59.995,170l-780.00012,0l0,780l780.00012,0l0,-780z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
        <g
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(6.25794 0 0 6.25794 0 190)"
          stroke="null"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="svg_460"
          x="0"
          y="5"
          height="260"
          width="100%">
          <style>
            {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
          </style>
          <rect
            id="svg_461"
            fill="none"
            strokeWidth="3px"
            height="100%"
            width="100%"
            y="0"
            x="0"
          />
          <text
            id="svg_462"
            fontFamily="Nunito Sans"
            fontSize={fontSize}
            fill={textColor}
            textAnchor="middle"
            dominantBaseline="middle"
            y="50%"
            x="50%">
            {text}
          </text>
        </svg>
      </g>
    </svg>
  );
};
