import React from "react";
import { Area } from "react-easy-crop";

import Cropper from "./Cropper";
import { Button } from "@/components/Button";
import ImageCropperSettings from "./ImageCropperSettings";

import {
  CONFIRM_BUTTON_DEFAULT_TEXT,
  IMAGE_MAX_ZOOM,
  IMAGE_MIN_ZOOM,
  getCroppedImage,
} from "./utils";

import { Flip, ImageCropperProps } from "./types";

import "./styles.scss";

export const ImageCropper = (props: ImageCropperProps) => {
  const {
    image,
    aspectRatio = 4 / 3,
    base64 = false,
    getFile = false,
    fileType,
    buttonConfirmText,
    onCroppedImage,
    onError,
  } = props;

  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>(undefined);
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [flip, setFlip] = React.useState<Flip>({
    horizontal: false,
    vertical: false,
  });

  const onGetImage = React.useCallback(async () => {
    if (!croppedAreaPixels) {
      return;
    }
    try {
      const croppedImage = await getCroppedImage({
        imageSrc: image,
        croppedAreaPixels,
        rotation,
        flip,
        base64,
        getFile,
        fileType,
      });

      if (onCroppedImage) {
        onCroppedImage(croppedImage);
      }
    } catch (error) {
      onError && onError({ message: "genericError", type: "error" });
    }
  }, [croppedAreaPixels, flip, rotation, getFile]);

  return (
    <div
      data-testid="image-cropper"
      className="image-cropper">
      <div className="image-cropper__cropper">
        <Cropper
          dataTestId="cropper"
          image={image}
          aspectRatio={aspectRatio}
          onChangeCroppedArea={setCroppedAreaPixels}
          onChangeZoom={setZoom}
          onChangeRotation={setRotation}
          minZoom={IMAGE_MIN_ZOOM}
          maxZoom={IMAGE_MAX_ZOOM}
          zoom={zoom}
          flip={flip}
          rotation={rotation}
        />
      </div>
      <div className="image-cropper__settings">
        <ImageCropperSettings
          dataTestId="cropper-settings"
          zoom={zoom}
          rotation={rotation}
          onChangeZoom={setZoom}
          onChangeRotation={setRotation}
          onFlipHorizontal={() => setFlip({ ...flip, horizontal: !flip.horizontal })}
          onFlipVertical={() => setFlip({ ...flip, vertical: !flip.vertical })}
        />
      </div>
      <Button
        data-testid="cropper-save-button"
        secondary
        label={buttonConfirmText || CONFIRM_BUTTON_DEFAULT_TEXT}
        className="image-cropper"
        onClick={onGetImage}
      />
    </div>
  );
};
