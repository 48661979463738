import { CroppedImageProps } from "./types";

export const CONFIRM_BUTTON_DEFAULT_TEXT = "Save image";

export const IMAGE_MIN_ZOOM = 1;
export const IMAGE_MAX_ZOOM = 10;
export const IMAGE_ZOOM_STEP = 0.1;
export const IMAGE_ROTATION_STEP = 5;

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export const getCroppedImage = async (props: CroppedImageProps): Promise<string | Blob> => {
  const {
    imageSrc,
    croppedAreaPixels,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
    base64 = false,
    getFile,
    fileType = "image/jpeg",
  } = props;

  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotationRadius = getRadianAngle(rotation);

  const { width: gridWidth, height: gridHeight } = rotateSize(image.width, image.height, rotation);

  canvas.width = gridWidth;
  canvas.height = gridHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(gridWidth / 2, gridHeight / 2);
  ctx.rotate(rotationRadius);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = croppedAreaPixels.width;
  croppedCanvas.height = croppedAreaPixels.height;

  croppedCtx.drawImage(
    canvas,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  // As a Base64 string
  if (base64) return croppedCanvas.toDataURL(fileType);

  // As a Blob
  return new Promise((resolve) => {
    croppedCanvas.toBlob((file) => {
      if (getFile) {
        resolve(file);
      }
      resolve(URL.createObjectURL(file));
    }, fileType);
  });
};

export const convertStringAspectRatioToNumber = (aspectRatio: string): number => {
  const parts = aspectRatio.split("/");

  if (parts.length === 2) {
    const numerator = parseFloat(parts[0]);
    const denominator = parseFloat(parts[1]);

    if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
      const result = numerator / denominator;
      return result;
    } else {
      return 1;
    }
  } else {
    return 1;
  }
};
