import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const asianFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } =
    props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" stroke="#BFBFBF" />
      <ellipse cx="48" cy="65" rx="33" ry="32" fill={frameBackgroundColor} />
      <g opacity="0.933">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.5372 22.0195C75.467 21.9674 81.396 22.0196 87.3239 22.1759C88.359 22.74 88.775 23.626 88.5721 24.8339C88.775 26.0419 88.359 26.9279 87.3239 27.4919C81.396 27.6483 75.467 27.7004 69.5372 27.6483C69.5372 25.772 69.5372 23.8958 69.5372 22.0195Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.0059 22.3322C54.3507 22.3322 60.6959 22.3322 67.0408 22.3322C67.0408 24 67.0408 25.6678 67.0408 27.3356C47.2644 27.2631 27.5014 26.8982 7.75164 26.2411C7.40511 25.3851 7.3531 24.4991 7.59561 23.583C8.13661 23.3489 8.70869 23.1925 9.31188 23.114C22.2629 22.957 35.1609 22.6964 48.0059 22.3322Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.5372 28.5864C75.3631 28.5343 81.1878 28.5865 87.0119 28.7428C88.1774 29.3056 88.6976 30.2437 88.5721 31.5572C88.6976 32.8706 88.1774 33.8088 87.0119 34.3716C81.1878 34.5279 75.3631 34.58 69.5372 34.5279C69.5372 32.5474 69.5372 30.5669 69.5372 28.5864Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.0059 29.2119C54.3507 29.2119 60.6959 29.2119 67.0408 29.2119C67.0408 30.7754 67.0408 32.339 67.0408 33.9025C47.2644 33.83 27.5014 33.4652 7.75163 32.808C7.40765 32.0572 7.35565 31.2755 7.59561 30.4627C8.1366 30.2285 8.70868 30.0722 9.31187 29.9936C22.2629 29.8366 35.1609 29.576 48.0059 29.2119Z"
          fill={frameColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.0492 34.2152C24.6091 34.2089 31.1621 34.4174 37.7083 34.8406C25.1194 40.3262 18.2543 49.9681 17.1131 63.7662C17.6889 74.6704 22.3696 83.2699 31.1553 89.5648C44.9859 97.4413 57.8319 96.086 69.6932 85.4995C79.773 74.2029 81.3332 61.7987 74.3739 48.2871C70.5451 42.5231 65.5002 38.1451 59.2396 35.1533C60.3839 35.0491 61.5278 34.9449 62.6721 34.8406C68.187 34.9945 73.6999 35.203 79.2107 35.4661C89.9857 48.4871 92.6902 63.1324 87.3239 79.4017C86.2773 81.656 85.1852 83.8972 84.0474 86.125C81.4247 90.0047 78.356 93.4965 74.842 96.6007C62.5242 105.903 48.8983 108.301 33.9637 103.793C30.8695 102.515 27.8531 101.056 24.9143 99.4151C23.1671 97.8081 21.2948 96.349 19.2974 95.0372C6.44692 81.772 2.91037 66.2407 8.68778 48.4435C9.73445 46.1892 10.8266 43.9481 11.9643 41.7202C13.2457 40.4357 14.2859 38.9764 15.0848 37.3423C16.0313 36.238 17.0195 35.1956 18.0492 34.2152Z"
          fill={frameColor}
        />
      </g>
      <foreignObject
        width="44%"
        height="41px"
        style={{ borderRadius: "3px" }}
        x="27"
        y="42"
      >
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",

            backgroundColor: `${frameBackgroundColor}`,
          }}
        >
          {urlQr ? <img src={urlQr} width="100%" height="auto" /> : children}
        </div>
      </foreignObject>
      <foreignObject width="100%" height="18px" y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}
        >
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
