import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const MuseumFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <g>
        <title>Layer 1</title>
        <path
          xmlns="http://www.w3.org/2000/svg"
          stroke={frameBackgroundColor}
          fill="#ffffff"
          strokeWidth="39"
          d="m92.00002,95.47413l713.99991,0l0,723.9258l-713.99991,0l0,-723.9258z"
          id="svg_928"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          stroke="null"
          id="svg_10"
          fill={frameColor}
          d="m0,914.28625l0,-914.28625l900,0l0,914.28625l-900,0zm30.87037,-895.23862l838.25963,0l-34.82125,38.09525l-768.617,0l-34.82137,-38.09525zm0,876.18987l34.8215,-38.095l768.61687,0l34.82125,38.095l-838.25963,0zm794.12963,-819.047l-750,0l0,761.9045l750,0l0,-761.9045z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
        <g
          id="svg_8"
          transform="matrix(6.13963 0 0 6.13963 3.24696 22.7919)"
          stroke="null">
          <g
            id="svg_16"
            stroke="null"
            dangerouslySetInnerHTML={{ __html: qrSvg }}></g>
        </g>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="260"
          x="0"
          y="940">
          <style>
            {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
          </style>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            strokeWidth="3px"
            fill="none"
          />
          <text
            x="50%"
            y="55%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={textColor}
            fontFamily="Nunito Sans"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </g>
    </svg>
  );
};
