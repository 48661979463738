import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const shoppingFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="84"
      height="96"
      viewBox="0 0 84 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0129935 25.5044C-0.275576 19.7907 4.2793 15 10.0003 15H72.9115C78.6523 15 83.2145 19.8227 82.8961 25.5547L78.5072 104.555C78.2128 109.854 73.8299 114 68.5226 114H13.9902C8.66337 114 4.27158 109.824 4.00289 104.504L0.0129935 25.5044Z"
        fill={frameColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.882 30C54.6932 30 59.2806 25.0635 58.8552 19.2678L58.121 9.26785C57.7375 4.04312 53.3867 1.66943e-06 48.1479 1.21144e-06L34.2907 0C29.0799 -4.55536e-07 24.7425 4.00126 24.3231 9.19508L23.5156 19.1951C23.0455 25.0163 27.6429 30 33.4831 30L48.882 30ZM47.18 25C50.6691 25 53.4224 22.0343 53.1635 18.5548L52.5682 10.5548C52.3352 7.42246 49.7258 5 46.5848 5L35.8089 5C32.685 5 30.0837 7.39703 29.8289 10.5106L29.1742 18.5106C28.8881 22.0056 31.6474 25 35.1542 25L47.18 25Z"
        fill={frameColor}
      />

      <foreignObject
        width="77%"
        height="64px"
        x="8"
        y="25">
        <div
          id="#qrCode"
          style={{
            border: `4px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: "white",
            paddingLeft: "1px",
            paddingTop: "1px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="90">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
