import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const BoardFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
      </style>
      <g
        id="svg_13"
        stroke="null">
        <path
          d="M16.25,137.87096548080444 C16.25,120.8702044708252 30.031720417900086,107.08870792388916 47.03225755691528,107.08870792388916 L852.96772813797,107.08870792388916 C869.9684891479492,107.08870792388916 883.7499856948853,120.8702044708252 883.7499856948853,137.87096548080444 L883.7499856948853,1167.677400112152 C883.7499856948853,1184.6803998317719 869.9684891479492,1198.4596576690674 852.96772813797,1198.4596576690674 L47.03225755691528,1198.4596576690674 C30.031720417900086,1198.4596576690674 16.25,1184.6803998317719 16.25,1167.677400112152 L16.25,137.87096548080444 z"
          fill={frameColor}
          strokeWidth="2.5"
          id="svg_2"
          stroke="#404040"></path>
        <rect
          x="91.80645036697388"
          y="216.22580289840698"
          width="716.3870849609375"
          height="716.3870849609375"
          fill="#ffffff"
          id="svg_3"
          stroke={frameBackgroundColor}
          strokeWidth="37"></rect>
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 145)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="1105.399969914556"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
        <path
          d="M237.3225769996643,107.08870792388916 C237.3225769996643,77.72411324682236 261.12677660713194,53.91935396194458 290.4919309616089,53.91935396194458 L609.5080547332764,53.91935396194458 C638.8732090877533,53.91935396194458 662.677408695221,77.72411324682236 662.677408695221,107.08870792388916 C662.677408695221,136.4538622783661 638.8732090877533,160.25806188583374 609.5080547332764,160.25806188583374 L290.4919309616089,160.25806188583374 C261.12677660713194,160.25806188583374 237.3225769996643,136.4538622783661 237.3225769996643,107.08870792388916 z"
          fill="#404040"
          id="svg_11"
          stroke="null"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M449.9999928474426,107.08870792388916 C479.362908492279,107.08870792388916 503.1693468093872,83.28394863901138 503.1693468093872,53.91935396194458 C503.1693468093872,24.554759284877775 479.362908492279,0.75 449.9999928474426,0.75 C420.63707720260624,0.75 396.83063888549805,24.554759284877775 396.83063888549805,53.91935396194458 C396.83063888549805,83.28394863901138 420.63707720260624,107.08870792388916 449.9999928474426,107.08870792388916 zM449.9999928474426,80.50403094291687 C464.68145066986085,80.50403094291687 476.5846698284149,68.60181920370103 476.5846698284149,53.91935396194458 C476.5846698284149,39.23688872018815 464.68145066986085,27.33467698097229 449.9999928474426,27.33467698097229 C435.3185350250244,27.33467698097229 423.41531586647034,39.23688872018815 423.41531586647034,53.91935396194458 C423.41531586647034,68.60181920370103 435.3185350250244,80.50403094291687 449.9999928474426,80.50403094291687 z"
          fill="#404040"
          id="svg_12"
          stroke="null"></path>
      </g>
    </svg>
  );
};
