import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const BrochureFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 8 0.5 L 88 0.5 C 92.142188 0.5 95.5 3.857813 95.5 8 L 95.5 120 C 95.5 124.142188 92.142188 127.5 88 127.5 L 8 127.5 C 3.857813 127.5 0.5 124.142188 0.5 120 L 0.5 8 C 0.5 3.857813 3.857813 0.5 8 0.5 Z M 8 0.5 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />

        <path
          fill="none"
          fillRule="evenodd"
          strokeWidth="3.57079"
          strokeLinecap="round"
          stroke={frameColor}
          strokeOpacity="1"
          d="M 30.35875 83.08375 L 14.125 29.028125 C 13.900625 28.282187 13.980625 27.47875 14.347188 26.792813 C 14.714063 26.106562 15.33875 25.595938 16.080313 25.37125 C 24.770312 22.744688 51.20625 14.7525 59.894375 12.124688 C 60.6375 11.899688 61.43875 11.981563 62.1225 12.349375 C 62.80625 12.7175 63.315313 13.3425 63.539375 14.088437 C 64.120937 16.025 64.790312 18.251875 64.790312 18.251875 M 81.61125 28.02875 C 81.6075 27.250937 81.293125 26.50625 80.741875 25.959063 C 80.189063 25.412188 79.443125 25.10875 78.667812 25.114062 C 69.593438 25.17375 41.983438 25.354688 32.90875 25.412813 C 32.133437 25.418125 31.39125 25.731875 30.845938 26.286563 C 30.300937 26.84125 29.998125 27.588438 30.00375 28.3675 C 30.074063 39.246563 30.320312 77.20625 30.392188 88.085 C 30.395938 88.863125 30.710313 89.6075 31.261563 90.154688 C 31.814375 90.701563 32.560625 91.005313 33.335625 91 C 42.410625 90.941563 70.020313 90.760937 79.095 90.70125 C 79.87 90.695937 80.6125 90.381875 81.1575 89.827188 C 81.702813 89.274375 82.005625 88.525625 82 87.74625 C 81.929688 76.867188 81.683437 38.907812 81.61125 28.02875 Z M 81.61125 28.02875 "
          transform="matrix(12.5,0,0,12.5,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 387.671875 355.429688 C 387.578125 341.640625 398.664062 330.382812 412.449219 330.257812 L 981.96875 325.222656 C 995.796875 325.097656 1007.09375 336.222656 1007.1875 350.046875 L 1012.328125 1099.828125 C 1012.421875 1113.703125 1001.203125 1125 987.328125 1125 L 417.808594 1125 C 404.070312 1125 392.90625 1113.914062 392.8125 1100.171875 Z M 387.671875 355.429688 "
        />

        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="600"
          y="1300.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
