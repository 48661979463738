export enum ActionTypes {
  // Action to select a plan during the signup or upgrade process.
  SELECT_PLAN = "SELECT_PLAN",
  // Action to navigate after unauthorized access.
  UNAUTHORIZED_ACCESS = "UNAUTHORIZED_ACCESS",
  // Action to navigate after login in the editor.
  EDITOR_LOGIN = "EDITOR_LOGIN",
  // Action to save data of logged users.
  SAVE_DATA_OF_LOGGED_USERS = "SAVE_DATA_OF_LOGGED_USERS",
  // Action to navigate after clicking the next button in the editor.
  ON_NEXT_BUTTON = "ON_NEXT_BUTTON",
  // Action to handle preview modal after coming from the login page.
  PREVIEW_MODAL = "PREVIEW_MODAL",
  // Action to toggle preview after coming from the login page.
  TOGGLE_PREVIEW = "TOGGLE_PREVIEW",
  // Action when we want to edit a QR code after coming from the login page.
  EDIT_QR = "EDIT_QR",
}
