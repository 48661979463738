import React from "react";
import { TbFlipHorizontal, TbFlipVertical } from "react-icons/tb";
import { MdOutlineRotate90DegreesCw, MdOutlineRotate90DegreesCcw } from "react-icons/md";

import { Button } from "@/components/Button";
import { Slider } from "@/components/Slider";
import { Typography } from "@/components/Typography";

import { IMAGE_MIN_ZOOM, IMAGE_ZOOM_STEP, IMAGE_MAX_ZOOM, IMAGE_ROTATION_STEP } from "./utils";

import { ImageCropperSettingsProps } from "./types";

import "./styles.scss";

export default function ImageCropperSettings(props: ImageCropperSettingsProps) {
  const {
    zoom,
    rotation,
    dataTestId,
    onChangeZoom,
    onChangeRotation,
    onFlipHorizontal,
    onFlipVertical,
  } = props;

  const rotateForward = () => {
    let nextRotation = Math.ceil((rotation + 1) / 90) * 90;
    if (nextRotation > 360) {
      nextRotation = 90;
    }

    onChangeRotation(nextRotation);
  };

  const rotateBackward = () => {
    let prevRotation = Math.floor((rotation - 1) / 90) * 90;
    if (prevRotation < 0) {
      prevRotation = 270;
    }

    onChangeRotation(prevRotation);
  };

  return (
    <React.Fragment>
      <div
        data-testid={dataTestId}
        className="image-cropper__settings__sliders">
        <Slider
          title="Zoom"
          defaultValue={zoom}
          onChange={onChangeZoom}
          min={IMAGE_MIN_ZOOM}
          step={IMAGE_ZOOM_STEP}
          max={IMAGE_MAX_ZOOM}
          className="image-cropper__settings__sliders--zoom"
        />
        <Slider
          title="Rotate"
          defaultValue={rotation}
          onChange={onChangeRotation}
          step={IMAGE_ROTATION_STEP}
          max={360}
          className="image-cropper__settings__sliders--rotate"
        />
      </div>
      <div className="image-cropper__settings__buttons">
        <div>
          <Typography Tag="p">Flip</Typography>
          <div className="image-cropper__settings__buttons--rotate">
            <Button
              type="button"
              square
              tertiary
              extralarge
              onClick={onFlipVertical}
              icon={<TbFlipHorizontal />}
            />
            <Button
              type="button"
              square
              tertiary
              extralarge
              onClick={onFlipHorizontal}
              icon={<TbFlipVertical />}
            />
          </div>
        </div>
        <div>
          <Typography Tag="p">Rotation</Typography>
          <div className="image-cropper__settings__buttons--flip">
            <Button
              type="button"
              square
              extralarge
              tertiary
              onClick={rotateForward}
              icon={<MdOutlineRotate90DegreesCw />}
            />
            <Button
              type="button"
              square
              extralarge
              tertiary
              onClick={rotateBackward}
              icon={<MdOutlineRotate90DegreesCcw />}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
