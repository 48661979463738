import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const partyhatFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.7813 29C84.4852 29.2983 85.1891 29.5966 85.8931 29.8949C87.8565 31.7393 88.1379 33.8306 86.7373 36.169C85.9615 37.1581 84.9507 37.7677 83.7049 37.9977C83.9573 47.1162 84.1669 56.2351 84.3336 65.3543C83.8823 66.9479 82.8409 67.7521 81.2094 67.7669C74.082 64.7465 66.9547 61.7262 59.8273 58.7058C59.1846 58.0182 58.855 57.2039 58.8386 56.2628C59.081 55.6909 59.3234 55.1189 59.5657 54.547C66.3222 48.5022 72.9573 42.3359 79.4712 36.0479C78.2596 33.9749 78.4588 32.0354 80.0687 30.2292C80.3827 29.8651 80.7432 29.8103 81.1504 30.0648C81.232 30.2696 81.2454 30.4829 81.1906 30.7046C79.1685 32.9396 79.3992 34.9056 81.8828 36.6028C84.2897 37.1915 85.8169 36.2818 86.4645 33.8737C86.4433 31.8564 85.4343 30.6244 83.4378 30.1778C82.7887 30.3716 82.2142 30.2578 81.7141 29.8366C81.788 29.6034 81.9032 29.3927 82.0598 29.2046C82.6374 29.0726 83.2112 29.0044 83.7813 29Z"
        fill={frameColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.1504 30.0648C81.3576 30.027 81.5455 29.951 81.7141 29.8366C82.2142 30.2578 82.7887 30.3716 83.4378 30.1778C85.4343 30.6244 86.4433 31.8564 86.4645 33.8737C85.8169 36.2818 84.2897 37.1915 81.8828 36.6028C79.3992 34.9056 79.1685 32.9396 81.1906 30.7046C81.2454 30.4829 81.232 30.2696 81.1504 30.0648Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.4186 38.7157C78.7602 38.9942 78.8903 39.3607 78.8089 39.8153C78.3395 41.6444 77.25 42.1947 75.5404 41.4662C76.5542 40.6015 77.5136 39.6847 78.4186 38.7157Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.4966 46.3601C82.7447 46.4767 82.8428 46.674 82.7907 46.9518C82.8191 49.7847 82.8901 52.6173 83.0041 55.4495C80.3469 55.0521 78.9632 53.4796 78.8533 50.7324C79.0932 48.4386 80.3078 46.9812 82.4966 46.3601Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.5303 44.1606C73.6219 45.4741 73.8757 46.9569 73.2918 48.6091C71.4729 51.6854 69.0598 52.2975 66.0525 50.4455C66.0204 50.2762 66.0763 50.1442 66.2203 50.0495C68.3857 48.1462 70.489 46.1833 72.5303 44.1606Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.1268 62.5076C83.2008 63.3465 83.2325 64.1903 83.2218 65.0389C83.0066 66.0158 82.4063 66.5138 81.4209 66.5332C80.8226 66.3574 80.2414 66.1371 79.677 65.8721C79.9607 65.2638 80.1996 64.6386 80.3941 63.9963C80.2354 63.5138 79.9273 63.3832 79.4702 63.6048C79.144 64.1913 78.8609 64.7978 78.6211 65.4246C77.8732 65.1077 77.1253 64.7907 76.3774 64.4738C76.661 63.8655 76.8999 63.2403 77.0944 62.598C76.9391 62.1221 76.6531 62.0009 76.2365 62.2344C75.8563 62.7692 75.5513 63.3666 75.3214 64.0263C74.6176 63.728 73.9135 63.4297 73.2096 63.1314C73.5237 62.5074 73.7408 61.8728 73.8607 61.2276C73.7478 60.8393 73.4806 60.6742 73.0587 60.7321C72.7138 61.3626 72.4121 62.0132 72.1537 62.6839C71.4058 62.367 70.6579 62.05 69.9099 61.7331C70.1935 61.1248 70.4325 60.4996 70.627 59.8573C70.4682 59.3747 70.1602 59.2442 69.7031 59.4657C69.3768 60.0522 69.0939 60.6588 68.854 61.2856C68.1061 60.9686 67.3582 60.6517 66.6103 60.3347C66.8938 59.7264 67.1328 59.1013 67.3273 58.4589C67.1685 57.9764 66.8605 57.8459 66.4034 58.0674C66.0771 58.6539 65.7942 59.2604 65.5543 59.8873C64.8064 59.5703 64.0585 59.2534 63.3106 58.9364C63.5941 58.3281 63.8331 57.7029 64.0276 57.0606C63.9165 56.6863 63.6711 56.5304 63.2916 56.593C62.9467 57.2236 62.645 57.8742 62.3866 58.5449C61.7598 58.3051 61.1532 58.0221 60.5668 57.6958C59.9068 56.8988 59.9098 56.0958 60.5758 55.2865C61.1049 54.7514 61.6519 54.2566 62.2168 53.8022C69.2143 56.6127 76.1844 59.5144 83.1268 62.5076Z"
        fill="white"
      />
      <rect
        x="19"
        y="29.6416"
        width="50"
        height="50"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="45%"
        height="42px"
        style={{ borderRadius: "3px" }}
        x="23"
        y="33">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="86">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
