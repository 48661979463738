import React from "react";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import { QRFRameProps } from "../types";

import "../styles.scss";

export const buttonFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="72"
      height="96"
      viewBox="0 0 72 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 1.79086 1.79086 0 4 0H68C70.2091 0 72 1.79086 72 4V68C72 70.2091 70.2091 72 68 72H4C1.79086 72 0 70.2091 0 68V4ZM0 79C0 76.7909 1.79086 75 4 75H68C70.2091 75 72 76.7909 72 79V92C72 94.2091 70.2091 96 68 96H4C1.79086 96 0 94.2091 0 92V79ZM66.9 4.5H4.5V66.9H66.9V4.5Z"
        fill={frameColor}
      />
      <foreignObject
        width="88%"
        height="63px"
        x="4"
        y="4">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "2px",
            backgroundColor: "white",
            paddingTop: "3px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="72">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="14"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
           fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
