import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const CafeFrameToDownload = (props: QRFRameToPrintProps): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
      </style>
      <g id="Layer_1">
        <title>Layer 1</title>
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}
          y="969.99998"
          x="450">
          {text}
        </text>
        <g
          id="svg_3"
          stroke="null">
          <path
            id="svg_4"
            d="m860.55395,394.48995c-21.112,21.11081 -48.74181,34.20264 -78.15987,37.31621l0,-258.2937c29.41805,3.11357 57.04786,16.20541 78.15987,37.31621c24.35476,24.35596 38.03629,57.38757 38.03629,91.83064c0,34.44307 -13.68154,67.47468 -38.03629,91.83064z"
            fill={frameColor}
            strokeWidth="2.28571"
            stroke="#404040"
          />
          <path
            id="svg_5"
            d="m110.84218,39.50687c0,-19.81837 16.06546,-35.88443 35.88443,-35.88443l598.07375,0c19.81897,0 35.88443,16.06606 35.88443,35.88443l0,478.459c0,72.66716 -58.90907,131.57623 -131.57623,131.57623l-406.69015,0c-72.66716,0 -131.57623,-58.90907 -131.57623,-131.57623l0,-478.459z"
            fill={frameColor}
            strokeWidth="2"
            stroke="#404040"
          />
          <path
            id="svg_6"
            d="m3.18891,621.63238c0,-4.40422 3.57026,-7.97472 7.97436,-7.97472l881.16156,0c4.40422,0 7.97472,3.5705 7.97472,7.97472c0,48.44517 -39.27191,87.71709 -87.71709,87.71709l-721.6761,0c-48.44493,0 -87.71744,-39.27191 -87.71744,-87.71709l-0.00001,0z"
            fill="#404040"
            stroke="null"
          />
          <rect
            xmlns="http://www.w3.org/2000/svg"
            id="svg_14"
            x="182.61103"
            y="39.50687"
            width="526.3049"
            height="526.3049"
            fill="#ffffff"
            stroke={frameBackgroundColor}
            strokeWidth="39"
            rx="5"
          />

          <g
            stroke="null"
            transform="matrix(4.32 0 0 4.32 140 10)"
            id="svg_485">
            <g
              dangerouslySetInnerHTML={{ __html: qrSvg }}
              stroke="null"
              id="svg_486"></g>
          </g>
        </g>
      </g>
    </svg>
  );
};
