import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const bucketFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } =
    props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="95" height="127" rx="7.5" stroke="#BFBFBF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.131 37.3217C88.4304 35.9393 88.0865 34.4936 87.1985 33.3918C86.3106 32.29 84.971 31.6501 83.5544 31.6501C68.5637 31.6501 28.673 31.6501 13.6824 31.6501C12.2658 31.6501 10.9262 32.29 10.0382 33.3918C9.15028 34.4936 8.8064 35.9393 9.1058 37.3217C11.6618 49.125 17.513 76.155 19.9305 87.3168C20.5567 90.2115 23.1178 92.2783 26.0811 92.2783C36.5944 92.2783 60.6424 92.2783 71.1557 92.2783C74.1189 92.2783 76.6801 90.2115 77.3063 87.3168C79.7237 76.155 85.5749 49.125 88.131 37.3217Z"
        fill={frameBackgroundColor}
      />
      <path
        d="M12.6501 31.4328C12.6364 31.2189 12.6295 31.0016 12.6295 30.7826C12.6295 25.7493 16.467 21.6637 21.1959 21.6637C22.7972 21.6637 24.2977 22.1325 25.5808 22.9503C25.5706 22.7655 25.5654 22.579 25.5654 22.3925C25.5654 17.3591 29.4029 13.2736 34.1318 13.2736C36.0856 13.2736 37.8871 13.9716 39.3294 15.1453C40.7049 12.0674 43.6545 9.93909 47.0677 9.93909C50.4791 9.93909 53.4287 12.0674 54.8059 15.1453C56.2482 13.9716 58.0497 13.2736 60.0036 13.2736C64.7307 13.2736 68.5699 17.3591 68.5699 22.3925C68.5699 22.579 68.5648 22.7655 68.5528 22.9503C69.8376 22.1325 71.3364 21.6637 72.9395 21.6637C77.6666 21.6637 81.5058 25.7493 81.5058 30.7826C81.5058 31.0016 81.4989 31.2189 81.4836 31.4328M37.1051 18.413C37.1051 18.413 38.9323 13.5661 44.0974 15.4738M58.4294 25.4122C58.4294 25.4122 64.467 24.6184 65.5004 30.7347M87.5125 37.1043C87.8119 35.7219 87.4681 34.2762 86.5801 33.1744C85.6922 32.0726 84.3526 31.4328 82.936 31.4328C67.9453 31.4328 28.0546 31.4328 13.064 31.4328C11.6474 31.4328 10.3078 32.0726 9.41981 33.1744C8.53187 34.2762 8.18799 35.7219 8.48739 37.1043C11.0434 48.9076 16.8946 75.9377 19.3121 87.0994C19.9383 89.9942 22.4994 92.0609 25.4627 92.0609C35.976 92.0609 60.024 92.0609 70.5373 92.0609C73.5005 92.0609 76.0617 89.9942 76.6879 87.0994C79.1053 75.9377 84.9565 48.9076 87.5125 37.1043Z"
        stroke={frameColor}
        stroke-width="3.42174"
        stroke-miterlimit="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <foreignObject
        width="56%"
        height="54px"
        style={{ borderRadius: "3px" }}
        x="21"
        y="34"
      >
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "3px",
            backgroundColor: `${frameBackgroundColor}`,
            paddingTop: "3px",
          }}
        >
          {urlQr ? <img src={urlQr} width="100%" height="auto" /> : children}
        </div>
      </foreignObject>
      <foreignObject width="100%" height="18px" y="102">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}
        >
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
