import React from "react";

export const useDebounce = (debounceValue: any, debounceTime: number) => {
  const [value, setValue] = React.useState(debounceValue);

  React.useEffect(() => {
    const debounceHandler = setTimeout(() => {
      setValue(debounceValue);
    }, debounceTime);

    return () => clearTimeout(debounceHandler);
  }, [debounceValue]);

  return value;
};
