import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const EnvelopeFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
      </style>
      <g
        id="svg_14"
        stroke="null">
        <path
          d="M4.5,1168.8172871203183 L4.5,622.1801246203183 C4.5,613.1995871203185 8.508881249999998,604.6868246203185 15.432693749999999,598.9658621203183 L423.7117875,261.63944962031843 C434.70202500000005,252.55991212031842 450.5531625,252.42997462031843 461.6906625,261.33007462031844 L884.1855375,598.9584371203184 C891.33705,604.6719746203185 895.5,613.3282871203184 895.5,622.4820746203184 L895.5,1168.8172871203183 C895.5,1185.4480496203184 882.018675,1198.9293746203184 865.3879125,1198.9293746203184 L34.612458749999995,1198.9293746203184 C17.98182,1198.9293746203184 4.5,1185.4480496203184 4.5,1168.8172871203183 z"
          fill="#404040"
          strokeWidth="2.43333"
          id="svg_2"
          stroke="#404040"></path>
        <path
          d="M69.035625,35.67937462031841 C69.035625,16.64526337031841 84.46588875,1.2149996203184141 103.5,1.2149996203184141 L796.5,1.2149996203184141 C815.5339875,1.2149996203184141 830.9643749999999,16.64526337031841 830.9643749999999,35.67937462031841 L830.9643749999999,976.1793746203184 C830.9643749999999,995.2133621203184 815.5339875,1010.6437496203184 796.5,1010.6437496203184 L103.5,1010.6437496203184 C84.46588875,1010.6437496203184 69.035625,995.2133621203184 69.035625,976.1793746203184 L69.035625,35.67937462031841 z"
          fill={frameBackgroundColor}
          strokeWidth="2.43"
          id="svg_3"
          stroke="#404040"></path>
        <rect
          x="103.5"
          y="35.67937462031841"
          width="680.635"
          height="680.625"
          fill="#FFFFFF"
          id="svg_4"
          stroke="#FFFFFF"></rect>
        <path
          d="M868.70565,1175.3401496203185 L530.311275,920.5487996203184 C521.9940375,914.2870496203184 522.3900375,901.6756871203185 531.0847125,895.9485371203184 L869.4790875,673.0054871203184 C879.4904625,666.4096121203185 892.8183375,673.5908246203185 892.8183375,685.5784871203184 L892.8183375,1163.3128871203185 C892.8183375,1175.7324371203185 878.627925,1182.8109371203184 868.70565,1175.3401496203185 zM31.293855,678.0185996203184 L369.68872500000003,932.8099496203184 C378.00596249999995,939.0716996203184 377.6099625,951.6830621203184 368.91528750000003,957.4114496203184 L30.52091250000001,1180.3532621203185 C20.50990875,1186.9491371203183 7.181291250000001,1179.7691621203185 7.181291250000001,1167.7802621203184 L7.181291250000001,690.0458621203185 C7.181291250000001,677.6263121203184 21.371827500000002,670.5478121203184 31.293855,678.0185996203184 z"
          fill={frameColor}
          strokeWidth="2.43333"
          id="svg_5"
          stroke="#404040"></path>
        <path
          d="M5.5,1168.2170996203183 L5.5,1121.3777246203183 C5.5,1112.3031371203183 9.43525,1103.6926121203185 16.249915,1097.8565621203184 L424.53234999999995,748.2454871203184 C435.5869375,738.7798496203185 451.67196249999995,738.6449621203185 462.877525,747.9249746203184 L885.369925,1097.8441871203186 C892.4113,1103.6765246203186 896.5,1112.4343121203183 896.5,1121.6858621203185 L896.5,1168.2170996203183 C896.5,1185.1795121203186 883.018675,1198.9293746203184 866.3879125,1198.9293746203184 L35.612458749999995,1198.9293746203184 C18.98182,1198.9293746203184 5.5,1185.1795121203186 5.5,1168.2170996203183 z"
          fill={frameColor}
          strokeWidth="2.43333"
          id="svg_6"
          stroke="#404040"></path>
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="450"
          y="1124.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
