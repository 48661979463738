import { BlackFrameToDownload } from "./black";
import { BoardFrameToDownload } from "./board";
import { ButtonFrameToDownload } from "./button";
import { CafeFrameToDownload } from "./cafe";
import { EnvelopeFrameToDownload } from "./envelope";
import { MobileFrameToDownload } from "./mobile";
import { MuseumFrameToDownload } from "./museum";
import { ReceiptFrameToDownload } from "./receipt";
import { ShoppingFrameToDownload } from "./shopping";
import { TicketFrameToDownload } from "./ticket";
import { WhiteFrameToDownload } from "./white";
import { EyelashesFrameToDownload } from "./eyelashes";
import { HairsalonFrameToDownload } from "./hairsalon";
import { BeautyFrameToDownload } from "./beauty";
import { CareFrameToDownload } from "./care";
import { WaiterFrameToDownload } from "./waiter";
import { BucketFrameToDownload } from "./bucket";
import { TakeawayFrameToDownload } from "./takeaway";
import { CupFrameToDownload } from "./cup";
import { BrochureFrameToDownload } from "./brochure";
import { AsianFrameToDownload } from "./asian";
import { SodaFrameToDownload } from "./soda";
import { MenuFrameToDownload } from "./menu";
import { RestaurantFrameToDownload } from "./restaurant";
import { WineFrameToDownload } from "./wine";
import { HeadphonesFrameToDownload } from "./headphones";
import { MicFrameToDownload } from "./mic";
import { ConcertFrameToDownload } from "./concert";
import { VideogameFrameToDownload } from "./videogame";
import { MovieFrameToDownload } from "./movie";
import { MusicFrameToDownload } from "./music";
import { Mp3FrameToDownload } from "./mp3";
import { FilmFrameToDownload } from "./film";
import { HandFrameToDownload } from "./hand";
import { LikeFrameToDownload } from "./like";
import { ChatFrameToDownload } from "./chat";
import { StarsFrameToDownload } from "./stars";
import { ReviewFrameToDownload } from "./review";
import { Ticket2FrameToDownload } from "./ticket2";
import { WeddingFrameToDownload } from "./wedding";
import { ChristmasFrameToDownload } from "./christmas";
import { PartyFrameToDownload } from "./party";
import { TicketsFrameToDownload } from "./tickets";
import { CelebrationFrameToDownload } from "./celebration";
import { PartyhatFrameToDownload } from "./partyhat";
import { BalloonsFrameToDownload } from "./balloons";
import { GarlandFrameToDownload } from "./garland";
import { BouquetFrameToDownload } from "./bouquet";
import { GiftboxFrameToDownload } from "./giftbox";
import { CardsFrameToDownload } from "./cards";
import { ShoppingbagFrameToDownload } from "./shoppingbag";
import { CartFrameToDownload } from "./cart";

export {
  BlackFrameToDownload,
  BoardFrameToDownload,
  ButtonFrameToDownload,
  CafeFrameToDownload,
  EnvelopeFrameToDownload,
  MobileFrameToDownload,
  MuseumFrameToDownload,
  ReceiptFrameToDownload,
  ShoppingFrameToDownload,
  TicketFrameToDownload,
  WhiteFrameToDownload,
  EyelashesFrameToDownload,
  HairsalonFrameToDownload,
  BeautyFrameToDownload,
  CareFrameToDownload,
  WaiterFrameToDownload,
  BucketFrameToDownload,
  TakeawayFrameToDownload,
  CupFrameToDownload,
  BrochureFrameToDownload,
  AsianFrameToDownload,
  SodaFrameToDownload,
  MenuFrameToDownload,
  RestaurantFrameToDownload,
  WineFrameToDownload,
  HeadphonesFrameToDownload,
  MicFrameToDownload,
  ConcertFrameToDownload,
  VideogameFrameToDownload,
  MovieFrameToDownload,
  MusicFrameToDownload,
  Mp3FrameToDownload,
  FilmFrameToDownload,
  HandFrameToDownload,
  LikeFrameToDownload,
  ChatFrameToDownload,
  StarsFrameToDownload,
  ReviewFrameToDownload,
  Ticket2FrameToDownload,
  WeddingFrameToDownload,
  ChristmasFrameToDownload,
  PartyFrameToDownload,
  TicketsFrameToDownload,
  CelebrationFrameToDownload,
  PartyhatFrameToDownload,
  BalloonsFrameToDownload,
  GarlandFrameToDownload,
  BouquetFrameToDownload,
  GiftboxFrameToDownload,
  CardsFrameToDownload,
  ShoppingbagFrameToDownload,
  CartFrameToDownload,
};