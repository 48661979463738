import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const sodaFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M24.8558 21.2343C24.9515 21.0788 25.0254 20.911 25.0756 20.7355L26.5754 15.4859C26.8255 14.6107 27.635 14.0148 28.545 14.0359L48.5 14.5L66.8524 14.0294C67.5631 14.0112 68.23 14.3716 68.6042 14.9761L74.2111 24.0333C74.4002 24.3388 74.5035 24.6896 74.5102 25.0488L75.9872 104.313C75.9955 104.758 75.8548 105.194 75.5875 105.55L73.0853 108.886C73.0285 108.962 72.9664 109.034 72.8995 109.101L68.5858 113.414C68.2107 113.789 67.702 114 67.1716 114H31.4721C31.1616 114 30.8554 113.928 30.5777 113.789L26.3271 111.664C26.1108 111.555 25.916 111.409 25.7519 111.231L20.5405 105.586C20.1934 105.21 20.0037 104.715 20.0103 104.203L20.9929 28.0521C20.9975 27.6908 21.1 27.3375 21.2894 27.0297L24.8558 21.2343Z"
        fill={frameBackgroundColor}
      />
      <path
        d="M29.5411 111.98L48.0003 112.399L66.4819 111.98C66.4819 111.98 66.8308 111.516 67.1788 111.075C67.457 110.704 67.7586 110.309 68.1074 109.984C68.6641 109.45 69.3617 109.101 70.1271 108.683C71.6829 107.895 73.7258 106.849 73.7258 103.924V29.5543C73.7258 28.8124 72.774 27.164 71.7061 25.3068C70.0575 22.45 68.1769 19.1766 68.1769 16.2741C68.1769 16.042 68.2234 15.8099 68.3161 15.5999H27.6837C27.7764 15.8099 27.8228 16.042 27.8228 16.2741C27.8228 19.1766 25.9431 22.45 24.2936 25.3068C23.2258 27.164 22.274 28.8124 22.274 29.5543V103.924C22.274 106.849 24.3176 107.895 25.8727 108.683C26.6622 109.101 27.3589 109.45 27.9156 109.984C28.2644 110.309 28.5427 110.704 28.8452 111.075C29.17 111.516 29.5186 111.98 29.5411 111.98ZM47.9771 115.672L29.4722 115.255C27.8933 115.208 27.0341 114.117 26.2439 113.072C26.0359 112.793 25.8263 112.514 25.6639 112.352C25.4552 112.143 24.944 111.888 24.3865 111.609C22.0652 110.402 19 108.823 19 103.924V29.5543C19 27.976 20.1614 25.9555 21.4612 23.6807C22.7147 21.5214 24.1312 19.0833 24.4792 17.1094C24.1312 16.7151 23.9225 16.2042 23.9225 15.6466V14.5791C23.9225 13.976 24.1785 13.3951 24.5961 12.9996C24.9904 12.582 25.5711 12.3276 26.1975 12.3276H69.8255C70.4528 12.3276 71.0095 12.582 71.4278 12.9996C71.8221 13.3951 72.0781 13.976 72.0781 14.5791V15.6466C72.0781 16.2042 71.8685 16.7151 71.5437 17.1094C71.8917 19.0833 73.2852 21.5214 74.5386 23.6807C75.8394 25.9555 77 27.976 77 29.5543V103.924C77 108.823 73.9586 110.402 71.6365 111.609C71.0798 111.888 70.5687 112.143 70.3599 112.352C70.1967 112.514 69.9879 112.793 69.7561 113.072C68.9665 114.117 68.1074 115.208 66.5516 115.255L47.9771 115.672Z"
        fill={frameColor}
      />
      <foreignObject
        width="100%"
        height="18px"
        y="25">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
      <foreignObject
        width="47%"
        height="45px"
        style={{ borderRadius: "3px" }}
        x="25"
        y="45">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",

            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
    </svg>
  );
};
