import React from "react";

const PDF_MAX_FILE_SIZE = 20000;
const IMAGE_MAX_FILE_SIZE = 15000;
const VIDEO_MAX_FILE_SIZE = 100000;
const AUDIO_MAX_FILE_SIZE = 50000;
const KILOBYTE_SIZE = 1024;
export const PDF_TYPE = "application/pdf";
export const IMAGE_TYPE = "image/*";
export const VIDEO_TYPE = "video/*";
export const AUDIO_TYPE = "video/*,audio/mpeg,audio/mp3,audio/wav,audio/ogg,audio/webm,audio/x-wav,audio/aac,audio/midi";

export const fileExceedSize = (file: any) => {
  const fileSizeKB = Number((file.size / KILOBYTE_SIZE).toFixed(2));
  if (
    (file.type === "application/pdf" && fileSizeKB > PDF_MAX_FILE_SIZE) ||
    (file.type.includes("image") && fileSizeKB > IMAGE_MAX_FILE_SIZE) ||
    (file.type.includes("video") && fileSizeKB > VIDEO_MAX_FILE_SIZE) ||
    (file.type.includes("audio") && fileSizeKB > AUDIO_MAX_FILE_SIZE)
  ) {
    return {
      errorName: "maxSizeExceeded",
      errorMessage:
        file.type === "application/pdf"
          ? "_pdfMaxSizeExceeded"
          : file.type.includes("image")
          ? "_imageMaxSizeExceeded"
          : file.type.includes("audio")
          ? "_mp3MaxSizeExceeded"
          : "_videoMaxSizeExceeded",
    };
  }
  return false;
};

const getInvalidFileTypeErrorMessage = (uploadedFileType: string) => {
  return uploadedFileType === "pdf"
    ? "_pdfInvalidFormat"
    : uploadedFileType === "image"
    ? "_imageInvalidFormat"
    : uploadedFileType === "video"
    ? "_videoInvalidFormat"
    : "_audioInvalidFormat";
}

export const invalidFileType = (uploadedFileType: string, file: File) => {
  if (
    (uploadedFileType === "pdf" && file.type !== PDF_TYPE) ||
    (uploadedFileType === "image" && !file.type.includes("image/")) ||
    (uploadedFileType === "video" && !file.type.includes("video/")) ||
    (uploadedFileType === "audio" && !file.type.includes("audio/"))
  ) {
    return {
      errorName: "invalidFileFormat",
      errorMessage: getInvalidFileTypeErrorMessage(uploadedFileType),
    };
  }

  return false;
};

export const getFileSizeInBytes = (file: string) => {
  const fileBlob = new Blob([file]);

  const fileSizeBytes = fileBlob.size;

  return fileSizeBytes;
};

export const fileRequireToBeChunked = (file: string, maxSize: number) => {
  const fileSizeBytes = getFileSizeInBytes(file);

  const fileSizeKB = Number((fileSizeBytes / KILOBYTE_SIZE).toFixed(2));

  return fileSizeKB >= maxSize;
};

export const base64ToBlob = async (base64Data: string) => {
  const data = await fetch(base64Data);
  const blob = data.blob();

  return blob;
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const svgToBase64 = (svgString: string, canvasSize: number): Promise<string> => {
  const svgBuffer = new TextEncoder().encode(svgString);
  const base64SVG = btoa(String.fromCharCode(...new Uint8Array(svgBuffer)));

  return new Promise<string>((resolve, reject) => {
    const image = new Image();

    image.src = "data:image/svg+xml;base64," + base64SVG;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = canvasSize;
      canvas.height = canvasSize;

      context.drawImage(image, 0, 0);

      const pngDataUrl = canvas.toDataURL("image/png");
      resolve(pngDataUrl);
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
};

export const fileToBase64 = async (file: File) => {
  const fileReader = new FileReader();

  const promise = new Promise<string>((resolve) => {
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
  });

  fileReader.readAsDataURL(file);

  return await promise;
};

export const InvalidVideoFormatToast = () => {
  return (
    <div className="invalid-provider-toast">
      Incorrect video format. Supported formats: <span>.mp4, .h264, .mov, .mkv, .hevc, .ogg </span>{" "}
      and
      <span> .webm .</span>
    </div>
  );
};

export const InvalidAudioFormatToast = () => {
  return (
    <div className="invalid-provider-toast">
      Incorrect audio format. Supported formats: <span>.mp3, .ogg </span>{" "}
      and
      <span> .wav .</span>
    </div>
  );
};

export const InvalidProviderToast = () => (
  <div className="invalid-provider-toast">
    Invalid link. Supported links:{" "}
    <span>YouTube, Facebook, SoundCloud, Vimeo, Wistia, Mixcloud, DailyMotion </span> and
    <span> Twitch.</span>
  </div>
);

export const InvalidYTProviderToast = () => (
  <div className="invalid-provider-toast">
    Invalid link. Supported link: <span>YouTube</span>
  </div>
);
