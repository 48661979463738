import React from "react";
import EasyCropper, { Area, Point } from "react-easy-crop";

import { CropperProps } from "./types";

export default function Cropper(props: CropperProps) {
  const {
    image,
    aspectRatio,
    zoom,
    rotation,
    minZoom,
    maxZoom,
    flip,
    dataTestId,
    onChangeRotation,
    onChangeZoom,
    onChangeCroppedArea,
  } = props;

  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    onChangeCroppedArea(croppedAreaPixels);
  };

  return (
    <EasyCropper
      data-testid={dataTestId}
      image={image}
      crop={crop}
      rotation={rotation}
      zoom={zoom}
      minZoom={minZoom}
      maxZoom={maxZoom}
      cropShape={aspectRatio === "profile" ? "round" : "rect"}
      aspect={aspectRatio === "profile" ? 1 : aspectRatio}
      onCropChange={setCrop}
      onRotationChange={onChangeRotation}
      onCropComplete={onCropComplete}
      onZoomChange={onChangeZoom}
      transform={[
        `translate(${crop.x}px, ${crop.y}px)`,
        `rotate(${rotation}deg)`,
        `scaleY(${flip.vertical ? -zoom : zoom})`,
        `scaleX(${flip.horizontal ? -zoom : zoom})`,
      ].join(" ")}
    />
  );
}
