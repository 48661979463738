import { FrameType } from "@components/QRFrame/types";

const defaultFrameColor = "#404040";
const defaultWhiteColor = "#ffffff";
const defaultBlackColor = "#000000";
const whiteTextColorFrames = ["mobile", "black", "ticket", "button", "shopping", "ticket2"];
const whiteColorFrames = ["cafe", "board", "envelope", "receipt"];
export const oldFrames = [
  "none",
  "mobile",
  "black",
  "white",
  "button",
  "ticket",
  "museum",
  "cafe",
  "shopping",
  "board",
  "envelope",
  "receipt",
];
const bigFrames = ["beauty", "care", "cup", "brochure", "asian", "menu", "wine"];
const mediumFrames = [
  "takeaway",
  "soda",
  "restaurant",
  "headphones",
  "mic",
  "concert",
  "videogame",
  "movie",
  "music",
  "mp3",
  "film",
  "hand",
  "like",
  "chat",
  "stars",
  "review",
  "ticket2",
  "christmas",
  "party",
  "celebration",
  "partyhat",
  "balloons",
  "garland",
  "bouquet",
  "giftbox",
  "cards",
  "shoppingbag",
  "cart",
];

const smallQrSize = ["restaurant", "film"];
const mediumQrSize = [
  "wine",
  "headphones",
  "mic",
  "videogame",
  "movie",
  "music",
  "like",
  "chat",
  "stars",
  "review",
  "ticket2",
  "wedding",
  "christmas",
  "tickets",
  "celebration",
  "partyhat",
  "bouquet",
  "cards",
  "shoppingbag",
  "cart",
];

export const qrHeight = (frameStyle): number => {
  if (bigFrames.includes(frameStyle)) return 1600;
  if (mediumFrames.includes(frameStyle)) return 1333;
  else return 1200;
};

export const qrWidth = (frameStyle): number => {
  if (bigFrames.includes(frameStyle)) return 1200;
  if (mediumFrames.includes(frameStyle)) return 1000;
  else return 900;
};

export const getqrCodeSize = (frameStyle) => {
  if (oldFrames.includes(frameStyle))
    return {
      width: 100,
      height: 100,
    };
  if (smallQrSize.includes(frameStyle))
    return {
      width: 43,
      height: 43,
    };
  if (mediumQrSize.includes(frameStyle))
    return {
      width: 71,
      height: 71,
    };
  else
    return {
      width: 81,
      height: 81,
    };
};

export const isBlackTextColor = (frameType: FrameType) => !whiteTextColorFrames.includes(frameType);

export const isWhiteFrameColor = (frameType: FrameType) => whiteColorFrames.includes(frameType);

export const validateFrameTextColor = (params) => {
  const { frameColor, frameTextColor, frameStyle } = params;

  if (frameColor === defaultFrameColor || frameColor === defaultWhiteColor) {
    if (isBlackTextColor(frameStyle)) {
      return frameTextColor === defaultWhiteColor ? defaultBlackColor : frameTextColor;
    } else {
      return frameTextColor === defaultBlackColor ? defaultWhiteColor : frameTextColor;
    }
  } else return frameTextColor;
};

export const validateFrameColor = (params) => {
  const { frameColor, frameStyle } = params;
  const isDefaultColor = frameColor === defaultFrameColor || frameColor === defaultWhiteColor;

  if (isWhiteFrameColor(frameStyle) && isDefaultColor) return defaultWhiteColor;

  return frameColor === defaultFrameColor || frameColor === defaultWhiteColor
    ? defaultFrameColor
    : frameColor;
};

export const setDefaultFrameTextColor = (params) => {
  const { frameColor, selectedFrameStyle, frameTextColorInput, frameTextColorPicker } = params;

  if (frameColor === defaultFrameColor || frameColor === defaultWhiteColor) {
    if (isBlackTextColor(selectedFrameStyle)) {
      if (frameTextColorInput.value.toLocaleLowerCase() === defaultWhiteColor) {
        frameTextColorInput.value = defaultBlackColor;
        frameTextColorPicker.setAttribute("style", "background-color: rgb(0, 0, 0);");
      }
    } else {
      if (frameTextColorInput.value === defaultBlackColor) {
        frameTextColorInput.value = defaultWhiteColor.toUpperCase();
        frameTextColorPicker.setAttribute("style", "background-color: rgb(255, 255, 255);");
      }
    }
  }
};
export const setDefaultFrameColor = (params) => {
  const { selectedFrameStyle, frameColorInput, frameColorPicker } = params;

  if (isWhiteFrameColor(selectedFrameStyle)) {
    if (frameColorInput.value.toLowerCase() === defaultFrameColor) {
      frameColorInput.value = defaultWhiteColor.toUpperCase();
      frameColorPicker.setAttribute("style", "background-color: rgb(255, 255, 255);");
    }
  } else {
    if (frameColorInput.value.toLowerCase() === defaultWhiteColor) {
      frameColorInput.value = defaultFrameColor;
      frameColorPicker.setAttribute("style", "background-color: rgb(64, 64, 64);");
    }
  }
};

export const displayFrameDesign = (selectedFrameStyle: string) => {
  const designFrameContainer = document.getElementsByClassName("designFrame")[0] as HTMLElement;
  return selectedFrameStyle !== "none"
    ? (designFrameContainer.style.display = "block")
    : (designFrameContainer.style.display = "none");
};

export const handleFrameSelectedSection = (data, qrData, className) => {
  const isFrameSelected = data.find((tabs) =>
    tabs.content.options.find((frame) => frame === qrData.qrStyle.frameStyle)
  );

  getFrameSectionClass(className, isFrameSelected.id);

  return isFrameSelected;
};

export const getFrameSectionClass = (className, id) => {
  const contentDivs = Array.from(
    document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>
  );

  for (let i = 0; i < contentDivs.length; i++) {
    const div = contentDivs[i];
    div.id === `section-${id}` ? (div.style.display = "block") : (div.style.display = "none");
  }
};
