"use client";
import React, { useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { InputProps } from "./types";

import { FormValidationMethods } from "../Form/types";

import { ConnectForm } from "@/utils/formHelper";
import { isClient } from "@/utils/browser";
import { InputTooltipHelper } from "@/utils/inputTooltipHelper";

import "./styles.scss";
import { ColorPicker } from "../ColorPicker";

export const Input = ({
  id,
  dataQA,
  label,
  type,
  placeholder,
  errorMessage,
  error,
  success,
  customTag,
  disabled,
  defaultValue,
  className,
  title,
  helper,
  name,
  colorPicker,
  colorPickerWithDebounce = false,
  noMb,
  onChange,
}: InputProps) => {
  const methods: FormValidationMethods = useFormContext();
  const classnames = classNames(`Input`, {
    [`Input--${className}`]: className,
    "Input--error": error,
    "Input--success": type !== "password" && success,
    "Input--disabled": disabled,
    "Input--colorPicker": colorPicker !== undefined,
    "Input--noMb": noMb,
  });

  const [color, setColor] = useState(defaultValue || colorPicker);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const Tag: any = customTag ? customTag : "input";

  const onHandleColorChange = (color: string) => {
    setColor(color);
    const input = document.getElementById(id);
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      isClient() && window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input, color);

    const ev2 = new Event("input", { bubbles: true });
    input.dispatchEvent(ev2);
    methods && methods.trigger(id);
  };

  React.useEffect(() => {
    setColor(defaultValue);
  }, [colorPicker]);

  const tooltipHelper = helper && typeof helper !== "string";

  return (
    <div
      data-testid="Input"
      data-qa={dataQA}
      className={classnames}>
      <div className="Input__label-container">
        {label && (
          <label
            data-testid="Input__label"
            htmlFor={id}
            className="Input__label">
            {label || ""}
          </label>
        )}

        {tooltipHelper && InputTooltipHelper({ helper, id })}
      </div>

      <div className="Input__wrapper">
        <ConnectForm>
          <Tag
            data-testid="Input__element"
            className="Input__element"
            id={id}
            title={title}
            type={passwordVisible ? "text" : type}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => (onChange ? onChange(e) : colorPicker && setColor(e))}
            autoComplete="off"
            defaultValue={defaultValue}
          />
        </ConnectForm>
        {type === "password" && (
          <span
            onClick={() => setPasswordVisible(!passwordVisible)}
            className="Input__passwordToggle"
            data-testid="Input__passwordToggle">
            {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
          </span>
        )}
        {colorPicker !== undefined && (
          <ColorPicker
            id={id}
            color={color}
            withDebounce={colorPickerWithDebounce}
            onChange={(color) => onHandleColorChange(color)}
          />
        )}{" "}
      </div>
      {error && (
        <p
          data-testid="Input__error-message"
          className="Input__error-message body-s">
          <FiAlertCircle />
          {errorMessage as string}
        </p>
      )}
      {helper && !tooltipHelper && <span className="Input__helper">{helper as string}</span>}
    </div>
  );
};
