import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const headphonesFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="96"
      height="128"
      viewBox="0 0 96 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="95"
        height="127"
        rx="7.5"
        stroke="#BFBFBF"
      />
      <path
        d="M78.9381 32.8966C71.7691 23.8329 60.5761 18 48.0001 18C35.4235 18 24.2307 23.8329 17.062 32.8966L17.0638 32.898C16.5788 33.5105 16.2894 34.2795 16.2894 35.1127C16.2894 35.5985 16.3877 36.0624 16.5657 36.4862C13.1962 41.5869 11.0747 47.5585 10.627 53.982H14.5532C14.9889 48.3895 16.8424 43.1867 19.7608 38.7209C19.8276 38.7244 19.8948 38.7263 19.9625 38.7263C21.1381 38.7263 22.1878 38.178 22.8609 37.328L22.8627 37.3294C28.6874 29.9644 37.7818 25.2251 48.0001 25.2251C58.2178 25.2251 67.3127 29.9644 73.1371 37.3294L73.139 37.328C73.8121 38.178 74.8621 38.7263 76.038 38.7263C76.1055 38.7263 76.1726 38.7244 76.2392 38.7209C79.1577 43.1867 81.0114 48.3895 81.4473 53.982H85.3725C84.925 47.5587 82.8037 41.5872 79.4344 36.4865C79.6125 36.0627 79.7109 35.5986 79.7109 35.1127C79.7109 34.2794 79.4214 33.5104 78.9364 32.898L78.9381 32.8966Z"
        fill={frameColor}
      />
      <path
        d="M14.5449 81.4235L14.5449 53.982L10.627 53.982V54.7811C8.52291 55.4908 7 57.4603 7 59.763V76.1601C7 79.0545 9.40757 81.4235 12.3508 81.4235H14.5449Z"
        fill={frameColor}
      />
      <path
        d="M19.7991 54.473C19.7991 53.3859 18.8945 52.4966 17.7895 52.4966C16.6843 52.4966 15.7798 53.3859 15.7798 54.473V81.4496C15.7798 82.5365 16.6843 83.4255 17.7895 83.4255C18.8945 83.4255 19.7991 82.5365 19.7991 81.4496V54.473Z"
        fill={frameColor}
      />
      <path
        d="M89 59.763C89 57.4602 87.4769 55.4906 85.3724 54.781L85.3725 53.982L81.4546 53.982V81.4235H83.6487C86.5923 81.4235 89 79.0545 89 76.1601V59.763Z"
        fill={frameColor}
      />
      <path
        d="M76.2008 54.473C76.2008 53.3859 77.1057 52.4966 78.2107 52.4966C79.3158 52.4966 80.22 53.3859 80.22 54.473V81.4496C80.22 82.5365 79.3158 83.4255 78.2107 83.4255C77.1057 83.4255 76.2008 82.5365 76.2008 81.4496V54.473Z"
        fill={frameColor}
      />
      <rect
        x="23"
        y="42"
        width="50"
        height="50"
        rx="2"
        fill={frameBackgroundColor}
      />
      <foreignObject
        width="46%"
        height="44px"
        style={{ borderRadius: "3px" }}
        x="26"
        y="45">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            /*    paddingTop: "2px",
            paddingBottom: "3px", */
            backgroundColor: `${frameBackgroundColor}`,
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="96">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="11"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="sans-serif"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
