import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const HeadphonesFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none">
      <style>
        {`
              @font-face {
                font-family: 'Nunito Sans';
                src: url(${uriText});
                font-weight: bold
              }
            `}
      </style>
      <g id="surface1">
        <path
          fillRule="nonzero"
          fill="white"
          fillOpacity="1"
          strokeWidth="1"
          stroke="#BFBFBF"
          d="M 7.999875 0.5 L 88.000125 0.5 C 92.142 0.5 95.500125 3.857839 95.500125 8 L 95.500125 120 C 95.500125 124.142161 92.142 127.5 88.000125 127.5 L 7.999875 127.5 C 3.858 127.5 0.499875 124.142161 0.499875 120 L 0.499875 8 C 0.499875 3.857839 3.858 0.5 7.999875 0.5 Z M 7.999875 0.5 "
          transform="matrix(10.416667,0,0,10.414062,0,0)"
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 822.273438 342.585938 C 747.59375 248.199219 631 187.453125 500 187.453125 C 368.996094 187.453125 252.402344 248.199219 177.730469 342.585938 L 177.746094 342.601562 C 172.695312 348.980469 169.679688 356.988281 169.679688 365.664062 C 169.679688 370.726562 170.707031 375.554688 172.558594 379.96875 C 137.460938 433.089844 115.363281 495.277344 110.699219 562.171875 L 151.597656 562.171875 C 156.132812 503.929688 175.441406 449.75 205.839844 403.242188 C 206.539062 403.277344 207.238281 403.296875 207.941406 403.296875 C 220.1875 403.296875 231.121094 397.589844 238.132812 388.734375 L 238.152344 388.75 C 298.828125 312.050781 393.558594 262.695312 500 262.695312 C 606.433594 262.695312 701.175781 312.050781 761.84375 388.75 L 761.863281 388.734375 C 768.875 397.589844 779.8125 403.296875 792.0625 403.296875 C 792.765625 403.296875 793.464844 403.277344 794.160156 403.242188 C 824.558594 449.75 843.867188 503.929688 848.410156 562.171875 L 889.296875 562.171875 C 884.636719 495.277344 862.539062 433.089844 827.441406 379.972656 C 829.296875 375.558594 830.320312 370.726562 830.320312 365.664062 C 830.320312 356.988281 827.304688 348.980469 822.253906 342.601562 Z M 822.273438 342.585938 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 151.507812 847.949219 L 151.507812 562.171875 L 110.699219 562.171875 L 110.699219 570.492188 C 88.78125 577.882812 72.917969 598.394531 72.917969 622.375 L 72.917969 793.136719 C 72.917969 823.277344 97.996094 847.949219 128.652344 847.949219 Z M 151.507812 847.949219 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 206.242188 567.285156 C 206.242188 555.964844 196.816406 546.703125 185.308594 546.703125 C 173.792969 546.703125 164.371094 555.964844 164.371094 567.285156 L 164.371094 848.222656 C 164.371094 859.539062 173.792969 868.796875 185.308594 868.796875 C 196.816406 868.796875 206.242188 859.539062 206.242188 848.222656 Z M 206.242188 567.285156 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 927.082031 622.375 C 927.082031 598.394531 911.21875 577.882812 889.296875 570.492188 L 889.296875 562.171875 L 848.484375 562.171875 L 848.484375 847.949219 L 871.339844 847.949219 C 902.003906 847.949219 927.082031 823.277344 927.082031 793.136719 Z M 927.082031 622.375 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameColor}
          d="M 793.757812 567.285156 C 793.757812 555.964844 803.183594 546.703125 814.695312 546.703125 C 826.207031 546.703125 835.625 555.964844 835.625 567.285156 L 835.625 848.222656 C 835.625 859.539062 826.207031 868.796875 814.695312 868.796875 C 803.183594 868.796875 793.757812 859.539062 793.757812 848.222656 Z M 793.757812 567.285156 "
        />

        <path
          stroke="none"
          fillRule="nonzero"
          fill={frameBackgroundColor}
          d="M 260.417969 437.390625 L 739.582031 437.390625 C 751.089844 437.390625 760.417969 446.714844 760.417969 458.21875 L 760.417969 937.265625 C 760.417969 948.769531 751.089844 958.09375 739.582031 958.09375 L 260.417969 958.09375 C 248.910156 958.09375 239.582031 948.769531 239.582031 937.265625 L 239.582031 458.21875 C 239.582031 446.714844 248.910156 437.390625 260.417969 437.390625 Z M 260.417969 437.390625 "
        />
        <g
          stroke="null"
          transform="matrix(6.2212 0 0 6.2212 0 -55)"
          id="svg_485">
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"></g>
        </g>
        <text
          x="500"
          y="1100.3999700784689"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={textColor}
          fontFamily="Nunito Sans"
          style={{ fontSize }}>
          {text}
        </text>
      </g>
    </svg>
  );
};
